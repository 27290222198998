import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import RespondPage from "../ServiceProvider/Requests/RespondsPage";
import "./appbar.scss";
import HomeIcon from "@mui/icons-material/Home";
import {
  BrowserRouter,
  Outlet,
  Route,
  Router,
  Routes,
  useNavigate,
} from "react-router-dom";
import { Icon } from "@mui/material";
import vendorMenudata from "../End-User/User-Data/VendorMenuItems";
import { Home } from "@mui/icons-material";
import { Vendor_Path } from "../CompServices/Api_Routes_Helper";
import DraftsIcon from "@mui/icons-material/Drafts";
import DraftPage from "../ServiceProvider/Requests/DraftPage";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Sidebar() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const navigate = useNavigate();
  const handleNavigate = (route) => {
    navigate(route);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} className="Appbar_style">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <i className="fas fa-angle-double-right" aria-hidden="true"></i>
          </IconButton>
          <Typography
            variant="h5"
            noWrap
            component="div"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
              cursor: "pointer",
            }}
            onClick={() => navigate("/vendorManagement")}
          >
            QuoteUrPrice
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open} className="sidebar_style">
        <DrawerHeader>
          <Typography
            variant="h5"
            noWrap
            component="div"
            sx={{
              marginRight: "25px",
            }}
            onClick={() => navigate("/vendorManagement")}
          >
            QuoteUrPrice
          </Typography>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <i className="fas fa-angle-double-left" aria-hidden="true"></i>
            ) : (
              <i className="fas fa-angle-double-left" aria-hidden="true"></i>
            )}
          </IconButton>
        </DrawerHeader>
        {/* <div>
          {open ? (
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <i className="fas fa-angle-double-left" aria-hidden="true" ></i>
              ) : (
                <i className="fas fa-angle-double-left" aria-hidden="true" ></i>
              )}
            </IconButton>
          ) : (
            
            <i className="fas fa-angle-double-right" aria-hidden="true" onClick={handleDrawerOpen}></i>
          )}
        </div> */}
        <Divider />
        <List>
          {vendorMenudata.map((text) => (
            <>
              <ListItem
                key={text.id}
                disablePadding
                sx={{ display: "block" }}
                // onClick={() => navigate("/vendorManagement")}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <Icon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                    onClick={() => navigate(text.path)}
                  >
                    {text.id % 2 !== 0 ? <HomeIcon /> : <DraftsIcon />}
                  </Icon>

                  <ListItemText
                    primary={text.name}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            </>
          ))}
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1 }}>
        <Outlet />
      </Box>
    </Box>
  );
}
