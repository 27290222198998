import React from "react";
import { Page, Document, StyleSheet, Image } from "@react-pdf/renderer";
import peermelogo from "../../../../assets/peermelogo.png";
import InvoiceTitle from "./InvoiceTitle";
import InvoiceNo from "./InvoiceNo";
import BillTo from "./BillTo";
import InvoiceThankYouMsg from "./InvoiceThankYou";
import InvoiceItemsTable from "./InvoiceItemTable";
import TermsTable from "./InvoicetermTable";
import InvoiceNotesTerms from "./InvoiceNotesTerms";
import InvoiceThankQuotation from "./InvoiceThankQuotaion";
import { Divider } from "@mui/material";
import InvoiceSubject from "./InvoiceSubject";
import SpecialNoteTable from "./InvoiceSpecialNoteTable";

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
    fontFamily: "Helvetica",
    fontSize: 11,
    paddingTop: 30,
    paddingLeft: 50,
    paddingRight: 50,
    lineHeight: 1.5,
    flexDirection: "column",
  },
  logo: {
    width: 84,
    height: 70,
    marginLeft: "auto",
    marginRight: "auto",
  },
});

const PdfDocument = ({
  invoicedata,
  invoicedatas,
  Clientinvoicedata,
  ClientDetails,
  tableValue,
}) => {
  return (
    <Document>
      {console.log("Clientinvoicedata", invoicedata)}
      <Page size="A4" style={styles.page}>
        <Image style={styles.logo} src={peermelogo} />
        <InvoiceTitle title={"Quotation"} />
        <InvoiceNo invoice={invoicedata} ClientInvoice={Clientinvoicedata} />
        <BillTo invoice={invoicedata} ClientDetails={ClientDetails} />
        <InvoiceSubject
          invoice={invoicedata}
          ClientDetails={Clientinvoicedata}
        />
        {invoicedata ? <TermsTable invoice={invoicedata} /> : null}
        {invoicedata ? <SpecialNoteTable invoice={invoicedata} /> : null}

        <InvoiceThankQuotation
          invoice={invoicedata}
          ClientDetails={ClientDetails}
        />
        <InvoiceItemsTable invoice={invoicedatas} ClientInvoice={tableValue} />
        {/* <InvoiceNotesTerms />
        <InvoiceThankYouMsg /> */}
      </Page>
    </Document>
  );
};

export default PdfDocument;
