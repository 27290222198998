import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  invoiceNoContainer: {
    flexDirection: "row",
    marginTop: 36,
    justifyContent: "flex-end",
  },
  invoiceDateContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  invoiceDate: {
    fontSize: 12,
    fontStyle: "bold",
    width: 110,
  },
  label: {
    width: 80,
  },
});

function formatDate(isoDateString) {
  const date = new Date(isoDateString);
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  return date.toLocaleDateString("en-US", options);
}
const InvoiceNo = ({ invoice, ClientInvoice }) => (
  <Fragment>
    {console.log("invoice", invoice)}
    {invoice ? (
      <>
        <View style={styles.invoiceNoContainer}>
          <Text style={styles.label}>Quotation No:</Text>
          <Text style={styles.invoiceDate}>{invoice[0].invoiceNumber}</Text>
        </View>
        <View style={styles.invoiceDateContainer}>
          <Text style={styles.label}>Date: </Text>
          <Text style={styles.invoiceDate}>
            {formatDate(invoice[0].createdAt)}
          </Text>
        </View>
      </>
    ) : null}
    {ClientInvoice ? (
      <>
        <View style={styles.invoiceNoContainer}>
          <Text style={styles.label}>Quotation No:</Text>
          <Text style={styles.invoiceDate}>{ClientInvoice.invoiceNumber}</Text>
        </View>
        <View style={styles.invoiceDateContainer}>
          <Text style={styles.label}>Date: </Text>
          <Text style={styles.invoiceDate}>
            {formatDate(ClientInvoice.createdAt)}
          </Text>
        </View>
      </>
    ) : null}
  </Fragment>
);

export default InvoiceNo;
