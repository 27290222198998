import React, { useState, useEffect, useCallback, useMemo } from "react";
import axios from "../../../../../api/axios";
import authHeader from "../../../../../Services/auth-header";
import {
  GET_RFQS_RESPONSES_VID,
  RFQ_REQ_GETLINEITEM,
} from "../../../../CompServices/Api_Routes_Helper";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";
import MDBox from "../../Dcomponents/MDBox";
import MDTypography from "../../Dcomponents/MDTypography";
import DataTableBase from "../../../../ReuseTable/DataTable";
import { Container, IconButton, Tooltip } from "@mui/material";
import boxShadow from "../../assets/theme/functions/boxShadow";
import { Visibility } from "@mui/icons-material";
import VendorResponses from "../../../Responses/VendorResponses";
import Moment from "react-moment";
import { FaEye } from "react-icons/fa";
import LoadingBars from "../../../../PageLoader/LoadingBars";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
const style = {
  // position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

const Tables = () => {
  const [resData, setResData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [open, setOpen] = useState(false);
  const [resId, setResId] = useState("");
  const [rfqResponse, setRfqResponse] = useState([]);
  //show or hide approved column
  const [isApproved, setIsApproved] = useState(false);
  const v_id = localStorage.getItem("v_ID");
  const { vid, role } = JSON.parse(v_id);
  const [resDataa, setResDataa] = useState();

  const handleClose = () => setOpen(false);

  const fetchRequests = useCallback(async () => {
    if (!vid) {
      return;
    }
    const { data } = await axios.get(`${GET_RFQS_RESPONSES_VID}${vid}`, {
      headers: await authHeader(),
    });
    if (data !== null) {
      const timeout = setTimeout((data) => {
        setLoading(false);
      }, 500);
      setResData(data.data);
      return () => clearTimeout(timeout);
    }
  }, [vid]);
  // useEffect hook to fetch the initial request data
  useEffect(() => {
    let isMounted;
    isMounted = true;

    fetchRequests();
    return () => {
      isMounted = false;
      setLoading(false);
    };
  }, [fetchRequests]);

  // Handler to open the modal and fetch response information
  const handleOpen = (e, id: any) => {
    e.preventDefault();
    setOpen(true);
    setResId(id);
    const request = resData.find((item) => item._id === id);
    if (request) {
      setRfqResponse(request);
    }
  };
  const columns = React.useMemo(() => {
    const basecolumns = [
      {
        name: "Subject",
        selector: (row) => row.subject,
        sortable: true,
        maxWidth: "350px",
        cell: (row) => <div className="table-sub-des-width">{row.subject}</div>,
      },
      {
        name: "description",
        selector: (row) => row.description,
        sortable: true,
        maxWidth: "400px",
        cell: (row) => (
          <div className="table-sub-des-width">{row.description}</div>
        ),
      },
      {
        name: "Date",
        selector: (row) => row.createdAt,
        sortable: true,
        maxWidth: "165px",
      },
      {
        name: "CreatedAt",
        selector: (row) => row.createdTime,
        maxWidth: "165px",
        sortable: true,
      },
      {
        name: "Action",
        selector: (row) => {
          return row.action;
        },
        maxWidth: "248px",
      },
      {
        name: "isApproved",
        selector: (row) => {
          return row.isApproved;
        },
        maxWidth: "150px",
        omit: true,
      },
    ];
    return basecolumns;
  }, []);
  let data = [];
  if (resData) {
    data = resData.map((item) => {
      if (item) {
        const createdTime = item.createdAt
          ? new Date(item.createdAt).toLocaleTimeString()
          : null;

        return {
          subject: item.mainRFQ?.subject,
          description: item.mainRFQ?.description,
          createdAt: <Moment format="DD/MM/YYYY">{item.createdAt}</Moment>,
          // createdDate: format(new Date(item.createdAt)),
          createdTime: createdTime ? createdTime : "--NA--",
          // status: item.status,
          action: (
            <div className="btn_space_bw">
              <Tooltip title="View">
                <IconButton
                  onClick={(event) => handleOpen(event, item._id)}
                  aria-describedby="show detailed view"
                >
                  <Visibility />
                </IconButton>
              </Tooltip>
            </div>
          ),
          isApproved: item.isApproved,
        };
      }
    });
  }

  const customStyles = {
    rows: {
      style: {
        minHeight: "55px",
        maxWidth: "100%",
        minWidth: "100%",
        textOverflow: "ellipsis",
      },
    },
    columns: {
      style: {
        maxWidth: "230px",
      },
    },
    headCells: {
      style: {
        textAlign: "center",
        color: "royalblue",
        fontSize: "14px",
        fontWeight: "600",
        paddingLeft: "8px",
        paddingRight: "8px",
        textTransform: "capitalize",
        fontFamily: "Poppins",
      },
    },
    cells: {
      style: {
        paddingLeft: "5px",
        paddingRight: "5px",
        width: "auto",
        whiteSpace: "wrap",
        overflow: "auto",
        maxWidth: "170px",
        textOverflow: "ellipsis",
        wordBreak: "break-word",
        flexGrow: 0,
        backgroundColor: "none",
        fontSize: "14px",
        fontWeight: "600",
        position: "relative",
        boxShadow: "inset 0 -1px 0 0 rgba(100,121,143,0.122)",
        backgroundColor: "rgba(0,0,0,0.09)",
      },
    },
  };

  const conditionalRowStyles = [
    {
      when: (row) => row.isApproved === "Approved",
      style: {
        backgroundColor: "rgb(79 198 42 / 66%)",
        boxShadow: "inset 0 -1px 0 0 rgba(100,121,143,0.122)",
        display: "-webkit-box",
        display: "-webkit-flex",
        display: "flex",
        position: "relative",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
  ];

  return (
    <DashboardLayout>
        <DashboardNavbar />
   <div className="vendor-container">
      <MDBox pt={4} pb={6}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                // mx={2}
                // mt={-3}
                // py={3}
                // px={2}
                style={{ padding: "25px 9px 13px 20px" }}
                // variant="gradient"

                // bgColor="info"
                bgColor=" rgba(69, 67, 67, 0.7490196078)"
                borderRadius="md"
                coloredShadow="light"
              >
                <MDTypography variant="h6" color="white">
                  RFQ Response Table
                </MDTypography>
              </MDBox>
              <DataTableBase
                columns={columns}
                data={data}
                // data={resData.length > 0 ? reversedData : resData}
                customStyles={customStyles}
                responsive={true}
                conditionalRowStyles={conditionalRowStyles}
                progressPending={loading}
                progressComponent={<LoadingBars />}
              />
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-response-info"
        aria-describedby="modal-response-description"
        style={style}
      >
        <MDBox variant="contained" className="response_container">
          <Container className="response_Cont_wrapper" maxWidth={"lg"}>
            <Grid container spacing={6}>
              <Card sx={{ height: "100%", boxShadow: !boxShadow && "none" }}>
                <div>
                  <h3 className="h3_div_res" style={{ padding: 0, margin: 0 }}>
                    <span style={{ fontSize: "18px" }}>Response Created</span>
                    <i className="fa fa-close" onClick={handleClose}></i>
                  </h3>
                </div>
                <div
                  style={{ width: "100%", height: "77vh", overflow: "scroll" }}
                >
                  <VendorResponses rowid={resId} />
                </div>
              </Card>
            </Grid>
          </Container>
        </MDBox>
      </Modal>
    </div>
    </DashboardLayout>
  );
};

export default Tables;
