const vendorMenudata = [
  {
    id: 1,
    name: "Home",
    icon: "HomeIcon",
  },
  // {
  //   id: 2,
  //   name: "Mail",
  //   icon: "MaiIcon",
  // },
  {
    id: 2,
    name: "Draft",
    icon: "DraftIcon",
    path: "/vendorManagement/draft",
  },
  //   {
  //     id: 3,
  //     name: "HelpCenter",
  //     icon:"HelpCenterIcon"
  //   },
];
module.exports = vendorMenudata;
