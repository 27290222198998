import React, { useState, useEffect } from "react";
import "./crad.css";
import video from "../../video/cover_video.mp4";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
// import { VENDOR_USERNAME } from "../CompServices/Api_Routes_Helper";
// import axios from "../../api/axios";
const Clientcard= () => {
  // const [vendorData, setVendorData] = useState([]);
  // console.log("vendorData", vendorData);
  // const lastVendor =
  //   vendorData.length > 0 ? vendorData[vendorData.length - 1] : null;
  // const lastvendorUsername = lastVendor ? lastVendor.username : null;
  // console.log("Last Vendor Data:", lastvendorUsername);

  // useEffect(() => {
   
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.get(`${VENDOR_USERNAME}`);
  //       if (response.status === 200) {
  //         setVendorData(response.data.data);
  //       } else {
  //         console.error("Error fetching data");
  //       }
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };

   
  //   fetchData();
  // }, []);
  const data = [
    {
      title: "Stick to your budget",
      details:
        "Find the right service for every price point. No hourly rates, just project-based pricing.",
    },
    {
      title: "Get quality work done quickly",
      details:
        "Hand your project over to a talented freelancer in minutes, get long-lasting results.",
    },
    {
      title: "Count on 24/7 support",
      details:
        "Our round-the-clock support team is available to help anytime, anywhere.",
    },
  ];
  return (
    <section className="addmoredesign">
      <h4 className="guide-heading">Guides to help you grow</h4>
      <div className="cardview">
        <div className="video-card">
          <div className="thumbnail">
          <video
                    // width={332}
                    // height={590}
                    autoPlay={true}
                    preload="auto"
                    loop
                    muted={true}
                    className="left213"
                    // className="video-grid"
                  >
                    <source src={video} type="video/mp4" />
                  </video>
          </div>
        </div>
        <div className="right21">
          <h1>The best part? Everything.</h1>
          <div className="author">
            <img
              src="https://randomuser.me/api/portraits/men/95.jpg"
              alt="Author"
            />

            <h2 className="demo-name">Demo-User</h2>
          </div>
          <div className="separator"></div>
          {data.map((items, index) => (
            <div className="content-demo" key={index}>
              <p className="demo-para">
                <i className="demo-icon">
                  <TaskAltIcon />
                </i>
                &nbsp;
                {items.title}
              </p>
              <span className="demo-details">{items.details}</span>
            </div>
          ))}
          <h5 className="demo-date">12</h5>
          <h6 className="demo-month">JANUARY</h6>
          <ul className="demo-ul">
            {/* <li>
              <i className="fa fa-eye fa-2x"></i>
            </li> */}
            <li className="demo-ul-li">
              <i className="fa fa-heart-o fa-2x"></i>
            </li>
            <li className="demo-ul-li">
              <i className="fa fa-envelope-o fa-2x"></i>
            </li>
            {/* <li>
              <i className="fa fa-share-alt fa-2x"></i>
            </li> */}
          </ul>
        </div>
        <div className="fab">
          <i className="fa fa-arrow-down fa-3x"></i>
        </div>
      </div>
    </section>
  );
};

export default Clientcard;
