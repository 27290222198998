import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import VendorSignupDetails from "./VendorSignupDetails";
import VendorPasswordlessSignup from "./VendorPasswordlessSignup";
import "./styles.css";
import { Container } from "@mui/material";
// import AuthHeader from "../../Header/AuthHeader";

export default function LabTabs() {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      className="v-signup-main-container"
      sx={{ width: "100%", typography: "body1", maxHeight: 550 }}
    >
      <TabContext value={value}>
        <Box sx={{ outline: "5px solid hotpink" }} p={1}>
          <TabList
            className="vsignup-tab-heading"
            onChange={handleChange}
            aria-label="SignIN API tabs"
          >
            <Tab label="Formal Signup" value="1" className="tabs_name" />
            {/* <Tab label="OTP Based Signup" value="2" className="tabs_name" /> */}
          </TabList>
        </Box>
        <Container maxWidth="lg">
          <TabPanel value="1" className="value1 p-0">
            <VendorSignupDetails />
          </TabPanel>
          {/* <TabPanel className="seller-tab-panel p-0" value="2">
            <VendorPasswordlessSignup />
          </TabPanel> */}
        </Container>
      </TabContext>
    </Box>
  );
}
