import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router";
import { Box, Card, Container, Grid, Typography } from "@mui/material";
import axios from "../../../api/axios";
import {
  GET_REQUEST_DETAILS,
  GET_RFQ_RESPONSE_QUOTE,
  POST_ACTIVATE_QUOTATION,
  RFQ_REQ_RESP_INFO,
} from "../../CompServices/Api_Routes_Helper";
import { FaArrowLeft } from "react-icons/fa";
import VendorDraftModel from "../../ServiceProvider/Requests/vendorDraft/VendorDraftModel";
import authHeader from "../../../Services/auth-header";
import ReuseablebothSubDes from "../../ReuseTable/ReuseablebothSubDes";
function ViewResponseLarge() {
  const { id } = useParams();

  const [specialNote, setNote] = useState([]);
  const [vendorText, setVendorText] = useState({});
  // const [vendorResponse, setVendorResponse] = useState([]);
  // const [requires, setRequires] = useState([]);
  const [TermsCondition, setTermsCondition] = useState([]);
  //to call useffect if any changes are made in Quotation updates.
  const [qtnupdated, setQtnUpdated] = useState(false);
  const [tableData, setTableData] = useState([]);
  const quotationUpdate = (data) => {
    console.log(data);
    setQtnUpdated(true);

    // setTableData(data.responses);
  };
  const [rowid, setRowId] = useState(null);
  const [rfqValues, setRfqValues] = useState([]);
  let subject;
  let description;
  if (rfqValues) {
    subject = rfqValues.mainRFQ?.subject;
    description = rfqValues.mainRFQ?.description;
  }
  // const handleSubmit = async () => {
  //   try {
  //     // Make the API call to update the status of the quotation
  //     const response = await axios.get(`${POST_ACTIVATE_QUOTATION}${id}`);
  //     const status = response.data.Result.isApproved;
  //     console.log("response", response);
  //     if (status) {
  //       // Update the state of the quotation item to reflect the "Approved" status
  //       setVendorResponse((prevValues) => {
  //         return prevValues.map((item) => {
  //           if (item._id === id) {
  //             return { ...item, isApproved: "Approved" };
  //           }
  //           return item;
  //         });
  //       });
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  useEffect(() => {
    const vendorDetail = async () => {
      try {
        const { data } = await axios.get(`${GET_RFQ_RESPONSE_QUOTE}${id}`, {
          headers: await authHeader(),
        });
        const {
          vendorLineItemResponseID,
          requirements,
          termsAndCondition,
          vendorSubject,
          vendorDescription,
          specialNote,
        } = data.data;

        if (data.vendorLineItemResponseID) {
          fetchQLIResponse(data.vendorLineItemResponseID);
          // getValue(data.vendorLineItemResponseID);
        }
        setRowId(vendorLineItemResponseID);
        setNote(specialNote);
        setTermsCondition(termsAndCondition);
        //object to hold sub and descp of vendor
        const newVendorText = {
          vendorSubject: vendorSubject,
          vendorDescription: vendorDescription,
        };
        setVendorText((prev) => ({
          ...prev,
          ...newVendorText,
        }));
      } catch (error) {
        console.error(error);
      }
    };
    vendorDetail();
  }, [id]);

  const goBackby = () => {
    window.history.back();
  };
  const fetchQLIResponse = async () => {
    try {
      const { data } = await axios(`${RFQ_REQ_RESP_INFO}${rowid}`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (data) {
        setTableData(data.structuredData);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    let isMounted = true;

    if (rowid !== null) {
      fetchQLIResponse();
    }

    return () => {
      isMounted = false;
    };
  }, [rowid]);
  // Fetch data when qtnUpdated changes
  useEffect(() => {
    if (qtnupdated) {
      fetchQLIResponse();
      setQtnUpdated(false); // Reset qtnUpdated after triggering update
    }
  }, [qtnupdated]);
  useEffect(() => {
    let componentMount;
    componentMount = true;
    const getValue = async () => {
      try {
        const { data } = await axios.get(`${GET_REQUEST_DETAILS}${rowid}`, {
          headers: await authHeader(),
        });
        if (data && componentMount) {
          setRfqValues(data.UpdatedData);
        }
      } catch (error) {
        console.error(error);
        componentMount = false;
      }
    };
    if (componentMount && rowid !== null) {
      getValue();
    }
    return () => (componentMount = false);
  }, [rowid]);

  return (
    <Container maxWidth="lg">
      <Grid container className="qup_respondPage_mainWrap">
        <Grid item md={12} sm={12} lg={12} className="respons_inlinestyle">
          <div className="respond_ui">
            <Card className="card_componet_update card_rounded">
              <Container maxWidth="lg" sx={{ pt: 2, m: "auto" }}>
                <Grid container rowSpacing={1}>
                  <Grid item md={12} lg={12} className="respond">  
              <FaArrowLeft className="backarrowclient" title="Back" onClick={goBackby}/>
                    <h3 className="rfq-details-heading">Request Details</h3>
                    <div className="respond_divWrap reusetable_alter">
                      <ReuseablebothSubDes
                        subject={subject}
                        description={description}
                        vendorSubject={vendorText.vendorSubject}
                        vendorDescription={vendorText.vendorDescription}
                      />
                    </div>
                  </Grid>
                  <Grid item md={12} sm={12} lg={12} className="respond">
                    <h3 className="rfq-details-heading">Requirements Requested</h3>
                    <VendorDraftModel
                      itemSet={tableData}
                      receiveQuotation={quotationUpdate}
                    />
                  </Grid>
                  <Grid item md={12} sm={12} lg={12} className="respond">
                    {TermsCondition.length > 0 ? (
                      <div>
                        <div className="border-text">
                          <h6 className="headding">Terms & Description</h6>
                          <div className="border-terms-des scrollables-content">
                            <div className="Respond_tables-form">
                              <div className="table-data">
                                <table id="table">
                                  <thead>
                                    <tr>
                                      <th>Terms</th>
                                      <th>Description</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {TermsCondition?.map((item, i) => (
                                      <tr key={i[item]}>
                                        <td>{item.terms}</td>
                                        <td>{item.description}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>

                              <br />
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </Grid>
                  {specialNote.length > 0 ? (
                    <Grid item md={12} sm={12} lg={12} className="respond">
                      <div>
                        <div className="border-text">
                          <h6 className="headding">Special Notes</h6>
                          <div className="border-terms-des scrollables-content">
                            <div className="Respond_tables-form">
                              <div className="table-data">
                                <table id="table">
                                  <thead>
                                    <tr>
                                      <th>Special Note</th>
                                    </tr>
                                  </thead>
                                  {specialNote?.map((item, i) => (
                                    <tbody key={i}>
                                      <tr>
                                        <td>{item.special}</td>
                                      </tr>
                                    </tbody>
                                  ))}
                                </table>
                              </div>

                              <br />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  ) : (
                    ""
                  )}
                </Grid>
                {/* <Grid item md={6} sm={12} lg={6} className="respond">
                      <label>Check other Responses</label>
                      <Typography variant="text" component={"button"}>
                        other Responses
                      </Typography>
                    </Grid> */}
              </Container>
            </Card>
            {/* {showToast && <ToastMessage onClose={handleToastClose} />}
                <ToastContainer /> */}
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}

export default ViewResponseLarge;
