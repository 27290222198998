import React from "react";
import { userNavItems } from "../User-Data/MenuItems";
// import { MenuItem, Typography } from "@mui/material";
import MenuItemsComponent from "./component";
const NavbarReuse = () => {
  return (
    <nav className="menu-container">
      <ul className="menus m-auto">
        {userNavItems.map((item, index) => {
          const depthLevel = 0;
          return (
            <MenuItemsComponent
              depthLevel={depthLevel}
              items={item}
              key={index}
            />
          );
        })}
      </ul>
    </nav>
  );
};

export default NavbarReuse;
