import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Snackbar,
  Button,
  Typography,
} from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import axios from "../../api/axios";
import { Link, Navigate, useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { Box } from "@mui/system";
import { SIGNIN_BUSSINESSVENDOR } from "../CompServices/Api_Routes_Helper";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import authHeader from "../../Services/auth-header";
import { otpInputStyle } from "./Vendor_SignIn";

const VendorSignInEP = () => {
  const intialValues = {
    email: "",
    password: "",
    loginrememberme: false,
  };
  const [formValues, setFormValues] = useState(intialValues);
  const { email, password } = formValues;

  //for hiding and display error(email & pwd)
  const [showErr, setshowErr] = React.useState(false);
  const [loginerr, setLoginErr] = useState("");
  const [expanded, setExpanded] = React.useState(false);
  const [toggle1, setToggle1] = useState(false);

  let navigate = useNavigate();

  const handleClosePopUpErr = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setshowErr(false);
  };
  const handleChangeAcc = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleInputEmailPwd = (event) => {
    const { name } = event.target;
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    setFormValues({ ...formValues, [name]: value });
    // console.log(name, value);
  };

  const formSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email cannot be empty")
      .email("Invalid email format"),
    password: Yup.string().required("Password cannot be empty"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(formSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });
  const handleFormSubmit = async () => {
    try {
      const { email, password } = formValues;
      let errors = {};
      // if (!email && email !== null) {
      //   errors.email = "Email is required!";

      // }
      // if (!password && password   !== null) {
      //   errors.password = "Password cannot be empty!";
      // }

      const response = await axios.post(
        `${SIGNIN_BUSSINESSVENDOR}`,
        formValues,
        { headers: await authHeader() }
      );

      if (response) {
        const data = response.data;
        if (data.type === "Success") {
          const vendoresponse = data.data;
          const vendor = {
            vid: vendoresponse._id,
            role: "VENDOR",
          };
          localStorage.setItem("v_ID", JSON.stringify(vendor));
          localStorage.setItem("v_info", vendoresponse?.email);
          setTimeout(() => {
            navigate("/vendorManagement/dashboard");
          }, 500);
        }
      }
    } catch (error) {
      console.error(error);
      const { data, status } = error?.response;
      if (!error?.response) {
        setLoginErr("No response from Server.Try Again !");
        setshowErr(true);
      } else if (data.type === "Failed" && status === 400) {
        setLoginErr(data.message);
        setshowErr(true);
      } else if (error.response.status === 500) {
        setLoginErr(data.message);
        setshowErr(true);
      }
    }
  };

  const handleErrors = (errors) => console.error(errors);

  return (
    <React.Fragment>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChangeAcc("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography component={"span"} sx={otpInputStyle} variant="caption">
            Login with Email & Password
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <form
            className="login-form"
            onSubmit={handleSubmit(handleFormSubmit, handleErrors)}
          >
            <div className="form">
              <input
                type="email"
                id="email"
                className="form-input"
                placeholder=" "
                name="email"
                {...register("email")}
                onChange={handleInputEmailPwd}
                value={email}
              />
              <label htmlFor="email" className="form-label">
                Email 
              </label>
            </div>
            {errors.email && (
              <span className="form-error" id="login_email">
                {errors.email.message}
              </span>
            )}

            <div className="form">
              <input
                // type="text"
                type={toggle1 ? "text" : "password"}
                id="password"
                className="form-input"
                placeholder=" "
                name="password"
                {...register("password")}
                onChange={handleInputEmailPwd}
                value={password}
              />
              <i
                id="showpass"
                // className="fa fa-eye icon"
                className={`fa ${toggle1 ? "fa-eye" : "fa-eye-slash"} icon`}
                onClick={() => {
                  setToggle1(!toggle1);
                }}
              ></i>
              <label htmlFor="password" className="form-label">
                Password
              </label>
            </div>

            {errors.password && (
              <span className="form-error" id="login_password">
                {errors.password.message}
              </span>
            )}

            {/* <div className="checkbox">
              <input
                type="checkbox"
                name="loginrememberme"
                className="form-checkbox"
                checked={formValues.loginrememberme}
                onChange={handleInputEmailPwd}
              />

              <p className="common-para">Remember me on this system</p>
            </div> */}

            {/* <div className="forgotPassword">
              <Link
                className="common-para"
                target="#"
                rel="noopener noreferrer"
              >
                Forgot Password?
              </Link>
            </div> */}

            <Button
              type="submit"
              color="primary"
              variant="contained"
              sx={{ p: 1.5, fontSize: 16 }}
            >
              LOGIN
            </Button>
          </form>
        </AccordionDetails>
      </Accordion>
      {CustomAlertBar()}
    </React.Fragment>
  );

  function CustomAlertBar() {
    return (
      <Snackbar
        open={showErr}
        autoHideDuration={2500}
        onClose={handleClosePopUpErr}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleClosePopUpErr}
          severity="error"
          sx={{ width: "100%", fontSize: 15 }}
        >
          {loginerr}
        </Alert>
      </Snackbar>
    );
  }
};

export default VendorSignInEP;
