import { createSlice } from "@reduxjs/toolkit";
const initialState = {};
const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    SetMessage: (state, action) => {
      return { message: action.payload };
    },
    clearMessage: () => {
      return { message: "" };
    },
  },
});
// console.log(messageSlice.actions);
const { reducer, actions } = messageSlice;
export const { SetMessage, clearMessage } = actions;
export default messageSlice.reducer;
