import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "#3778C2";
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderBottomColor: "#3778C2",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    fontStyle: "bold",
  },
  categoryName: {
    width: "30%",
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
  },

  items: {
    width: "40%",
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
  },
  quantity: {
    width: "20%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "right",
    paddingRight: 8,
  },

  unit: {
    width: "20%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "right",
    paddingRight: 8,
  },
  price: {
    width: "20%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "right",
    paddingRight: 8,
  },
  responseQuantity: {
    width: "15%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "right",
    paddingRight: 8,
  },
  responsePrice: {
    width: "15%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "right",
    paddingRight: 8,
  },
  responseDate: {
    width: "25%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "right",
    paddingRight: 8,
  },
  rate: {
    width: "15%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "right",
    paddingRight: 8,
  },
  amount: {
    width: "20%",
    textAlign: "right",
    paddingRight: 8,
  },
  description: {
    width: "85%",
    textAlign: "right",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingRight: 8,
  },
  total: {
    width: "15%",
    textAlign: "right",
    paddingRight: 8,
  },
});

const InvoiceTableRow = ({ items, ClientItems }) => {
  const calculateTaxAmount = (price, taxPercentage) => {
    const taxAmount = (price * taxPercentage) / 100;
    return taxAmount;
  };

  const totalAmounts = items
    ? items.map((item) => {
        const responsePrice = parseFloat(item.responsePrice);
        const responseTaxPercentage = parseFloat(item.responseTax);
        const taxAmount = calculateTaxAmount(
          responsePrice,
          responseTaxPercentage
        );
        const totalAmount =
          responsePrice !== null && taxAmount !== null
            ? responsePrice + taxAmount
            : null; // Calculate totalAmount only if both responsePrice and taxAmount are not null
        return totalAmount;
      })
    : [];

  const totalSum =
    totalAmounts.length > 0
      ? totalAmounts.reduce(
          (acc, currentValue) =>
            !isNaN(currentValue) ? acc + currentValue : acc, // Sum only if currentValue is not NaN
          0
        )
      : 0;

  const Clientrows = ClientItems
    ? ClientItems.map((item) => (
        <View style={styles.row} key={item._id.toString()}>
          <Text style={styles.categoryName}>{item.categoryName}</Text>
          <Text style={styles.items}>{item.items}</Text>
          <Text style={styles.quantity}>{item.quantity}</Text>
          <Text style={styles.unit}>{item.unit}</Text>
          <Text style={styles.price}>{item.price}</Text>
          <Text style={styles.amount}>{item.price}</Text>
        </View>
      ))
    : null;

  const rows = items
    ? items.map((item) => {
        const responsePrice = parseFloat(item.responsePrice);
        const responseTaxPercentage = parseFloat(item.responseTax);
        const taxAmount = calculateTaxAmount(
          responsePrice,
          responseTaxPercentage
        );
        const totalAmount =
          responsePrice !== null && taxAmount !== null
            ? responsePrice + taxAmount
            : null;

        return (
          <>
            <View style={styles.row} key={item.lineItemId.toString()}>
              <Text style={styles.categoryName}>{item.categoryName}</Text>
              <Text style={styles.items}>{item.items}</Text>
              <Text style={styles.quantity}>{item.quantity}</Text>
              <Text style={styles.unit}>{item.unit}</Text>
              <Text style={styles.price}>{item.price}</Text>
              <Text style={styles.responseQuantity}>
                {item.responseQuantity !== null && item.responseQuantity !== ""
                  ? item.responseQuantity
                  : "-"}
              </Text>
              <Text style={styles.responsePrice}>
                {item.responsePrice !== null && item.responsePrice !== ""
                  ? item.responsePrice
                  : "-"}
              </Text>
              <Text style={styles.responsePrice}>
                {item.responseTax !== null && item.responseTax !== ""
                  ? `${item.responseTax}%`
                  : "-"}
              </Text>
              <Text style={styles.amount}>
                {!isNaN(totalAmount) ? totalAmount.toFixed(2) : "-"}
              </Text>
            </View>
          </>
        );
      })
    : [];

  const totalRow = items ? (
    <View style={styles.row}>
      <Text style={styles.description}>Total:</Text>
      <Text style={styles.total}>{totalSum.toFixed(2)}</Text>
    </View>
  ) : null;

  return (
    <Fragment>
      {rows}
      {totalRow}
      {Clientrows}
    </Fragment>
  );
};

export default InvoiceTableRow;
