import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderBottomColor: "#3778C2",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    fontStyle: "bold",
  },

  description: {
    width: "100%",
    textAlign: "left",
    // borderRightWidth: 1,
    paddingLeft: 8,
  },

  terms: {
    width: "100%",
    textAlign: "left",
    borderRightWidth: 1,
    paddingLeft: 8,
  },
});

const Termscondition = ({ items, special }) => {
  const rows = items
    ? items?.map((item) => (
        <View style={styles.row}>
          <Text style={styles.terms}>{item.terms}</Text>
          <Text style={styles.description}>{item.description}</Text>
        </View>
      ))
    : null;

  const specialrows = special
    ? special?.map((item) => (
        <View style={styles.row}>
          <Text style={styles.description}>{item.special}</Text>
        </View>
      ))
    : null;
  return (
    <Fragment>
      {rows}
      {specialrows}
    </Fragment>
  );
};

export default Termscondition;
