import React, { useState, useEffect } from "react";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Paper,
  Button,
  Container,
  Alert,
  Snackbar,
  LinearProgress,
  Typography,
} from "@mui/material";
import Details from "./Details";
import Pricing from "./Pricing";
import Review from "./Review";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import {
  API_AUTH_URL,
  CREATE_RFQ,
  CREATE_RFQ_DRAFT,
} from "../CompServices/Api_Routes_Helper";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useLocation } from "react-router-dom";
import { FaEnvelopeOpen } from "react-icons/fa";
import {
  getSubDescp,
  removeOnSubmit,
  removeRequestItems,
} from "../../slices/RfqInfo";
import { useDispatch, useSelector } from "react-redux";
import DeliveryAddress from "./DeliveryAddress/DeliveryAddress";
import { addAddress, deleteAddress } from "../../slices/RFQAddress";

const CreateRfq = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [fileUploadProgress, setFileUploadProgress] = useState(0);
  const [details, setDetails] = useState({});
  const [addedAddress, setAddedAddress] = useState({});
  //to toggle b/w requets & service
  const [toggleRFQ, setToggleRFQ] = useState(null);
  const [addItem, setAddItem] = useState(false);
  //delivery info
  const [delinfo, setDelInfo] = useState([]);
  const [selectedImage, setSelectedImage] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async () => {
    try {
      setIsLoading(true);
      // Simulate async operation (e.g., API call)
      await new Promise((resolve) => setTimeout(resolve, 2000));
      // Your submit or next step logic here
    } catch (error) {
      // Handle errors if needed
    } finally {
      // Reset loading state
      setIsLoading(false);
    }
  };

  const [uploadTrigger, setUploadTrigger] = useState(false);
  //to verify state of address
  const [isAddressAdded, setAddressAdded] = useState(false);
  const [selectID, setSelectID] = useState({});
  const [isSnackbarOpen, setSnackbarOpen] = useState(false);
  const [outcome, setOutcome] = useState([]);
  const [getadddd,setvalueaddress]= useState([]);
  const [image, setImage] = useState();
  //toggle submit button
  const [toggleSubmit, setToggleSubmit] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [buttonClicked, setButtonClicked] = useState(false);
  const searchData = searchParams.get("q");
  const scategoryName = searchParams.get("categoryName");
  const dispatch = useDispatch();
  const userId = localStorage.getItem("userID");
  const methods = useForm();
  const steps = [
    "Tell us your Service Description",
    "Add Time and Budget",
    "Add Delivery Details",
    "Review and Submit",
  ];
  useEffect(function () {
    async function getuserInfo() {
      const { data } = await axios.get(
        `${API_AUTH_URL}get/delivery-info/?userId=${userId}`,
        {
          headers: {
            "Content-Type": "Application/json",
          },
        }
      );
      setDelInfo(data);
    }
    getuserInfo();
  }, []);
  const handleNext = () => {
    const { Subject, description } = details;
    const errors = {};
    const subTrimmed = Subject.trim();
    const descTrimmed = description.trim();
    if (subTrimmed === "") {
      errors.subject = "Subject field is required";
    }
    if (descTrimmed === "") {
      errors.description = "Descriptions is required";
    }
    if (Object.keys(errors).length > 0) {
      setSnackbarOpen(true);
      return;
    }
    switch (activeStep) {
      case 0:
        dispatch(
          getSubDescp({ sub: Subject.trim(), descp: description.trim() })
        );
        break;
      case 2:
        // const { landMark, primaryAddress,  } =
        //   addedAddress;
        dispatch(
          addAddress({
           deliveryAddress:addedAddress
          })
        );
        break;

      default:
        break;
    }
    // Proceed to the next step if no issues are encountered
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  // const handleNext = async () => {
  //   const isValid = await methods.trigger(); // Trigger validation
  //   if (isValid) {
  //     setActiveStep((prevStep) => prevStep + 1);
  //   }
  // };
  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };
  const handleBack1 = () => {
    setActiveStep((prevStep) => prevStep - 2);
  };
  const handleBack2 = () => {
    setActiveStep((prevStep) => prevStep - 3);
  };
  const handleDetailsChange = (outValues) => {
    const { rfq_type } = outValues;
    setDetails(outValues);
    setToggleRFQ(rfq_type);
  };
  const handleAddressChange = (outValues: any) => {
    console.log("new dummy",outValues);
    setAddedAddress(outValues);
  };

  const handleDetailsChanges = (outValues) => {
    setSelectID(outValues);
  };

  const handleItemChange = (values) => {
    setOutcome(values);
  };
  const handleaddess =(values) =>{
    setvalueaddress(values);
  }
  useEffect(() => {
    setUploadTrigger(false);
  }, [uploadTrigger]);

  const storeImageData = async (img) => {
    const formData = new FormData();
    formData.append("file", img);
    formData.append("upload_preset", "Quote_ur_price");

    await fetch(`https://api.cloudinary.com/v1_1/dynbagilk/image/uploads`, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("vendor requests", data);
        return data.secure_url;
        // Add the Cloudinary URL to the createValues object
      })
      .catch((err) => console.error(err));
  };
  const handleFileSelect = async (data) => {
    console.log("image data", data);
    for (let i = 0; i < data.length; i++) {
      let file = data[i];
      const formData = new FormData();
      formData.append("file", file);

      formData.append("upload_preset", "sjfckigv");
      formData.append("cloud_name", "dp7bersiq");

      await fetch(`https://api.cloudinary.com/v1_1/dp7bersiq/upload`, {
        method: "POST",
        body: formData,
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          selectedImage.push(data["secure_url"]);
        })
        .catch((err) => console.error(err));
      setSelectedImage(selectedImage);
      setUploadTrigger(true);
    }
  };

  // Check if the form values are empty
  const isSubjectDescriptionEmpty =
    details.Subject == "" || details.description == "";

  const isOutcomeNotEmpty = outcome.length > 0;
  const variant =
    isSubjectDescriptionEmpty || !isOutcomeNotEmpty || !addedAddress
      ? "outlined"
      : "outlined";
  const className =
    isSubjectDescriptionEmpty || !isOutcomeNotEmpty || addedAddress
      ? "btn-disabled"
      : "step-next-btn";

  const [isSecondPageValid, setIsSecondPageValid] = useState(false);

  const handleSubmit = async () => {
    try {
      setToggleSubmit(true);
      await handleFormSubmit();
    } catch (error) {
      setToggleSubmit(false);
      console.error("An error occurred during form submission:", error);
      // Handle the error, show a user-friendly message, etc.
    } finally {
      setToggleSubmit(false);
      console.log("requests submitted succesfully!");
    }
  };

  
  const u_Id = localStorage.getItem("userID");
  // const checkid = localStorage.getItem("userNew");
  // const u_Id = JSON.parse(checkid)?.res?.user?.uid;

  const QupIdgenerator = async () => {
    // const rfqId = uuidv4();
    // Retrieve the last used invoice number from storage or a database
    let lastInvoiceNumber = localStorage.getItem("lastInvoiceNumber");
    // Parse the last invoice number to extract the incrementing part
    let incrementingPart = parseInt(
      lastInvoiceNumber ? lastInvoiceNumber.split("-")[1] : "0"
    );
    incrementingPart++;
    let PaddedIncrementPart = String(incrementingPart).padStart(2, "0");
    let date = new Date();
    let newDate = date.getDate().toString().padStart(2, "0");
    let newMonth = (date.getMonth() + 1).toString().padStart(2, "0");
    let newYear = date.getFullYear().toString();
    let fullDate = `${newYear}${newDate}${newMonth}`;
    let invoicenumber = `QUPRFQ${fullDate}-${PaddedIncrementPart}`;
    localStorage.setItem("lastInvoiceNumber", invoicenumber);
    return invoicenumber;
    // Increment the incrementing part for the next invoice
  };
  const loadingOption = {
    onUploadProgress: (progressEvent) => {
      const { total, loaded } = progressEvent;
      const precentage = Math.floor(((loaded / 1000) * 100) / (total / 1000));
      setFileUploadProgress(precentage);
    },
  };
    const handleFormSubmit = async () => {
      try {
      // return;
        const createValues = [
          {
            // cat_Id: selectID,
            // categoryName: details.categoryName,
            subject: details.Subject,
            description: details.description,
            address: addedAddress,
            requests: outcome,
            customer_Id: u_Id,
            uniqueRFQId: await QupIdgenerator(),
            RFQtype: toggleRFQ,

            image: selectedImage,
          },
        ];
        // const data = new FormData();
        // data.append("file", selectedImage);
        // data.append("upload_preset", "MyQuotes");

        // Upload image to Cloudinary
        // const response = await fetch(
        //   "https://api.cloudinary.com/v1_1/dzblzw7ll/image/upload",
        //   {
        //     method: "POST",
        //     body: data,
        //   }
        // )
        //   .then((res) => res.json())
        //   .then((data) => {
        //     console.log("vendor requests", data);
        //     // Add the Cloudinary URL to the createValues object
        //     createValues.image = data.secure_url;
        //     setImage(data.secure_url);
        //   })

        //   .catch((err) => console.error(err));

        // Submit the form with the createValues object
        const { data } = await axios.post(
          `${CREATE_RFQ}`,
          createValues,
          loadingOption
        );
        if (data !== null) {
          toast.success(data.message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
            onClose: () => {
              navigate(`/user_in/categoryTable?rfq_type=${toggleRFQ}`, {
                replace: true,
              });
            },
          });
          sessionStorage.removeItem("itemSet");
          dispatch(removeOnSubmit());
          dispatch(deleteAddress());
        }
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message;
        console.error("error caught while submitting form ", message);
        dispatch(removeOnSubmit());
        dispatch(deleteAddress());
      }
    };

  const CreaterfqDraft = async () => {
    if (buttonClicked) return;
    try {
      setButtonClicked(true);
      const draftValues = [
        {
          subject: details.Subject,
          description: details.description,
          address: addedAddress,
          requests: outcome,
          customer_Id: u_Id,
          uniqueRFQId: await QupIdgenerator(),
          isDraft: true,
          RFQtype: toggleRFQ,
        },
      ];
      const createDraft = await axios.post(`${CREATE_RFQ_DRAFT}`, {
        data: draftValues,
      });
      if (createDraft) {
        const { message } = createDraft.data;

        toast.success(message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
          onClose: () => {
            navigate(`/user_in/categoryTable?rfq_type=${toggleRFQ}`);
            sessionStorage.removeItem("itemSet");
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch(removeOnSubmit());
      dispatch(deleteAddress());
    }
  };
  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Details
            onChange={handleDetailsChange}
            selectedId={handleDetailsChanges}
            setAddItem={setAddItem}
            urlSearch={searchData}
          />
        );
      case 1:
        return (
          <Pricing
            itemSett={handleItemChange}
            toggleRFQBtn={toggleRFQ}
            addingItem={addItem}
            setItemAdding={setAddItem}
            handleItemChange={setIsSecondPageValid}
            urlSearchName={scategoryName}
          />
        );
      case 2:
        return (
          <DeliveryAddress
            onChange={handleAddressChange}
            deliverydata={delinfo}
            selectedImage={handleFileSelect}
            imageChoosen={selectedImage}
            triggerUpload={uploadTrigger}
            setAddressAdded={() => setAddressAdded(true)}
            getaddress={handleaddess}
            // toggleNext={setContinueNext(true)}
          />
        );
      case 3:
        return (
          <Review
            selectID={selectID}
            pricing={outcome}
            delivary={addedAddress}
            selectedImage={handleFileSelect}
            imageChoosen={selectedImage}
            triggerUpload={uploadTrigger}
            
            onRedirectcase1={handleBack1}
            onRedirectcase2={handleBack}
            onRedirectcase3={handleBack2}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="rfq-main-container">
      <Container
        component="main"
        maxWidth="lg"
        // sx={{ mb: 4 }}
      >
        <Paper
          elevation={24}
          sx={{ my: { xs: 6, md: 6 }, p: { xs: 3, md: 3 } }}
        >
          <Stepper
            activeStep={activeStep}
            sx={{ pt: 3, pb: 5 }}
            alternativeLabel
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleSubmit)}>
              {activeStep === steps.length ? (
                <div>
                  <Review details={details} />
                </div>
              ) : (
                <div>
                  {getStepContent(activeStep)}
                  <div className="step-nav-btn-container">
                    <Button
                      className="step-back-btn"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                    >
                      <ArrowBackIos /> Back
                    </Button>
                    <Box sx={{ p: 1, display: "inline-flex" }}>
                      {activeStep === steps.length - 1 && (
                        <Button
                          className="step-back-btn save-as-button"
                          variant="outlined"
                          color="secondary"
                          // disabled={isFormEmpty}
                          onClick={CreaterfqDraft}
                          disabled={buttonClicked}
                        >
                          {/* <FaEnvelopeOpen /> */}
                          Save as Draft
                        </Button>
                      )}

                      <Button
                        // className={className}
                        className={`${
                          className +
                          ((activeStep === 0 && !isSubjectDescriptionEmpty) ||
                          (activeStep === 1 && isSecondPageValid) ||
                          (activeStep === 2 && isAddressAdded) ||
                          activeStep === 3
                            ? " additional-class"
                            : "")
                        }`}
                        variant={variant}
                        disabled={
                          // activeStep === 1
                          //   ? !isSecondPageValid
                          //   : isSubjectDescriptionEmpty ||
                          //     toggleSubmit
                          // activeStep === 1
                          //   ? !isSecondPageValid
                          //   : activeStep === 2 && !isAddressAdded
                          (activeStep === 1 && !isSecondPageValid) ||
                          (activeStep === 2 && !isAddressAdded) ||
                          isSubjectDescriptionEmpty
                        }
                        onClick={
                          activeStep === steps.length - 1
                            ? handleSubmit
                            : handleNext
                        }
                      >
                        {activeStep === steps.length - 1
                          ? "Submit"
                          : "Continue"}
                        <ArrowForwardIos />
                      </Button>
                    </Box>
                  </div>
                </div>
              )}
            </form>
          </FormProvider>
        </Paper>
      </Container>
      <ToastContainer />

      {/* Snackbar */}
      <Snackbar
        open={isSnackbarOpen}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <Alert severity="warning">Please Fill all necessary fields!</Alert>
      </Snackbar>
    </div>
  );
};

export default CreateRfq;
