import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { Divider } from "@mui/material";

const styles = StyleSheet.create({
  invoiceNoContainer: {
    flexDirection: "row",
    marginTop: 26,
    justifyContent: "flex",
  },
  label: {
    width: 60,
    fontStyle: "none",
    marginTop: 3,
  },
  invoiceDate: {
    fontSize: 14,
    fontStyle: "bold",
  },
  titleContainer: {
    marginTop: 6,
  },
  reportNotes: {
    marginTop: 5,
    fontSize: 8,
    textAlign: "left",
    fontSize: 10,
  },
  divider: {
    marginTop: 2,
    borderBottom: "1px solid gray",
  },
});

const InvoiceThankQuotation = ({ invoice, ClientDetails }) => (
  <View style={styles.titleContainer}>
    {invoice ? (
      <>
        <View style={styles.invoiceNoContainer}>
          <Text style={styles.label}>Dear</Text>
          <Text style={styles.invoiceDate}>
            <Text>{invoice[0].customerDetails.firstName}</Text>&nbsp;
            <Text>{invoice[0].customerDetails.lastName}</Text>
          </Text>
        </View>
        <Text style={styles.reportNotes}>
          Thank you for your interest in our company and opportunity to quote.
          We are pleased to quote as follows:
        </Text>
        <Text style={styles.divider}></Text>
      </>
    ) : null}
    {ClientDetails ? (
      <>
        <View style={styles.invoiceNoContainer}>
          <Text style={styles.label}>Dear</Text>
          <Text style={styles.invoiceDate}>
            <Text>{ClientDetails.firstName}</Text>&nbsp;&nbsp;
            <Text>{ClientDetails.lastName}</Text>
          </Text>
        </View>
        <Text style={styles.reportNotes}>
          Thank you for your interest in our company and opportunity to quote.
          We are pleased to quote as follows:
        </Text>
        <Text style={styles.divider}></Text>
      </>
    ) : null}
  </View>
);

export default InvoiceThankQuotation;
