import {
  Button,
  Container,
  Grid,
  List,
  ListItem,
  ListItemText,
  p,
  Paper,
} from "@mui/material";
import React from "react";

const WhyChoice = () => {
  return (
    <>
      <section className="why_chice_section">
        <Container>
          <div className="why_heading">
            <h4>Why Choose QuoteUrPrice</h4>
          </div>
          <Grid container className="Why_choice_grid" spacing={2}>
            <Grid item xs={12} sm={3} md={3} lg={3} style={{ display: "flex", flexDirection: "row" }}>
              <img
                src="https://textech.wpenginepowered.com/wp-content/uploads/100-years-experience.svg"
                alt="Trulli"
                className="why_image"
              />
              <p className="why_subtitle1">Over 1 Years of Experience</p>
            </Grid>

            <Grid item xs={12} sm={3} md={3} lg={3} style={{ display: "flex", flexDirection: "row" }}>
              <img
                src="https://textech.wpenginepowered.com/wp-content/uploads/high-performance-and-quality-brands.svg"
                alt="Trulli"
                className="why_image"
              />
              <p className="why_subtitle1">
                High Performance and Quality Brands
              </p>
            </Grid>

            <Grid item xs={12} sm={3} md={3} lg={3} style={{ display: "flex", flexDirection: "row" }}>
              <img
                src="https://textech.wpenginepowered.com/wp-content/uploads/iso-certified-1.svg"
                alt="Trulli"
                className="why_image"
              />
              <p className="why_subtitle11">ISO Certified</p>
            </Grid>

            <Grid item xs={12} sm={3} md={3} lg={3} style={{ display: "flex", flexDirection: "row" }}>
              <img
                src="https://textech.wpenginepowered.com/wp-content/uploads/solutions-for-multiple-industries-1.svg"
                alt="Trulli"
                className="why_image"
              />
              <p className="why_subtitle1">Solutions for Multiple Industries</p>
            </Grid>
          </Grid>
        </Container>
        <div className="why_button">
          <button className="why_button1">Learn More About Us</button>
          <button className="why_button2">Contact Us</button>
        </div>
      </section>
    </>
  );
};

export default WhyChoice;
