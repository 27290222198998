import React, { useEffect, useState } from "react";
import Texture from "../../assets/bg_texture.jpg";
import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  Grid,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Paper,
  Typography,
} from "@mui/material";
import "./UserProfile.scss";
import axios from "../../api/axios";
import MDAvatar from "../ServiceProvider/dashboard/Dcomponents/MDAvatar";
import TapsComponent from "../ServiceProvider/vendor_Signup/TapsComponent";
import { Steps } from "antd";
import { submitButton } from "../DesignTools/ButtonStyles/ButtonStyles";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import {
  CURR_USR_PRF_INFO,
  UPDATEPROFILE_CURR_USER,
  USER_GET_AllRFQS,
  USER_IN_PROFILE_UPDATE,
} from "../CompServices/Api_Routes_Helper";
import { useNavigate } from "react-router-dom";
import { Summary } from "./User-Data/MenuItems";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaCamera } from "react-icons/fa";
import { HiPencilAlt } from "react-icons/hi";
import { BiLogoFacebookCircle } from "react-icons/bi";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { CgProfile } from "react-icons/cg";
import { TiLocationOutline } from "react-icons/ti";
import IconButton from "@mui/material/IconButton";
import { IoPersonSharp } from "react-icons/io5";
import { RiChatUploadFill } from "react-icons/ri";
import { RiWallet3Fill } from "react-icons/ri";
import { MdFolderShared } from "react-icons/md";
import { RiLogoutCircleLine } from "react-icons/ri";
import EditAddress from "../CategoryList/EditAddress";
function UserProfilePage() {
  const [filee, setFile] = useState();
  const email = localStorage.getItem("email");
  const [values, setValues] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [address, setAddress] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [phone, setPhone] = useState();
  const SetEmail = values.email;
  const image = values.avatarImage;
  const [images, setImages] = useState("");
  const id = values._id;
  const navigate = useNavigate();
  const [isEditable, setIsEditable] = useState(false);
  const handleEditClick = () => {
    setIsEditable(true); // Set the form to editable state
  };
  const [isEditables, setIsEditables] = useState(false);
  const [open,setopen] = useState(false);
  const handleopen = () => {
    setopen(true);
  }
  const handleEditClicks = () => {
    setIsEditables(true); // Set the form to editable state
  };
  
  const [isHovered, setIsHovered] = useState(false);
  const [uploading, setUploading] = useState(false);

  const handleChanges = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      const reader = new FileReader();

      reader.onload = (event) => {
        const uploadedImage = event.target.result;
        setUploading(true);
        handleFormSubmit(uploadedImage);
      };

      reader.readAsDataURL(selectedFile);
    }
  };

  useEffect(() => {
    // Fetch the values from the API and set the initial state
    const fetchData = async () => {
      try {
        const response = await axios.get(`${CURR_USR_PRF_INFO}${email}`);
        const data = response.data[0];
        setFirstName(data.firstName);
        setLastName(data.lastName);
        setPhone(data.phone);
        setValues(data);
        setAddress(data.address);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  //update code
  const handleFormSubmit = async (Image) => {
    const inputvalue = {
      firstName: getValues().firstName,
      lastName: getValues().lastName,
      email: email,
      phone: getValues().phone,
      address: getValues().address,
      avatarImage: "",
    };
    const data = new FormData();
    data.append("file", Image);
    data.append("upload_preset", "MyQuotes");
    try {
      const response = await fetch(
        "https://api.cloudinary.com/v1_1/dzblzw7ll/image/upload",
        {
          method: "POST",
          body: data,
        }
      );
      const cloudinaryData = await response.json();
      inputvalue.avatarImage = cloudinaryData.secure_url;
      setImages(cloudinaryData.secure_url);
      setUploading(false);

      // Update the profile on the server after successfully uploading the image
      const axiosResponse = await axios.put(
        `${UPDATEPROFILE_CURR_USER}${id}`,
        inputvalue
      );

      // Show the toast notification after successful profile update
      toast.success(axiosResponse.data.message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
        className: "custom-toast",
      });
      setIsEditable(false);
      setIsEditables(false)
    } catch (error) {
      console.log(error);
    }
  };
 
  const {
    register,
    handleSubmit,
    getValues,
    reset,
    control,
    formState: { errors },
  } = useForm();

  function handleChange(event) {
    setFile(URL.createObjectURL(event.target.files[0]));
    const file = event.target.files[0];
    setSelectedImage(file);
  }
  const [showEditProfile, setShowEditProfile] = useState(true);

  const handleTabClick = (isEditProfile) => {
    setShowEditProfile(isEditProfile);
  };

  return (
    <>
    
      {/* <section className="profile_section">
        <div className="grid-container">
          <div className="small-column">
            <ul className="profile_ul">
              <li
                className={`profile_li profile-buts ${
                  showEditProfile && "edit-selected"
                }`}
                onClick={() => handleTabClick(true)}
              >
                <CgProfile style={{ fontSize: "1.5rem", color: "black" }} />{" "}
                &nbsp; Profile{" "}
              </li>
              {showEditProfile && <hr className="profile_lii" />}
              <li
                className={`profile_li profile-buts ${
                  !showEditProfile && "edit-selected"
                }`}
                onClick={() => handleTabClick(false)}
              >
                <TiLocationOutline
                  style={{ fontSize: "1.5rem", color: "black" }}
                />
                &nbsp; Address
              </li>
              {!showEditProfile && <hr className="profile_lii" />}
            </ul>
          </div>
          {showEditProfile && (
            <div className="large-column">
              <Container>
                <h1 className="profile_heading">Profile</h1>
                <p className="profile_text">
                  Keep your personal details private. Information you add here
                  is visible to any who can view your profile.
                </p>
                <br />
                <Grid
                  container
                  columnGap={3}
                  rowGap={3}
                  style={{ background: "white" }}
                >
                  <Grid
                    item
                    md={4}
                    lg={4}
                    sm={12}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      borderRadius: "6px",
                      borderTop: "3px solid blue",
                      boxShadow:
                        "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                    }}
                  >
                    <div className="profile_imag_part">
                      <p className="profile_heading"></p>
                      <div className="img_userProfile">
                        <label
                          className={`profile-pict editable ${
                            isHovered ? "hovered" : ""
                          }`}
                          onMouseEnter={() => setIsHovered(true)}
                          onMouseLeave={() => setIsHovered(false)}
                        >
                          <div>
                            <img
                              src={images || image}
                              // alt="hello"
                              className="image_hover1"
                              // className="image_userProfile userprofilere"
                              style={{
                                width: "120px",
                                height: "120px",
                                border: "4px solid gray",
                                borderRadius: "100px",
                              }}
                            />
                            <input
                              type="file"
                              style={{ marginTop: "28px", display: "none" }}
                              onChange={handleChanges}
                            />
                          </div>
                          {isHovered && (
                            <div className="hover-overlay">
                              <FaCamera size={24} color="black" />
                            </div>
                          )}
                        </label>
                      </div>
                    </div>
                    <div className="name_userProfile">
                      <h2>
                        {firstName}&nbsp;{lastName}
                      </h2>
                    </div>
                    <div>
                      <p
                        style={{
                          fontFamily: "poppins",
                          textAlign: "center",
                          padding: "10px 10px",
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: "3",
                          height: "80px",
                          overflow: "hidden",
                        }}
                      >
                        {address}
                      </p>
                    </div>
                  </Grid>

                  <Grid item md={7} lg={7} sm={12} className="profile_details">
                    <Box>
                      <p className="profile_heading">Personal Details :</p>
                      <br />
                      <Grid
                        container
                        rowSpacing={1}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      >
                        <Grid item md={4} className="grid_1">
                          <label className="profilelable">Email:</label>
                        </Grid>
                        <Grid item md={7}>
                          <input
                            type="email"
                            id="outlined-basic"
                            label="Email"
                            className="input-field"
                            value={SetEmail}
                          />
                        </Grid>{" "}
                      </Grid>
                      <br />
                      <Grid
                        container
                        rowSpacing={1}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      >
                        <Grid item md={4} className="grid_1">
                          <label className="profilelable">First Name:</label>
                        </Grid>
                        <Grid item md={7} className="profile-input-hover">
                          <input
                            type="text"
                            name="FirstName"
                            className="input-field"
                            id="outlined-basic"
                            {...register("firstName")}
                            value={firstName}
                            disabled={!isEditable}
                            onChange={(e) => setFirstName(e.target.value)}
                          />
                          {errors?.firstName && (
                            <span className="form_error">
                              {errors.firstName.message}
                            </span>
                          )}
                        </Grid>
                      </Grid>
                      <br />
                      <Grid
                        container
                        rowSpacing={1}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      >
                        <Grid item md={4} className="grid_1">
                          <label className="profilelable">Last Name:</label>
                        </Grid>
                        <Grid item md={7}>
                          <input
                            type="text"
                            className="input-field"
                            id="outlined-basic"
                            {...register("lastName")}
                            value={lastName}
                            disabled={!isEditable}
                            onChange={(e) => setLastName(e.target.value)}
                          />
                          {errors?.lastName && (
                            <span className="form_error">
                              {errors.lastName.message}
                            </span>
                          )}
                        </Grid>
                      </Grid>
                      <br />
                      <Grid
                        container
                        rowSpacing={1}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      >
                        <Grid item md={4} className="grid_1">
                          <label className="profilelable">Phone Number:</label>
                        </Grid>
                        <Grid item md={7}>
                          <input
                            type="numbers"
                            id="outlined-basic"
                            label="Phone Number"
                            {...register("phone")}
                            className="input-field"
                            value={phone}
                            disabled={!isEditable}
                            onChange={(e) => setPhone(e.target.value)}
                          />
                          {errors?.phone && (
                            <span className="form_error">
                              {errors.phone.message}
                            </span>
                          )}
                        </Grid>
                      </Grid>
                      <br />
                      <Grid
                        container
                        rowSpacing={1}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      >
                        <Grid item md={4} className="grid_1">
                          <label className="profilelable">Descriptions:</label>
                        </Grid>
                        <Grid item md={7}>
                          <textarea
                            type="text"
                            id="outlined-basic"
                            label="address"
                            {...register("address")}
                            className="input-fieldaddress"
                            value={address}
                            disabled={!isEditable}
                            onChange={(e) => setAddress(e.target.value)}
                          />

                          {errors?.address && (
                            <span className="form_error">
                              {errors.address.message}
                            </span>
                          )}
                        </Grid>
                      </Grid>
                      <br />
                      <br /> <br />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          position: "relative",
                          top: "-15px",
                        }}
                      >
                        {isEditable ? (
                          <>
                            <button
                              className="custom-btn btn-7"
                              onClick={handleFormSubmit}
                            >
                              <span>Update</span>{" "}
                            </button>
                            &nbsp;&nbsp;
                            <button
                              className="custom-btn btn-7"
                              onClick={() => setIsEditable(false)}
                            >
                              <span>Cancel</span>
                            </button>
                          </>
                        ) : (
                          <Tooltip title="Edit Profile">
                            <button
                              className="custom-btn btn-7"
                              onClick={handleEditClick}
                            >
                              <span>
                                {" "}
                                <HiPencilAlt /> &nbsp; Profile
                              </span>
                            </button>
                          </Tooltip>
                        )}
                      </div>
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </div>
          )}
          {!showEditProfile && (
            <div className="large-column1">
              <Container style={{ height: "100vh" }}>
                <h1 className="profile_heading">Address</h1>
                <p className="profile_text">
                  Keep your personal details private. Information you add here
                  is visible to any who can view your profile.
                </p>
              </Container>
              <br />
            </div>
          )}
        </div>
      </section> */}
      <section className="profile_section">
        <Grid container spacing={2} gap={5}>
          <Grid item xs={12} sm={3}>
            <Paper style={{ padding: "10px" }}>
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <img src="https://static-assets-web.flixcart.com/fk-p-linchpin-web/fk-cp-zion/img/profile-pic-male_4811a1.svg"></img>
                </Grid>
                <Grid item>
                  <Typography className="userprofile_sidebar_heading">
                    Hello
                  </Typography>
                  {firstName ? (
                    <Typography variant="h6">{firstName}&nbsp;{lastName}</Typography>
                ) : (
                    <Typography variant="h6">Username</Typography>
                )}
                </Grid>
              </Grid>
            </Paper>
            <br />

            <Paper>
              <div className="userprofile_sidebar_h1">
                <RiChatUploadFill size={28} style={{ color: "blue" }} />
                <p className="userprofile_sidebar_heading">MY ORDERS</p>
              </div>
              <Divider />
              <div className="userprofile_sidebar_h1">
                <IoPersonSharp size={28} style={{ color: "blue" }} />
                <p className="userprofile_sidebar_heading">ACCOUNT SETTINGS</p>
              </div>
              <div className="userprofile_sidebar_linking">
              <a href="#" className="userprofile_sidebar_atag" onClick={() =>setopen(false)}>
                  Profile Information
                </a>
                <a href="#" className="userprofile_sidebar_atag" onClick={handleopen}>
                  Manage Addresses
                </a>
              <a href="" className="userprofile_sidebar_atag">
                  PAN Card Information
                </a>           
              </div>
              <Divider />
              <div className="userprofile_sidebar_h1">
                <RiWallet3Fill size={28} style={{ color: "blue" }} />
                <p className="userprofile_sidebar_heading">PAYMENTS</p>
              </div>
              <div className="userprofile_sidebar_linking">
                <a href="" className="userprofile_sidebar_atag">
                  Gift Cards
                </a>
                <a href="" className="userprofile_sidebar_atag">
                  Saved UPI
                </a>
                <a href="" className="userprofile_sidebar_atag">
                  Saved Cards
                </a>
              </div>
              <Divider />
              <div className="userprofile_sidebar_h1">
                <MdFolderShared size={28} style={{ color: "blue" }} />
                <p className="userprofile_sidebar_heading">MY STUFF</p>
              </div>
              <div className="userprofile_sidebar_linking">
                <a href="" className="userprofile_sidebar_atag">
                  My Coupons
                </a>
                <a href="" className="userprofile_sidebar_atag">
                  My Reviews & Ratings
                </a>
                <a href="" className="userprofile_sidebar_atag">
                  All Notifications
                </a>
                <a href="" className="userprofile_sidebar_atag">
                  My Wishlist
                </a>
              </div>
              <Divider />
              <div className="userprofile_sidebar_h1">
                <RiLogoutCircleLine size={28} style={{ color: "blue" }} />
                <p className="userprofile_sidebar_heading">LOGOUT</p>
              </div>
            </Paper>
            {/* <br />
            <Paper>
              <Container>
                <div style={{ padding: "3px" }}>
                  <Typography>Frequently Visited:</Typography>
                </div>
                <div style={{ display: "flex", gap: "20px" }}>
                  <a href="" className="track_user">
                    Track Order
                  </a>
                  <a href="" className="track_user">
                    Help Center
                  </a>
                </div>
              </Container>
            </Paper> */}
          </Grid>

          {!open &&  ( <Grid item xs={12} sm={8}>
            <Paper style={{ padding: 20 }}>
        <div>
              <div>
                <h1
                  className="profile_heading"
                  style={{ paddingTop: "0px", fontSize: "18px" }}
                >
                  Personal Information
                  {!isEditable ? (
                    <button
                      className="profile-edit-button"
                      onClick={handleEditClick}
                    >
                      Edit
                    </button>
                  ) : (
                    <button
                      className="profile-edit-button"
                      onClick={() => setIsEditable(false)}
                    >
                      Cancel
                    </button>
                  )}
                </h1>
                <div className="profile-edit-div">
                  {!isEditable && (
                    <>
                      <input
                        input
                        type="text"
                        name="FirstName"
                        className="disable-input"
                        id="outlined-basic"
                        {...register("firstName")}
                        value={firstName}
                        disabled={!isEditable}
                      />
                      <input
                        type="text"
                        className="disable-input"
                        id="outlined-basic"
                        {...register("lastName")}
                        value={lastName}
                        disabled={!isEditable}
                      />
                    </>
                  )}
                  {isEditable && (
                    <>
                      <label
                        htmlFor="outlined-basic"
                        className="editing-input-label"
                      >
                        First Name
                      </label>
                      <input
                        input
                        type="text"
                        name="FirstName"
                        className="editing-input"
                        id="outlined-basic"
                        {...register("firstName")}
                        value={firstName}
                        disabled={!isEditable}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                      <label
                        htmlFor="outlined-basic-last"
                        className=" editing-input-label last-label"
                      >
                        Last Name
                      </label>
                      <input
                        type="text"
                        className="editing-input"
                        id="outlined-basic-last"
                        {...register("lastName")}
                        value={lastName}
                        disabled={!isEditable}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                      <Button
                        variant="contained"
                        className="mui-profile-button"
                        // className="profile-save-button"
                        onClick={handleFormSubmit}
                      >
                        Save
                      </Button>
                    </>
                  )}
                </div>
              </div>
              <div style={{ marginTop: "50px" }}>
                <h1
                  className="profile_heading"
                  style={{ paddingTop: "0px", fontSize: "18px" }}
                >
                  Email Address
                </h1>
                <div className="profile-edit-div">
                  <input
                    type="email"
                    id="outlined-basic"
                    label="Email"
                    className="disable-input"
                    value={SetEmail}
                  />
                </div>
              </div>
              <div style={{ marginTop: "50px" }}>
                <h1
                  className="profile_heading"
                  style={{ paddingTop: "0px", fontSize: "18px" }}
                >
                  Mobile Number
                  {!isEditables ? (
                    <button
                      className="profile-edit-button"
                      onClick={handleEditClicks}
                    >
                      Edit
                    </button>
                  ) : (
                    <button
                      className="profile-edit-button"
                      onClick={() => setIsEditables(false)}
                    >
                      Cancel
                    </button>
                  )}
                </h1>
                <div className="profile-edit-div">
                  {!isEditables && (
                    <>
                      <input
                        type="numbers"
                        id="outlined-basic"
                        label="Phone Number"
                        {...register("phone")}
                        className="disable-input"
                        value={phone}
                        disabled={!isEditable}
                      />
                    </>
                  )}
                  {isEditables && (
                    <>
                      <label
                        htmlFor="outlined-basic"
                        className="editing-input-label"
                      >
                        Mobile Number
                      </label>
                      <input
                        type="numbers"
                        id="outlined-basic"
                        // label="Phone Number"
                        {...register("phone")}
                        className="editing-input"
                        value={phone}
                        disabled={!isEditables}
                        onChange={(e) => setPhone(e.target.value)}
                      />

                      <Button
                        variant="contained"
                        className="mui-profile-button"
                        onClick={handleFormSubmit}
                      >
                        Save
                      </Button>
                    </>
                  )}
                </div>
              </div>
              </div>
            </Paper>
          </Grid>)}
      {open && (    <Grid item xs={12} sm={8}>
       
          <div>
                  <EditAddress />
          </div>
          
          </Grid>)}
        </Grid>
      </section>
    </>
  );
}

export default UserProfilePage;
