import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Link } from "react-router-dom";
import { Provider } from "react-redux";
//Material ui theme Provider
import { ThemeProvider, createTheme } from "@mui/material/styles";
import App from "./App";
// import "./Vstyles.css";
import "./styles.css";
//  Global stylesheet
import "./styles/styles.scss";
import store from "./store";
import { CookiesProvider } from "react-cookie";
import { theme } from "./Theme";
import { MaterialUIControllerProvider } from "./Components/ServiceProvider/dashboard/context";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>

  <Provider store={store}>
    <BrowserRouter>
      <MaterialUIControllerProvider>
        <CookiesProvider>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </CookiesProvider>
      </MaterialUIControllerProvider>
    </BrowserRouter>
  </Provider>

  // </React.StrictMode>
);
