import React, { useEffect, useState } from "react";
import MainRoutes from "./Routes";
import { Router } from "react-router-dom";
import { calculateWindowSize } from "./Components/QupAdmin/utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import { getAuthStatus } from "./Components/QupAdmin/utils/oidc-providers";
import { setAuthentication } from "./Components/QupAdmin/store/reducers/auth";
import { useWindowSize } from "./Components/QupAdmin/hooks/useWindowSize";
import { setWindowSize } from "./Components/QupAdmin/store/reducers/ui";

const App = () => {
  // const [isAppLoading, setIsAppLoading] = useState(true);
  const windowSize = useWindowSize();
  const screenSize = useSelector((state: any) => state.ui.screenSize);
  const dispatch = useDispatch();
  const getSession = async () => {
    try {
      let responses: any = await Promise.all([getAuthStatus()]);
      responses = responses.filter((r: any) => Boolean(r));
      if (responses && responses.length > 0) {
        dispatch(setAuthentication(responses[0]));
      }
    } catch (error: any) {
      console.error("error", error);
    }
    // setIsAppLoading(false);
  };

  useEffect(() => {
    getSession();
  }, []);
  useEffect(() => {
    const size = calculateWindowSize(windowSize.width);
    if (screenSize !== size) {
      dispatch(setWindowSize(size));
    }
  }, [windowSize]);
  // if (isAppLoading) {
  //   return <p>Loading</p>;
  // }

  return (
    <>
      <MainRoutes />
    </>
  );
};
export default App;
