import React, { useEffect, useState } from "react";
import { HiEye } from "react-icons/hi";
import { MdDeleteForever } from "react-icons/md";
import {
  Box,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "../../../api/axios";
import Preloader from "./Preloader";
import { GET_APPROVEDRFQ_VID } from "../../CompServices/Api_Routes_Helper";

function ApprovedRequest() {
  const v_id = localStorage.getItem("v_ID");
  const { vid, role } = JSON.parse(v_id);
  const [companyList, setCompanyList] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getCompany = async () => {
      await axios.get(`${GET_APPROVEDRFQ_VID}${vid}`).then((res) => {
        setCompanyList(res.data.data);
      });
    };
    getCompany();
  }, []);

  const reversedData = [...companyList].reverse();

  const columnName = [
    "CategoryName",
    "Subject",
    "Price",
    // "deliveryDate",
    "Action",
  ];

  const viewUrl = "/vendor/approvedrfqresponseView/";

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <div>
      {loading ? (
        <Preloader />
      ) : (
        <React.Fragment>
          <Container maxWidth={"lg"}>
            <br />
            <div className="Table_Container">
              <h4>RFQ Table</h4>
              <Box className="Table_wrapper" maxWidth={"xl"}>
                <TableContainer>
                  <Table className="table">
                    <TableHead>
                      <TableRow>
                        {columnName.map((list, i) => (
                          <TableCell key={i}>{list}</TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {reversedData.map((row, index) => (
                        <TableRow key={row._id} className="tableRow">
                          <TableCell>{row.categoryName}</TableCell>
                          <TableCell>{row.subject}</TableCell>
                          <TableCell>{row.price}</TableCell>
                          {/* <TableCell>{row.deliveryDate}</TableCell> */}
                          <TableCell>
                            {/* <button title="Edit" className="rfq_edit_btn">
                            <HiPencilAlt
                              onClick={() => {
                                navigate(`${editUrl}${row._id}`);
                              }}
                            />
                          </button> */}
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <Tooltip title="View">
                              <button className="rfq_view_btnn">
                                <HiEye
                                  onClick={() => {
                                    navigate(`${viewUrl}${row._id}`);
                                  }}
                                />
                              </button>
                            </Tooltip>
                            &nbsp;&nbsp;&nbsp;
                            <Tooltip title="Delete">
                              <button className="rfq_view_btnnn">
                                <MdDeleteForever
                                  onClick={() => {
                                    // navigate(`${viewUrl}${row._id}`);
                                  }}
                                />
                              </button>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </div>
          </Container>
        </React.Fragment>
      )}
    </div>
  );
}

export default ApprovedRequest;
