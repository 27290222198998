import ac1 from "./images/ac1.avif";
import ac2 from "./images/ac2.avif";
import bike1 from "./images/bike1.avif";
import bike2 from "./images/bike2.avif";
import car1 from "./images/car1.avif";
import car2 from "./images/car2.avif";
import event1 from "./images/event1.avif";
import event2 from "./images/event2.avif";
import ice1 from "./images/ice1.avif";
import ice2 from "./images/ice2.avif";
import manog1 from "./images/manog1.avif";
import manog2 from "./images/manog2.avif";
import nat1 from "./images/nat1.avif";
import nat2 from "./images/nat2.avif";
import paint1 from "./images/paint1.avif";
import paint2 from "./images/paint2.avif";
export const SlideMapping = [
  {
    image1: ac2,
    image2: ac1,
    avatar:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6eSZm1ovyXXKvzOonPb_U16AMpBj938lZlq7wf5xhtg&usqp=CAU&ec=48665698",
    name: "Ethan Hunt",
    level: "Level 2",
    para: "Slide 1 description Lorem ipsum dolor sit amet, consecteforn the same side lorem",
    star: "5.0",
    span: "(1.7)",
    rate: "₹500",
  },
  {
    image1: nat2,
    image2: nat1,
    avatar:
      "https://static.vecteezy.com/system/resources/thumbnails/001/993/889/small/beautiful-latin-woman-avatar-character-icon-free-vector.jpg",
    name: "Hermani ",
    level: "High Qty",
    para: "quas fugit sint soluta, consequatur et a quisquam! Tenetur quidem suscipit quia.",
    star: "4.8",
    span: "(1.8)",
    rate: "₹600",
  },
  {
    image1: manog2,
    image2: manog1,
    avatar:
      "https://media.istockphoto.com/id/1331335536/vector/female-avatar-icon.jpg?s=170667a&w=0&k=20&c=-iyD_53ZEeZPc4SmvmGB1FJXZcHy_fvbJBv6O8HblHs=",
    name: "Soniya",
    level: "Level 1",
    para: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Minima, labore? Mollitia",
    star: "3.9",
    span: "(1.9)",
    rate: "₹900",
  },
  {
    image1: event1,
    image2: event2,
    avatar:
      "https://png.pngtree.com/png-vector/20191101/ourmid/pngtree-male-avatar-simple-cartoon-design-png-image_1934458.jpg",
    name: "Avatar",
    level: "Level 2",
    para: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Minima, labore? Mollitia",
    star: "3.9",
    span: "(1.5)",
    rate: "₹800",
  },
  {
    image1: bike1,
    image2: bike2,
    avatar:
      "https://media.istockphoto.com/id/1331335633/vector/female-avatar-icon.jpg?s=612x612&w=0&k=20&c=2wtNjpPMjZqxpbDTuowyu2D2fHit6uMeESMfZOkCSKQ=",
    name: "Lusifer",
    level: "Level 1",
    para: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Minima, labore? Mollitia",
    star: "4.8",
    span: "(1.9)",
    rate: "₹800",
  },
  {
    image1: car1,
    image2: car2,
    avatar:
      "https://static.vecteezy.com/system/resources/thumbnails/002/002/332/small/ablack-man-avatar-character-isolated-icon-free-vector.jpg",
    name: "jack",
    level: "Top Quty",
    para: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Minima, labore? Mollitia",
    star: "4.8",
    span: "(1.5)",
    rate: "₹900",
  },
  {
    image1: paint1,
    image2: paint2,
    avatar:
      "https://static.vecteezy.com/system/resources/thumbnails/002/002/427/small/man-avatar-character-isolated-icon-free-vector.jpg",
    name: "fernadas",
    level: "Level 2",
    para: "loren is a high level software solution which is used for the same level of testing",
    star: "4.8",
    span: "(1.9)",
    rate: "₹800",
  },
  {
    image1: ice1,
    image2: ice2,
    avatar:
      "https://static.vecteezy.com/system/resources/thumbnails/002/002/403/small/man-with-beard-avatar-character-isolated-icon-free-vector.jpg",
    name: "Bobmarle",
    level: "Top Quty",
    para: "loren is a high level software solution which is used for the same level of testing",
    star: "4.8",
    span: "(1.5)",
    rate: "₹900",
  },
];
