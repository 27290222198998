import React, { useEffect, useState } from "react";
import { Box, Modal } from "@mui/material";

const RFQModal = ({ open, onClose, children, tableHeader }) => {
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoader(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          minWidth: "400px",
          bgcolor: "background.paper",
          boxShadow: 14,
          width: "calc(100% - 220px)",
          height: 550,
          overflowY: "scroll",
          overflowX: "hidden",
          borderRadius: "10px",
          display: "flex",
          flexDirection: "column", // Adjust this if needed
        }}
      >
        <div>
          <h4 className="h3_div_res">{tableHeader}</h4>
          <div className="closeModal" onClick={onClose}></div>
          {children}
        </div>
      </Box>
    </Modal>
  );
};

export default RFQModal;
