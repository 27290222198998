import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  headerContainer: {
    marginTop: 36,
    justifyContent: "flex-start",
    width: "50%",
  },

  billTo: {
    marginTop: 20,
    paddingBottom: 3,
    fontFamily: "Helvetica-Oblique",
  },
});

const BillTo = ({ invoice, ClientDetails }) => (
  <View style={styles.headerContainer}>
    {invoice ? (
      <>
        <Text style={styles.billTo}>Bill To:</Text>
        <Text style={styles.div}>
          <Text>{invoice[0].customerDetails.firstName}</Text>&nbsp;&nbsp;
          <Text>{invoice[0].customerDetails.lastName}</Text>
        </Text>
        <Text>{invoice[0].customerDetails.email}</Text>
        <Text>{invoice[0].customerDetails.phone}</Text>
      </>
    ) : null}
    {ClientDetails ? (
      <>
        {console.log("ClientDetails", ClientDetails)}
        <Text style={styles.billTo}>Bill From:</Text>
        <Text style={styles.div}>
          <Text>{ClientDetails.firstName}</Text>&nbsp;&nbsp;
          <Text>{ClientDetails.lastName}</Text>
        </Text>
        <Text>{ClientDetails.email}</Text>
        <Text>{ClientDetails.phone}</Text>
      </>
    ) : null}
  </View>
);

export default BillTo;
