import React, { useState } from "react";
import "./styles.css";
import { Button, Card, Container, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { CREATE_BUSSINESSVENDOR_OTPBASED } from "../../CompServices/Api_Routes_Helper";
import axios from "../../../api/axios";
import { useForm } from "react-hook-form";
import { ToastContainer } from "react-toastify";
import ToastMaker from "../../CompServices/ToastMaker";
import { submitButton } from "../../DesignTools/ButtonStyles/ButtonStyles";

const vendorSchema = Yup.object().shape({
  email: Yup.string()
    .required("email is required")
    .email("Email not matching criteria")
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      "Invalid email address"
    ),
});
const VendorPasswordlessSignup = () => {
  const {
    register,
    handleSubmit,
    getValues,
    reset,
    control,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      email: "",
    },
    resolver: yupResolver(vendorSchema),
  });
  const initialValues = {
    email: "",
  };
  const [alertResponse, setAlertResponse] = useState("");
  const [successful, setSuccessful] = useState(false);
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState(initialValues);

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormValues({ ...formValues, [name]: value });
  };

  const handleFormSubmit = async () => {
    try {
      const vendordata = getValues();
      console.log("vendordata", vendordata);
      const notifier = document.getElementById("notification-div");
      const postData = await axios
        .post(
          CREATE_BUSSINESSVENDOR_OTPBASED,

          vendordata,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          // console.log(response.data);
          setSuccessful(true);

          const signup_response = response.data;
          console.log(signup_response);
          const { type, message } = signup_response;
          if (type === "success") {
            console.log("working :>>");
            setAlertResponse(message);
            ToastMaker(message);
            notifier.classList.add("notify-success");
          }

          reset();
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status === 400) {
            const response = error.response;
            const errormessage = response.data;
            notifier.classList.add("notify-failure");
            console.log(errormessage);
            setAlertResponse(errormessage.message);
            ToastMaker(errormessage.message);
          }
          reset();
        });
    } catch (error) {
      console.error(error);
    }
  };

  const handleButtonClick = () => {
    navigate("/signin", {
      state: { tap: 2 },
    });
  };

  return (
      <div className="seller_page_star">
        <div className="b2b_intr">
          {/* <Card className="b2b_intr_card"> */}
          {successful === true ? (
            <div>
              <Typography
                variant="h3"
                component={"h5"}
                className="bold-text"
                sx={{ color: "green", textAlign: "center", margin: "auto" }}
              >
                Registration Successfull !
              </Typography>
              <p className="common-para register-para">
                "Congratulations! Your email has been confirmed and your account
                is ready.Please close this page and go back to the Email app to
                account."
              </p>
              <div className="common-para register-para">
                <Button
                  onClick={() => navigate("/signin")}
                  style={submitButton}
                >
                  Signin
                </Button>
              </div>
            </div>
          ) : (
            <div className="pm_form_main">
              <h2 className="bold-text1">QuoteurPrice</h2>
              <div className="pm-form-group">
                <label htmlFor="companyname" className="pm-form-label">
                  Email
                </label>
                <div>
                  <input
                    type="text"
                    className="pm-form-control"
                    id="email"
                    name="email"
                    {...register("email")}
                    autoComplete="off"
                    autoCapitalize="off"
                    spellCheck="true"
                    // value={formValues.email}
                    onChange={handleChange}
                  />
                  {errors?.email && (
                    <span className="input-validation-error">
                      {errors.email.message}
                    </span>
                  )}
                </div>
              </div>
              <br />
              <div className="pm-form-group">
                <button
                  type="submit"
                  className="pmt_sign-up-btn pm-btn pm-btn-primary sign-up-btn"
                  id="sign-up-btn"
                  // onClick={handleSubmit(handleFormSubmit, onErrors)}
                  onClick={handleFormSubmit}
                >
                  Create account
                </button>
              </div>

              <div className="pm-awesome-divider-div">
                <div className="pm-awesome-divider" data-label="or"></div>
              </div>
              <div className="sign-in-link-container">
                <div>
                  {/*                 
                Don't have account yet?
                <Link
                  className="pmt_sign-in-instead-lnk"
                  id="sign-in-link"
                  to="/signin"
                  style={{ outline: 0 }}
                >
                  Sign In
                </Link>
                {/* <Typography component="p" variant="p" className="common-para">
                  Don't have account yet?{" "}
                  <Typography
                    variant="p"
                    className="common-para"
                    component={Link}
                    to="/vendor/signin"
                    color="blue"
                    sx={{cursor:"pointer"}}
                  >
                    Sign Up
                  </Typography>
                  here
                </Typography> */}
                  <Typography
                    component="p"
                    variant="p"
                    className="common-para"
                    // sx={{ marginTop: "1rem", marginBottom: "1rem" }}
                  >
                   Already have an account?
                    <Typography
                      variant="p"
                      className="common-para"
                      onClick={handleButtonClick}
                      style={{
                        color: "blue",
                        fontWeight: "600",
                        cursor: "pointer",
                      }}
                    >
                      &nbsp; Sign In&nbsp;
                    </Typography>
                    here
                  </Typography>
                </div>
              </div>
              {/* <a
                    href="/google/oauth2?signup=1"
                    className="pm-btn pm-btn-info google-sign-up"
                  >
                    <div className="google-logo" >
                      <img
                        src="https://assets.getpostman.com/common-share/google-logo-icon-sign-in.svg"
                        width={"18px"}
                        height={"18px"}
                      />
                    </div>
                    <div className="google-text">
                      <div className="pmt_sign-up-w-google-btn">
                        Sign up with Google
                      </div>
                    </div>
                  </a> */}
            </div>
          )}
          {/* </Card> */}
          {/* </Box>
        </Modal> */}
        </div>
        <ToastContainer />
      </div>
  );
};

export default VendorPasswordlessSignup;
