import React, { useState, useEffect } from "react";
import styled from "styled-components";
import TextField from "@mui/material/TextField";
import { Container, Grid } from "@mui/material";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "../../../api/axios";
import { AiOutlineMore } from "react-icons/ai";
import LocationOnIcon from "@mui/icons-material/LocationOn";
const CustomCheckBox = styled("input")`
  border: 1px solid blue;
  &&:checked {
    background-color: #2196f3;
  }
`;

const CustomLabel = styled.label`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  padding: 10px;
  font-weight: 500;
  font-size: 15px;
  flex-flow: row wrap;
  margin-bottom: 0rem;
`;

const DeliveryComponent = ({ address, onSelect, isSelected, onsubmit }) => {
  const [addressInfo, setAddressInfo] = useState("");
  const [checked, setChecked] = useState(false);
  const [show, setShow] = useState(false);
  const [editedAddress, setEditedAddress] = useState({ ...address });

  useEffect(() => {
    function formatAddress(address) {
      return setAddressInfo(
        <div className="address_container ">
          <span style={{ fontFamily: "Poppins" }}>
            {address.recepientname}-({address.mobileNumber})
          </span>
          <p style={{ marginBottom: "0rem", fontWeight: 200, color: "gray" }}>
            {address.HBNo}, {address.roadAreaCol}, {address.landMark},
            {address.city}, {address.state} - {address.pincode}. <br />
            {Object.keys(address["AdditionalDlvGdlns"]).length > 0 && (
              <p style={{ marginBottom: "0rem" }}>
                Additional Guidelines: {address.AdditionalDlvGdlns}
              </p>
            )}
          </p>
        </div>
      );
    }
    formatAddress(address);
  }, [address]);

  const handleCheck = (event) => {
    setChecked(event.target.value);
    onSelect(address, !isSelected);
  };
  const [showEditForm, setShowEditForm] = useState(false);
  const handleEditClick = () => {
    setShowEditForm(true);
  };
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    console.log("editedAddress in handleClose:", editedAddress);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name !== "_id") {
      setEditedAddress({ ...editedAddress, [name]: value });
    }
    console.log("editedAddress in handleInputChange:", editedAddress);
  };
  const chooseAdd = () => {
    onsubmit(editedAddress);
  };
  const handleSaveChanges = async (e) => {
    e.preventDefault();
    const userId = localStorage.getItem("userID");
    // const config = { headers: { "Content-Type": "application/json" } };

    try {
      const { status, data } = await axios.put(
        `http://localhost:80/api/auth/updateadd`,
        {
          uid: userId,
          address: editedAddress._id,
          updatedAddress: editedAddress,
        }
        // config
      );

      if (status === 200) {
        console.log("Address updated successfully");
        // Optionally, you can handle success response here
      } else {
        console.log("Failed to update address");
        // Optionally, you can handle failure response here
      }
    } catch (error) {
      console.error("Error updating address:", error);
    }
    handleClose();
  };
  useEffect(() => {
    setEditedAddress({ ...address });
  }, [address]);
  console.log("editedAddress after useEffect:", editedAddress);
  return (
    <li>
      <div className="address_wrap">
        <CustomLabel htmlFor={`Delivery_Address_${address}`}>
          <div className="address_wrap_sub">
            <input
              type="radio"
              value={address}
              checked={isSelected}
              onChange={(e) => handleCheck(e)}
              name="addresses"
              id={`address_${address.recepientname}`}
            />
            <div>{addressInfo}</div>
            {isSelected && (
              <div className="address_edit">
                <div className="dropdowns">
                  <AiOutlineMore className="dropbtns" />
                  <div className="dropdown-contents">
                    <span onClick={handleShow}>Edit</span>
                    <span>Delete</span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </CustomLabel>

        <div
          className="modal show"
          style={{ display: show ? "block" : "none", position: "initial" }}
        >
          <Modal show={show} onHide={handleClose} centered>
            <Modal.Header>
              <Modal.Title>Edit Address</Modal.Title>
            </Modal.Header>
            <Button
              variant="primary"
              style={{ width: "200px", marginLeft: "40px", marginTop: "10px" }}
              size="sm"
            >
              <LocationOnIcon />
              &nbsp; Use my current location
            </Button>
            <Modal.Body>
              <Container disableGutters>
                <Grid container spacing={2}>
                  {Object.keys(editedAddress).map((key) => {
                    if (key !== "_id") {
                      return (
                        <Grid item xs={12} md={6} key={key}>
                          <TextField
                            label={key}
                            variant="outlined"
                            fullWidth
                            name={key}
                            value={editedAddress[key]}
                            onChange={handleInputChange}
                          />
                        </Grid>
                      );
                    }
                    return null;
                  })}
                </Grid>
              </Container>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={handleSaveChanges}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>
        </div>

        {isSelected && (
          <div className="address_continue d-block ">
            <button
              type="submit"
              style={{ marginTop: "-20px", marginLeft: "35px" }}
              className="btn btn-primary btn-sm"
              onClick={chooseAdd}
            >
              Deliver Here
            </button>
          </div>
        )}
      </div>
    </li>
    // <li>
    //   <div className="address_wrap">
    //     <CustomLabel htmlFor={`Delivery_Address_${address}`}>
    //       <div className="address_wrap_sub">
    //         <input
    //           type="radio"
    //           value={address}
    //           checked={isSelected}
    //           onChange={(e) => handleCheck(e)}
    //           name="addresses"
    //           id={`address_${address.recepientname}`}
    //         />
    //         {!showEditForm && <div>{addressInfo}</div>}
    //         {isSelected && (
    //           <div className="address_edit">
    //             <div className="dropdowns">
    //               <AiOutlineMore className="dropbtns" />
    //               <div className="dropdown-contents">
    //                 <span onClick={handleEditClick}>Edit</span>
    //                 <span>Delete</span>
    //               </div>
    //             </div>
    //           </div>
    //         )}

    //         {showEditForm && isSelected && (
    //           <div className="edit-form">
    //             <Container>
    //               <Grid container spacing={2}>
    //                 {Object.keys(editedAddress).map((key) => {
    //                   if (key !== "_id") {
    //                     return (
    //                       <Grid item xs={12} md={6} key={key}>
    //                         <TextField
    //                           label={key}
    //                           variant="outlined"
    //                           fullWidth
    //                           name={key}
    //                           value={editedAddress[key]}
    //                           onChange={handleInputChange}
    //                         />
    //                       </Grid>
    //                     );
    //                   }
    //                   return null;
    //                 })}
    //               </Grid>
    //             </Container>

    //             <Button variant="primary" onClick={handleSaveChanges}>
    //               Save Changes
    //             </Button>
    //           </div>
    //         )}
    //       </div>
    //     </CustomLabel>

    //     {!showEditForm && isSelected && (
    //       <div className="address_continue d-block ">
    //         <button
    //           type="submit"
    //           style={{ marginTop: "-20px", marginLeft: "12px" }}
    //           className="btn btn-primary btn-sm"
    //         >
    //           Deliver Here
    //         </button>
    //       </div>
    //     )}
    //   </div>
    // </li>
  );
};

export default DeliveryComponent;
