import React, { useEffect } from "react";
import { useNavigate, Navigate } from "react-router-dom";
import { logoutUser } from "../slices/auth";
import { useDispatch } from "react-redux";
import { auth } from ".././Services/Firebase";
import { signOut } from "firebase/auth";
const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logout = async () => {
    try {
      localStorage.removeItem("userNew");
      localStorage.removeItem("userID");
      localStorage.removeItem("email");
      await navigate("/signin", { replace: true, preventScrollReset: true });

      // await dispatch(logoutUser());
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div>
      <button type="submit" onClickCapture={logout}>
        logout
      </button>
    </div>
  );
};

export default Logout;
