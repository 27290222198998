/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "../../Dcomponents/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "../.././examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../.././examples/Navbars/DashboardNavbar";
import Footer from "../.././examples/Footer";
import ReportsBarChart from "../.././examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "../.././examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "../.././examples/Cards/StatisticsCards/ComplexStatisticsCard";
import Footers from "../../../../Footer/index";

// Data
import reportsBarChartData from "./data/reportsBarChartData";
import reportsLineChartData from "./data/reportsLineChartData";

// Dashboard components
import Projects from "./components/Projects";
import OrdersOverview from "./components/OrdersOverview";
import React, { useEffect, useState } from "react";
import { Cookies, useCookies } from "react-cookie";
import axios from "../../../../../api/axios";
import {
  Fetch_RFQ_request,
  GET_RFQS_RESPONSES_VID,
  VENDOR_INFO,
} from "../../../../CompServices/Api_Routes_Helper";
import RecievedRFQs from "./components/Projects/RecentRFQs";
import { Card } from "@mui/material";
import CategoryInfo from "../billing/components/CategoryInfo";
import { useLocation } from "react-router-dom";
import RFQModal from "../../../../CategoryList/RFQModal";
import ProfileField from "../profile/ProfileField";
import { ToastContainer } from "react-toastify";

function VDashboard() {
  const { sales, tasks } = reportsLineChartData;
  const [requestno, setRequestsNo] = useState(0);
  const [sendRespNo, setSendRespNo] = useState(0);
  const [details, setDetails] = useState([]);
  const v_id = localStorage.getItem("v_ID");
  const { vid, role } = JSON.parse(v_id);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

useEffect(() => {
  const fetchData = async () => {
    try {
      if (vid) {
        await Promise.all([
          getVendorDetails(vid),
          fetchVendorReqDetails(vid),
          fetchVendorSendReqDetails(vid),
        ]);
      }

      const response = await axios.get(`${VENDOR_INFO}/${vid}`);
      const shouldShowPopup = response.data.data.isChecked;

      if (shouldShowPopup === "Received") {
        handleOpen();
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  fetchData();
}, [vid]);


  // useEffect(() => {
  //   if (vid) {
  //     getVendorDetails(vid);
  //     fetchVendorReqDetails(vid);
  //     fetchVendorSendReqDetails(vid);
  //   }
  //   axios
  //     .get(`${VENDOR_INFO}/${vid}`)
  //     .then((response) => {
  //       const shouldShowPopup = response.data.data.isChecked;
  //       if (shouldShowPopup === "Received") {
  //         handleOpen();
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error checking popup status:", error);
  //     });
  // }, [vid, requestno, sendRespNo]);

  const getVendorDetails = async (vendorId) => {
    if (!vendorId) {
      return;
    }
    try {
      const response = await axios.get(`${VENDOR_INFO}/${vendorId}`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (
        response &&
        response.data &&
        response.data.data &&
        response.data.data.category
      ) {
        const vendorholdings = response.data.data.category;
        setDetails(vendorholdings);
      }
    } catch (error) {
      console.error(error);
    } finally {
      console.log("getVendorDetails function execution completed.");
    }
  };
  const fetchVendorReqDetails = async (vendorId) => {
    if (!vendorId) {
      return;
    }
    try {
      const response = await axios.get(`${Fetch_RFQ_request}${vendorId}`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      // if (response) {
      //   const vendorholdings = response.data;
      //   if (Array.isArray(vendorholdings)) {
      //     setRequestsNo(vendorholdings.length);
      //   } else {
      //     setRequestsNo(0);
      //   }
      // } else {
      //   setRequestsNo(0);
      // }
      
      if (response) {
        const vendorholdings = response.data;
        setRequestsNo(Array.isArray(vendorholdings) ? vendorholdings.length : 0);
      } else {
        setRequestsNo(0);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchVendorSendReqDetails = async (vendorId) => {
    if (!vendorId) {
      return;
    }
    try {
      const { data } = await axios.get(`${GET_RFQS_RESPONSES_VID}${vendorId}`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (data) {
        const vendorholdings = data.data;
        // if (vendorholdings !== null) {
        //   setSendRespNo(vendorholdings.length);
        // }
        setSendRespNo(vendorholdings !== null ? vendorholdings.length : 0);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const setPostReq = (data) => {
    getVendorDetails(vid);
    handleClose();
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="weekend"
                title="RFQ request's"
                list=""
                count={requestno}
                percentage={{
                  color: "success",
                  amount: "+55%",
                  label: "than lask week",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="leaderboard"
                title="RFQ response's"
                count={sendRespNo}
                list=""
                percentage={{
                  color: "success",
                  amount: "+3%",
                  label: "than last month",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="person_add"
                title="OUR SERVICES"
                list={
                  details.length > 1
                    ? details.map((value, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && ", "}
                          {value}
                        </React.Fragment>
                      ))
                    : details[0]
                }
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>

      {/* <RFQModal
        open={open}
        // onClose={handleClose}
        tableHeader={"Profile Update"}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ProfileField setPostReq={setPostReq} />
      </RFQModal> */}
      <ToastContainer />
      <MDBox mt={4.5}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={3}>
              <ReportsBarChart
                color="info"
                title="website views"
                description="Last Campaign Performance"
                date="campaign sent 2 days ago"
                chart={reportsBarChartData}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={3}>
              <ReportsLineChart
                color="success"
                title="daily sales"
                description={
                  <>
                    (<strong>+15%</strong>) increase in today sales.
                  </>
                }
                date="updated 4 min ago"
                chart={sales}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={3}>
              <ReportsLineChart
                color="dark"
                title="completed tasks"
                description="Last Campaign Performance"
                date="just updated"
                chart={tasks}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox>
        <Grid container spacing={3} mb={4}>
          <Grid item xs={12} md={6} lg={8}>
            {/* <Projects /> */}
            <RecievedRFQs vendorIdProps={vid} />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <OrdersOverview />
          </Grid>
        </Grid>
      </MDBox>
      {/* </MDBox> */}
      {/* <MDBox>
        <Grid item xs={12} md={6} lg={6}>
          <MDBox mb={1.5}>
            <Card>
              <CategoryInfo />
            </Card>
          </MDBox>
        </Grid>
      </MDBox> */}
      <Footers />
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default VDashboard;
