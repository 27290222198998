import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import Moment from "react-moment";
import { CheckCircle, Edit, Cancel, Chat } from "@mui/icons-material";
import "../Respond.scss";
import { HiEye, HiPencilAlt } from "react-icons/hi";
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  Modal,
  Rating,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import {
  APPROVE_ARRRFQ_POST,
  CREATE_ORDER_INFO,
  REJECT_REJECT_POST,
} from "../../../CompServices/Api_Routes_Helper";
import { toggleRowStatus, toggleReject } from "../../../../slices/RFQstatus";
import {
  addrowtobatch,
  clearBatchUpdate,
  removefrombatch,
} from "../../../../slices/RFQresponse";
import authHeader from "../../../../Services/auth-header";
import { useLocation } from "react-router-dom";

const VendorDraftModel = ({ itemSet, popupOpens, receiveQuotation }) => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { sendId } = state;

  //can select ether of 2 to get row id values
  const Currstatus = useSelector((state) => state.rfqstatus);
  const Batchids = useSelector((state) => state.batchresponse);
  // const userInfo = useSelector((state) => {
  //   const user = state.auth?.user;
  //   const info = JSON.parse(user);
  //   const details = {
  //     clientId: info.res?._id,
  //     clientName: info.res?.firstName || null,
  //     clientEmail: info.res?.email || null,
  //   };
  //   return details;
  // });
  // useEffect(() => {}, [dispatch]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rating, setRating] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  

  const handleResponse = async (event) => {
    setIsModalOpen(true);
    try {
      const rowids = Object.keys(Currstatus).filter((rfqid) => {
        return Currstatus[rfqid] === "Accepted";
      });

      const { data } = await axios.post(`${APPROVE_ARRRFQ_POST}`, rowids, {
        headers: await authHeader(),
      });
      const result = data;
      if (result) {
        dispatch(clearBatchUpdate());
        // dispatch(clearAllRowStatus());
      }
    } catch (error) {
      console.error(error);
      dispatch(clearBatchUpdate());
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleRatingChange = (event, newValue) => {
    setRating(newValue);
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleSaveReview = () => {
    // Collect review data and display in the console
    console.log("Rating:", rating);
    console.log("Selected Option:", selectedOption);

    // Close the modal
    handleCloseModal();

    setRating(0);
    setSelectedOption("");
  };

  const handleRejectResponse = async (Currstatus) => {
    try {
      const rowids = Object.keys(Currstatus).filter((rfqid) => {
        return Currstatus[rfqid] === "Rejected";
      });
      const { data } = await axios.post(`${REJECT_REJECT_POST}`, rowids, {
        headers: await authHeader(),
      });
      // const result = data;
      if (data) {
        dispatch(clearBatchUpdate());
      }
    } catch (error) {
      console.error(error);
      dispatch(clearBatchUpdate());
    }
  };

  const handleOrder = async (event) => {
    event.preventDefault();
    const { data } = await axios.post(
      `${CREATE_ORDER_INFO}`,
      { Batchids, sendId },
      {
        headers: await authHeader(),
      }
    );
    // Reset the acceptance status
    setIsAccepted(false);
  };
  // const handleOrder = async (event) => {
  //   event.preventDefault();
  //   const { data } = await axios.post(
  //     `${CREATE_ORDER_INFO}`,
  //     { Batchids, userInfo, sendId },
  //     {
  //       headers: await authHeader(),
  //     }
  //   );
  // };
  const [isAccepted, setIsAccepted] = useState(false);

  const handleSaveClick = async (event, data) => {
    try {
      event.preventDefault();
      let rfqid = data;
      dispatch(toggleRowStatus({ rfqid, status: "Accepted" }));
      dispatch(addrowtobatch(data));
      setIsAccepted(true); // Set acceptance status to true
    } catch (error) {
      console.error(error);
    }
  };


  const handleRejectClick = async (event, data) => {
    event.preventDefault();
    let rfqid = data;
    dispatch(toggleReject({ rfqid, status: "Rejected" }));
    dispatch(removefrombatch(rfqid));
    const obj = {};
    obj[rfqid] = "Rejected";
    await handleRejectResponse(obj);
  };

  const handleEditClick = () => {
    popupOpens();
  };

  const columns = [
    { field: "items", headerName: "Item", width: 120 },
    {
      field: "quantity",
      headerName: "quantity",
      width: 80,
      type: "number",
      // editable: true,
    },
    {
      field: "unit",
      headerName: "Unit",
      width: 80,
      type: "singleSelect",
      valueOptions: ["kg", "grams", "Tonnes", "Pieces"],
    },
    {
      field: "price",
      headerName: "Expected Price",
      width: 100,
      valueFormatter: (params) => {
        const price = params.value;
        if (price !== null) {
          const newPrice = new Intl.NumberFormat("en-IN", {
            // style: "currency",
            currency: "INR",
          }).format(price);
          return newPrice;
        }
        return "---NA---";
      },
    },
    {
      field: "responseQuantity",
      headerName: "Quatation Quantity",
      width: 100,
    },
    {
      field: "responsePrice",
      headerName: "Quatation Price",
      width: 100,
      valueFormatter: (params) => {
        const responsePrice = params.value;
        if (responsePrice !== null) {
          const newResponsePrice = new Intl.NumberFormat("en-IN", {
            currency: "INR",
          }).format(responsePrice);
          return newResponsePrice;
        }
        return "---NA---";
      },
    },
    {
      field: "responseTax",
      headerName: "Quatation Tax",
      width: 100,
      valueFormatter: (params) => {
        if (params.value === null) {
          return "";
        }
        return params.value + "%";
      },
    },
    {
      field: "responseDate",
      headerName: "Quatation Date",
      width: 140,
      renderCell: (params) => {
        const responseDate = params.row.responseDate;

        if (responseDate) {
          return <Moment format="DD/MM/YYYY">{responseDate}</Moment>;
        } else {
          return <span>{"---NA---"}</span>;
        }
      },
    },
    {
      field: "status",
      headerName: "status",
      width: 100,
      renderCell: (params) => {
        const { _id } = params.row;
        const rowAcceptanceStatus = Currstatus[_id];
        return rowAcceptanceStatus;
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      renderCell: (params) => {
        const actions = (id) => {
          if (receiveQuotation) {
            // Return actions with CheckCircle icon
            return [
              <Box>
                <GridActionsCellItem
                  icon={<CheckCircle />}
                  title="Accepted"
                  label="Save"
                  sx={{
                    color: "success.main",
                  }}
                  onClick={(e) => handleSaveClick(e, id)}
                />
                <GridActionsCellItem
                  icon={<Cancel />}
                  title="Reject"
                  label="Reject"
                  sx={{
                    color: "red",
                  }}
                  onClick={(e) => handleRejectClick(e, id)}
                />
                <GridActionsCellItem
                  icon={<Chat />}
                  title="Chat"
                  label="Chat"
                  sx={{
                    color: "primary.main",
                  }}
                  onClick={(e) => handleRejectClick(e, id)}
                />
              </Box>,
            ];
          } else {
            // Return actions with Edit icon
            return [
              <GridActionsCellItem
                icon={<Edit />}
                label="Edit"
                sx={{
                  color: "primary.main",
                }}
                onClick={handleEditClick}
              />,
            ];
          }
        };
        const { _id } = params.row;
        return actions(_id);
      },
      // getActions: actions(),
    },
  ];

  return (
    <Container style={{ padding: 0 }}>
      {/* <Typography component={"section"} variant={"body1"}> */}
      <Grid container>
        <Grid item lg={12} md={12} xs={12}>
          <Box sx={{ overflow: "auto" }}>
            <DataGrid
              rows={itemSet}
              columns={columns}
              getRowId={(row) => row.lineItemId}
              headerClassName="custom-header-cell"
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
             {isAccepted && (
              <>
                <Button
                  variant="contained"
                  color="info"
                  onClick={(event) => handleResponse(event)}
                >
                  Save
                </Button>
                {/* <Modal
              open={isModalOpen}
              onClose={handleCloseModal}
              aria-labelledby="modal-title"
              aria-describedby="modal-description"
            >
              <Box
                sx={{
                  position: "absolute",
                  width: 400,
                  bgcolor: "background.paper",
                  border: "2px solid #000",
                  boxShadow: 24,
                  p: 4,
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <Typography id="modal-title" variant="h6" component="h2">
                  Vendor Review
                </Typography>
                <Rating
                  name="vendor-rating"
                  value={rating}
                  onChange={handleRatingChange}
                />
                <FormControl sx={{ mt: 2, minWidth: 200, marginRight: 10 }}>
                  <Select
                    value={selectedOption}
                    onChange={handleOptionChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Select an option" }}
                  >
                    <MenuItem value="" disabled>
                      Select an option
                    </MenuItem>
                    <MenuItem value="top-rated">Top Rated</MenuItem>
                    <MenuItem value="level-1">Level 1</MenuItem>
                    <MenuItem value="level-2">Level 2</MenuItem>
                  </Select>
                </FormControl>
                <Button
                  variant="contained"
                  sx={{ mt: 3 }}
                  onClick={handleSaveReview}
                >
                  Save Review
                </Button>
              </Box>
            </Modal> */}
                <Button
                  type="submit"
                  onClick={(event) => handleOrder(event)}
                  variant="outlined"
                  color="success"
                >
                  Create Order
                </Button>
              </>
            )}
          </Box>
        </Grid>
      </Grid>
      {/* </Typography> */}
    </Container>
  );
};

export default VendorDraftModel;
