import { getAuthStatus } from "./oidc-providers";
import { setAuthentication } from "../store/reducers/auth";
export const sleep = (time: number) =>
  new Promise((res) => setTimeout(res, time));
export const calculateWindowSize = (windowWidth: number) => {
  if (windowWidth >= 1200) {
    return "lg";
  }
  if (windowWidth >= 992) {
    return "md";
  }
  if (windowWidth >= 768) {
    return "sm";
  }
  return "xs";
};

export const setWindowClass = (classList: string) => {
  const window: HTMLElement | null =
    document && document.getElementById("root");
  if (window) {
    // @ts-ignore
    window.classList = classList;
  }
};
export const addWindowClass = (classList: string) => {
  const window: HTMLElement | null =
    document && document.getElementById("root");
  if (window) {
    // @ts-ignore
    window.classList.add(classList);
  }
};

export const removeWindowClass = (classList: string) => {
  const window: HTMLElement | null =
    document && document.getElementById("root");
  if (window) {
    // @ts-ignore
    window.classList.remove(classList);
  }
};
