import authService from "../Services/auth.service";
import {
  createSlice,
  createAsyncThunk,
  //  current
} from "@reduxjs/toolkit";
import message, { SetMessage } from "./message";
import { auth } from "../Services/Firebase";
import { signOut } from "firebase/auth";
import { SIGNUP_CLIENT } from "../Components/CompServices/Api_Routes_Helper";
import axios from "axios";
const user = localStorage.getItem("userNew");

export const registerUser = createAsyncThunk(
  "auth/register",
  async (details, thunkAPI) => {
    try {
      const { data } = await axios.post(`${SIGNUP_CLIENT}`, details);
      const { type, message } = data;
      if (type === "SUCCESS") {
        thunkAPI.dispatch(SetMessage(message));
        return message;
      }
    } catch (error) {
      console.error(error);
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.toString();
      console.error(message);
      thunkAPI.dispatch(SetMessage(message));
      return thunkAPI.rejectWithValue({ message });
    }
  }
);
export const login = createAsyncThunk(
  "auth/login",
  async ({ email, otp }, thunkAPI) => {
    try {
      const response = await authService.login(email, otp);
      // console.log(response);

      if (response.status === 401 && response.statusText === "Unauthorized") {
        console.log(message, "inside auth");
        thunkAPI.dispatch(SetMessage(message));
        return thunkAPI.rejectWithValue(response);
      } else if (response.status === 500) {
        const message = response.data.message;

        throw new Error(message);
      } else if (response.status === 419) {
        const { message } = response.data;
        thunkAPI.dispatch(SetMessage(message));
        return thunkAPI.rejectWithValue(response);
      } else if (response.status === 400) {
        const { message } = response.data;
        thunkAPI.dispatch(SetMessage(message));
        return thunkAPI.rejectWithValue(response);
      } else {
        // const {data}=response;
        // const {message} = data;
        // console.log(message);
        thunkAPI.dispatch(SetMessage("User Login Successfull!"));
        return response;
      }
    } catch (error) {
      console.log("Error in redux thunk" + error);
      const errorMessage = "Invalid OTP";
      thunkAPI.dispatch(SetMessage(errorMessage));
      throw new Error(errorMessage);
    }
  }
);
//for email and password login (Later)
export const loginwithEmailPwd = createAsyncThunk(
  "auth/login",
  async ({ username, password }, thunkAPI) => {
    try {
      const data = await authService.login(username, password);
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(SetMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const logoutUser = createAsyncThunk(
  "auth/logout",
  async (url, thunkAPI) => {
    try {
      const userType = auth?.currentUser;
      function removeStored(any) {
        localStorage.removeItem("userNew");
        localStorage.removeItem("userID");
        localStorage.removeItem("email");
      }
      if (userType) {
        if (userType.providerId === "firebase") {
          await signOut(auth);
          thunkAPI.dispatch(SetMessage("User logout successfull !"));
          removeStored();
          return "Logout successful";
        }
      } else {
        removeStored();
        thunkAPI.dispatch(SetMessage("User logout successfull !"));
        return "Logout successful";
      }
    } catch (error) {
      console.error(error);
      throw new Error(error);
      // return thunkAPI.rejectWithValue();
    }
  }
);
const initialState = user
  ? { isLoggedIn: true, user }
  : { isLoggedIn: false, user: null };
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(registerUser.fulfilled, (state, action) => {
        state.isLoggedIn = false;
        state.user = action.payload.data;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.isLoggedIn = false;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoggedIn = true;
        state.user = action.payload;
        // state.message = action.payload;
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoggedIn = false;
        state.user = null;
      })
      .addCase(logoutUser.rejected, (state, action) => {
        state.isLoggedIn = true;
        state.user = action.payload;
      })
      .addCase(logoutUser.fulfilled, (state, action) => {
        state.isLoggedIn = false;
        state.user = null;
      });
  },
});

const { reducer } = authSlice;

export default reducer;
