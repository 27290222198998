import { Box, Button, Container, Divider, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import "./SellStyles.scss";
import LanguageSelector from "./Language";
import { useForm } from "react-hook-form";
import { message } from "antd";

const SellingBussinessInfo = ({
  finalData,
  handleFinalData,
  steps,
  current,
  prev,
  next,
}) => {
  const [Subject, setSubject] = useState();

  const [dataFromChild, setDataFromChild] = useState("");

  const [file, setFile] = useState();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const { register, getValues, handleSubmit } = useForm({
    defaultValues: finalData,
  });

  const onSubmit = () => {
    const value = getValues();

    handleFinalData({
      ...finalData,
      firstName: value.firstName,
      lastName: value.lastName,
      displayName: value.displayName,
      languages: dataFromChild,
    });
    next();
  };

  const handleChildData = (data) => {
    setDataFromChild(data);
  };

  function handleChange(e) {
    console.log(e.target.files);
    setFile(URL.createObjectURL(e.target.files[0]));
  }

  return (
    <div className="seller_onboarding_info">
      <Container className="personal-info-container">
        <div className="onboarding-introduction">
          <div id="personal_info" className="start_form">
            <div className="onboarding-step personal-info">
              <div className="step-header">
                <h2>Personal Info</h2>
                <p>
                  Tell us a bit about yourself. This information will appear on
                  your public profile, so that potential buyers can get to know
                  you better.
                </p>
                <p className="mandatory">
                  <small>* mandatory Fields</small>
                </p>
              </div>
              <Divider />
              <Box component={"div"} aria-labelledby="person_information">
                <form className="personal_form">
                  <div id="full_name" className="onboarding-field is-required">
                    <aside>
                      <h3 className="font-accent">
                        <span>Full Name</span>
                        <small>Private</small>
                      </h3>
                      <div className="education-text">Ex. John Smith</div>
                    </aside>

                    <div className="field-content">
                      <div className="full-name">
                        <div className="full-name-inputs">
                          <div className="WvIqLXU eXfr7qm name-input">
                            <div className="+T1b6wH">
                              <input
                                className="GD3asS+"
                                type="text"
                                name="first_name"
                                maxLength="35"
                                placeholder="Full Name"
                                {...register("firstName", {
                                  required: true,
                                })}
                                value={Subject}
                                onChange={(e) => setSubject(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="display_name"
                    className="onboarding-field is-required"
                  >
                    <aside>
                      <h3 className="font-accent">
                        <span>Display Name</span>
                      </h3>
                      <div className="education-text">
                        To help build credible and authentic connections with
                        customers, they’ll now see your display name.
                        <br />
                        We suggest using your first name and first initial of
                        your last name.
                      </div>
                    </aside>
                    <div className="field-content">
                      <div className="display-name-container">
                        <div className="WvIqLXU eXfr7qm name-input">
                          <div className="+T1b6wH">
                            <input
                              className="GD3asS+"
                              type="text"
                              name="display_name"
                              maxLength="35"
                              placeholder="Display Name"
                              {...register("displayName", { required: true })}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="profile_pic"
                    className="onboarding-field is-required"
                  >
                    <aside>
                      <h3 className="font-accent">
                        <span>Profile Pic</span>
                      </h3>
                      <div className="education-text">
                        Add a profile picture of yourself so customers will know
                        exactly who they’ll be working with.
                      </div>
                    </aside>
                    <div className="field-content">
                      <section className="">
                        <div className="user-profile-image">
                          <label className="profile-pict editable">
                            <div>
                              <img
                                src={file}
                                className="image_hover"
                                style={{
                                  width: "200px",
                                  height: "200px",
                                  border: "2px solid",
                                  borderRadius: "100px",
                                  display: "flex",
                                }}
                              />{" "}
                              <input
                                type="file"
                                style={{ marginTop: "30px", display: "none" }}
                                onChange={handleChange}
                              />
                            </div>
                          </label>
                        </div>
                      </section>
                    </div>
                  </div>
                  <div
                    id="display_name"
                    className="onboarding-field is-required"
                  >
                    <aside>
                      <h3 className="font-accent">
                        <span>Languages</span>
                      </h3>
                      <div className="education-text">
                        Select which languages you can communicate in and your
                        proficiency level.
                      </div>
                    </aside>
                    <div className="field-content">
                      <LanguageSelector onChildData={handleChildData} />
                    </div>
                  </div>
                  <div className="button_SellingInfo">
                    {/* <Button
                      type="submit"
                      variant="contained"
                      onClick={onSubmit}
                    >
                      continue
                    </Button> */}
                  </div>
                </form>
              </Box>
            </div>
          </div>
        </div>
        <div
          style={{
            marginTop: 24,
            display: "flex",
            justifyContent: "end",
            marginBottom: "30px",
          }}
        >
          {current > 0 && (
            <Button
              style={{
                margin: "0 8px",
              }}
              onClick={() => prev()}
            >
              Previous
            </Button>
          )}
          {current < steps.length - 1 && (
            <Button type="primary" onClick={handleSubmit(onSubmit)}>
              Next
            </Button>
          )}
          {current === steps.length - 1 && (
            <Button
              type="primary"
              onClick={() => message.success("Processing complete!")}
            >
              Done
            </Button>
          )}
        </div>
      </Container>
    </div>
  );
};

export default SellingBussinessInfo;
