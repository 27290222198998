import React from "react";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { setWindowClass } from "../../utils/helpers";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Form, InputGroup } from "react-bootstrap";
import { PfButton } from "@profabric/react-components";

const RecoverPassword = () => {
  const [t] = useTranslation();

  const { handleChange, values, handleSubmit, touched, errors } = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(5, "Must be 5 characters or more")
        .max(30, "Must be 30 characters or less")
        .required("Required"),
      confirmPassword: Yup.string()
        .min(5, "Must be 5 characters or more")
        .max(30, "Must be 30 characters or less")
        .required("Required")
        .oneOf([Yup.ref("password")], "Password must be matching"),
    }),
    onSubmit: (values) => {
      toast.warn("Not yet functional");
      // eslint-disable-next-line no-console
      console.log("values", values);
    },
  });

  setWindowClass("hold-transition login-page");
  return (
    <div className="login-box">
      <div className="card card-outline card-primary">
        <div className="card-header text-center">
          <Link to="/" className="h1">
            <span>QuoteUrPrice</span>
          </Link>
        </div>
        <div className="card-body">
          <p className="login-box-msg">{t ("recover.oneStepAway") as string}</p>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <InputGroup className="mb-3">
                <Form.Control
                  id="password"
                  name="password"
                  type="password"
                  placeholder="Password"
                  onChange={handleChange}
                  value={values.password}
                  isValid={touched.password && !errors.password}
                  isInvalid={touched.password && !!errors.password}
                />
                {touched.password && errors.password ? (
                  <Form.Control.Feedback type="invalid">
                    {errors.password}
                  </Form.Control.Feedback>
                ) : (
                  // <InputGroup.Append>
                  <InputGroup.Text>
                    <i className="fas fa-lock" />
                  </InputGroup.Text>
                  // </InputGroup.Append>
                )}
              </InputGroup>
            </div>
            <div className="mb-3">
              <InputGroup className="mb-3">
                <Form.Control
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  placeholder="Confirm password"
                  onChange={handleChange}
                  value={values.confirmPassword}
                  isValid={touched.confirmPassword && !errors.confirmPassword}
                  isInvalid={
                    touched.confirmPassword && !!errors.confirmPassword
                  }
                />
                {touched.confirmPassword && errors.confirmPassword ? (
                  <Form.Control.Feedback type="invalid">
                    {errors.confirmPassword}
                  </Form.Control.Feedback>
                ) : (
                  // <InputGroup.Append>
                    <InputGroup.Text>
                      <i className="fas fa-lock" />
                    </InputGroup.Text>
                  //  </InputGroup.Append> 
                )}
              </InputGroup>
            </div>
            <div className="row">
              <div className="col-12">
                <PfButton type="submit" block>
                  {/* @ts-ignore */}
                  {t("recover.changePassword") as string}
                </PfButton>
              </div>
            </div>
          </form>
          <p className="mt-3 mb-1">
            <Link to="/qup/admin/login">
              {t("login.button.signIn.label") as string}
            </Link>
          </p>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default RecoverPassword;
