import { auth } from "./Firebase";

export default async function authHeader() {
  const user = JSON.parse(localStorage.getItem("userNew"));

  if (auth.currentUser) {
    try {
      const accessToken = await auth.currentUser.getIdToken();
      return { "x-acces-token": `Bearer ${accessToken}` };
    } catch (error) {
      console.error("error fetching token", error);
      throw error;
    }
  } else if (user) {
    const token = user?.res.tokens;
    const accessToken = token.find((t) => t.type === "access");
    return { Authorization: `Bearer ${accessToken.token}` };
  } else {
    return;
  }
}
