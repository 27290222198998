import React, { useEffect, useState } from "react";
import format from "../_helpers/formatTime";
import moment from "moment";
import PropTypes from "prop-types";
interface StatusTrackerProps {
  stages: Array<{
    completed: boolean;
    title: string;
    stageName: string;
    createdAt: string;
  }>;
}
const StatusTracker: React.FC<StatusTrackerProps> = ({ stages }) => {
  const [stagesIn, setStagesIn] = useState<
    Array<{
      completed: boolean;
      title: string;
      stageName: string;
      createdAt: string;
    }>
  >(stages);
  const [crrstatus, setCrrstatus] = useState("");
  function customClassName(stageClass: boolean) {
    let name = "stepper-item";
    if (stageClass) {
      name = "stepper-item complete";
    }
    return name;
  }

  return (
    <div className="row">
      <div className="col-12">
        <div className="stepper-container">
          <ol className="stepper">
            {stagesIn.map((data: any, index: number) => {
              return (
                <li
                  className={customClassName(data.completed)}
                  key={`stage_${index}`}
                >
                  <span className="stepper-counter"></span>
                  <div className="stepper-link">
                    <h6 className="stepper-title">{data.title}</h6>
                    <div className="stepper-body-data">
                      <ol>
                        <li className="stepper-stg-li-item">
                          <span>{data.stageName}.</span>
                          <span className="stepper-initiate-dt">
                            {moment(data.createdAt).format(
                              "dddd, MMMM Do YYYY, h:mm:ss a"
                            )}
                          </span>
                        </li>
                      </ol>
                    </div>
                  </div>
                  <span className="stepper-line"></span>
                </li>
              );
            })}
          </ol>
        </div>
      </div>
    </div>
  );
};
export default StatusTracker;
