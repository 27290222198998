import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ContentHeader } from "../components";
import {
  DataGrid,
  GridColDef,
  GridValueFormatterParams,
  GridActionsCellItem,
  GridToolbar,
  GridRowParams,
  GridEventListener,
} from "@mui/x-data-grid";
import { Box, Tooltip } from "@mui/material";
import axios from "../../../api/axios";
import { ADMIN_FETCH_RFQS, host } from "../../CompServices/Api_Routes_Helper";
import moment from "moment";
import RFQModal from "../../CategoryList/RFQModal";
import CategoryListView from "../../CategoryList/CategoryListView";
import { AdminGreenIcon, AdminRedIcon } from "../../Syledcomponent/Style";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import BookmarkRemoveRoundedIcon from "@mui/icons-material/BookmarkRemoveRounded";

import ModifyRequests from "../components/admin-modify/modifyRequests";
import ReuseDataGrid from "../../ReuseTable/ReuseDataGrid";
interface Rowtype {
  _id: string;
}
const Blank = () => {
  const [rfqdata, setRqfData] = useState([]);
  //to handle Modal open/close
  const [viewReqmdl, setViewReqmdl] = useState(false);
  const [requestId, setRequestId] = useState(null);
  // const [rowActed, setRowActed] = useState(false);
  const [uDt, setUDt] = useState(null);
  const [selectedRow, setSelectedRow] = React.useState<Rowtype | null>(null);
  const handleRowClick: GridEventListener<"rowClick"> = (params) => {
    setSelectedRow(params.row);
    setViewReqmdl(true);
  };

  const handleRejectClick = () => {
    const reason = window.prompt("Enter reason for rejection:");

    if (reason !== null) {
      storeRejectionReason(reason);
    } else {
      handleCancelReject();
    }
  };

  const storeRejectionReason = async (reason: any) => {
    try {
      const response = await fetch(
        "http://localhost:80/api/admin/create/reject/value",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ rejectionReason: reason }),
        }
      );

      if (!response.ok) {
        console.error("Failed to store rejection reason:", response.statusText);
        // Handle error as needed
      } else {
        const data = await response.json();
        console.log("Rejection reason stored successfully:", data);
        // You can update state or perform other actions as needed
      }
    } catch (error: any) {
      console.error("Error storing rejection reason:", error?.message);
    }
  };

  const handleCancelReject = () => {
    console.log("User canceled rejection");
  };

  const OpenRFQRequest = (event: any, idArr: any, data: any) => {
    event.preventDefault();
    //state for admin rfq row id
    console.log("value", idArr);
    setRequestId(idArr);
    //state containing complete row data
    setUDt(data);
    setViewReqmdl(true);
  };
  //function to handle rfq rejection from admin
  const handleRfqReject = (event: any, rowdata: any) => {
    event.preventDefault();
    console.log(rowdata);
  };
  const handleOutClick = (event: any) => {
    event.preventDefault();
    setViewReqmdl(false);
    // setRowActed(true);
  };
  const ShowAssignedVen = ({ info }: { info: string }) => {
    const [venData, setVenData] = useState("");
    useEffect(() => {
      if (info !== null) {
        setVenData(info);
      } else {
        setVenData("--NA--");
      }
    }, []);
    return (
      <React.Fragment>
        <div className="row">
          <Tooltip title={venData}>
            <span className="vendor_Info_tag text-primary">info</span>
          </Tooltip>
        </div>
      </React.Fragment>
    );
  };
  const columns: GridColDef[] = useMemo(
    () => [
      { field: "_id", headerName: "uid" },
      {
        field: "customer_ID",
        headerName: "user",
        type: "string",
        width: 150,
        align: "left",
        headerAlign: "center",
        renderCell(params) {
          return params.row?.userInfo?.email;
        },
      },
      {
        field: "subject",
        headerName: "subject",
        width: 180,
        editable: false,
        renderCell: ({ row }) => <span>{row?.result?.subject}</span>,
      },

      // {
      //   field: "category",
      //   headerName: "Category",
      // },
      {
        field: "requirements",
        headerName: "Requests",
        valueGetter({ row }) {
          return row?.requirements.length;
        },
        editable: false,
        headerAlign: "left",
      },
      {
        field: "vendor_assigned",
        headerName: "Vendor",
        description: "potential vendors",
        renderCell(params) {
          return <ShowAssignedVen info={params.value} />;
        },
      },

      {
        field: "createdAt",
        headerName: "Date",
        type: "date",
        valueFormatter(params) {
          return moment(params.value).format("DD/MM/YY");
        },
      },
      {
        field: "updatedAt",
        headerName: "Updated On",
        valueFormatter: (params) => moment(params.value).format("DD/MM/YY"),
      },
      {
        field: "action",
        headerName: "Action",
        type: "actions",
        width: 180,
        getActions: (params: GridRowParams) => [
          <>
            <Tooltip title="Review">
              <GridActionsCellItem
                className="admin-styleicon"
                label="Accept"
                icon={<AdminGreenIcon />}
                onClick={(e) => OpenRFQRequest(e, params.row?._id, params?.row)}
              />
            </Tooltip>
            <Tooltip title="Reject">
              <GridActionsCellItem
                className="admin-styleicon"
                label="Reject"
                icon={<AdminRedIcon />}
                onClick={(e) => handleRfqReject(e, params?.row)}
                sx={{
                  position: "relative",
                  ":hover::after": {
                    content: "none",
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    border: "2px solid black",
                  },
                }}
              />
            </Tooltip>
          </>,
        ],
      },
      {
        field: "responseSubmissionStatus",
        headerName: "Admin \n Status",
        width: 160,
        renderCell: (params) => {
          const isAdminActionNeeded =
            params.value === null || params.value === undefined;

          return (
            <React.Fragment key="icon">
              {isAdminActionNeeded ? (
                <React.Fragment>
                  <BookmarkRemoveRoundedIcon style={{ fontSize: "1.7rem" }} />
                  <span style={{ marginLeft: "5px" }}>Action not Taken</span>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <BookmarkAddedIcon
                    style={{ fontSize: "1.7rem", color: "blue" }}
                  />
                  {params.value}
                  {/* {params.value.map((data, index) => (
                    <span style={{ marginLeft: "5px" }} key={index}>
                      {data}
                    </span>
                  ))} */}
                </React.Fragment>
              )}
            </React.Fragment>
          );
        },

        // valueFormatter(params) {
        //   if (params.value === null || params.value === undefined) {
        //     return "Action not Taken";
        //   } else if (Array.isArray(params.value)) {
        //     return params.value.map((data) => data);
        //   } else {
        //     return "Invalid data format";
        //   }
        // },
      },
      {
        field: "status",
        headerName: "Current Status",
        renderCell: (params) => {
          return params?.row?.statusResult?.name;
        },
      },
    ],
    []
  );
  // const handleUpdater = () => {
  //   setRowActed(true);
  // };
  const getRFQs = async () => {
    const { data } = await axios.get(`${ADMIN_FETCH_RFQS}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    setRqfData(data);
  };

  useEffect(() => {
    getRFQs();
  }, []);

  return (
    <div>
      {/* <ContentHeader title="RFQS Received" /> */}
      <section className="content">
        <div className="container-fluid">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">
                RFQ Requests Received from Users.{rfqdata.length}
              </h3>
              <div className="card-tools">
                <button
                  type="button"
                  className="btn btn-tool"
                  data-widget="collapse"
                  data-toggle="tooltip"
                  title="Collapse"
                >
                  <i className="fa fa-minus" />
                </button>
                <button
                  type="button"
                  className="btn btn-tool"
                  data-widget="remove"
                  data-toggle="tooltip"
                  title="Remove"
                >
                  <i className="fa fa-times" />
                </button>
              </div>
            </div>
            <div className="card-body ">
              <span className="text-center">
                Start creating your amazing application!
              </span>
              <Box height={"auto"} p={1}>
                <DataGrid
                  columns={columns}
                  rows={rfqdata}
                  onRowClick={handleRowClick}
                  getRowId={(row: any) => row._id}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 5,
                      },
                    },
                    columns: {
                      columnVisibilityModel: {
                        _id: false,
                        responseSubmissionStatus: true,
                        updatedAt: false,
                      },
                    },
                  }}
                  slots={{
                    toolbar: GridToolbar,
                  }}
                  slotProps={{
                    columnsPanel: {
                      disableHideAllButton: true,
                      disableShowAllButton: true,
                    },
                    pagination: {
                      align: "center",
                    },
                  }}
                  localeText={{ noRowsLabel: "Found no Vendors" }}
                  pageSizeOptions={[10]}
                />
              </Box>
            </div>
            {/* <div className="card-footer bg-secondary">Footer</div> */}
          </div>
        </div>
      </section>
      <RFQModal
        open={viewReqmdl}
        onClose={(e: any) => handleOutClick(e)}
        tableHeader={"RFQ Info..."}
      >
        <ModifyRequests
          reqId={selectedRow !== null ? selectedRow?._id : requestId}
          rowInfo={selectedRow || uDt}
        />
      </RFQModal>
    </div>
  );
};

export default Blank;
