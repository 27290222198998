import React, { useEffect, useState } from "react";
import "./styles.css";

import {
  Alert,
  Autocomplete,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  CREATE_BUSSINESSVENDOR,
  GET_ALL_CATSERVICE,
} from "../../CompServices/Api_Routes_Helper";
import axios from "../../../api/axios";
import authHeader from "../../../Services/auth-header";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import { AiFillEye } from "react-icons/ai";

const vendorSchema = Yup.object().shape({
  // category: Yup.array()
  //   .min(1, "Select at least one category")
  //   .nullable() // Allow the field to be nullable
  //   .required("Service Category Cannot be Empty"),

  // country: Yup.string().required("Country is required"),
  email: Yup.string()
    .required("email is required")
    .email("Email not matching criteria")
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      "Invalid email address"
    ),
  username: Yup.string().required("username is required"),
  companyname: Yup.string().required("companyname is required"),
  // phone: Yup.string().required("phone is required"),
  password: Yup.string()
    .required("Password is required")
    .min(4, "Must contain a minimum of 4 characters"),
  confirmPassword: Yup.string()
    .required("Password is required.")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
  // .max(8, "can contain upto 8 only"),
  role: Yup.string()
    .test("role-test", "Invalid role", function (value) {
      const allowedRoles = ["supplier", "buyer", "both"];

      if (!allowedRoles.includes(value)) {
        return false;
      }

      return true;
    })
    .required("Role is required"),
});

const VendorSignupDetails = () => {
  const {
    register,
    handleSubmit,
    getValues,
    reset,
    watch,
    control,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      category: [],
      email: "",
      username: "",
      password: "",
      confirmPassword: "",
      companyname: "",
      password: "",
      role: "",
      userEmail: "",
    },
    resolver: yupResolver(vendorSchema),
  });
  const initialValues = {
    email: "",
    username: "",
    companyname: "",
    category: [],
    confirmPassword: "",
    password: "",
  };

  const [passwordShown, setpasswordShown] = useState(false);

  const handlePasswordShow = () => {
    setpasswordShown(!passwordShown);
  };
  const [formErrors, setFormErrors] = useState({});
  const [formValues, setFormValues] = useState(initialValues);

  //to fetch and display services
  const [values, setValues] = useState([]);

  const navigate = useNavigate();
  const inlineMessageElement = document.getElementsByClassName(
    "input-validation-error"
  );
  const inputElement = document.getElementsByClassName("pm-form-control");

  useEffect(() => {
    let componentDidMount;
    componentDidMount = true;
    const servicesCategories = async () => {
      try {
        const response = await axios.get(GET_ALL_CATSERVICE, {
          headers: await authHeader(),
        });
        if (response !== null && componentDidMount) {
          const { data } = response.data;
          setValues(data);
        } else {
          throw new Error("Services not found.");
        }
      } catch (error) {
        console.error(error);
      }
    };
    servicesCategories();
    return () => {
      componentDidMount = false;
    };
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormValues({ ...formValues, [name]: value });
  };

  const [showErr, setshowErr] = React.useState(false);
  const [errMsg, setErrMsg] = useState("");

  const handleClosePopUpErr = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setshowErr(false);
  };

  const handleFormSubmit = async () => {
    try {
      const vendordata = {
        username: getValues().username,
        email: getValues().email,
        companyname: getValues().companyname,
        category: formValues.category,
        role: getValues().role,
        password: getValues().password,
        confirmPassword: getValues().confirmPassword,
      };
      const notifier = document.getElementById("notification-div");

      await axios
        .post(
          CREATE_BUSSINESSVENDOR,

          vendordata,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          const signup_response = response?.data;
          const { type, message } = signup_response;

          if (type === "success") {
            console.log("working :>>");
          }
          setshowErr(true);
          setErrMsg(message);
          navigate("/signin");
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status === 400) {
            const response = error.response;
            const errormessage = response.data.message;
            setshowErr(true);
            setErrMsg(errormessage);
            console.log(errormessage);
          }
        });
    } catch (error) {
      console.error(error);
    }
  };
  const onErrors = (errors) => console.error(errors);
  const handleFormErrors = (data) => {
    const errors = {};
    let isValid = true;
    if (!formValues.email) {
      errors.email = "Email is required";
      isValid = false;
    }
    if (!formValues.username) {
      errors.username = "Username is required";
      isValid = false;
    }
    if (!formValues.companyname) {
      errors.companyname = "companyname is required";
      isValid = false;
    }
    if (!formValues.category) {
      errors.category = "category is required";
      isValid = false;
    }

    if (!formValues.phone) {
      errors.phone = "phone is required";
      isValid = false;
    }
    if (!formValues.password) {
      errors.password = "Password is required";
      isValid = false;
    }
    if (!isValid) {
      console.error(errors);
    }
    setFormErrors(errors);
    return isValid;
  };

  const handleCatChanges = (event, newValue) => {
    setFormValues((prevData) => ({
      ...prevData,
      category: newValue.map((item) => item.categoryName),
    }));
  };

  const handleButtonClick = () => {
    navigate("/signin", {
      state: { tap: 2 },
    });
  };

  return (
    <div className="seller_page_star">
      <form id="sign-up-for">
        <h2 className="bold-text1 *">QuoteurPrice</h2>
        <div className="pm-form-group">
          <label htmlFor="email" className="pm-form-label">
            Email
          </label>
          <input
            type="text"
            className="pm-form-control"
            id="email"
            name="email"
            {...register("email")}
            autoFocus={true}
            autoComplete="off"
            autoCapitalize="off"
            spellCheck="true"
            // value={formValues.email}
            onChange={handleChange}
          />
          {/* {formErrors.email && ( */}
          {errors?.email && (
            <span className="input-validation-error">
              {errors.email.message}
            </span>
          )}
          {/* )} */}
        </div>
        <div className="pm-form-group">
          <label htmlFor="username" className="pm-form-label">
            User Name
          </label>
          <input
            type="text"
            className="pm-form-control"
            id="username"
            name="username"
            {...register("username")}
            autoComplete="off"
            autoCapitalize="off"
            spellCheck="true"
            // value={formValues.username}
            onChange={handleChange}
          />
          {/* {formErrors.username && ( */}
          {errors?.username && (
            <span className="input-validation-error">
              {errors.username.message}
            </span>
          )}
          {/* )} */}
        </div>
        <div className="pm-form-group">
          <label htmlFor="companyname" className="pm-form-label">
            Company Name
          </label>
          <input
            type="text"
            className="pm-form-control"
            id="companyname"
            name="companyname"
            {...register("companyname")}
            autoComplete="off"
            autoCapitalize="off"
            spellCheck="true"
            // value={formValues.companyname}
            onChange={handleChange}
          />
          {errors?.companyname && (
            <span className="input-validation-error">
              {errors.companyname.message}
            </span>
          )}
        </div>
        <div className="pm-form-group">
          <span className="pm-form-grouprework">
            What Category of Service you provide?
          </span>
          <br />
          {/* <label htmlFor="serviceCategory" className="pm-form-label">
            Service category
          </label> */}

          <Autocomplete
            multiple
            options={values}
            onChange={handleCatChanges}
            getOptionLabel={(option) => option.categoryName}
            isOptionEqualToValue={(option, value) => option?._id === value?._id}
            renderOption={(props, option, { selected }) => (
              <li {...props}>{option.categoryName}</li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Service Category"
                {...register("category")}
              />
            )}
          />
          {/* {errors.category && (
            <span className="input-validation-error">
              {errors.category.message}
            </span>
          )} */}
        </div>
        {/*country */}

        {/* <div className="pm-form-group">
                  <label htmlFor="country" className="pm-form-label">
                    Country/Region
                  </label>
                  <select
                    {...register("country")}
                    id="country"
                    name="country"
                    className="pm-form-control"
                    onChange={(event) => {
                      console.log(event.target.value);
                      setBvCountry(event.target.value);
                    }}
                    value={bvCountry}
                  >
                    <option value="--none--">default</option>
                    <option value="india">India</option>
                    <option value="America">America</option>
                    <option value="UK">UK</option>
                  </select>
                  {errors?.country && (
                    <span className="input-validation-error">
                      {errors.country.message}
                    </span>
                  )}
                </div> */}
        {/*phone */}
        {/* <div className="pm-form-group">
                  <label htmlFor="phone" className="pm-form-label">
                    Phone
                  </label>
                  <input
                    type="text"
                    className="pm-form-control"
                    id="phone"
                    name="phone"
                    {...register("phone")}
                    autoComplete="off"
                    autoCapitalize="off"
                    spellCheck="true"
                    // value={formValues.phone}
                    onChange={handleChange}
                  />
                  {errors?.phone && (
                    <span className="input-validation-error">
                      {errors.phone.message}
                    </span>
                  )}
                </div> */}
        <div className="pm-form-group">
          <label
            htmlFor="user_role"
            className="pm-form-label"
            style={{ position: "relative", top: "3px" }}
          >
            Role
          </label>
          <div className="pm-user-role-selector">
            <input
              type="radio"
              {...register("role")}
              id="user_role1"
              value="supplier"
            />
            &nbsp;
            <label
              htmlFor="user_role1"
              className="pm-form-label"
              style={{ position: "relative", top: "3px" }}
            >
              Supplier
            </label>
            &nbsp;&nbsp;
            <input
              type="radio"
              {...register("role")}
              id="user_role2"
              value="buyer"
            />
            &nbsp;
            <label
              htmlFor="user_role2"
              className="pm-form-label"
              style={{ position: "relative", top: "3px" }}
            >
              Buyer
            </label>
            &nbsp;&nbsp;
            <input
              type="radio"
              {...register("role")}
              id="user_role3"
              value="both"
            />
            &nbsp;
            <label
              htmlFor="user_role3"
              className="pm-form-label"
              style={{ position: "relative", top: "3px" }}
            >
              Both
            </label>
          </div>
          {errors?.role && (
            <span className="input-validation-error">
              {errors.role.message}
            </span>
          )}
        </div>
        <div className="pm-form-group">
          <label htmlFor="password" className="pm-form-label">
            Password
            <span
              id="show-password"
              className="show-password"
              onClick={() => handlePasswordShow()}
            >
              <AiFillEye className="eyeicon" />
            </span>
          </label>

          <input
            type={passwordShown ? "text" : "password"}
            className="pm-form-control"
            id="password"
            name="password"
            {...register("password")}
            // value={formValues.password}
            onChange={handleChange}
          />
          {errors?.password && (
            <span className="input-validation-error">
              {errors.password.message}
            </span>
          )}
          <br />
          <br />
          <label htmlFor="confirmPassword" className="pm-form-label">
            Confirm Password
          </label>

          <input
            type="text"
            className="pm-form-control"
            id="confirmPassword"
            name="confirmPassword"
            {...register("confirmPassword")}
            // value={formValues.password}
            onChange={handleChange}
          />
          {watch("confirmPassword") !== watch("password") &&
          getValues("confirmPassword") ? (
            <p className="input-validation-error">password not matching</p>
          ) : null}

          <div className="pm-password-strength-indicator">
            <div className="pm-password-strength-pointer"></div>
            <div className="pm-password-strength">
              <div className="pm-password-strength-content">
                <div className="strength-title">Password Strength: </div>
                <div
                  className="strength-text"
                  id="password-strength-text"
                ></div>
                <svg className="strength-progress" width="210px" height="10px">
                  <line
                    x1="0"
                    y1="5"
                    x2="210"
                    y2="5"
                    strokeWidth="4px"
                    strokeLinecap="round"
                    className="strength-progress-background"
                  ></line>
                  <line
                    x1="0"
                    y1="5"
                    x2="210"
                    y2="5"
                    strokeWidth="4px"
                    strokeLinecap="round"
                    className="strength-progress-foreground"
                    id="password-strength-progress-foreground"
                  ></line>
                </svg>
                <div className="strength-description">
                  Passwords should be at least 7 characters long. Try using
                  uncommon words or inside jokes, non-standard uppercasing,
                  creative spelling, and non-obvious numbers and symbols.
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="pm-form-group">
                  <input
                    type="checkbox"
                    id="marketing-updates-checkbox"
                    className="pm-form-control"
                  />
                  <label htmlFor="marketing-updates-checkbox">
                    Sign up to get product updates, news, and other marketing
                    communications.
                  </label>
                </div> 

                <div className="pm-form-group">
                  <input
                    type="checkbox"
                    id="remember-me-checkbox"
                    className="pm-form-control"
                    checked={checkbox}
                    onChange={handlecheckbox}
                  />
                  <label htmlFor="remember-me-checkbox">
                    Stay signed in for 30 days
                  </label>
                  <div
                    className="do-not-remember-message"
                    id="do-not-remember-message"
                    style={{ display: "none" }}
                  >
                    <span>
                      You will be logged out after 30 minutes of inactivity.
                    </span>
                  </div>
                </div>
*/}
        <p id="terms-and-privacy">
          By creating an account, I agree to the
          <a href="#" target="_blank" className="vendorachange">
            Terms
          </a>
          <span> and </span>
          <a href="#" target="_blank" className="vendorachange">
            Privacy Policy
          </a>
          .
        </p>

        <div className="pm-form-group">
          <button
            type="submit"
            className="pm-btn pm-btn-primary sign-up-btn"
            id="sign-up-btn"
            onClick={handleSubmit(handleFormSubmit, onErrors)}
          >
            Create free account
          </button>
        </div>

        {/* <input
                  type="hidden"
                  name="csrfToken"
                  value="NMJkEUBw4RgY5kyEjaEAkF8e2UGyF3Gaif4Vo="
                /> */}
      </form>
      <div className="pm-awesome-divider-div">
        <div className="pm-awesome-divider" data-label="or"></div>
      </div>
      <br />
      <Typography component="p" variant="p" className="common-para">
        Already have an account?
        <Typography
          variant="p"
          className="common-para"
          onClick={handleButtonClick}
          style={{ color: "blue", fontWeight: "600", cursor: "pointer" }}
        >
          &nbsp; Sign In&nbsp;
        </Typography>
        here
      </Typography>
      {/* <div className="sign-in-link-container">
        <div>
          <Link
            className="pmt_sign-in-instead-lnk"
            id="sign-in-link"
            to="/vendor/signin"
            style={{
              outline: 0,
              fontWeight: "500",
              fontSize: "15px",
              cursor: "pointer",
              color: "#1a73e8",
            }}
          >
            Sign In
          </Link>{" "}
          instead?
        </div>
      </div> */}
      {/* <a
                    href="/google/oauth2?signup=1"
                    className="pm-btn pm-btn-info google-sign-up"
                  >
                    <div className="google-logo" >
                      <img
                        src="https://assets.getpostman.com/common-share/google-logo-icon-sign-in.svg"
                        width={"18px"}
                        height={"18px"}
                      />
                    </div>
                    <div className="google-text">
                      <div className="pmt_sign-up-w-google-btn">
                        Sign up with Google
                      </div>
                    </div>
                  </a> */}
      {/* </div> */}
      {/* </div> */}
      {/* </div> */}
      {/* </Box>
        </Modal> */}
      {/* </div> */}
      <ToastContainer />

      <Snackbar
        open={showErr}
        autoHideDuration={2500}
        onClose={handleClosePopUpErr}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleClosePopUpErr}
          severity="info"
          sx={{ width: "100%", fontSize: 16 }}
        >
          {typeof errMsg === "boolean" ? errMsg.toString() : errMsg}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default VendorSignupDetails;
