import React, { useState, useEffect, useRef } from "react";
import {
  TextField,
  Typography,
  MenuItem,
  Button,
  Grid,
  Container,
  Tooltip,
  Box,
  InputLabel,
  Slide,
  Select,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
} from "@mui/material";
import { useForm } from "react-hook-form";
import Money from "../../assets/time-money.avif";
import axios from "../../api/axios";
import authHeader from "../../Services/auth-header";
import { GET_ALL_CATSERVICE } from "../CompServices/Api_Routes_Helper";
import CategoryListTable from "../ReuseTable/CategoryListTable";
import DataTableBase, {
  customStyles,
  customizStyles,
} from "../ReuseTable/DataTable";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { HiEye, HiPencilAlt } from "react-icons/hi";
import { MdDelete } from "react-icons/md";
import Moment from "react-moment";
import { submitButton } from "../DesignTools/ButtonStyles/ButtonStyles";
import { InputAdornment } from "@mui/material";
import { CurrencyRupee } from "@mui/icons-material";
const useStyles = makeStyles((theme) => ({
  textField: {
    "& label": {
      color: "black",
    },
    "& input": {
      color: "#000000ba",
    },
  },
}));

const schema = yup.object().shape({
  categoryName: yup.string().required("Category Name is required"),
  quantity: yup.string().required("Quantity is required"),
  items: yup.string().required("Items is required"),
  unit: yup.string().required("Unit is required"),
  price: yup.string().required("Price is required"),
  date: yup.string().required("Date is required"),
  specialNotes: yup.string().required("Special Notes is required"),
});
const units = [
  {
    value: "Kg",
    label: "Kg",
  },
  {
    value: "Quintal",
    label: "quintals",
  },
  {
    value: "Grams",
    label: "Grams",
  },
  {
    value: "metre",
    label: "metre",
  },
  {
    value: "Litre",
    label: "Litre",
  },
  {
    value: "Units",
    label: "Units",
  },
  {
    value: "Ton",
    label: "Ton",
  },
];

const Pricing = ({
  onChange,
  itemSett,
  toggleRFQBtn,
  addingItem,
  setItemAdding,
  handleItemChange,
  urlSearchName,
}) => {
  const {
    register,
    setError,
    formState: { errors },
    watch,
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      categoryName: "",
      items: "",
      unit: "",
      quantity: "",
      price: "",
      date: "",
      specialNotes: "",
    },
  });

  const outValues = watch();

  const [attachmentName, setAttachmentName] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [itemSet, setItemSet] = useState([]);
  //to maintain search lists
  const [rfqitems, setRfqItems] = useState([]);
  const [selectedID, setSelectedId] = useState(null);
  const [values, setValues] = useState([]);
  const location = useLocation();
  const initialRfqType = location.state?.rfq_type || "Request";
  //to search lists
  const [filterText, setFilterText] = useState("");
  const [inputData, setInputData] = useState({
    uid: "",
    cat_Id: selectedID,
    categoryName: "",
    price: "",
    items: "",
    unit: "",
    quantity: "",
    specialNotes: "",
    date: "",
  });
  const [typedate, setTypeDate] = useState("text");
  const tablesrefs = useRef(null);
  useEffect(() => {
    setInputData({ ...inputData, cat_Id: selectedID });
  }, [selectedID]);

  useEffect(() => {
    if (urlSearchName && urlSearchName.trim() !== "") {
      console.log("urlSearchName:", urlSearchName);
      const matchingCategory = values.find(
        (option) =>
          option.categoryName.trim().toLowerCase() ===
          urlSearchName.trim().toLowerCase()
      );
      // console.log("matchingCategory:", matchingCategory);

      if (matchingCategory) {
        setInputData({
          ...inputData,
          categoryName: matchingCategory.categoryName,
          cat_Id: matchingCategory._id,
        });
      }
    }
  }, [urlSearchName, values]);

  useEffect(() => {
    fetchServices();
  }, []);

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
    if (file) {
      setAttachmentName(file.name);
    } else {
      setAttachmentName("");
    }
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;

    setInputData({
      ...inputData,
      [name]: value,
    });
  };

  const handleEditRow = (e, id) => {
    e.preventDefault();
    const selectedRow = itemSet.find((item) => item.uid === id);
    setNewRow(selectedRow);
    handleEditPageOpen();
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleEditPageOpen = (id) => {
    setIsModalOpen(true);
  };
  const handleCloseEditModal = () => {
    setIsModalOpen(false);
  };

  const [newRow, setNewRow] = useState(null);

  const handleNewRowChange = (e, field) => {
    const updatedNewRow = { ...newRow };
    updatedNewRow[field] = e.target.value;
    setNewRow(updatedNewRow);
    // console.log("updatedNewRow", updatedNewRow);
  };

  const handleSave = () => {
    if (newRow) {
      // Update the itemSet state with the updated newRow
      setItemSet((prevItemSet) => {
        // Find the index of the item you want to update in itemSet (if needed)
        // For example, you may have an item with a specific uid

        // Create a copy of the itemSet
        const updatedItemSet = [...prevItemSet];

        // Find the index of the item to update (modify this logic based on your use case)
        const indexToUpdate = updatedItemSet.findIndex(
          (item) => item.uid === newRow.uid
        );

        if (indexToUpdate !== -1) {
          // Update the item with the new data
          updatedItemSet[indexToUpdate] = newRow;
        } else {
          // If the item with newRow's uid doesn't exist in itemSet, you can add it
          updatedItemSet.push(newRow);
        }
        // console.log("newRowupdated", newRow);
        return updatedItemSet;
      });
    }

    handleCloseEditModal(); // Close the dialog after saving
  };

  const handleDelete = (e, id) => {
    e.preventDefault();
    const deleted = itemSet.filter((item) => item.uid !== id);
    setItemSet(deleted);
  };

  const TableHeaders = [
    {
      name: "Item",
      selector: (row) => row.items,
      grow: 1,
    },
    {
      name: "Expected Quantity",
      selector: (row) => row.quantity,
    },
    {
      name: "Unit",
      selector: (row) => row.unit,
    },
    {
      name: "Expected Price",
      selector: (row) => row.price,
    },
    {
      name: "Date",
      selector: (row) => row.date,
      format: (row) => <Moment format="DD/MM/YYYY">{row.date}</Moment>,
    },
    {
      name: "Action",
      selector: (row) => (
        <Box sx={{ width: "100%", display: "flex", pb: 1 }}>
          <Tooltip title="Edit">
            <button
              name="edit"
              //  className="table_actBtns edit"
              className="edit-icons"
              onClick={(e) => handleEditRow(e, row.uid)}
            >
              <HiPencilAlt />
            </button>
          </Tooltip>
          <Tooltip title="Delete">
            <button
              name="delete"
              onClick={(e) => handleDelete(e, row.uid)}
              // className="table_actBtns delete"
              className="edit-icons delete-icons"
            >
              <MdDelete />
            </button>
          </Tooltip>
        </Box>
      ),
    },
  ];
  const onChangeHandler = (e) => {
    const { name, value } = e.target;

    if (name === "price") {
      // Check if the entered value is greater than 0
      const isValidPrice = parseFloat(value) > 0;
      // Set an error if the price is 0 or less
      setError("price", {
        type: "manual",
        message: isValidPrice ? "" : "Price must be greater than 0",
      });
    }

    if (name === "categoryName") {
      const selectedCategory = values.find(
        (option) => option.categoryName === value
      );
      const selectedId = selectedCategory ? selectedCategory._id : null;
      setSelectedId(selectedId);
      setInputData({ ...inputData, cat_Id: selectedId });
    }

    const newData = { ...inputData };
    function generateUid() {
      return `${new Date().getTime()}-${Math.random()}`;
    }
    newData.uid = generateUid();
    newData[name] = value;
    setInputData(newData);
  };

  const add = (e) => {
    try {
      e.preventDefault();
      if (!inputData) {
        throw new Error("only optional fields can be left empty!");
      } else {
        setItemSet([...itemSet, inputData]);
        setInputData({
          uid: "",
          cat_Id: selectedID,
          price: "",
          items: "",
          categoryName: inputData.categoryName,
          date: "",
          unit: "",
          quantity: "",
          specialNotes: "",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (itemSet.length > 0) {
      const showUp = tablesrefs.current.scrollIntoView({
        behaviour: "smooth",
        block: "start",
      });
    }
  }, [itemSet]);
  const saveService = async () => {
    try {
      if (!inputData) {
        console.error("fill all the values");
      } else {
        setItemSet([...itemSet, inputData]);

        setInputData({
          uid: "",
          cat_Id: selectedID,
          price: inputData.price,
          items: inputData.items,
          categoryName: inputData.categoryName,
          unit: inputData.unit,
          quantity: inputData.quantity,
          specialNotes: inputData.specialNotes,
          date: inputData.date,
        });
        setItemAdding(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const searchData = itemSet.filter((list) =>
      list.items.toLowerCase().includes(filterText.toLowerCase().trim())
    );
    setRfqItems(searchData);
  }, [filterText]);

  const isFormValid = !!(
    inputData.items.trim().length > 0 &&
    inputData.categoryName.trim().length > 0
  );
  useEffect(() => {
    handleFormChange();
  }, [selectedImage, outValues, onChange]);

  const handleFormChange = () => {
    itemSett(itemSet);
  };
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1; // Months are zero-indexed
    let day = today.getDate();

    // Add leading zeros for months and days less than 10
    if (month < 10) {
      month = `0${month}`;
    }
    if (day < 10) {
      day = `0${day}`;
    }

    return `${year}-${month}-${day}`;
  };

  const fetchServices = async () => {
    await axios
      .get(`${GET_ALL_CATSERVICE}`, { headers: await authHeader() })

      .then((res) => {
        setValues(res?.data?.data);
      })

      .catch((error) => console.log(error));
  };

  useEffect(() => {
    const storedItemSet = sessionStorage.getItem("itemSet");
    if (storedItemSet) {
      setItemSet(JSON.parse(storedItemSet));
    }
  }, []);

  useEffect(() => {
    sessionStorage.setItem("itemSet", JSON.stringify(itemSet));
  }, [itemSet]);

  const [selectedItemIndex, setSelectedItemIndex] = useState(null);

  const handleEditClick = (index) => {
    const selectedItem = itemSet[index];
    setSelectedItemIndex(index);
    setInputData(itemSet[index]);
  };

  const [indexv, setIndexvalue] = useState("");
  const renderInputFields = () => {
    return itemSet.map((item, index) => (
      <div key={index}>
        <button
          onClick={() => {
            handleEditClick(index);
            setIndexvalue(index);
          }}
        >
          Edit
        </button>
      </div>
    ));
  };

  const handleSecondPageValidation = () => {
    const isValid = !!(itemSet.length > 0);

    handleItemChange(isValid);
  };

  useEffect(() => {
    handleSecondPageValidation();
  }, [itemSet]);

  const classes = useStyles();
  const SearchComponent = (
    <div className="table_searchdiv">
      <input
        type="text"
        name="searchList"
        placeholder="search"
        value={filterText}
        onChange={(event) => setFilterText(event.target.value)}
      />
    </div>
  );
  // const CustomSubHeader = ({ onSearch }) => {
  //   const [filterText, setFilterText] = useState('');

  //   const handleSearch = () => {
  //     onSearch(filterText);
  //   };

  //   return (
  //     <div style={{ display: 'flex', alignItems: 'center' }}>
  //       <input
  //         type="text"
  //         placeholder="Search"
  //         value={filterText}
  //         onChange={(e) => setFilterText(e.target.value)}
  //       />
  //       <button onClick={handleSearch}>Search</button>
  //     </div>
  //   );
  // };
  return (
    <Container maxWidth="lg">
      {/* <div className="pricing-main-container"> */}
      <Grid container spacing={1}>
        <Grid item md={1} xs={12}></Grid>
        <Grid item md={5} xs={12} sm={12} lg={5} gap={1}>
          <div className="pricing-left-sub">
            <h3 className="rfq-details-heading">Enter budget and timelines.</h3>
            <p className="rfq-details-content">
              You've made some progress. Enter the requirement details and
              budget.
            </p>
            <div className="image-containers">
              <img src={Money} alt="match" className="rfq-image" srcSet="" />
            </div>
          </div>
        </Grid>
        <Grid item md={5} xs={12} sm={12} lg={6}>
          <div className="pricing-right-su">
            <Grid container>
              <Grid item xs={12} md={12} lg={12} sm={12}>
                <TextField
                  // name="categoryName"
                  className="mb-2"
                  label="Select Category Name"
                  select
                  {...register("categoryName")}
                  value={inputData.categoryName}
                  onChange={onChangeHandler}
                  style={{ width: "100%" }}
                  SelectProps={{
                    MenuProps: {
                      style: { maxHeight: "310px" },
                    },
                  }}
                  required
                >
                  {values.map((option, i) => (
                    <MenuItem key={i} value={option.categoryName}>
                      {option.categoryName}
                    </MenuItem>
                  ))}
                </TextField>
                {errors?.categoryName && (
                  <span className="error_div">
                    {errors?.categoryName.message}
                  </span>
                )}
                <TextField
                  id="outlined-basic"
                  label="Items"
                  placeholder="Example: apples"
                  {...register("items")}
                  name="items"
                  variant="outlined"
                  style={{
                    width: "100%",
                  }}
                  value={inputData.items}
                  onChange={onChangeHandler}
                />
                {errors?.items && (
                  <span className="error_div">{errors?.items.message}</span>
                )}
              </Grid>
            </Grid>

            <Grid container columnSpacing={1} rowSpacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  // name="unit"
                  select
                  label="Select Unit"
                  {...register("unit")}
                  style={{ width: "100%" }}
                  value={inputData.unit}
                  onChange={onChangeHandler}
                >
                  {units.map((option, i) => (
                    <MenuItem key={i} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                {errors?.unit && (
                  <span className="error_div">{errors?.unit.message}</span>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  id="outlined-basic"
                  label="Quantity"
                  // name="quantity"
                  {...register("quantity")}
                  variant="outlined"
                  className="quantity-width"
                  type="number"
                  value={inputData.quantity}
                  onChange={onChangeHandler}
                />
                {errors?.quantity && (
                  <span className="error_div">{errors?.quantity.message}</span>
                )}
              </Grid>
            </Grid>

            <Grid container columnSpacing={1} rowSpacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  id="outlined-basic"
                  label="Price per unit"
                  // name="price"
                  variant="outlined"
                  {...register("price")}
                  style={{ width: "100%" }}
                  value={inputData.price}
                  onChange={onChangeHandler}
                  type="text"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CurrencyRupee color="inherit" fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                />
                <div style={{ width: "300px" }}>
                  {errors?.price && (
                    <span className="error_div">{errors?.price.message}</span>
                  )}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  type={typedate}
                  label="Delivery date"
                  onFocus={(e) => setTypeDate("date")}
                  onBlur={(e) => setTypeDate("text")}
                  className={`${classes.textField} quantity-width `}
                  // name="date"
                  {...register("date")}
                  value={inputData.date}
                  onChange={handleDateChange}
                  inputProps={{ min: getCurrentDate() }} // Set the min attribute to the current date
                  sx={{
                    " & .MuiFormLabel-root": {
                      color: "rgba(0,0,0,0.6)",
                    },
                    ":placeholder-shown": {
                      display: "none",
                    },
                  }}
                />
                <div>
                  {errors?.date && (
                    <span className="error_div">{errors?.date.message}</span>
                  )}
                </div>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item lg={12} sm={12} md={12} xs={12}>
                <TextField
                  multiline
                  rows={2}
                  fullWidth
                  id="outlined-basic"
                  label="Special Notes"
                  // name="specialNotes"
                  variant="outlined"
                  {...register("specialNotes")}
                  style={{ width: "100%" }} // Add other styles as needed
                  value={inputData.specialNotes}
                  onChange={onChangeHandler}
                />
              </Grid>
            </Grid>
            {errors?.specialNotes && (
              <span className="error_div">{errors?.specialNotes.message}</span>
            )}
            {toggleRFQBtn === "Request" && (
              <Button
                onClick={(e) => add(e)}
                className="pricing_button"
                variant="contained"
                color="primary"
                disabled={!isFormValid}
                aria-labelledby="add-items"
              >
                {itemSet.length > 0 ? "Add More Items" : "save"}
              </Button>
            )}
            {toggleRFQBtn === "Service" && !addingItem && (
              <Button
                onClick={saveService}
                className="pricing_button"
                variant="contained"
                color="primary"
                disabled={!isFormValid}
              >
                Save
              </Button>
            )}
          </div>
        </Grid>

        <Grid
          item
          md={10}
          xs={12}
          sm={12}
          sx={{ margin: "0px auto" }}
          className="price-table"
          ref={tablesrefs}
        >
          {filterText ? (
            <DataTableBase
              title="RFQ Items"
              columns={TableHeaders}
              data={rfqitems}
              noTableHead={false}
              customStyles={customizStyles}
              subHeader
              subHeaderComponent={SearchComponent}
              persistTableHead
            />
          ) : (
            <DataTableBase
              title="RFQ Items"
              columns={TableHeaders}
              data={itemSet}
              noTableHead={false}
              customStyles={customizStyles}
              subHeader
              subHeaderComponent={SearchComponent}
              persistTableHead
            />
          )}
        </Grid>
        {newRow && (
          <Dialog
            open={isModalOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleCloseEditModal}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle
              className="h3_div_res"
              style={{ padding: 0, marginLeft: 0 }}
            >
              <Box className="w-100">
                <span>Edit Row</span>
                <i
                  className="fa fa-close float-right"
                  onClick={handleCloseEditModal}
                ></i>
              </Box>
            </DialogTitle>
            <DialogContent>
              <Container>
                <div>
                  <Grid container>
                    <Grid item xs={12} md={12} sm={12} lg={12}>
                      <FormControl style={{ width: "100%", marginTop: "10px" }}>
                        <InputLabel id="demo-simple-select-autowidth-label">
                          Select Category Name
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-autowidth-label"
                          value={newRow.categoryName}
                          onChange={(e) =>
                            handleNewRowChange(e, "categoryName")
                          }
                          label="Select Category Name"
                        >
                          {values.map((category, index) => (
                            <MenuItem key={index} value={category.categoryName}>
                              {category.categoryName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <br />
                  <Grid container>
                    <Grid item xs={12} md={12} sm={12} lg={12}>
                      <TextField
                        value={newRow.items}
                        onChange={(e) => handleNewRowChange(e, "items")}
                        label="Item"
                        style={{ width: "100%" }}
                      />
                    </Grid>
                  </Grid>
                  <br />
                  <Grid container columnSpacing={1} rowSpacing={2}>
                    <Grid item md={6} sm={12} xs={12} lg={6}>
                     <FormControl>
                        <InputLabel id="demo-simple-selec-autowidth-label">
                          Unit
                        </InputLabel>
                        <Select
                          labelId="demo-simple-selec-autowidth-label"
                          onChange={(e) => handleNewRowChange(e, "unit")}
                          style={{ width: "190px" }}
                          label="Unit"
                        >
                          {units.map((category, index) => (
                            <MenuItem key={index} value={category.value}>
                              {category.value}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item md={6} sm={12} xs={12} lg={6}>
                      <TextField
                        value={newRow.quantity}
                        onChange={(e) => handleNewRowChange(e, "quantity")}
                        label="Quantity"
                        style={{ width: "100%" }}
                        type="number"
                      />
                    </Grid>
                  </Grid>
                  <br />
                  <Grid container columnSpacing={1} rowSpacing={2}>
                    <Grid item md={6} xs={12} sm={12} lg={6}>
                      <TextField
                        onChange={(e) => handleNewRowChange(e, "price")}
                        value={newRow.price}
                        label="Price"
                        style={{ width: "100%" }}
                        type="number"
                      />
                    </Grid>
                    <Grid item md={6} xs={12} sm={12} lg={6}>
                      <TextField
                        type="date"
                        label="Delivery date"
                        onChange={(e) => handleNewRowChange(e, "date")}
                        value={newRow.date}
                        style={{
                          width: "100%",
                          height: "53px",
                          // border: "1px solid #8080806b",
                          borderRadius: "5px",
                        }}
                      />
                    </Grid>
                  </Grid>
                  <br />
                  <Grid container>
                    <Grid item lg={12} xs={12} sm={12} md={12}>
                      <TextField
                        multiline
                        rows={2}
                        id="outlined-basic"
                        label="Special Notes"
                        name="specialNotes"
                        variant="outlined"
                        style={{ width: "100%" }}
                        value={newRow.specialNotes}
                        onChange={(e) => handleNewRowChange(e, "specialNotes")}
                      />
                    </Grid>
                  </Grid>
                  <br />
                  <div style={{ textAlign: "end" }}>
                    <Button style={submitButton} onClick={handleSave}>
                      Save
                    </Button>
                  </div>
                </div>
              </Container>
            </DialogContent>
          </Dialog>
        )}
      </Grid>
      {/* </div> */}
    </Container>
  );
};

export default Pricing;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
