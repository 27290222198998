import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Snackbar,
  Button,
  Typography,
  TextField,
  Grid,
} from "@mui/material";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Modal } from "react-bootstrap";
import { MdOutlineMailOutline, MdLock } from "react-icons/md";
// import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import {
  getAuth,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import { LOGIN_SUCCESS } from "../../slices/types";
import { otpInputStyle } from "./Vendor_SignIn";
import Otplogin from "./Otplogin";
import InputAdornment from "@mui/material/InputAdornment";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import axios from "axios";
import { SIGNIN_EMLPWD } from "../CompServices/Api_Routes_Helper";
const JoinLogin = () => {
  const intialValues = {
    email: "",
    password: "",
  };
  const [formValues, setFormValues] = useState(intialValues);
  const { email, password } = formValues;

  //for hiding and display error(email & pwd)
  const [showErr, setshowErr] = React.useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [expanded, setExpanded] = React.useState(false);
  const [toggle1, setToggle1] = React.useState(false);
  const navigate = useNavigate();

  const handleClosePopUpErr = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setshowErr(false);
  };
  const handleChangeAcc = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleInputEmailPwd = (event) => {
    const { name } = event.target;
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    setFormValues({ ...formValues, [name]: value });
    // console.log(name, value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // const auth = getAuth();
    try {
      const { data, status } = await axios.post(`${SIGNIN_EMLPWD}`, {
        email,
        password,
      });
      // Check if the user's email is verified
      if (status === 200 && data !== null) {
        const { _id, email } = data?.data;
        localStorage.setItem(
          "userNew",
          JSON.stringify({ res: data?.data, role: "USER" })
        );
        localStorage.setItem("userID", _id);
        localStorage.setItem("email", email);
        setSuccessMsg("SignIn successfully!");
        setshowErr(true);
        window.location.assign("/");
        // navigate("/", { preventScrollReset: true });
      }
    } catch (error) {
      console.error("Error during  user authentication", error);
      const { status, data } = error?.response;

      switch (status) {
        case 404:
          setErrMsg(data?.message);
          break;
        case 401:
          setErrMsg(data?.message);
          break;
        case 500:
          setErrMsg(data?.message);
          break;
        default:
          setErrMsg("Unknown error occurred during user login");
          break;
      }
      setshowErr(true);
    }
  };

  return (
    <React.Fragment>
      <form className="login-form" onSubmit={(event) => handleSubmit(event)}>
        <div className="form">
          <input
            type="email"
            id="email"
            label="Email Id"
            className="form-input new-log-input"
            placeholder=" "
            name="email"
            onChange={handleInputEmailPwd}
            required
            value={email}
            variant="outlined"
          />
          <i className="input-iconss">
            <MdOutlineMailOutline />
          </i>
          <label htmlFor="email" className="form-label new-form-label">
            Email-ID
          </label>
        </div>

        {formErrors.email && (
          <span className="form-error">{formErrors.email}</span>
        )}

        <div className="form">
          <input
            type={toggle1 ? "text" : "password"}
            id="password"
            label="Password"
            className="form-input new-log-input"
            placeholder=" "
            name="password"
            onChange={handleInputEmailPwd}
            value={password}
            required
            autoComplete="off"
            autoCapitalize="false"
            variant="outlined"
          />
          <i
            id="showpass"
            // className="fa fa-eye icon"
            style={{ right: "30px" }}
            className={`fa ${toggle1 ? "fa-eye" : "fa-eye-slash"} icon`}
            onClick={() => {
              setToggle1(!toggle1);
            }}
          ></i>
          <i className="input-iconss">
            <MdLock />
          </i>
          <label htmlFor="password" className="form-label new-form-label ">
            Password
          </label>
        </div>

        {formErrors.password && (
          <span className="form-error">{formErrors.password}</span>
        )}

        <div className="forgotPassword">
          <p className="commonn-paraa">Forgot Password?</p>
        </div>

        <div style={{ display: "block", margin: "auto", alignItems: "center" }}>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            sx={{ p: 1.5, fontSize: 16 }}
            className="new-button-style"
          >
            LOGIN
          </Button>
        </div>
      </form>

      {CustomAlertBar()}
    </React.Fragment>
  );

  function CustomAlertBar() {
    return (
      <Snackbar
        open={showErr}
        autoHideDuration={2500}
        onClose={handleClosePopUpErr}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleClosePopUpErr}
          severity={errMsg ? "error" : "success"}
          sx={{ width: "100%", fontSize: 15 }}
        >
          {errMsg || successMsg}
        </Alert>
      </Snackbar>
    );
  }
};

export default JoinLogin;
