import React, { useState, useEffect } from "react";
import VendorDraftModel from "../../ServiceProvider/Requests/vendorDraft/VendorDraftModel";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import authHeader from "../../../Services/auth-header";
import { useParams } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { ordersColumns } from "./rowsOrderData";
import { Container, Card, Grid } from "@mui/material";
import {
  GET_ORDER_INFO,
  GET_RFQ_EXPLAIN,
} from "../../CompServices/Api_Routes_Helper";

const RfqOrder = () => {
  const { id } = useParams();
  const [tableData, setTableData] = useState([]);
  const [values, setValues] = useState([]);

  useEffect(() => {
    const getValue = async () => {
      await axios
        .get(`${GET_RFQ_EXPLAIN}/${id}`)
        .then((res) => {
          const requestedData = res.data.data;
          const setRequestedData = [requestedData];
          setValues(setRequestedData);
          // setRequests(res.data.data.requests);
        })
        .catch((error) => console.error(error));
    };
    if (id !== null) {
      getValue();
    }
  }, [id]);
  useEffect(() => {
    let isMounted = true;
    const getOrderData = async () => {
      const { data } = await axios.get(`${GET_ORDER_INFO}/${id}`, {
        headers: await authHeader(),
      });
      if (data && isMounted) {
        const orderData = data.data[0];
        const { orders } = orderData;
        setTableData(orders);
      }
    };
    getOrderData();
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <Container maxWidth="lg">
      <Grid container className="qup_respondPage_mainWrap">
        <Grid item md={12} sm={12} lg={12} className="respons_inlinestyle">
          <div className="respond_ui">
            <Card className="card_componet_update card_rounded">
              <Container>
                <div className="orderInfoMainwrap">
                  <h4> RfqOrder Info </h4>
                  {id &&
                    values.length > 0 &&
                    values.map((data, item) => (
                      <div className="categoryview-card ">
                        <div className="reusetable_alter">
                          <h2 className="headding">Client Request</h2>
                          <Grid container>
                            <Grid item sm={12} md={4} className="viewPart">
                              <label className="subject-text">Subject</label>
                            </Grid>
                            <Grid
                              item
                              sm={12}
                              md={8}
                              className="respond clientborder"
                            >
                              <p className="scrollable-content">
                                {data.subject}
                              </p>
                            </Grid>
                          </Grid>
                          <br />
                          <Grid container className="grid-con">
                            <Grid sm={12} md={4} className="viewPart">
                              <label className="subject-text">description</label>
                            </Grid>

                            <Grid
                              sm={12}
                              md={8}
                            
                              className="respond clientborder"
                            >
                              <p className="scrollable-content">
                                {data.description}
                              </p>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    ))}

                  <section className="orderWrapper">
                    <DataGrid
                      columns={ordersColumns}
                      rows={tableData}
                      getRowId={(row) => row._id}
                    />
                  </section>
                </div>
              </Container>
            </Card>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default RfqOrder;
