import Moment from "react-moment";

export const ordersColumns = [
  { field: "items", headerName: "Item", width: 120 },
  {
    field: "quantity",
    headerName: "quantity",
    width: 80,
    type: "number",
    // editable: true,
  },
  {
    field: "unit",
    headerName: "Unit",
    width: 80,
    type: "singleSelect",
    valueOptions: ["kg", "grams", "Tonnes", "Pieces"],
  },
  {
    field: "price",
    headerName: "Expected Price",
    width: 100,
    valueFormatter: (params) => {
      const price = params.value;
      if (price !== null) {
        const newPrice = new Intl.NumberFormat("en-IN", {
          // style: "currency",
          currency: "INR",
        }).format(price);
        return newPrice;
      }
      return "";
    },
  },
  {
    field: "OrderQuantity",
    headerName: "Order Quantity",
    width: 100,
  },
  {
    field: "OrderPrice",
    headerName: "Order Price",
    width: 100,
    valueFormatter: (params) => {
      const responsePrice = params.value;
      if (responsePrice !== null) {
        const newResponsePrice = new Intl.NumberFormat("en-IN", {
          currency: "INR",
        }).format(responsePrice);
        return newResponsePrice;
      }
      return "";
    },
  },
  {
    field: "OrderTax",
    headerName: "Order Tax",
    width: 100,
    valueFormatter: (params) => {
      if (params.value === null) {
        return "";
      }
      return params.value + "%";
    },
  },
  {
    field: "OrderDate",
    headerName: "Order Date",
    width: 140,
    renderCell: (params) => {
      const responseDate = params.row.OrderDate;

      if (responseDate) {
        return <Moment format="DD/MM/YYYY">{responseDate}</Moment>;
      } else {
        return <span>--NA--</span>;
      }
    },
  },
  { field: "vendorName", header: "Vendor Info" },
  {
    field: "OrderStatus",
    headerName: "status",
    width: 100,
    //   renderCell: (params) => {
    //     const { _id } = params.row;
    //     const rowAcceptanceStatus = Currstatus[_id];
    //     return rowAcceptanceStatus;
    //   },
  },
];
