import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  invoiceNoContainer: {
    flexDirection: "row",
    marginTop: 3,
    justifyContent: "flex",
  },
  label: {
    width: 80,
  },
  titleContainer: {
    marginTop: 12,
  },
  reportTitle: {
    fontSize: 12,

    marginLeft: 6,
    width: 500,
  },
  reportTerms: {
    fontSize: 12,
    marginTop: 12,
    textAlign: "left",
  },
  reportNotes: {
    marginTop: 5,
    fontSize: 8,
    textAlign: "left",
  },
});

const InvoiceSubject = ({ invoice, ClientDetails }) => {
  return (
    <>
      <View style={styles.titleContainer}>
        {invoice ? (
          <>
            <View style={styles.invoiceNoContainer}>
              <Text style={styles.label}>Subject :</Text>
              <Text style={styles.reportTitle}>
                <Text>{invoice[0].vendorSubject}</Text>
              </Text>
            </View>
            <View style={styles.invoiceNoContainer}>
              <Text style={styles.label}>Description :</Text>
              <Text style={styles.reportTitle}>
                <Text>{invoice[0].vendorDescription}</Text>
              </Text>
            </View>
          </>
        ) : null}

        {/* <>
          <View style={styles.invoiceNoContainer}>
            <Text style={styles.label}>Subject :</Text>
            <Text style={styles.reportTitle}>
              <Text>{vendorSubject}</Text>
            </Text>
          </View>
          <View style={styles.invoiceNoContainer}>
            <Text style={styles.label}>Description :</Text>
            <Text style={styles.reportTitle}>
              <Text>{vendorDescription}</Text>
            </Text>
          </View>
        </> */}

        {ClientDetails ? (
          <>
            <View style={styles.invoiceNoContainer}>
              <Text style={styles.label}>Subject :</Text>
              <Text style={styles.reportTitle}>
                <Text>{ClientDetails.subject}</Text>
              </Text>
            </View>
            <View style={styles.invoiceNoContainer}>
              <Text style={styles.label}>Description :</Text>
              <Text style={styles.reportTitle}>
                <Text>{ClientDetails.description}</Text>
              </Text>
            </View>
          </>
        ) : null}
      </View>
    </>
  );
};

export default InvoiceSubject;
