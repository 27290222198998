// import * as React from "react";
// import PropTypes from "prop-types";
// import DemoNavbarBar from "@mui/material/AppBar";
// import Box from "@mui/material/Box";
// import Divider from "@mui/material/Divider";
// import Drawer from "@mui/material/Drawer";
// import IconButton from "@mui/material/IconButton";
// import List from "@mui/material/List";
// import ListItem from "@mui/material/ListItem";
// import ListItemButton from "@mui/material/ListItemButton";
// import ListItemText from "@mui/material/ListItemText";
// import MenuIcon from "@mui/icons-material/Menu";
// import Toolbar from "@mui/material/Toolbar";
// import Typography from "@mui/material/Typography";
// import Button from "@mui/material/Button";
// import { Container } from "@mui/material";
// import { Link } from "react-router-dom";
// import "./Navbar.scss";
// import { values } from "../End-User/User-Data/MenuItems";
// import LinkComponent from "../DesignTools/LinkComponent";
// import {
//   linkStyle,
//   linkStyleLogo,
// } from "../DesignTools/ButtonStyles/ButtonStyles";

// const drawerWidth = 240;
// function DemoNavbar(props) {
//   const { window } = props;

//   const [mobileOpen, setMobileOpen] = React.useState(false);

//   const handleDrawerToggle = () => {
//     setMobileOpen((prevState) => !prevState);
//   };
//   const [anchorElUser, setAnchorElUser] = React.useState(null);

//   const handleOpenUserMenu = (event) => {
//     setAnchorElUser(event.currentTarget);
//   };
//   const handleCloseUserMenu = () => {
//     setAnchorElUser(null);
//   };
//   const drawer = (
//     <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
//       <Container maxWidth="xl" className="navbar_body">
//         <Typography variant="h6" sx={{ my: 2 }}>
//           <span className="navbar_logo">MyQuotes</span>
//         </Typography>
//         <Divider />
//         <List>
//           {values.map((item, index) => (
//             <ListItem key={index} disablePadding>
//               <ListItemButton sx={{ textAlign: "center" }}>
//                 <ListItemText primary={item.name} />
//               </ListItemButton>
//             </ListItem>
//           ))}
//         </List>
//       </Container>
//     </Box>
//   );

//   const container =
//     window !== undefined ? () => window().document.body : undefined;

//   return (
//     <>
//       <Box sx={{ display: "flex", marginTop: "30px" }}>
//         <AppBar component="nav" className="navbar_header">
//           <Container maxWidth="xl" className="navbar_body">
//             <Toolbar>
//               <Box sx={{ display: { xs: "flex", sm: "block" } }}>
//                 {values.map((item, index) => (
//                   <Button key={index} sx={{ color: "#fff" }}>
//                     <Link to={item.url} className="sub_menu_li">
//                       <Typography textAlign="center">{item.name}</Typography>
//                     </Link>
//                   </Button>
//                 ))}
//               </Box>
//             </Toolbar>
//           </Container>
//         </AppBar>
//       </Box>
//     </>
//   );
// }

// DemoNavbar.propTypes = {
//   /**
//    * Injected by the documentation to work in an iframe.
//    * You won't need it on your project.
//    */
//   window: PropTypes.func,
// };

// export default DemoNavbar;

import { Button, message, Steps, theme } from "antd";
import { useState } from "react";
import SellingBussinessInfo from "./SellingBInfo";
import ProfessinalInfo from "./ProfessinalInfo";
import AccountInfo from "./AccountInfo";
import "./SellStyles.scss";
import { Paper } from "@mui/material";
import { AccountTree, Business, Person2 } from "@mui/icons-material";
const steps = [
  {
    title: "Personal Info",
    content: "gjhg",
    icon: <Person2 />,
  },
  {
    title: "Professional Info",
    content: "Second-content",
    icon: <Business />,
  },
  {
    title: "Account Info",
    content: "Last-content",
    icon: <AccountTree />,
  },
];

const DemoNavbar = () => {
  const { token } = theme.useToken();
  const [current, setCurrent] = useState(0);
  const [finalData, setFinalData] = useState({});

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
    icon: item.icon,
  }));

  const contentStyle = {
    lineHeight: "260px",
    textAlign: "center",
    color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    // border: `1px dashed ${token.colorBorder}`,
    marginTop: 16,
  };

  return (
    <>
      <div className="navbar-content-profile">
        <Paper className="vendor-profile-paper" elevation={24} maxWidth="lg">
          <Steps
            className="vendor-profile-steps"
            current={current}
            items={items}
          />
          <div style={contentStyle}>
            {steps[current].title === "Personal Info" ? (
              <SellingBussinessInfo
                finalData={finalData}
                handleFinalData={setFinalData}
                steps={steps}
                current={current}
                prev={prev}
                next={next}
              />
            ) : steps[current].title === "Professional Info" ? (
              <ProfessinalInfo
                finalData={finalData}
                handleFinalData={setFinalData}
                steps={steps}
                current={current}
                prev={prev}
                next={next}
              />
            ) : (
              steps[current].title === "Account Info" && (
                <AccountInfo
                  finalData={finalData}
                  handleFinalData={setFinalData}
                  steps={steps}
                  current={current}
                  prev={prev}
                  next={next}
                />
              )
            )}
          </div>
        </Paper>
      </div>
    </>
  );
};
export default DemoNavbar;
