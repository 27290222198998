import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
// import {loginUser} from '@store/reducers/auth';
import { setWindowClass } from "../../utils/helpers";
import { Form, InputGroup } from "react-bootstrap";
import { PfButton, PfCheckbox } from "@profabric/react-components";

import {
  GoogleProvider,
  adminReg,
  authLogin,
  facebookLogin,
} from "../../utils/oidc-providers";
import { setAuthentication } from "../../store/reducers/auth";

const Register = () => {
  const [isAuthLoading, setAuthLoading] = useState(false);
  const [isGoogleAuthLoading, setGoogleAuthLoading] = useState(false);
  const [isFacebookAuthLoading, setFacebookAuthLoading] = useState(false);
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const register = async (
    email: string,
    password: string,
    passwordRetype: string
  ) => {
    try {
      setAuthLoading(true);
      const data = { email, password, passwordRetype };
      const response = await adminReg(data);
      dispatch(setAuthentication(response as any));
      toast.success("Registration is success");
      navigate("/qup/admin/");
    } catch (error: any) {
      console.log(error);
      toast.error(error.message || "Failed");
      setAuthLoading(false);
    }
  };

  const registerByGoogle = async () => {
    try {
      setGoogleAuthLoading(true);
      const response = await GoogleProvider.signinPopup();
      dispatch(setAuthentication(response as any));
      setGoogleAuthLoading(false);
      toast.success("Authentication is succeed!");
      navigate("/");
    } catch (error: any) {
      toast.error(error.message || "Failed");
      setGoogleAuthLoading(false);
    }
  };

  const registerByFacebook = async () => {
    try {
      setFacebookAuthLoading(true);
      const response = await facebookLogin();
      dispatch(setAuthentication(response as any));
      setFacebookAuthLoading(false);
      navigate("/");
    } catch (error: any) {
      setFacebookAuthLoading(false);
      toast.error(error.message || "Failed");
    }
  };

  const { handleChange, values, handleSubmit, touched, errors } = useFormik({
    initialValues: {
      email: "",
      password: "",
      passwordRetype: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address").required("Required"),
      password: Yup.string()
        .min(5, "Must be 5 characters or more")
        .max(30, "Must be 30 characters or less")
        .required("Required"),
      passwordRetype: Yup.string()
        // .oneOf([Yup.ref("password"), null], "Password must be same")
        .test("passwordRetype", "Passwords must be same", function (value) {
          return this.parent.password === value;
        })
        .required("Required"),
    }),
    onSubmit: (values) => {
      register(values.email, values.password, values.passwordRetype);
    },
  });

  setWindowClass("hold-transition register-page");

  return (
    <div className="register-box">
      <div className="card card-outline card-primary">
        <div className="card-header text-center">
          <Link to="/" className="h1">
            <b>QuoteUrPrice</b>
          </Link>
        </div>
        <div className="card-body">
          <p className="login-box-msg">{t ("register.registerNew") as string}</p>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <InputGroup className="mb-3">
                <Form.Control
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Email"
                  onChange={handleChange}
                  value={values.email}
                  isValid={touched.email && !errors.email}
                  isInvalid={touched.email && !!errors.email}
                />
                {touched.email && errors.email ? (
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                ) : (
                  <InputGroup.Text
                    id="basic-addon2"
                    style={{ padding: "0px 4px" }}
                  >
                    <div className="input-group-addon">
                      <i className="fas fa-envelope" />
                    </div>
                  </InputGroup.Text>
                )}
              </InputGroup>
            </div>
            <div className="mb-3">
              <InputGroup className="mb-3">
                <Form.Control
                  id="password"
                  name="password"
                  type="password"
                  placeholder="Password"
                  onChange={handleChange}
                  value={values.password}
                  isValid={touched.password && !errors.password}
                  isInvalid={touched.password && !!errors.password}
                />
                {touched.password && errors.password ? (
                  <Form.Control.Feedback type="invalid">
                    {errors.password}
                  </Form.Control.Feedback>
                ) : (
                  <InputGroup.Text
                    id="basic-addon2"
                    style={{ padding: "0px 4px" }}
                  >
                    <div className="input-group-addon">
                      <i className="fas fa-lock"></i>
                    </div>
                  </InputGroup.Text>
                )}
              </InputGroup>
            </div>

            <div className="mb-3">
              <InputGroup className="mb-3">
                <Form.Control
                  id="passwordRetype"
                  name="passwordRetype"
                  type="password"
                  placeholder="Retype password"
                  onChange={handleChange}
                  value={values.passwordRetype}
                  isValid={touched.passwordRetype && !errors.passwordRetype}
                  isInvalid={touched.passwordRetype && !!errors.passwordRetype}
                />

                {touched.passwordRetype && errors.passwordRetype ? (
                  <Form.Control.Feedback type="invalid">
                    {errors.passwordRetype}
                  </Form.Control.Feedback>
                ) : (
                  <InputGroup.Text
                    id="basic-addon2"
                    style={{ padding: "0px 4px" }}
                  >
                    <div className="input-group-addon">
                      <i className="fas fa-lock"></i>
                    </div>
                  </InputGroup.Text>
                )}
              </InputGroup>
            </div>

            <div className="row">
              <div className="col-7">
                <PfCheckbox checked={false}>
                  <span>I agree to the </span>
                  <Link to="/">terms</Link>
                </PfCheckbox>
              </div>
              <div className="col-5">
                <PfButton
                  type="submit"
                  block
                  loading={isAuthLoading}
                  disabled={isGoogleAuthLoading || isFacebookAuthLoading}
                >
                  Register
                </PfButton>
              </div>
            </div>
          </form>
          <div className="social-auth-links text-center">
            <PfButton
              block
              className="mb-2"
              onClick={registerByFacebook}
              loading={isFacebookAuthLoading}
              disabled
              // disabled={isAuthLoading || isGoogleAuthLoading}
            >
              <i className="fab fa-facebook mr-2" />
              {t("login.button.signIn.social", {
                what: "Facebook",
              }) as string}
            </PfButton>
            <PfButton
              block
              theme="danger"
              onClick={registerByGoogle}
              loading={isGoogleAuthLoading}
              disabled
              // disabled={isAuthLoading || isFacebookAuthLoading}
            >
              <i className="fab fa-google mr-2" />
              {t ("login.button.signUp.social", { what: "Google" }) as string}
            </PfButton>
          </div>
          <Link to="/qup/admin/login" className="text-center">
            {t("register.alreadyHave") as string}
          </Link>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Register;
