import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Slide,
  TextField,
} from "@mui/material";
import { IconButton } from "@mui/material";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import DoneIcon from "@material-ui/icons/DoneAllTwoTone";
import AddIcon from "@mui/icons-material/Add";
import { HiEye, HiPencilAlt } from "react-icons/hi";
import {
  removeButton,
  submitButton,
} from "../DesignTools/ButtonStyles/ButtonStyles";
import { units } from "../End-User/User-Data/ServicesData";
import { BootstrapTooltip } from "../DesignTools/Tooltip";
import Moment from "react-moment";

function DemoTable({ dataValue, options, updateRequires, RfqType }) {
  const [rows, setRows] = useState(dataValue);
  const [previous, setPrevious] = useState({});
  const [newRow, setNewRow] = useState(null);
  updateRequires(rows);
  console.log("rows", rows);
  const columns = [
    {
      field: "categoryName",
      headerName: "Category Name",
      width: 180,
      renderCell: (params) => {
        const { row } = params;
        return row.isEditMode ? (
          <Select
            value={row.categoryName}
            onChange={(e) => handleDropdownChange(e, row, "categoryName")}
            style={{ width: "100%" }}
          >
            {options.map((category) => (
              <MenuItem
                key={category.categoryName}
                value={category.categoryName}
              >
                {category.categoryName}
              </MenuItem>
            ))}
          </Select>
        ) : (
          row.categoryName
        );
      },
    },
    {
      field: "items",
      headerName: "Items",
      width: 150,
      renderCell: (params) => {
        const { row } = params;
        const inputRef = React.createRef();
        const handleKeyDown = (e) => {
          if (row.isEditMode) {
            if (e.key === " ") {
              e.stopPropagation();
            } else if (e.key === "a" && (e.ctrlKey || e.metaKey)) {
              e.preventDefault();
              inputRef.current?.select();
            }
          }
        };
        return row.isEditMode ? (
          <input
            ref={inputRef}
            className="edit_table_input"
            value={row.items}
            type="text"
            onChange={(e) => onChange(e.target.value, row, "items")}
            onKeyDown={handleKeyDown}
            onDoubleClick={(e) => e.stopPropagation()}
            style={{ width: "100%" }}
          />
        ) : (
          row.items
        );
      },
    },
    {
      field: "price",
      headerName: "Price",
      width: 120,
      renderCell: (params) => {
        const { row } = params;
        return row.isEditMode ? (
          <input
            className="edit_table_input"
            value={row.price}
            type="number"
            onChange={(e) => onChange(e.target.value, row, "price")}
            style={{ width: "100%" }}
          />
        ) : (
          row.price
        );
      },
    },
    {
      field: "quantity",
      headerName: "Quantity",
      width: 120,
      renderCell: (params) => {
        const { row } = params;
        return row.isEditMode ? (
          <input
            value={row.quantity}
            className="edit_table_input"
            type="number"
            onChange={(e) => onChange(e.target.value, row, "quantity")}
            style={{ width: "100%" }}
          />
        ) : (
          row.quantity
        );
      },
    },
    {
      field: "unit",
      headerName: "Unit",
      width: 120,
      renderCell: (params) => {
        const { row } = params;
        return row.isEditMode ? (
          <Select
            value={row.unit}
            onChange={(e) => handleDropdownChange(e, row, "unit")}
            style={{ width: "100%" }}
          >
            {units.map((category, index) => (
              <MenuItem key={index} value={category.value}>
                {category.value}
              </MenuItem>
            ))}
          </Select>
        ) : (
          row.unit
        );
      },
    },
    {
      field: "date",
      headerName: "Date",
      width: 120,
      renderCell: (params) => {
        const { row } = params;

        // Function to format the date
        const formatDate = (dateString) => {
          const options = { day: "2-digit", month: "2-digit", year: "numeric" };
          return new Date(dateString).toLocaleDateString(undefined, options);
        };

        return row.isEditMode ? (
          <input
            type="date"
            value={
              row.date ? new Date(row.date).toISOString().split("T")[0] : ""
            }
            onChange={(e) => handleDropdownChange(e, row, "date")}
            style={{ background: "none", border: "none " }}
          />
        ) : (
          <Moment format="DD/MM/YYYY">{row.date}</Moment>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      renderCell: (params) => {
        const { row } = params;
        return row.isEditMode === true ? (
          <>
            <BootstrapTooltip title="Save">
              <IconButton
                aria-label="done"
                onClick={() => handleSaveClicks(row.id)}
              >
                <DoneIcon style={{ fontSize: "17px" }} />
              </IconButton>
            </BootstrapTooltip>
            <BootstrapTooltip title="Delete">
              <IconButton
                aria-label="delete"
                onClick={() => handleDeleteRow(params.row.id)}
              >
                <DeleteIcon style={{ fontSize: "17px" }} />
              </IconButton>
            </BootstrapTooltip>
            {/* <IconButton aria-label="revert" onClick={() => onRevert(row._id)}>
              <RevertIcon />
            </IconButton> */}
          </>
        ) : (
          <>
            <BootstrapTooltip title="Edit">
              <IconButton
                aria-label="edit"
                onClick={() => onToggleEditMode(row.id)}
              >
                <HiPencilAlt style={{ fontSize: "17px" }} />
              </IconButton>
            </BootstrapTooltip>
            <BootstrapTooltip title="Delete">
              <IconButton
                aria-label="delete"
                onClick={() => handleDeleteRow(params.row.id)}
              >
                <DeleteIcon style={{ fontSize: "17px" }} />
              </IconButton>
            </BootstrapTooltip>
          </>
        );
      },
    },
  ];

  const updateRows = (newRows) => {
    setRows(newRows);
    updateRequires(newRows);
  };

  const onToggleEditMode = (id) => {
    setRows((state) => {
      return rows.map((row) => {
        if (row.id === id) {
          return { ...row, isEditMode: !row.isEditMode };
        }
        return row;
      });
    });
  };

  const handleDropdownChange = (e, row, name) => {
    const { value } = e.target;
    onChange(value, row, name);
  };

  const onChange = (value, row, name) => {
    const id = row.id;
    const newRows = rows.map((row) => {
      if (row.id === id) {
        return { ...row, [name]: value };
      }
      return row;
    });
    setRows(newRows);
  };

  const onRevert = (_id) => {
    const newRows = rows.map((row) => {
      if (row._id === _id) {
        return previous[_id] ? previous[_id] : row;
      }
      return row;
    });
    setRows(newRows);
    setPrevious((state) => {
      const newState = { ...state };
      delete newState[_id];
      return newState;
    });
    onToggleEditMode(_id);
  };

  const handleAddRow = () => {
    const id = Math.random().toString(36).substr(2, 9);
    const defaultNewRow = {
      id,
      categoryName: "",
      items: "",
      price: "",
      quantity: "",
      unit: "",
      isEditMode: true,
    };
    setNewRow(defaultNewRow);
    handleEditPageOpen();
  };

  const handleNewRowChange = (e, name) => {
    const { value, name: fieldName } = e.target;
    console.log("value", value);

    const selectedCategory = options.find(
      (category) => category.categoryName === value
    );
    console.log("selectedCategory", selectedCategory);

    if (name === "categoryName") {
      setNewRow((prevState) => ({
        ...prevState,
        [name]: value,
        cat_id: selectedCategory ? selectedCategory._id : "",
      }));
    } else {
      setNewRow((prevState) => ({
        ...prevState,
        [name]: value,
        cat_id: prevState.cat_id || "",
      }));
    }
  };

  const handleSaveClicks = (id) => {
    if (newRow) {
      const newRows = [...rows, newRow];
      setRows(newRows);
      setNewRow(null);
      updateRows(newRows);
    }
    onToggleEditMode(id);
  };
  const handleCancelClick = () => {
    setNewRow(null);
    handleCloseEditModal();
  };

  const handleSaveClick = () => {
    if (newRow) {
      const newRows = [...rows, newRow];
      setRows(newRows);
      setNewRow(null);
      updateRows(newRows);
      handleCloseEditModal();
    }
  };
  const handleDeleteRow = (id) => {
    const newRows = rows.filter((row) => row.id !== id);
    setRows(newRows);
  };

  const handleRowDoubleClick = (params) => {
    const editedRows = rows.map((row) =>
      row.id === params.id ? { ...row, isEditMode: true } : row
    );
    setRows(editedRows);
  };

  //modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleEditPageOpen = (id) => {
    setIsModalOpen(true);
  };
  const handleCloseEditModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div>
        <div style={{ textAlign: "end" }} className="categoryadd">
          {RfqType === "Service" ? (
            <>
              <Button disabled>
                <AddIcon />
                Add Row
              </Button>
            </>
          ) : (
            <Button onClick={handleAddRow} style={submitButton}>
              <AddIcon />
              Add Row
            </Button>
          )}
        </div>
        {newRow && (
          <Dialog
            open={isModalOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleCloseEditModal}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle
              className="h3_div_res"
              style={{ padding: 0, marginLeft: 0 }}
            >
              <span>{"Add New Row :"}</span>
              <i className="fa fa-close" onClick={handleCloseEditModal}></i>
            </DialogTitle>
            <DialogContent>
              <Container>
                <div>
                  <Grid container>
                    <Grid item xs={12} md={12} sm={12} lg={12}>
                      <FormControl style={{ width: "100%", marginTop: "10px" }}>
                        <InputLabel id="demo-simple-select-autowidth-label">
                          Select Category Name
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-autowidth-label"
                          value={newRow.categoryName}
                          onChange={(e) =>
                            handleNewRowChange(e, "categoryName")
                          }
                          label="Select Category Name"
                        >
                          {options.map((category, index) => (
                            <MenuItem key={index} value={category.categoryName}>
                              {category.categoryName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <br />
                  <Grid container>
                    <Grid item xs={12} md={12} sm={12} lg={12}>
                      <TextField
                        onChange={(e) => handleNewRowChange(e, "items")}
                        label="Item"
                        style={{ width: "100%" }}
                      />
                    </Grid>
                  </Grid>
                  <br />
                  <Grid container columnGap={1} rowSpacing={2}>
                    <Grid item md={5} xs={12} sm={12} lg={5}>
                      <FormControl>
                        <InputLabel id="demo-simple-selec-autowidth-label">
                          Unit
                        </InputLabel>
                        <Select
                          labelId="demo-simple-selec-autowidth-label"
                          onChange={(e) => handleNewRowChange(e, "unit")}
                          style={{ width: "150px" }}
                          label="Unit"
                        >
                          {units.map((category, index) => (
                            <MenuItem key={index} value={category.value}>
                              {category.value}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item md={6} sm={12} xs={12} lg={6}>
                      <TextField
                        onChange={(e) => handleNewRowChange(e, "quantity")}
                        label="Quantity"
                        style={{ width: "100%" }}
                        type="number"
                      />
                    </Grid>
                  </Grid>
                  <br />
                  <Grid container columnGap={1} rowGap={2}>
                    <Grid item md={5} xs={12} sm={12} lg={5}>
                      <TextField
                        onChange={(e) => handleNewRowChange(e, "price")}
                        label="Price"
                        style={{ width: "100%" }}
                        type="number"
                      />
                    </Grid>
                    <Grid item md={6} xs={12} sm={12} lg={6}>
                      <input
                        type="date"
                        onChange={(e) => handleNewRowChange(e, "date")}
                        label="Date"
                        style={{
                          width: "100%",
                          height: "55px",
                          border: "1px solid #8080806b",
                          borderRadius: "5px",
                          color: "#00000078",
                        }}
                      />
                    </Grid>
                  </Grid>
                  <br />
                  <Grid container>
                    <Grid item lg={12} xs={12} sm={12} md={12}>
                      <TextField
                        multiline
                        rows={2}
                        id="outlined-basic"
                        label="Special Notes"
                        name="specialNotes"
                        variant="outlined"
                        style={{ width: "100%" }}
                        onChange={(e) => handleNewRowChange(e, "specialNotes")}
                      />
                    </Grid>
                  </Grid>
                  <br />
                  <div style={{ textAlign: "end" }}>
                    <Button onClick={handleSaveClick} style={submitButton}>
                      Add
                    </Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button onClick={handleCancelClick} style={removeButton}>
                      Remove
                    </Button>
                  </div>
                </div>
              </Container>
            </DialogContent>
          </Dialog>
        )}
        <br />
        <div style={{background:'white'}}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={5}
          editMode="row"
          getRowId={(row) => row.id}
          onRowDoubleClick={handleRowDoubleClick}
        />
        </div>
      </div>
    </>
  );
}

export default DemoTable;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
