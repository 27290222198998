import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
const useAuth = () => {
  let user: any;
  // let jwt = { token: false };
  const _user = localStorage.getItem("userNew");
  if (_user) {
    user = JSON.parse(_user);
    console.log("user", user);
  }
  if (user) {
    return {
      auth: true,
      role: user?.role,
    };
  } else {
    return {
      auth: false,
      role: null,
    };
  }
};
type ProtectedRouteType = {
  roleRequired?: ("USER" | "ADMIN" | "PUBLIC")[];
};
interface userData {
  auth: any;
}
const ProtectedRoutes = (props: ProtectedRouteType) => {
  const user = useSelector((state: userData) => state.auth.user);
  const { auth, role } = useAuth();

  //if the role required is there or not
  if (props.roleRequired) {
    const isAuthorized = props.roleRequired.includes(role);

    return auth ? (
      isAuthorized && user !== null ? (
        <Outlet />
      ) : (
        <Navigate to="/denied" />
      )
    ) : (
      <Navigate to="/signin" />
    );
  } else {
    return auth ? <Outlet /> : <Navigate to="/signin" />;
  }
};

export default ProtectedRoutes;
