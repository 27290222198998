import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "#3778C2";
const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    borderBottomColor: "#3778C2",
    backgroundColor: "#3778C2",
    color: "#fff",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    textAlign: "center",
    fontStyle: "bold",
    flexGrow: 1,
  },
  terms: {
    width: "100%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "center",
  },
  description: {
    width: "100%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "center",
  },
});

const TermsHeader = ({ terms, special }) => (
  <View style={styles.container}>
    {terms ? (
      <>
        <Text style={styles.description}>Terms</Text>
        <Text style={styles.terms}>Description</Text>
      </>
    ) : null}
    {special ? (
      <>
        <Text style={styles.description}>Special Note</Text>
      </>
    ) : null}
  </View>
);

export default TermsHeader;
