import React from "react";
import Slider from "react-slick";

const testimonialData = [
  {
    name: "John Doe",
    position: "CEO, Company A",
    review:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed pulvinar mi vel risus iaculis fringilla.",
    image:
      "https://imgv3.fotor.com/images/gallery/Realistic-Male-Profile-Picture.jpg",
  },
  {
    name: "Jane Smith",
    position: "CTO, Company B",
    review:
      "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas fringilla.",
    image:
      "https://images.unsplash.com/photo-1511367461989-f85a21fda167?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8cHJvZmlsZXxlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
  },
  {
    name: "John Doe",
    position: "CEO, Company A",
    review:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed pulvinar mi vel risus iaculis fringilla.",
    image:
      "https://imgv3.fotor.com/images/gallery/Realistic-Male-Profile-Picture.jpg",
  },
  {
    name: "Jane Smith",
    position: "CTO, Company B",
    review:
      "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas fringilla.",
    image:
      "https://images.unsplash.com/photo-1511367461989-f85a21fda167?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8cHJvZmlsZXxlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
  },
  {
    name: "John Doe",
    position: "CEO, Company A",
    review:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed pulvinar mi vel risus iaculis fringilla.",
    image:
      "https://imgv3.fotor.com/images/gallery/Realistic-Male-Profile-Picture.jpg",
  },
  {
    name: "Jane Smith",
    position: "CTO, Company B",
    review:
      "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas fringilla.",
    image:
      "https://images.unsplash.com/photo-1511367461989-f85a21fda167?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8cHJvZmlsZXxlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
  },
];

const Testimonials = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 10000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="testimonials-container">
      <h2 className="testimonials-title">Client Testimonials</h2>
      <Slider {...settings}>
        {testimonialData.map((testimonial, index) => (
          <div className="testimonial-main" key={index}>
            <div className="testimonial">
              <div className="testimonial-image">
                <img
                  src={testimonial.image}
                  alt={`${testimonial.name}'s profile`}
                />
              </div>
              <p className="testimonial-text">{testimonial.review}</p>
              <div className="testimonial-author">
                <p className="testimonial-author-name">{testimonial.name}</p>
                <p className="testimonial-author-position">
                  {testimonial.position}
                </p>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Testimonials;
