import React from 'react'
import {
    DataGrid,
    GridColDef,
    GridValueFormatterParams,
    GridActionsCellItem,
    GridToolbar,
    GridRowParams,
  } from "@mui/x-data-grid";
  import { Box, Tooltip } from "@mui/material";

  interface ReuseDataGridProps {
    columns: GridColDef[];
    rows: any[];
  }
  const ReuseDataGrid: React.FC<ReuseDataGridProps> = ({ columns, rows }) => {
  return (
    <>
        <Box height={"auto"} p={1}>
                <DataGrid
                  columns={columns}
                  rows={rows}
                  getRowId={(row: any) => row._id}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 5,
                      },
                    },
                    columns: {
                      columnVisibilityModel: {
                        _id: false,
                        responseSubmissionStatus: true,
                        updatedAt: false,
                      },
                    },
                  }}
                  slots={{
                    toolbar: GridToolbar,
                  }}
                  slotProps={{
                    columnsPanel: {
                      disableHideAllButton: true,
                      disableShowAllButton: true,
                    },
                  }}
                  localeText={{ noRowsLabel: "No Data Found" }}
                  pageSizeOptions={[5]}
                />
              </Box>
    </>
  )
}

export default ReuseDataGrid