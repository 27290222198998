import image1 from "../../../assets/image1.svg";
import image2 from "../../../assets/image2.svg";
import image3 from "../../../assets/image3.svg";
import image8 from "../../../assets/image8.svg";
import image9 from "../../../assets/image9.svg";
import video from "../../../assets/video1.mp4";
import video1 from "../../../assets/video.mp4";
import video2 from "../../../assets/video2.mp4";
import video3 from "../../../assets/video3.mp4";
import img1 from "../../../images/t1.jpg";
import img2 from "../../../images/t1.jpg";
import img3 from "../../../images/t2.jpg";

//mui icon
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import SellIcon from "@mui/icons-material/Sell";

export const userNavItems = [
  // {
  //   name: "MyQuotes-Bussiness",
  //   url: "/local-services/business-owners/list_your_business",
  // },
  {
    title: "Create RFQ",
    url: "/user_in/categoryList",
  },
  {
    title: "Requests",
    url: "/user_in/categoryTable?rfq_type=Request",
  },
  {
    title: "Services",
    url: "/user_in/services?rfq_type=Service",
  },
  // {
  //   title: "Orders",
  //   url: "/user_in/Responseorders",
  //   submenu: [
  //     {
  //       title: "Completed",
  //       url: "/user_in/orders/completed",
  //     },
  //     {
  //       title: "On Going",
  //       url: "/user_in/orders/under_process",
  //     },
  //   ],
  // },
];

export const settings = [
  // {
  //   name: "Account Settings",
  //   url: "/My-Account-Settings",
  // },
  // {
  //   name: "My Dashboard",
  //   url: "/My-Dashboard",
  // },
  {
    name: "Profile",
    icon: AccountCircleIcon,
    url: "/user_in/profile",
  },
  {
    name: "Become a Seller",
    icon: SellIcon,
    url: "/user_in/start_selling",
  },
  {
    name: "Logout",
    icon: LogoutIcon,
    url: "/logout",
  },
];
export const setting = [
  {
    name: "Account Settings",
    url: "/My-Account-Settings",
  },
  {
    name: "My Dashboard",
    url: "/My-Dashboard",
  },
  {
    name: "Switch",
    url: "#",
  },
  {
    name: "Become a User",
    url: "/user_in/start_selling",
  },
  {
    name: "Logout",
    url: "/logout",
  },
];
export const navMenu = [
  {
    name: "MyQuotes-Bussiness",
    url: "/local-services/business-owners/list_your_business",
  },
  {
    name: "Requests",
    url: "/vendor/get_all_requests",
  },
  {
    name: "Responses",
    url: "/vendor/respones_sent",
  },
  {
    name: "On Going Bussiness",
    url: "/settings",
  },
];

export const values = [
  {
    name: "Personal",
    url: "/user/personal",
  },
  {
    name: "Professional",
    url: "/user/professinal",
  },
];

export const CarouselList = [
  {
    id: 1,
    image:
      "https://cdn.pixabay.com/photo/2017/01/14/10/56/people-1979261_960_720.jpg",
  },
  {
    id: 2,
    image: "https://api.feril.io/public/Carousel/web%20banner%203.png",
  },
  {
    id: 3,
    image:
      "https://fiverr-res.cloudinary.com/q_auto,f_auto,w_1400,dpr_1.0/v1/attachments/generic_asset/asset/50218c41d277f7d85feeaf3efb4549bd-1599072608122/bg-signup-1400-x1.png",
  },
];
export const Summary = [
  {
    desc: "A profile summary is a brief description of your qualifications, experience and accomplishments relevant to the job you are applying for",
  },
];
export const items = [
  {
    title: "Ac Repair",
    description: "Build your brand",
    imageSrc:
      "https://thumbs.dreamstime.com/b/technician-fixing-air-conditioner-young-male-screwdriver-88091969.jpg",
  },
  {
    title: "Gamer",
    description: "Someone likes playing",
    imageSrc:
      "https://media.istockphoto.com/id/1320799591/vector/game-on-neon-game-controller-or-joystick-for-game-console-on-blue-background.jpg?s=612x612&w=0&k=20&c=CbxRq6ctP5Ta1QLu18UMtvgJf4D-zFpTMm0Rz14_Gy0=",
  },
  {
    title: "Doctor",
    description: "to give Medical treatment",
    imageSrc:
      "https://img.freepik.com/free-photo/woman-doctor-wearing-lab-coat-with-stethoscope-isolated_1303-29791.jpg",
  },
  {
    title: "Music",
    description: "Reach More Customer",
    imageSrc:
      "https://images.unsplash.com/photo-1470225620780-dba8ba36b745?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8bXVzaWN8ZW58MHx8MHx8&w=1000&q=80",
  },
  {
    title: "Dancer",
    description: "Unlock Growth Online ",
    imageSrc:
      "https://i.pinimg.com/originals/27/c0/49/27c04936d46099571fb16b7f403c998c.jpg",
  },
];

export const second = [
  {
    title: "Key Kim Co-Founder",
    description:
      " ' It's extremely exciting that MyQuotes has freelancers from all over the world — it broadens the talent pool. One of the best things about MyQuotes is that while we're sleeping, someone's working. ' ",
    video: video,
  },
  {
    title: "Brighid Gannon",
    description:
      " ' We've used MyQuotes for Shopify web development, graphic design, and backend web development. Working with MyQuotes makes my job a little easier every day. ' ",
    video: video1,
  },
  {
    title: "Brighid Gannon",
    description:
      " ' We've used MyQuotes for Shopify web development, graphic design, and backend web development. Working with MyQuotes makes my job a little easier every day. ' ",
    video: video2,
  },
  {
    title: "Brighid Gannon",
    description:
      " ' We've used MyQuotes for Shopify web development, graphic design, and backend web development. Working with MyQuotes makes my job a little easier every day. ' ",
    video: video3,
  },
];
export const cardinfo = [
  {
    id: 1,
    image: img3,
    text: " Earn Easy Money with the Gig Ads Widget",
    text1:
      "Earn Easy Money with the Gig Ads Widget Looking to earn some extra money to fuel your summer…",
  },
  {
    id: 2,
    image: img1,
    text: "Meet  Affiliates Start The Year Strong 2022",
    text1:
      "Meet Tamila Affiliates Start The Year Strong 2022 We believe that our affiliates are our partners and we…",
  },
  {
    id: 3,
    image: img3,
    text: "Summer Offer: One widget, up to $200 extra",
    text1:
      "One widget, up to $200 extra commission This summer, Affiliates like you can receive extra commissions for generating…",
  },
];
export const guides = [
  {
    id: 1,
    image:
      "https://fiverr-res.cloudinary.com/q_auto,f_auto,w_350,dpr_1.0/v1/attachments/generic_asset/asset/d532c0ad4feed007b3899cebad595286-1599611560157/guide-start-online-business-552-x2.png",
    text: "Start an online business and work from home",
    text1: "A complete guide to starting a small business online",
  },
  {
    id: 2,
    image:
      "https://fiverr-res.cloudinary.com/q_auto,f_auto,w_350,dpr_1.0/v1/attachments/generic_asset/asset/d532c0ad4feed007b3899cebad595286-1599611560168/guide-digital-marketing-552-x2.png",
    text: "Digital Marketing Made easy",
    text1: "A practical guide to understand what is digital marketing",
  },
  {
    id: 3,
    image:
      "https://fiverr-res.cloudinary.com/q_auto,f_auto,w_350,dpr_1.0/v1/attachments/generic_asset/asset/d532c0ad4feed007b3899cebad595286-1599611560155/guide-create-a-logo-552-x2.png",
    text: "Create a Business Account",
    text1: "A step-by-step guide to create a memorable business logo",
  },
];

export const CarouselLists = [
  {
    id: 1,
    image:
      "https://cdn.pixabay.com/photo/2018/03/10/12/00/teamwork-3213924__340.jpg",
  },
  {
    id: 2,
    image:
      "https://www.ringcentral.com/gb/en/blog/wp-content/uploads/2021/02/business-coworkers-640x427.jpg",
  },
  {
    id: 3,
    image:
      "https://theskyscorner.com/wp-content/uploads/2021/10/Entrepreneur-business-man-wearing-a-formal-suit-and-tie-with-laptop-scaled.jpeg",
  },
];

export const VendornavMenu = [
  {
    name: "Requests",
    url: "/vendor/get_all_requests",
  },
  {
    name: "Orders",
    url: "/vendor/get_postOrders",
  },
  {
    name: "On Going Bussiness",
    url: "/settings",
  },
];
export const vendorsetting = [
  {
    name: "My Dashboard",
    url: "/My-Dashboard",
  },
  {
    name: "Logout",
    url: "/logout",
  },
];
export const services_offered_select = [
  {
    id: 1,
    link: "/categories/content",
    img_src: image1,
    name: "Graphics & Design",
  },
  { id: 2, link: "/categories/content", img_src: image2, name: "Music" },
  { id: 3, link: "/categories/content", img_src: image3, name: "Video" },
  { id: 4, link: "/categories/content", img_src: image8, name: "Hotels" },
  { id: 5, link: "/categories/content", img_src: image9, name: "Developer" },
];

export const QupFeatuers = [
  "Best Quotation",
  "Talent matching",
  "Team collaboration tools",
  "Business solutions",
];
