import axios from "axios";
const BASE_URL = "http://localhost:3000";
const source = axios.CancelToken.source();
export default axios.create({
  baseURL: BASE_URL,
  cancelToken: source.token,
});

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});
