/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";
import { useNavigate, useNavigation } from "react-router-dom";
// Material Dashboard 2 React components
import MDBox from "../../Dcomponents/MDBox";
import MDTypography from "../../Dcomponents/MDTypography";

// Material Dashboard 2 React base styles
import typography from "../../assets/theme/base/typography";
import { BsFacebook, BsGoogle, BsInstagram, BsTwitter } from "react-icons/bs";
import { Grid } from "@mui/material";

function Footer({ company, links }) {
  const navigate = useNavigate();
  const { href, name } = company;
  const { size } = typography;

  const renderLinks = () =>
    links.map((link) => (
      <MDBox key={link.name} component="li" px={2} lineHeight={1}>
        <Link href={link.href} target="_blank">
          <MDTypography variant="button" fontWeight="regular" color="text">
            {link.name}
          </MDTypography>
        </Link>
      </MDBox>
    ));

  // const socialIcons = () => {
  //   const icons = [
  //     { id: 1, url: "/", icon: BsFacebook, name: "fb" },
  //     { id: 2, url: "/", icon: BsTwitter, name: "tw" },
  //     { id: 3, url: "/", icon: BsGoogle, name: "google" },
  //     { id: 4, url: "/", icon: BsInstagram, name: "inst" },
  //   ];
  //   icons.map((data) => {
  //     return (
  //       <MDBox>
  //         <span>{data.name}</span>
  //       </MDBox>
  //     );
  //   });
  // };
  return (
    <>
      <MDBox
        width="100%"
        display="flex"
        flexDirection={{ xs: "column", lg: "row" }}
        justifyContent="space-between"
        alignItems="center"
        p={1.5}
      >
        <MDBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexWrap="wrap"
          color="text"
          fontSize={size.md}
          px={1.5}
          mt={2}
        >
          <h6>Copyright &copy; {new Date().getFullYear()} QuoteUrPrice</h6>
        </MDBox>
        <MDBox
          component="ul"
          sx={({ breakpoints }) => ({
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
            listStyle: "none",
            mt: 3,
            mb: 0,
            p: 0,

            [breakpoints.up("lg")]: {
              mt: 0,
            },
          })}
        >
          {renderLinks()}
          <div className="d-flex mx-auto" style={{ gap: "10px" }}>
            <a href="#" className="foot-icon" onClick={() => navigate("/")}>
              <BsFacebook className="foot-icon1" />
            </a>
            <a href="#" className="foot-icon" onClick={() => navigate("/")}>
              <BsTwitter className="foot-icon1" />
            </a>
            <a href="#" className="foot-icon" onClick={() => navigate("/")}>
              <BsGoogle className="foot-icon1" />
            </a>
            <a href="#" className="foot-icon" onClick={() => navigate("/")}>
              <BsInstagram className="foot-icon1" />
            </a>
          </div>
          {/* {socialIcons()} */}
        </MDBox>
      </MDBox>
    </>
  );
}

// Setting default values for the props of Footer
Footer.defaultProps = {
  company: { href: "https://www.peerme.tech/", name: "PeerMe" },
  links: [
    { href: "https://www.peerme.tech/", name: "PeerMe" },
    { href: "#", name: "About Us" },
    { href: "#", name: "License" },
  ],
};

// Typechecking props for the Footer
Footer.propTypes = {
  company: PropTypes.objectOf(PropTypes.string),
  links: PropTypes.arrayOf(PropTypes.object),
};

export default Footer;
