import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "../api/axios";
import { API_AUTH_URL } from "./CompServices/Api_Routes_Helper";
import success_popper from "../images/party_popper.c0c8accf.svg";
const Welcome = () => {
  const { token } = useParams();
  const [msg, setMsg] = useState("");
  const [tokenExpired, setTokenExpired] = useState(false);
  const [poper, setPoper] = useState(false);
  //request email from client

  useEffect(() => {
    // console.log(token);
    const controller = new AbortController();
    axios

      .get(API_AUTH_URL + "/confirm/" + token, {
        signal: controller.signal,
      })
      .then((response) => {
        // console.log(response);
        setPoper(true);
        setMsg(response?.data?.message);
      })

      .catch((error) => {
        console.log(error);
        if (error.response) {
          //destructing to get response
          const { data } = error.response;
          // console.log(type);

          switch (data.type) {
            case "INVALID-INPUT":
              setMsg(data.message);
              console.error(data.message);
              break;
            case "NOT-FOUND":
              setMsg(data.message);
              console.error(data.message);
              break;
            case "TOKEN-EXPIRED":
              setMsg(data.message);
              setTokenExpired(true);

              console.error(data.message);
              break;
            case "USER-NOT-FOUND":
              setMsg(data.message);
              console.error(data.message);
              break;
            case "ALREADY-VERIFIED":
              setMsg(data.message);
              console.log(data.message);
              break;
            case "SERVER-ERROR":
              setMsg(data.message);
              console.error(data.message);
              break;
            default:
              console.error("An unknown error occurred");
          }
        }
      });
    return () => {
      controller.abort();
    };
  }, []);
  const RegenerateToken = async () => {
    try {
      // console.log(token);

      await axios
        .put(`${API_AUTH_URL}request-new/confirm/${token}`, {
          headers: "Content-Type=text/html",
        })
        .then((response) => {
          console.log("pls Check u r mail");
          setMsg(response?.data?.message);
          setPoper(true);
        })
        .catch((error) => {
          console.error(error);
          const { data } = error.response;
          switch (data.type) {
            case "ALREADY-VERIFIED":
              // console.log("user already verified");
              setPoper(true);
              setMsg(data.message);
              setTokenExpired(false);

              break;
            case "TOKEN-NOT-EXPIRED":
              console.log("previous token still valid ");
              break;
            default:
              console.log("error occured during token creation");

              break;
          }
        });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="welcome_cont">
      <div className="Container">
        <div className="container_body">
          {poper && (
            <img
              src={success_popper}
              alt="account verified"
              sizes="(max-width: 710px) 120px,
            (max-width: 991px) 193px,
            278px"
            />
          )}
          <span className="serv_resp">{msg}</span>
          {tokenExpired && (
            <Button
              variant="contained"
              color="primary"
              sx={{
                width: "100%",
                maxWidth: "200px",
                minHeight: "50px",
                p: 1,
                fontSize: "14px",
                "&:hover": {
                  backgroundColor: "CaptionText",
                  color: "white",
                  fontSize: "15px",
                },
              }}
              onClick={RegenerateToken}
            >
              Regenerate Activation Link
            </Button>
          )}
          {!tokenExpired ? (
            <>
              <p>pls login to use our services</p>
              <Link to={"/signin"} style={{ textDecoration: "none" }}>
                <Button
                  variant="contained"
                  color="success"
                  sx={{
                    width: "70%",
                    minWidth: "200px",
                    minHeight: "50px",
                    p: 1,
                    "&:hover": { backgroundColor: "red", fontSize: "15px" },
                  }}
                >
                  LOGIN
                </Button>
              </Link>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default Welcome;
