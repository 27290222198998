import { createSlice } from "@reduxjs/toolkit";
interface RfqOptions {
  subject: string;
  description: string;
}
const initialState: RfqOptions = { subject: "", description: "" };
const rfqInfo = createSlice({
  initialState,
  name: "rfqinfo",
  reducers: {
    getSubDescp: (state, action) => {
      const { sub, descp } = action.payload;
      state.subject = sub;
      state.description = descp;
    },
    removeOnSubmit: (state, action) => {
      return initialState;
    },
  },
});
const { actions } = rfqInfo;
export const { getSubDescp, removeOnSubmit } = actions;
export default rfqInfo.reducer;
