import { Grid } from "@mui/material";
import React from "react";

function ReuseableClientSubDes({ subject, description, title }) {
  return (
    <div>
      <Grid item sm={12} md={6} lg={12} style={{ padding: "10px" }}>
        <div className="border-text reusetable_alter">
          <h2 className="headding">{title}</h2>
          <Grid container style={{ marginTop: "10px" }}>
            <Grid item sm={12} md={6} lg={2} className="respond res">
              <p className="subject-text">Subject</p>
            </Grid>
            <Grid item sm={12} md={6} lg={6} className="respond clientborder">
              <p
                style={{ padding: "10px", fontSize: "16px" ,fontWeight:"400"}}
                className="scrollable-content"
              >
                {subject}
              </p>
            </Grid>
          </Grid> 

          <Grid container style={{ marginTop: "15px" }}>
            <Grid item sm={12} md={6} lg={2} className="respond res">
              <p className="subject-text">Description</p>
            </Grid>
            <Grid item sm={12} md={6} lg={6} className="respond clientborder">
              <div className="descriptio">
                <p
                  style={{ padding: "10px", fontSize: "16px",fontWeight:"400" }}
                  className="scrollable-content"
                >
                  {description}
                </p>
              </div>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </div>
  );
}

export default ReuseableClientSubDes;
