import React from "react";
import { Outlet, Navigate } from "react-router-dom";
const useAuth = () => {
  let user: any;
  const _user = localStorage.getItem("v_ID");
  // console.log(user);

  if (_user) {
    user = JSON.parse(_user);
    console.log("user", user);
  }
  if (user) {
    return {
      auth: true,
      role: user.role,
    };
  } else {
    return {
      auth: false,
      role: null,
    };
  }
};
type ProtectedRouteType = {
  roleRequired?: ("ADMIN" | "VENDOR")[];
};
const VendorProtectedRoutes = (props: ProtectedRouteType) => {
  const { auth, role } = useAuth();
  console.log(props.roleRequired);

  //if the role required is there or not
  if (props.roleRequired) {
    const isAuthorized = props.roleRequired.includes(role);

    return auth ? (
      isAuthorized ? (
        <Outlet />
      ) : (
        <Navigate to="/denied" />
      )
    ) : (
      <>
        {alert("login required !")}
        <Navigate to="/vendor/signin" />
      </>
    );
  } else {
    console.log("Authentication required");

    return auth ? <Outlet /> : <Navigate to="/vendor/signin" />;
  }
};

export default VendorProtectedRoutes;
