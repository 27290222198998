import { TextField } from "@mui/material";
import { useState } from "react";
import styled from "styled-components";
const InputAddress = styled(TextField)`
&&{
  border: "1px solid black";
  outline:none,
  display:block
  width: 100%,
  background: #fff,
  borderBottom: none,
  borderRadius: 0}
`;
export function TextFieldValidation({ ...args }) {
  const [firstClick, setFirstClick] = useState(true);

  return (
    <TextField
      {...args}
      onFocus={() => setFirstClick(false)}
      sx={{
        margin: "10px 0px 0px 10px",
        "& input:valid + fieldset": {
          borderColor: "#28c282",
        },
        "& input:invalid + fieldset": {
          borderColor: firstClick ? "primary" : "red",
        },
      }}
    />
  );
}
export function AddressInputField({
  id,
  label,
  value,
  onChange,
  variant,
  required,
  ...args
}) {
  return (
    <InputAddress
      {...args}
      id={id}
      label={label}
      value={value}
      onChange={(event) => {
        event.preventDefault();
        onChange(event);
      }}
      variant={"outlined"}
      required={required}
      style={{ width: "100%" }}
    />
  );
}
