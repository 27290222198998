import {
  LOGIN_CLIENT,
  SIGNUP_CLIENT,
} from "../Components/CompServices/Api_Routes_Helper";
import axios from "../api/axios";
const Config = {
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
};

const login = (email, otp) =>
  axios
    .post(
      `${LOGIN_CLIENT}`,
      {
        email,
        otp,
      },
      Config
    )
    .then((response) => {
      const { data } = response?.data;
      const res = data;
      const { _id, email } = data;
      if (res) {
        localStorage.setItem("userNew", JSON.stringify({ res, role: "USER" }));
        localStorage.setItem("userID", _id);
        localStorage.setItem("email", email);
      }

      return res;
      // return response.data;
    })
    .catch((error) => {
      console.error("Error During Login ", error);
      const serverResponse = error?.response;
      return serverResponse;
    });

export default { login };
