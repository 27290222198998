import { Button, Grid, TextField } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import axios from "../../../api/axios";
import * as yup from "yup";
const schema = yup.object().shape({
  recepientame: yup.string().required("Name is required !"),
  mobileNumber: yup.string().required("mobileNumber is required !"),
  pincode: yup.string().required(" pincode is required !"),
  location: yup.string().required("location is required !"),
  city: yup.string().required("city is required !"),
  state: yup.string().required(" state is required !"),
  // alternativeNumber: yup.string().required(" alternativeNumber  is required !"),
  AdtnlDlvGdlns: yup.string(),
});
export const AddNewAddress = ({ onSubmit, setAddressAdded }) => {
  const StoredAddress = useSelector((state) => {
    const { addressDetails } = state.rfqAddress;
    return addressDetails;
  });
  const [address, SetAddress] = useState({
    landMark: StoredAddress?.landMark || "",
    recepientname: StoredAddress?.recepientname || "",
    mobileNumber: StoredAddress?.mobileNumber || "",
    pincode: StoredAddress?.pincode || "",
    HBNo: StoredAddress?.HBNo || "",
    roadAreaCol: StoredAddress?.roadAreaCol || "",
    city: StoredAddress?.city || "",
    state: StoredAddress?.state || "",
    AdditionalDlvGdlns: StoredAddress?.AdditionalDlvGdlns || "",
    // alternativeNumber: StoredAddress?.StoredAddress.alternativeNumber || "",
  });
  const userId = localStorage.getItem("userID");
  const {
    control,
    register,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      landMark: "",
      recepientname: "",
      mobileNumber: "",
      pincode: "",
      roadAreaCol: "",
      HBNo: "",
      city: "",
      state: "",
      AdditionalDlvGdlns: "",
      // alternativeNumber: "",
    },
  });
  const addressFields = [
    {
      type: "text",
      name: "Receipient's Name",
      id: "recepientname",
      value: address.recepientname,
      required: true,
    },
    {
      type: "text",
      name: "Mobile Number",
      id: "mobileNumber",
      value: address.mobileNumber,
      required: true,
    },
    {
      type: "text",
      name: "House/building No.",
      id: "HBNo",
      value: address.HBNo,
      required: true,
    },
    {
      type: "text",
      name: "Road name,Area,colony",
      id: "roadAreaCol",
      value: address.roadAreaCol,
      required: true,
    },
    {
      type: "text",
      name: "City",
      id: "city",
      value: address.city,
      required: true,
    },
    {
      type: "text",
      name: "State",
      id: "state",
      value: address.state,
      required: true,
    },
    {
      type: "text",
      name: "Land Mark",
      id: "landMark",
      value: address.landMark,
    },
    {
      type: "text",
      name: "Pincode",
      id: "pincode",
      value: address.pincode,
      required: true,
    },
    {
      type: "textArea",
      name: "Additionl delivery Guidlines",
      id: "AdditionalDlvGdlns",
      placeholder: "No.97 Living Street..",
      value: address.AdditionalDlvGdlns,
    },
  ];
  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    SetAddress({ ...address, [name]: value.trim() });
  };
  const onNewSubmitAddress = async (e) => {
    e.preventDefault();
    const config = { headers: { "Content-Type": "application/json" } };
    const { status, data } = await axios.post(
      "http://localhost:80/api/auth/updateAdditionalDeliveryAddres",
      { uid: userId, address },
      config
    );
    onSubmit(address);
    setAddressAdded(true);
    // toggleNext(true);
  };
  return (
    <div className="pricing-right-su" style={{ minWidth: 350 }}>
      <Grid container spacing={1}>
        {addressFields.map((data, index) =>
          data.type === "text" ? (
            <Grid item xs={12} lg={6} key={`Address_fields_${index}`}>
              <div className="addressFields_wrap">
                <Controller
                  name={data.name}
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      className="w-100"
                      label={data.name}
                      value={data.value}
                      {...register(data.id)}
                      required={data.required}
                      onChange={handleAddressChange}
                    />
                  )}
                />
              </div>
            </Grid>
          ) : (
            <Grid item xs={12} key={`Address_fields_${index}`}>
              <Controller
                name={data.name}
                control={control}
                defaultValue=" "
                render={({ field }) => (
                  <TextField
                    id={data.id}
                    label={data.name}
                    className="address_textArea"
                    multiline
                    rows={4}
                    placeholder={data.placeholder}
                    {...register("AdditionalDlvGdlns")}
                    onChange={handleAddressChange}
                    variant="outlined"
                    value={data.value}
                  />
                )}
              />
            </Grid>
          )
        )}
      </Grid>
      <Button type="submit" variant="contained" onClick={onNewSubmitAddress}>
        Add Address / Save
      </Button>
    </div>
  );
};
