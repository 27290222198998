import React, { useState, useEffect } from "react";
import {
  Slide,
  Button,
  Dialog,
  Typography,
  DialogContent,
  Grid,
  Box,
  Tooltip,
  TextField,
  LinearProgress,
  Container,
  Checkbox,
  Paper,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import { HiEye, HiPencilAlt, HiUpload } from "react-icons/hi";
import { MdDelete } from "react-icons/md";
import CategoryListTable from "../ReuseTable/CategoryListTable";
import Summary from "../../assets/summary.avif";
import { useDispatch, useSelector } from "react-redux";
import DataTableBase, {
  customStyles,
  customizStyles,
} from "../ReuseTable/DataTable";
import Moment from "react-moment";
import { CheckBox, CloudUploadOutlined, Delete } from "@mui/icons-material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import LoadingBars from "../PageLoader/LoadingBars";
import { Cloudinary, CloudinaryConfig } from "@cloudinary/url-gen";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Review = ({
  details,
  pricing,
  // selectedImage,
  onRedirectcase1,
  onRedirectcase2,
  onRedirectcase3,
  delivary,

  // imageChoosen,
  // triggerUpload,
}) => {
  // const [imageDataUrl, setImageDataUrl] = useState(imageChoosen);
  console.log("gettadr", delivary);
  const [showFullContent, setShowFullContent] = useState(false);

  const [open, setOpen] = useState(false);
  const getSubject = useSelector((state) => state.rfqRequests.subject);
  const getDescription = useSelector((state) => state.rfqRequests.description);

  //to handle image Uploads
  // const [rfqImg, setRfqImg] = useState([]);

  // const [uploaded, setUploaded] = useState(triggerUpload);
  //to toggle image upload process
  // useEffect(() => {
  //   setImageDataUrl(imageChoosen);
  // }, [imageDataUrl, uploaded]);
  //handle chnages in image display behaviour
  // useEffect(() => {
  //   setUploaded(true);
  // }, []);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleRowDelete = (e, id) => {
    e.preventDefault();
  };

  const TableHeaders = [
    {
      name: "Item",
      selector: (row) => row.items,
      grow: 1,
    },
    {
      name: "Expected Quantity",
      selector: (row) => row.quantity,
    },
    {
      name: "Unit",
      selector: (row) => row.unit,
    },
    {
      name: "Expected Price",
      selector: (row) => row.price,
    },
    {
      name: "Date",
      selector: (row) => row.date,
      format: (row, i) => <Moment format="DD/MM/YYYY">{row.date}</Moment>,
    },
    {
      name: "Action",
      selector: (row) => (
        <>
          <Tooltip title="Edit">
            <button
              className="edit-icons"
              onClick={handleRedirect1}
              name="edit"
              // onClick={handleImages}
            >
              <HiPencilAlt />
            </button>
          </Tooltip>
          <Tooltip title="Delete">
            <button
              className="edit-icons delete-icons"
              name="delete"
              onClick={handleRedirect1}
              // onClick={(e) => {
              //   handleRowDelete(e, row.cat_Id);
              // }}
            >
              <MdDelete />
            </button>
          </Tooltip>
        </>
      ),
    },
  ];

  const handleClick = () => {
    setShowFullContent(!showFullContent);
  };

  const handleRedirect1 = () => {
    onRedirectcase1();
  };
  const handleRedirect2 = () => {
    onRedirectcase3();
  };
  // const handleImages = (e) => {
  //   const files = e.target.files;
  //   setRfqImg(files);
  // };
  // const handleFileSelect = (e) => {
  //   e.preventDefault();
  //   setUploaded(true);
  //   selectedImage(rfqImg);
  // };
  // const handleDeleteImage = async (value) => {
  //   console.log(value);
  //   const findInfo = imageDataUrl[value];
  //   const cloudName = "dp7bersiq";
  // };

  return (
    // <div className="summary-border">
    //   <div className="summary-main-container" style={{ padding: "1px 10px" }}>
    //     <Grid container>
    //       {/* <Grid item md={1} lg={1}></Grid> */}
    //       <Grid item md={6} sm={12} lg={6} xs={0}>
    //         <div className="summary-left-sub">
    //           <h3 className="rfq-details-heading">
    //             It's time to review your brief.
    //           </h3>
    //           <p className="rfq-details-content">
    //             Service providers will be matched with your brief and will send
    //             you offers via email.
    //           </p>
    //           <div className="image-container">
    //             <img src={Summary} alt="match" className="rfq-image" />
    //           </div>
    //         </div>
    //       </Grid>
    //       <Grid item md={6} sm={12} lg={6} xs={12}>

    //         <div className="summary-right-sdub">
    //           <div className="summary-right-head">
    //             <Typography className="summary-sub-head">
    //               Brief Summary
    //             </Typography>
    //           </div>
    //           <br />
    //           <div className="summary-right-content">
    //             <Grid container>
    //               <Grid item md={4} lg={4} sm={12} xs={12}>
    //                 <Typography className="summary-content-head">
    //                   Subject
    //                 </Typography>
    //               </Grid>
    //               <Grid
    //                 item
    //                 md={8}
    //                 lg={8}
    //                 sm={12}
    //                 xs={12}
    //                 style={{ display: "flex" }}
    //               >
    //                 <Typography
    //                   className="summary-content myTypography"
    //                   component={"div"}
    //                   variant="body1"
    //                 >
    //                   {getSubject}
    //                 </Typography>
    //               </Grid>
    //               <Tooltip title="Edit">
    //                 <button onClick={handleRedirect2} className="cancel-icon">
    //                   <HiPencilAlt style={{ fontSize: "15px" }} />
    //                 </button>
    //               </Tooltip>
    //             </Grid>
    //             <Grid container>
    //               <Grid item md={4} lg={4} xs={12}>
    //                 <Typography className="summary-content-head">
    //                   Description
    //                 </Typography>
    //               </Grid>
    //               <Grid item md={8} lg={8} xs={12}>
    //                 <Typography
    //                   className="summary-content myTypography"
    //                   onClick={handleClick}
    //                 >
    //                   {getDescription}
    //                 </Typography>
    //               </Grid>
    //             </Grid>

    //             {/* {imageDataUrl !== null && (
    //               <div className="image-btn-container">
    //                 <Grid container>
    //                   <Grid item md={6} lg={4} sm={12}>
    //                     <Typography className="summary-content-head">
    //                       Image
    //                     </Typography>
    //                   </Grid>
    //                   <Grid item md-={6} lg={8} sm={12}>
    //                     <Button onClick={handleClickOpen}>Click to view</Button>
    //                   </Grid>
    //                 </Grid>
    //               </div>
    //             )} */}
    //           </div>
    //           {/* <Dialog
    //             open={open}
    //             TransitionComponent={Transition}
    //             keepMounted
    //             onClose={handleClose}
    //             aria-describedby="alert-dialog-slide-description"
    //           >
    //             <DialogContent>
    //               <div className="image-container">
    //                 {imageDataUrl &&
    //                   imageDataUrl?.map((url, index) => (
    //                     <img
    //                       key={index}
    //                       className="rfq-image"
    //                       src={url}
    //                       alt="Selected"
    //                     />
    //                   ))}
    //               </div>
    //             </DialogContent>
    //             <Button onClick={handleClose}>Close</Button>
    //           </Dialog> */}
    //           <br />
    //           {/* <div className="price-table">
    //             <div className="table-form">
    //               <Grid container flexShrink={1}>
    //                 <Grid item md={12} sm={12} xs={12} lg={12}>
    //                   <DataTableBase
    //                     data={pricing}
    //                     columns={TableHeaders}
    //                     customStyles={customizStyles}
    //                   />
    //                 </Grid>
    //               </Grid>
    //               <CategoryListTable itemSet={pricing} editData={handleRedirect2} />
    //             </div>
    //           </div> */}
    //         </div>
    //         {/* </Grid> */}
    //       </Grid>
    //       {/* <Grid item md={12} lg={12} xs={12}>
    //       <Typography className="summary-content-head">
    //         Upload Images
    //       </Typography>
    //       <Box sx={{ width: "100%", maxWidth: "350px" }}>
    //         <Button
    //           variant="contained"
    //           component="label"
    //           color="secondary"
    //           fullWidth
    //         >
    //           <CloudUploadOutlined />
    //           &nbsp; Select Images
    //           <input
    //             type="file"
    //             id="add_image"
    //             onChange={handleImages}
    //             hidden
    //             multiple
    //           />
    //         </Button>
    //         <Button
    //           onClick={handleFileSelect}
    //           color={"primary"}
    //           variant="contained"
    //         >
    //           Upload
    //         </Button>
    //       </Box>
    //       <Container maxWidth="lg">
    //         {imageDataUrl &&
    //           imageDataUrl.map((data, index) => (
    //             <Box key={index} className="image-box">
    //               <div className="image-container" style={{ margin: "0px" }}>
    //                 {!uploaded ? (
    //                   <div className="loading-text">Loading</div>
    //                 ) : (
    //                   <LazyLoadImage
    //                     src={data}
    //                     alt="uploaded"
    //                     className="uploaded-image"
    //                   />
    //                 )}
    //               </div>
    //               <Typography className="image-name" variant="caption">
    //                 Image Name
    //               </Typography>
    //               <Button
    //                 endIcon={<Delete />}
    //                 className="delete-button"
    //                 onClick={() => handleDeleteImage(index, data)}
    //               >
    //                 Delete
    //               </Button>
    //             </Box>
    //           ))}
    //       </Container>
    //     </Grid> */}
    //     </Grid>
    //   </div>

    //   <Grid container>
    //     <Grid item xs={0} sm={0} md={0} lg={3}></Grid>

    //     <Grid item xs={12} sm={12} md={12} lg={9}>
    //       <DataTableBase
    //         data={pricing}
    //         columns={TableHeaders}
    //         customStyles={customizStyles}
    //       />
    //     </Grid>
    //   </Grid>
    //   <Container>
    //     <Typography variant="h5">Delivery Address</Typography>

    //     <div className="address_container">
    //       <span style={{ fontFamily: "Poppins" }}>
    //         {delivary.recepientname}
    //       </span>
    //       <p style={{ fontWeight: 200, color: "gray" }}>
    //         {delivary.HBNo}, {delivary.roadAreaCol}, {delivary.landMark},
    //         {delivary.city}, {delivary.state} - {delivary.pincode}.
    //         <br />
    //         {Object.keys(delivary["AdditionalDlvGdlns"]).length > 0 && (
    //           <span style={{ display: "block", marginBottom: "0rem" }}>
    //             Additional Guidelines: {delivary.AdditionalDlvGdlns}
    //           </span>
    //         )}
    //       </p>
    //       <span>Phone number {delivary.mobileNumber}</span>
    //     </div>
    //   </Container>
    //   {/* <Container maxWidth="lg">
    //   <Typography variant="h5">Delivery Details:</Typography>
    //   <Container>
    //     {Object.entries(delivary).map(([key, value]) => (
    //       <div key={key}>
    //         <Typography variant="subtitle1">{`${key}: ${value}`}</Typography>
    //       </div>
    //     ))}
    //   </Container>

    // </Container> */}
    // </div>
    <section>
      <div className="Review_page_section">
        <div className="Review_page_section_Title">
          <h3 className="rfq-details-heading">
            It's time to review your brief.
          </h3>
          <p className="rfq-details-content">
            Service providers will be matched with your brief and will send you
            offers via email.
          </p>
        </div>
        <Paper
          elevation={3}
          style={{ padding: "10px", backgroundColor: "#f1f3f6" }}
        >
          <div className="Review_page_section_Title">
            <Typography variant="h6">Brief Summary</Typography>
          </div>
          <br />
          <div
            className="summary-right-content"
            // style={{ position: "relative", left: "185px" }}
          >
            <Grid container>
              <Grid item md={4} lg={2} sm={12} xs={12}>
                <Typography className="summary-content-head">
                  Subject
                </Typography>
              </Grid>
              <Grid
                item
                md={8}
                lg={6}
                sm={12}
                xs={12}
                style={{ display: "flex" }}
              >
                <Typography
                  className="summary-content myTypography"
                  component={"div"}
                  variant="body1"
                >
                  {getSubject}
                </Typography>
              </Grid>
              <Tooltip title="Edit">
                <button onClick={handleRedirect2} className="cancel-icon">
                  <HiPencilAlt style={{ fontSize: "15px" }} />
                </button>
              </Tooltip>
            </Grid>
            <Grid container>
              <Grid item md={4} lg={2} xs={12}>
                <Typography className="summary-content-head">
                  Description
                </Typography>
              </Grid>
              <Grid item md={8} lg={6} xs={12}>
                <Typography
                  className="summary-content myTypography"
                  onClick={handleClick}
                >
                  {getDescription}
                </Typography>
              </Grid>
            </Grid>
          </div>
        </Paper>
        <br />
        <div className="Review_page_section_Table">
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <DataTableBase
                data={pricing}
                columns={TableHeaders}
                customStyles={customizStyles}
              />
            </Grid>
          </Grid>
        </div>
        <div className="Review_page_section_Address">
          <Paper
            elevation={3}
            style={{ padding: "10px", backgroundColor: "#f1f3f6" }}
          >
            <Container>
              <Typography variant="h6" style={{ fontFamily: "Poppins" }}>
                Delivery Address
              </Typography>

              <div className="address_container">
                <span style={{ fontFamily: "Poppins" }}>
                  {delivary.recepientname}
                </span>
                <p style={{ fontWeight: 200, color: "gray" }}>
                  {delivary.HBNo}, {delivary.roadAreaCol}, {delivary.landMark},
                  {delivary.city}, {delivary.state} - {delivary.pincode}.
                  <br />
                  {Object.keys(delivary["AdditionalDlvGdlns"]).length > 0 && (
                    <span style={{ display: "block", marginBottom: "0rem" }}>
                      Additional Guidelines: {delivary.AdditionalDlvGdlns}
                    </span>
                  )}
                </p>
                <span style={{ fontFamily: "Poppins" }}>
                  Phone number {delivary.mobileNumber}
                </span>
              </div>
            </Container>
          </Paper>
        </div>
      </div>
    </section>
  );
};

export default Review;
