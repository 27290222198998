import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import "./styles.css";
import { SlideMapping } from "./SlideMapping";
import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";
import IconButton from "@mui/material/IconButton";
import VolumeUpRoundedIcon from "@mui/icons-material/VolumeUpRounded";
import { LazyLoadImage } from "react-lazy-load-image-component";
import blurimg from "../../images/blur1.jpg";
import "react-lazy-load-image-component/src/effects/blur.css";
import VolumeOffRoundedIcon from "@mui/icons-material/VolumeOffRounded";
import PauseCircleFilledRoundedIcon from "@mui/icons-material/PauseCircleFilledRounded";
import PlayCircleFilledRoundedIcon from "@mui/icons-material/PlayCircleFilledRounded";
import {
  ArrowBackIosNew,
  ArrowForwardIos,
  FavoriteBorder,
} from "@mui/icons-material";
import { GET_ALL_VENDOR } from "../CompServices/Api_Routes_Helper";
import axios from "../../api/axios";
// import "react-responsive-carousel/lib/styles/carousel.min.css";

const innersettings = {
  dots: false,
  infinite: true,
  speed: 1000,

  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  // prevArrow: (
  //   <button className="slick-prev">
  //     <ArrowBackIosNew />
  //   </button>
  // ),
  // nextArrow: (
  //   <button className="slick-next">
  //     <ArrowForwardIos />
  //   </button>
  // ),
};

const settings = {
  dots: false,
  currentSlide: undefined,
  infinite: true,
  autoplay: true,
  speed: 5000,
  slidesToShow: 4,
  slidesToScroll: 2,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const MySlider = () => {
  // const videoRefs = useRef([]);

  // const [isPlayingList, setIsPlayingList] = useState(
  //   SlideMapping.map(() => false)
  // );
  // const [isMutedList, setIsMutedList] = useState(SlideMapping.map(() => false));

  // const handlePlayPause = (videoIndex) => () => {
  //   const video = videoRefs.current[videoIndex];
  //   const newIsPlayingList = [...isPlayingList];
  //   newIsPlayingList[videoIndex] = !newIsPlayingList[videoIndex];
  //   setIsPlayingList(newIsPlayingList);

  //   if (newIsPlayingList[videoIndex]) {
  //     video.play();
  //   } else {
  //     video.pause();
  //   }
  // };

  // const handleMuteUnmute = (videoIndex) => () => {
  //   const video = videoRefs.current[videoIndex];
  //   const newIsMutedList = [...isMutedList];
  //   newIsMutedList[videoIndex] = !newIsMutedList[videoIndex];
  //   setIsMutedList(newIsMutedList);

  //   video.muted = newIsMutedList[videoIndex];
  // };

  const levelColor = (level) => {
    switch (level) {
      case "Level 2":
        return "level2";
      case "Level 1":
        return "level1";
      case "Top Quty":
        return "top";
      case "High Qty":
        return "high";
      default:
        return "";
    }
  };

  const [currentSlide, setCurrentSlide] = useState(0);

  const handleChange = (currentSlide) => {
    setCurrentSlide(currentSlide);
  };

  const [vendorData, setVendorData] = useState([]);
  // console.log("vendorData", vendorData);

  useEffect(() => {
    // Define a function to fetch data from your backend
    const fetchData = async () => {
      try {
        const response = await axios.get(`${GET_ALL_VENDOR}`);
        if (response.status === 200) {
          setVendorData(response.data.data);
        } else {
          console.error("Error fetching data");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function to initiate the API request
    fetchData();
  }, []);

  return (
    <div className="carousel-wrapper">
      <Slider {...settings}>
        {SlideMapping.map((slide, index) => (
          <div key={index} className="slide">
            <div>
              <Slider key={index} {...innersettings} className="in-carousel">
                <div className="image1">
                  {/* <img src={slide.image1} alt="Image 1" className="1-img" /> */}
                  <LazyLoadImage
                    src={slide.image1}
                    alt="Image 1"
                    className="1-img"
                    placeholdersrc={blurimg}
                    effect="blur"
                  />
                  {/* <div className="icon-on-img">
                    <FavoriteBorder />
                  </div> */}
                </div>
                {/* <div className="in-vid"> */}
                {/* <video
                    className="video"
                    // ref={(el) => (videoRefs.current[index] = el)}
                    controls
                  >
                    <source src={slide.video} />
                  </video>
                  <div className="icon-on-img">
                    <FavoriteBorder />
                  </div> */}
                {/* <div className="icon-button">
                    <IconButton onClick={handlePlayPause(index)}>
                      {isPlayingList[index] ? (
                        <PauseCircleFilledRoundedIcon className="icon" />
                      ) : (
                        <PlayCircleFilledRoundedIcon className="icon" />
                      )}
                    </IconButton>
                    <IconButton onClick={handleMuteUnmute(index)}>
                      {isMutedList[index] ? (
                        <VolumeOffRoundedIcon className="icon" />
                      ) : (
                        <VolumeUpRoundedIcon className="icon" />
                      )}
                    </IconButton>
                  </div> */}
                {/* </div> */}

                {/* <div className="image1">
                  <img src={slide.image2} alt="Image 2" className="1-img" />
                </div> */}
              </Slider>
            </div>

            <div className="avatar-img-div">
              <div className="avatar-img-div2">
                <img
                  className="avatar-img"
                  src={slide.avatar}
                  // src="https://media.istockphoto.com/id/1331335633/vector/female-avatar-icon.jpg?s=612x612&w=0&k=20&c=2wtNjpPMjZqxpbDTuowyu2D2fHit6uMeESMfZOkCSKQ="
                  alt="Avathar-image"
                />
                <h3>{slide.name}</h3>
              </div>
              <div>
                <h6 className={`level ${levelColor(slide.level)}`}>
                  {slide.level}
                  {/* Top Rated */}
                </h6>
              </div>
            </div>

            <a className="para-link" href="#">
              <p>{slide.para}</p>
            </a>

            <div className="star-span">
              <StarRateRoundedIcon style={{ color: "orange" }} />
              <span className="star-rate">{slide.star}</span>
              <span className="star-bracket">
                {slide.span}
                {/* (2.9k) */}
              </span>
            </div>
            <div>
              <span className="rate">
                <span className="from-rate">From </span>
                {slide.rate}
                {/* ₹800 */}
              </span>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default MySlider;
