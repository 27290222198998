import React, { useEffect, useState } from "react";
// import BreadCrumbs from "../BreadCrumbs/index";
import { Box, Button, Container, Grid, Modal, Typography } from "@mui/material";
import { useCookies } from "react-cookie";
import "./styles.scss";
import ProfileSection from "./ProfileSection";
import { Card, Carousel } from "antd";
import { MdAccountCircle } from "react-icons/md";
import ReactApexChart from "react-apexcharts";
import Chart from "react-apexcharts";
import Slider from "react-slick";
import {
  data,
  data1,
  data2,
  help,
  vendorCarousel,
  vendorCarousell,
} from "./Requests/json";
import Footer from "../Footer";
import { items } from "../End-User/User-Data/MenuItems";
import axios from "../../api/axios";
import authHeader from "../../Services/auth-header";
import { AiFillEye } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { GET_VENDORS_REQUESTS } from "../CompServices/Api_Routes_Helper";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function Index() {
  // const [cookie, setCookie] = useCookies();
  // const { _id, isBussinessAccount, email } = cookie.user[0];
  const _id = localStorage.getItem("userID");
  const [bussinessAcc, setBussinessAcc] = useState(true);
  // const [showProfile, setShowProfile] = useState(true);

  // const hideProfileDetails = (e) => {
  //   e.preventDefault();
  //   setShowProfile(false);
  // };

  // const showProfileDetails = (e) => {
  //   e.preventDefault();
  //   setShowProfile(true);
  // };

  // useEffect(() => {
  //   document.title = "vendor HomePage";
  // }, []);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [chartData, setChartData] = useState({
    options: {
      chart: {
        id: "basic-line-chart",
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
      stroke: {
        curve: "smooth",
      },
      markers: {
        size: 5,
      },
    },
    series: [
      {
        name: "Sales",
        data: [30, 40, 35, 50, 49, 60, 70, 91, 125, 100, 80, 60],
      },
    ],
  });
  const [chartDataa, setChartDataa] = useState({
    options: {
      chart: {
        id: "donut-chart",
      },
      labels: ["Income", "Taxes", "Fees"],
      colors: ["#008FFB", "#00E396", "#FEB019"],
      legend: {
        show: true,
        position: "bottom",
      },
      tooltip: {
        enabled: true,
      },
    },
    series: [65, 35, 13],
  });

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };
  const id = localStorage.getItem("vendorID");
  const [resdata, setResData] = useState([]);
  const onNavigate = () => {
    navigate("/vendor/get_all_requests");
  };

  useEffect(() => {
    const fetchRequests = async () => {
      if (!id) {
        return;
      }
      const response = await axios.get(`${GET_VENDORS_REQUESTS}${id}`, {
        headers: await authHeader(),
      });
      console.log("vendo Info", response.data);
      setResData(response.data[0].vendorRequests);
    };
    fetchRequests();
  }, [id]);

  const navigate = useNavigate();
  const onSubmit = () => {
    navigate("/vendor/get_all_requests");
  };

  const [isLoading, setIsLoading] = useState(false);
  const handleClick = () => {
    setIsLoading(true);
    // Simulating an asynchronous operation
    setTimeout(() => {
      setIsLoading(false);
      handleOpen();
    }, 1000);
  };

  return (
    <React.Fragment>
      <Box maxWidth={"lg"} p={2} m={1}>
        {/* <BreadCrumbs home={"Home"} current="vendor HomePage" /> */}
      </Box>
      {!bussinessAcc ? (
        <React.Fragment>
          <div className="create_bussiness_acc">
            <Box className="bussiness_wrapper">
              <h4>Welcome to QuoteUrPrice!</h4>
              <p>create your Seller Account in few Steps</p>
              <button className="btn CreateAcc_btn" style={{ padding: "10px" }}>
                Become Seller
              </button>
            </Box>
          </div>
        </React.Fragment>
      ) : (
        <div className="create_bussiness_acc">
          <div className="main_card">
            <Grid container>
              <Grid item md={3} sm={12}>
                <Card className="card_comp">
                  <div>
                    <h3>Hi</h3>
                    <p>Get matched with sellers for your project.</p>
                    <div className="button_vendor">
                      <button
                        color="success"
                        variant="contained"
                        onClick={() => {
                          handleClick();
                        }}
                      >
                        {isLoading ? "" : "Quick Info"}
                        {isLoading && (
                          <div className="rolling-preloader">
                            <div className="rolling-preloader-circle"></div>
                          </div>
                        )}
                      </button>
                    </div>
                  </div>
                </Card>
              </Grid>
              <Grid item md={9} sm={12}>
                <div>
                  <Carousel dots={false} autoplay className="vendor_carossel">
                    {vendorCarousel.map((list, i) => (
                      <div key={i}>
                        <img
                          className="vendor_carousel_image"
                          src={list.image}
                          alt="carousel"
                        />
                      </div>
                    ))}
                  </Carousel>
                </div>
              </Grid>
            </Grid>
          </div>

          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="modal_class"
          >
            <Box sx={style}>
              <div className="vendor_wrapper">
                <i className="fa fa-close" onClick={handleClose}></i>
                <div className="Vndr_accInfo">
                  <ProfileSection userId={_id} />
                </div>
              </div>
            </Box>
          </Modal>
          {/* {showProfile && (
            <div className="vendor_wrapper">
              <Card className="card">
                <i className="fa fa-close" onClick={hideProfileDetails}></i>
                <div className="Vndr_accInfo">
                  <ProfileSection userId={_id} />
                </div>
              </Card>
            </div>
          )} */}
          {/* </Container> */}
          {/* <ul>
            <li>link to requests received</li>
            <li>link to responses sent</li>
            <li>link to ongoing bussinness</li>
          </ul> */}

          {/* </Container> */}
        </div>
      )}
      <div className="details_vendor">
        <Container>
          <Grid container spacing={2}>
            <Grid item md={4} sm={12}>
              <Card className="card_vendor">
                <Grid container>
                  <Grid item md={3} className="grid">
                    <div>
                      <ReactApexChart
                        options={data.options}
                        series={data.series}
                        type="pie"
                        width={100}
                      />
                    </div>
                  </Grid>
                  <Grid item md={9}>
                    <h4>18 New Orders Today</h4>
                    <p>Yesterday was 12</p>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item md={4} sm={12}>
              <Card className="card_vendor">
                <Grid container>
                  <Grid item md={3} className="grid">
                    <div>
                      <ReactApexChart
                        options={data1.options}
                        series={data1.series}
                        type="pie"
                        width={100}
                      />
                    </div>
                  </Grid>
                  <Grid item md={9}>
                    <h4>12 Items Shipped Today</h4>
                    <p>Yesterday was 6</p>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item md={4} sm={12}>
              <Card className="card_vendor">
                <Grid container>
                  <Grid item md={3} className="grid">
                    <div>
                      <ReactApexChart
                        options={data2.options}
                        series={data2.series}
                        type="pie"
                        width={100}
                      />
                    </div>
                  </Grid>
                  <Grid item md={9}>
                    <h4>25 Users Registered Today</h4>
                    <p>Yesterday was 21</p>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </div>
      <Container>
        <div className="main_chart_con">
          <Grid container>
            <Grid item md={8}>
              <div>
                <h3>Sales Report</h3>
                <div className="chart_card_comp">
                  <Chart
                    options={chartData.options}
                    series={chartData.series}
                    type="line"
                    height={350}
                  />
                </div>
              </div>
            </Grid>
            <Grid item md={4}>
              <h4>Earnings</h4>
              <div className="sec_main_chart">
                <Chart
                  options={chartDataa.options}
                  series={chartDataa.series}
                  type="donut"
                  height={350}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>
      <div className="container_card_vendor">
        <Container>
          <Grid container>
            <Grid item md={6} sm={12}>
              <Card className="grid_main_vendor_rfq">
                <div className="a-tag">
                  <h4>Recent RFQ's</h4>
                  <a href="/vendor/get_all_requests">more...</a>
                </div>
                {resdata.slice(0, 2).map((item, i) => (
                  <Grid container className="grid_main_vendor_rfqs">
                    <Grid item md={8} className="antd-card-vendor">
                      <div>
                        <div className="antd-card-design-values">
                          <label>categoryName :</label>
                          <p>{item.categoryName}</p>
                        </div>
                        <div className="antd-card-design-values">
                          <label>subject :</label>
                          <p>{item.subject}</p>
                        </div>
                        <div className="antd-card-design-values">
                          <label>status :</label>
                          <p>{item.status}</p>
                        </div>
                      </div>
                    </Grid>

                    <Grid item md={4} className="second_grid_vendor">
                      <button onClick={onNavigate}>
                        <AiFillEye className="eye_icon" /> &nbsp;View
                      </button>
                    </Grid>
                  </Grid>
                ))}
              </Card>
            </Grid>
            <Grid item md={5} sm={12}></Grid>
          </Grid>
          {/* <Slider {...settings}>
            {resdata.map((item, i) => (
              <div key={i}>
                <Card
                  hoverable
                  className="antd-card-designn"
                  onClick={onSubmit}
                >
                  <p>{item.categoryName}</p>

                  <p>{item.description}</p>

                  <p>{item.status}</p>
                </Card>
              </div>
            ))}
          </Slider> */}
        </Container>
      </div>
      <div>
        <div className="help_more">
          <h3>Guides to help you grow</h3>
          <br />
          <Grid container>
            {help.map((name, i) => (
              <Grid item key={i} md={4} sm={12} lg={4}>
                <img src={name.image} />
                <h4>{name.text}</h4>
                <p>{name.text1}</p>
              </Grid>
            ))}
          </Grid>
        </div>
      </div>

      <div>
        <div>
          <Carousel dots={false} autoplay className="buttom_carousel">
            {vendorCarousell.map((list, i) => (
              <div key={i}>
                <p>{list.text}</p>
                <h4>{list.text1}</h4>
                <img
                  className="vendor_carousel_image"
                  src={list.image}
                  alt="carousel"
                />
              </div>
            ))}
          </Carousel>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default Index;
