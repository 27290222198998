import React from 'react';
import { Navigate, Link, Outlet } from 'react-router-dom';
const useAuth = () => {
    const user = localStorage.getItem("UserNew")
    if (user) {
        return true
    } else { return false; }

}
const PublicRoutes = (props: any) => {
    const auth = useAuth();
    return auth ? <Navigate to={"/"} /> : <Outlet />

}   

export default PublicRoutes;