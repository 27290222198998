import { createSlice } from "@reduxjs/toolkit";

const initialState = {};
const RfqStatus = createSlice({
  name: "rfqtogglerbtn",
  initialState,
  reducers: {
    toggleRowStatus: (state, action) => {
      // Update the status of the specified row in the state
      const { rfqid, status } = action.payload;
      state[rfqid] = status;
    },
    toggleReject: (state, action) => {
      // Update the status of the specified row in the state
      const { rfqid, status } = action.payload;
      state[rfqid] = status;
    },
    clearAllRowStatus: () => {
      return initialState;
    },
   
  },
});
const { actions, reducer } = RfqStatus;

export const { toggleRowStatus, clearAllRowStatus, toggleReject } =
  actions;

export default RfqStatus.reducer;
