import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Avatar,
  Box,
  Button,
  Card,
  Container,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import {
  ADD_CLIENT_COMMENTS,
  GET_RESPONSE_INFO,
  GET_SUBJECT_DES,
  POST_ACTIVATE_QUOTATION,
  VIEW_POSTED_COMMENTS,
} from "../CompServices/Api_Routes_Helper";
import ButtonStyles, {
  ApprovedButton,
  deleteButton,
  submitButton,
} from "../DesignTools/ButtonStyles/ButtonStyles";
import format from "../_helpers/formatTime";
import { useNavigate, useLocation } from "react-router";
import ReuseableClientSubDes from "../ReuseTable/ReuseableClientSubDes";
import imgess from "../../assets/bg-seller.svg";

function ViewResponse({ sendId, onBack }) {
  // const { id } = useParams();
  //to add loader anime
  const [loader, setLoader] = useState(true);
  const userId = localStorage.getItem("userID");
  const [values, setValues] = useState([]);
  //to show description of rfq
  // const [rfqdesc, setRfqDesc] = useState("");
  //to get comments about rfq
  const [postsData, setPostsData] = useState([]);
  // to post reply to comment/post
  const [reply, setReply] = useState("");
  //to handle open & close of reply btn
  const [commentReplies, setCommentReplies] = useState([]);
  const [SubDes, setSubDes] = useState({});

  const navigate = useNavigate();
  console.log(useLocation());
  //to get client comment and post

  const handleSubmit = async (event, id) => {
    event.preventDefault();

    // Find the quotation item with the matching id
    const quotationItem = values.find((item) => item._id === id);
    if (!quotationItem) return;

    // Check if the quotation item is already approved
    if (quotationItem.isApproved === "Approved") {
      // Do not proceed further if the item is already approved
      return;
    }

    try {
      // Make the API call to update the status of the quotation
      const response = await axios.get(`${POST_ACTIVATE_QUOTATION}${id}`);
      const status = response.data.Result.isApproved;
      if (status) {
        // Update the state of the quotation item to reflect the "Approved" status
        setValues((prevValues) => {
          return prevValues.map((item) => {
            if (item._id === id) {
              return { ...item, isApproved: "Approved" };
            }
            return item;
          });
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${GET_RESPONSE_INFO}${sendId}`);
        const data = response.data;
        setValues(data);
        if (data.length > 0) {
          fetchDatas(sendId);
        }
      } catch (error) {
        console.error(error);
        setLoader(true);
      }
    };
    fetchData();
  }, []);

  const fetchDatas = async (id) => {
    try {
      const { data } = await axios.get(`${GET_SUBJECT_DES}${id}`);
      const details = data.data;
      setSubDes(details);
    } catch (error) {
      console.error(error);
    }
  };

  //Handle Comments
  // async function PostedComments() {
  //   await axios
  //     .get(`${VIEW_POSTED_COMMENTS}`, {
  //       params: {
  //         id: values.map((id) => id.requestID),
  //       },
  //     })
  //     .then((result) => {
  //       const { data } = result.data;
  //       setPostsData(data);
  //     })
  //     .catch((error) => console.error(error));
  // }
  //will be used later
  // useEffect(() => {
  //   if (values) {
  //     PostedComments();
  //   }
  // }, [values]);
  const handleReply = async (commentId) => {
    setCommentReplies((prevReplies) => {
      const updatedReplies = [...prevReplies];

      // Check if the commentId already exists in the array
      const index = updatedReplies.findIndex(
        (reply) => reply.commentId === commentId
      );
      if (index !== -1) {
        // CommentId already exists, toggle the reply state
        updatedReplies[index].isReplyOpen = !updatedReplies[index].isReplyOpen;
      } else {
        // CommentId does not exist, add a new entry with isReplyOpen as true
        updatedReplies.push({ commentId, isReplyOpen: true });
      }

      return updatedReplies;
    });
  };
  const handlePostComment = async () => {
    try {
      const postComment = await axios.post(`${ADD_CLIENT_COMMENTS}`, {
        data: {
          message: reply,
          userId: userId,
          commentId: commentReplies[0].commentId,
        },
      });
      if (postComment) {
        setReply("");
        console.log("post Comment", postComment);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleViewSubmit = (event, id) => {
    event.preventDefault();
    navigate(`/user_in/view/VendorResponse/${id}`, {
      replace: false,
      //to send main rfq id to backend
      state: { sendId },
    });
  };

  const handleRejectSubmit = (event) => {
    event.preventDefault();
    alert("are you sure ?");
  };

  const handleOrders = (event, id) => {
    event.preventDefault();
    navigate(`/user_in/view/Order/${id}`, {
      state: { sendId },
    });
  };
  //to handle loader
  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoader(false);
    }, 1000);
    return () => clearTimeout(timeout);
  }, []);
  return (
    <section>
      {loader ? (
        <div className="multy_loader_wrap">
          <div className="loading_spinnner">
            <div className="multy_clr_loader"></div>
          </div>
        </div>
      ) : (
        <Container>
          {values.length > 0 ? (
            <Grid container spacing={1} sx={{ display: "flex" }}>
              <Container maxWidth={"lg"}>
                <br />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    // width:'100%',maxWidth:'1000px'
                  }}
                >
                  <ReuseableClientSubDes
                    subject={SubDes.subject}
                    description={SubDes.description}
                    title={"Client Request"}
                  />

                  <button
                    className="custom-btn btn-7"
                    // variant="contained"
                    onClick={(event) => handleOrders(event, sendId)}
                  >
                    <span> View Order</span>
                  </button>

                  {/* <Button
                  variant="contained"
                  color="info"
                  onClick={(event) => handleOrders(event, sendId)}
                >
                  View Order
                </Button> */}
                  <p
                    // sx={{ mt: 0.5, ml: 2, fontSize: "17px", fontWeight: 700 }}
                    className="rfq-details-heading"
                    style={{ marginTop: "25px" }}
                    // color="text.primary"
                    // display="block"
                    // variant="caption"
                  >
                    Quotation's Received
                  </p>
                </Box>
              </Container>
              {/* <Container> */}
              {values?.map((item, index) => (
                <section id="cardsv" key={index}>
                  <div
                    className="col-lg-12 col-md-12 mb-4 pt-5"
                    style={{ width: "380px" }}
                  >
                    <div className="cardv shadow-sm border-0">
                      <div className="card-body">
                        <div className="user-picture">
                          <img
                            src="https://cdn-icons-png.flaticon.com/512/9131/9131529.png"
                            className="shadow-sm rounded-circle"
                            height="100"
                            width="100"
                            alt="User"
                          />
                        </div>
                        <div className="user-content">
                          <h5 className="text-capitalize user-name">
                            {item.vendorName}
                          </h5>
                          <p className="text-muted small blockquote-footer">
                            Vendor name
                          </p>
                          <div className="vendor-sun-des">
                            <div className="vendor-view-div">
                              <p className="vendor-sub-card">Subject</p>
                              <p
                                className="vendor-des-card"
                                style={{ marginLeft: "30px" }}
                              >
                                {" "}
                                {item.vendorSubject}
                              </p>
                            </div>
                            <div className="vendor-view-div">
                              <p className="vendor-sub-card">Description</p>
                              <p className="vendor-des-card">
                                {" "}
                                {item.vendorDescription}
                              </p>
                            </div>
                          </div>
                          <div className="view-ress">
                            <button
                              className="accept-Action-but"
                              onClick={(event) =>
                                handleViewSubmit(event, item._id)
                              }
                            >
                              <span>View</span>
                            </button>
                            &nbsp; &nbsp; &nbsp;
                            <button
                              className="accept-Action-but"
                              style={{ background: "red" }}
                              onClick={(event) =>
                                handleRejectSubmit(event, item._id)
                              }
                            >
                              <span> Reject</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              ))}
              {/* </Container> */}
              {/* {values?.map((item, index) => (
              <Grid item md={6} key={index}>
                <div className="grid_main_vendor_rfq">
                  <div className="card-top-image">
                    <img className="im" src={imgess} />
                  </div>
                  <Grid container>
                    <Grid item md={12} lg={12} className="antd-card-vendor">
                      <Grid
                        container
                        style={{
                          position: "relative",
                          left: "92px",
                          top: "-54px",
                        }}
                      >
                        <Grid
                          item
                          sm={2}
                          md={4}
                          className="antd-card-design-values"
                        >
                          <label>Vendor Name :</label>
                        </Grid>
                        <Grid item sm={2} md={2}>
                          <p>{item.vendorName}</p>
                        </Grid>
                      </Grid>
                      <Grid container className="antd-card-design-values">
                        <Grid item md={4} sm={8}>
                          <label>Subject :</label>
                        </Grid>
                        <Grid item md={4} sm={8}>
                          <p className="ven-sub-overflow">
                            {item.vendorSubject}
                          </p>
                        </Grid>
                      </Grid>
                      <Grid container className="antd-card-design-values">
                        <Grid item md={4} sm={8}>
                          <label>Description :</label>
                        </Grid>
                        <Grid item md={4} sm={8}>
                          <p className="ven-sub-overflow">
                            {item.vendorDescription}
                          </p>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <div className="view-res">
                    <Button
                      variant="contained"
                      style={submitButton}
                      onClick={(event) => handleViewSubmit(event, item._id)}
                    >
                      <span> View</span>
                    </Button>
                    &nbsp; &nbsp; &nbsp;
                    <Button
                      variant="contained"
                      style={deleteButton}
                      onClick={(event) => handleRejectSubmit(event, item._id)}
                    >
                      <span> Reject</span>
                    </Button>
                  </div>
                </div>
              </Grid>
            ))} */}
              {/* <Container
              maxWidth={"lg"}
              sx={{
                padding: "10px",
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "row",
                gap: "10px",
              }}
            >
              {values?.map((item, index) => {
                return (
                  <ResponseCard
                    item={item}
                    viewBtn={handleViewSubmit}
                    key={`VenResp_key${index}`}
                  />
                );
              })}
            </Container> */}
            </Grid>
          ) : (
            <div className="no_records_container">
              <Card className="no_record">
                <div className="image_div">
                  <img
                    className="round-image"
                    alt="no response found"
                    src="https://img.freepik.com/free-vector/hand-drawn-no-data-concept_52683-127829.jpg?size=626&ext=jpg&ga=GA1.1.1010560099.1687948618&semt=ais"
                  />
                </div>
                <p className="no_data_para">There are no records to display</p>

                <div className="no_record_button no_records">
                  <button
                    className="button_style"
                    color="error"
                    onClick={onBack}
                  >
                    Go Back
                  </button>
                </div>
              </Card>
            </div>
          )}
        </Container>
      )}
    </section>
  );
}

export default ViewResponse;
