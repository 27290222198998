import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Snackbar,
  Button,
  Typography,
  TextField,
  Grid,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Modal } from "react-bootstrap";

// import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import {
  getAuth,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import { LOGIN_SUCCESS } from "../../slices/types";
import { otpInputStyle } from "./Vendor_SignIn";
import axios from "axios";
import { SIGNIN_EMLPWD, host } from "../CompServices/Api_Routes_Helper";
const SignInEmailPwd = () => {
  const intialValues = {
    email: "",
    password: "",
    loginrememberme: false,
  };
  const [formValues, setFormValues] = useState(intialValues);
  const { email, password } = formValues;

  //for hiding and display error(email & pwd)
  const [showErr, setshowErr] = React.useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [expanded, setExpanded] = React.useState(false);
  const [toggle1, setToggle1] = React.useState(false);
  const navigate = useNavigate();

  const handleClosePopUpErr = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setshowErr(false);
  };
  const handleChangeAcc = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleInputEmailPwd = (event) => {
    const { name } = event.target;
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    setFormValues({ ...formValues, [name]: value });
    // console.log(name, value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // const auth = getAuth();
    try {
      const { data, status } = await axios.post(`${SIGNIN_EMLPWD}`, {
        email,
        password,
      });
      // Check if the user's email is verified
      if (status === 200 && data !== null) {
        const { _id, email } = data?.data;
        localStorage.setItem(
          "userNew",
          JSON.stringify({ res: data?.data, role: "USER" })
        );
        localStorage.setItem("userID", _id);
        localStorage.setItem("email", email);
        setSuccessMsg("SignIn successfully!");
        setshowErr(true);
        window.location.assign("/");
        // navigate("/", { preventScrollReset: true });
      }
    } catch (error) {
      console.error("Error during  user auth", error);
      const { status, data } = error?.response;

      switch (status) {
        case 404:
          setErrMsg(data?.message);
          break;
        case 401:
          setErrMsg(data?.message);
          break;
        case 500:
          setErrMsg(data?.message);
          break;
        default:
          setErrMsg("Unknown error occurred during user login");
          break;
      }
      setshowErr(true);
    }
  };

  // forgot page model
  // const [show, setShow] = useState(false);

  // const   = () => setShow(true);
  // const handleClose = () => setShow(false);
  const handleForgotPassword = async () => {
    try {
      if (!email) {
        setErrMsg("Please enter your email address.");
        setshowErr(true);
        return;
      }

      const auth = getAuth();
      await sendPasswordResetEmail(auth, email);

      console.log("Password reset email sent successfully!");

      setshowErr(true);
      setSuccessMsg(
        "Password reset email sent successfully! Check your email."
      );
    } catch (error) {
      console.error("Error sending password reset email:", error.message);

      if (error.code === "auth/too-many-requests") {
        setErrMsg("Too many requests. Please try again later.");
      } else {
        setErrMsg("Error sending password reset email.");
      }

      setshowErr(true);
    }
  };

  return (
    <React.Fragment>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChangeAcc("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography component={"span"} variant="caption" sx={otpInputStyle}>
            Login with Email & Password
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <form
            className="login-form"
            onSubmit={(event) => handleSubmit(event)}
          >
            <div className="form">
              <input
                type="email"
                id="email"
                className="form-input"
                placeholder=" "
                name="email"
                onChange={handleInputEmailPwd}
                required
                value={email}
              />
              <label htmlFor="email" className="form-label">
                Email
              </label>
            </div>

            {formErrors.email && (
              <span className="form-error">{formErrors.email}</span>
            )}

            <div className="form">
              <input
                // type="password"
                type={toggle1 ? "text" : "password"}
                id="password"
                className="form-input"
                placeholder=" "
                name="password"
                onChange={handleInputEmailPwd}
                value={password}
                required
                autoComplete="none"
                autoCapitalize="false"
              />
              <i
                id="showpass"
                // className="fa fa-eye icon"
                className={`fa ${toggle1 ? "fa-eye" : "fa-eye-slash"} icon`}
                onClick={() => {
                  setToggle1(!toggle1);
                }}
              ></i>
              <label htmlFor="password" className="form-label">
                Password
              </label>
            </div>

            {formErrors.password && (
              <span className="form-error">{formErrors.password}</span>
            )}

            {/* <div className="checkbox">
              <input
                type="checkbox"
                name="loginrememberme"
                className="form-checkbox"
                checked={formValues.loginrememberme}
                onChange={handleInputEmailPwd}
              />

              <p className="common-para">Remember me on this system</p>
            </div> */}

            <div className="forgotPassword">
              <p className="common-para" onClick={handleForgotPassword}>
                Forgot Password?
              </p>
              {/* <Button
                variant="text"
                color="primary"
                onClick={handleForgotPassword}
              >
                Forgot Password?
              </Button> */}
            </div>

            {/* <Modal show={show} onHide={handleClose}> */}
            {/* <Modal.Header> */}
            {/* <Modal.Title>Forgot Password?</Modal.Title>
                <div className="closeModal" onClick={handleViewPageClose}></div>
              </Modal.Header>
              <Modal.Body> */}
            {/* <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField fullWidth label="Email" variant="outlined" />
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      size="small"
                    >
                      Send
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField fullWidth label="OTP" variant="outlined" />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="New Password"
                      type="password"
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Confirm Password"
                      type="password"
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button> */}

            {/* <Button variant="primary" onClick={handleClose}>
                  Continue
                </Button>
              </Modal.Footer>
            </Modal> */}

            <Button
              type="submit"
              color="primary"
              variant="contained"
              sx={{ p: 1.5, fontSize: 16 }}
            >
              LOGIN
            </Button>
          </form>
        </AccordionDetails>
      </Accordion>
      {CustomAlertBar()}
    </React.Fragment>
  );

  function CustomAlertBar() {
    return (
      <Snackbar
        open={showErr}
        autoHideDuration={2500}
        onClose={handleClosePopUpErr}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleClosePopUpErr}
          severity={errMsg ? "error" : "success"}
          sx={{ width: "100%", fontSize: 15 }}
        >
          {errMsg || successMsg}
        </Alert>
      </Snackbar>
    );
  }
};

export default SignInEmailPwd;
