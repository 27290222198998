import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import "./SellStyles.scss";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import axios from "../../api/axios";
import { message } from "antd";
import { GET_ALL_CATSERVICE } from "../CompServices/Api_Routes_Helper";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ProfessinalInfo = ({
  finalData,
  handleFinalData,
  steps,
  current,
  prev,
  next,
}) => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const { register, getValues, handleSubmit } = useForm({
    defaultValues: finalData,
  });

  const [value, setValue] = useState(null);
  const semestervalue = {};
  const [semester, setsemester] = useState([semestervalue]);
  const [personName, setPersonName] = React.useState([]);
  const navigate = useNavigate();
  const [Values, setValues] = useState([]);

  useEffect(() => {
    getValue();
  }, []);

  const handleAddSemester = () => {
    setsemester((prev) => [...prev, semestervalue]);
  };

  const handleoption = useCallback((index, key, value) => {
    setsemester((prev) => {
      const newValue = [...prev];
      newValue[index][key] = value;
      return newValue;
    });
  }, []);

  const handleRemove = useCallback((idx, setValue) => {
    setsemester((prev) => {
      const newOne = prev.filter((item, index) => index !== idx);
      setValue("semester", newOne);
      return newOne;
    });
  }, []);

  const onSubmit = () => {
    handleFinalData({
      ...finalData,
      category: personName,
      website: getValues().website,
    });
    next();
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  //getValues code
  const getValue = async () => {
    await axios.get(`${GET_ALL_CATSERVICE}`).then((res) => {
      setValues(res.data.data);
    });
  };

  return (
    <div className="seller_onboarding_info">
      <Container>
        <div className="onboarding-introduction">
          <div id="personal_info" className="start_form">
            <div className="onboarding-step personal-info">
              <div className="step-header">
                <h2>Professional Info</h2>
                <p>
                  This is your time to shine. Let potential buyers know what you
                  do best and how you gained your skills, certifications and
                  experience.
                </p>
                <p className="mandatory">
                  <small>* mandatory Fields</small>
                </p>
              </div>
              <Divider />
              <Box component={"div"} aria-labelledby="person_information">
                <form className="personal_form">
                  <div
                    id="display_name"
                    className="onboarding-field is-required"
                  >
                    <aside>
                      <h3 className="font-accent">
                        <span>Category</span>
                      </h3>
                      <div className="education-text">
                        Select which category you can communicate in and your
                        proficiency level.
                      </div>
                    </aside>
                    <div className="field-content">
                      <FormControl sx={{ m: 1, width: 300 }}>
                        <InputLabel id="demo-multiple-checkbox-label">
                          Category
                        </InputLabel>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          id="demo-multiple-checkbox"
                          multiple
                          value={personName}
                          onChange={handleChange}
                          input={<OutlinedInput label="Tag" />}
                          renderValue={(selected) => selected.join(", ")}
                          MenuProps={MenuProps}
                        >
                          {Values.map((name, i) => (
                            <MenuItem key={i} value={name.categoryName}>
                              <Checkbox
                                checked={
                                  personName.indexOf(name.categoryName) > -1
                                }
                              />
                              <ListItemText primary={name.categoryName} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <div
                    id="profile_pic"
                    className="onboarding-field is-required"
                  >
                    <aside>
                      <h3 className="font-accent">
                        <span>Personal Website</span>
                        <small>Private</small>
                      </h3>
                    </aside>
                    <div className="field-content">
                      <section className="section_value">
                        <div className="WvIqLXU eXfr7qm name-input">
                          <div className="+T1b6wH">
                            <input
                              className="GD3asS+"
                              type="text"
                              name="website"
                              maxLength="35"
                              placeholder="Website"
                              {...register("website", {
                                required: true,
                              })}
                            />
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                  <div className="button_SellingInfo"></div>
                </form>
              </Box>
            </div>
          </div>
        </div>
        <div
          style={{
            marginTop: 24,
            display: "flex",
            justifyContent: "end",
            marginBottom: "30px",
          }}
        >
          {current > 0 && (
            <Button
              style={{
                margin: "0 8px",
              }}
              onClick={() => prev()}
            >
              Previous
            </Button>
          )}
          {current < steps.length - 1 && (
            <Button type="primary" onClick={handleSubmit(onSubmit)}>
              Next
            </Button>
          )}
          {current === steps.length - 1 && (
            <Button
              type="primary"
              onClick={() => message.success("Processing complete!")}
            >
              Done
            </Button>
          )}
        </div>
      </Container>
    </div>
  );
};

export default ProfessinalInfo;
