import React, { useState } from "react";
import CategoryListTable from "../../../ReuseTable/CategoryListTable";

const CreateQupResponse = ({
  data,
  setResult,
  addQuoatationActivate,
  addQuoatationActivates,
}) => {
  const [values, setValues] = useState([]);
  const handleQuotationEnter = (data, index) => {
    console.log(index);

    setValues((prevdata) => ({
      ...prevdata,
      data,
    }));
  };

  const handleVendorQuoate = (e) => {
    e.preventDefault();
    const dynamicProp = Object.keys(values).filter((key) => key.includes("_"));
    console.log(dynamicProp);

    console.log(values);
    setResult(() => values.map((data) => data));
  };

  const handleQuotationsubmit = (data) => {
    setResult(data);
  };

  return (
    <div>
      <CategoryListTable
        itemSet={data}
        addQuoatationActivate={addQuoatationActivate}
        onSubmit={handleQuotationsubmit}
        addQuoatationActivates={addQuoatationActivates}
      />
      {/* <form onSubmit={(e) => handleVendorQuoate(e)}> */}
      {/* <CategoryListTable
          itemSet={data}
          addQuoatationActivate
          onChange={handleQuotationEnter}
        /> */}
      {/* <button>Submit</button> */}
      {/* </form> */}
    </div>
  );
};

export default CreateQupResponse;
