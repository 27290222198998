import React, { useState, useEffect, useRef, useMemo } from "react";

const AdminActions = ({ onActTaken, vId }) => {
  const defaultAccept = "RFQ Sent";
  const [selectedOpt, setSelectOpt] = useState(defaultAccept);
  const ActionRef = useRef();
  // useEffect(() => {
  //   ActionRef.current = selectedOpt;
  // }, [selectedOpt]);
  const handleChange = (event) => {
    const action = event.target.value;
    setSelectOpt(action);
  };
  useEffect(() => {
    onActTaken(selectedOpt);
  }, [selectedOpt]);

  return (
    <span style={{ maxWidth: 100 }}>
      <select
        ref={ActionRef}
        onChange={(e) => handleChange(e)}
        value={selectedOpt}
        style={{
          width: "100%",
          padding: "5px",
          border: "none",
          borderBottom: "1px solid silver",
        }}
      >
        {/* <option value={""}>Select Any</option> */}
        <option value={"RFQ Sent"}>Approve</option>
        <option value={"Reject"}>Reject</option>
        <option value={"onHold"}>OnHold</option>
      </select>
    </span>
  );
};

export default AdminActions;
