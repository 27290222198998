import React from "react";
import { MenuItem, Menu, Tooltip, IconButton } from "@mui/material";
import {
  CancelPresentation,
  ElectricBolt,
  MoreVert,
} from "@mui/icons-material";
import { Navigate, useNavigate } from "react-router-dom";
import "./Respond.scss";

const RowMenu = ({ id, status }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const onsubmit = (e, id) => {
    e.preventDefault();
    navigate("/vendorManagement/rfq/edit", { state: id });
  };

  const getTooltipTitle = () => {
    return status === "accepted" ? "Check RFQ'S Send" : "Respond";
  };

  return (
    <div>
      <Tooltip title={getTooltipTitle()}>
        <span>
          <IconButton
            onClick={(e) => onsubmit(e, id)}
            disabled={status === "accepted"}
          >
            <ElectricBolt />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title="Reject">
        <IconButton>
          <CancelPresentation />
        </IconButton>
      </Tooltip>
      {/* <span onClick={handleOpenMenu}>
        <MoreVert fontSize="medium" />
      </span>
      <Menu
        anchorEl={anchorEl}
        aria-labelledby="modal-options"
        aria-describedby="various options available for selection"
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        className="custom_option_menu"
      >
        <MenuItem key={id} onClick={(e) => onsubmit(e, id)}>
          Respond
        </MenuItem> */}
      {/* <MenuItem key={id}>
            Progress
          </MenuItem> */}
      {/* </Menu> */}
    </div>
  );
};

export default RowMenu;
