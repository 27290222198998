import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  toggleControlSidebar,
  toggleSidebarMenu,
} from "../../../store/reducers/ui";
import MessagesDropdown from "./messages-dropdown/MessagesDropdown";
import NotificationsDropdown from "./notifications-dropdown/NotificationsDropdown";
import LanguagesDropdown from "./languages-dropdown/LanguagesDropdown";
import UserDropdown from "./user-dropdown/UserDropdown";
import AdminMessagesDropdown from "./messages-dropdown/MessagesNew";
import NewNotificationsDropdown from "./notifications-dropdown/NewNotifications";

const Header = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const navbarVariant = useSelector((state: any) => state.ui.navbarVariant);
  const headerBorder = useSelector((state: any) => state.ui.headerBorder);

  const handleToggleMenuSidebar = () => {
    dispatch(toggleSidebarMenu());
  };

  const handleToggleControlSidebar = () => {
    dispatch(toggleControlSidebar());
  };

  const getContainerClasses = useCallback(() => {
    let classes = `main-header navbar navbar-expand ${navbarVariant}`;
    if (headerBorder) {
      classes = `${classes} border-bottom-0`;
    }
    return classes;
  }, [navbarVariant, headerBorder]);

  return (
    <nav className={getContainerClasses()}>
      <ul className="navbar-nav">
        <li className="nav-item">
          <button
            onClick={handleToggleMenuSidebar}
            type="button"
            className="nav-link"
          >
            <i className="fas fa-bars" />
          </button>
        </li>
        <li className="nav-item d-none d-sm-inline-block">
          <Link to="/" className="nav-link">
            {t("header.label.home") as string}
          </Link>
        </li>
        <li className="nav-item d-none d-sm-inline-block">
          <Link to="/" className="nav-link">
            {t("header.label.contact") as string}
          </Link>
        </li>
      </ul>
      <ul className="navbar-nav ml-auto">
        <AdminMessagesDropdown />
        <NewNotificationsDropdown />
        {/* <LanguagesDropdown /> */}
        <UserDropdown />
        <li className="nav-item">
          <button
            type="button"
            className="nav-link"
            onClick={handleToggleControlSidebar}
          >
            <i className="fas fa-th-large" />
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Header;
