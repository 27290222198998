import React, { useEffect, useRef,useState } from 'react';
import { isMobile } from '../../../utils';
const TypeWriters = ({ words, wait = 3000 }) => {
  const txtElementRef = useRef(null);
  let txt = '';
  let wordIndex = 0;
  let isDeleting = false;

  const type = () => {
    const current = wordIndex % words.length;
    const fullTxt = words[current];

    if (isDeleting) {
      txt = fullTxt.substring(0, txt.length - 1);
    } else {
      txt = fullTxt.substring(0, txt.length + 1);
    }

    if (txtElementRef.current) {
      txtElementRef.current.innerHTML = `<span className="typewrite-txt typewrite-txt-color">${txt}</span>`;
    }

    let typeSpeed = 100;

    if (isDeleting) {
      typeSpeed /= 2;
    }

    if (!isDeleting && txt === fullTxt) {
      typeSpeed = wait;
      isDeleting = true;
    } else if (isDeleting && txt === '') {
      isDeleting = false;
      wordIndex++;
      typeSpeed = 300;
    }

    setTimeout(type, typeSpeed);
  };

  useEffect(() => {
    type();
  }, []); 

  return <span ref={txtElementRef} className="typewrite-txt-type" dangerouslySetInnerHTML={{ __html: '' }} />;
};

const TypeWriter = () => {
  const mobile = isMobile();
  // const [isMobile, setIsMobile] = useState(false);

  // useEffect(() => {
  //   const handleResize = () => {
  //     setIsMobile(window.innerWidth <= 440);
  //   };
  //   handleResize();
  //   window.addEventListener("resize", handleResize);    
  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);
  const helllooRef = useRef(null);

  const handleMobileBehavior = () => {
    // Example: Disable some functionality on mobile
    if (helllooRef.current) {
      helllooRef.current.style.display = 'none';
    }
  };
  useEffect(() => {
    if (mobile) {
      handleMobileBehavior();
    }
  }, [mobile]);


  return (
    <div
    //  className={` ${isMobile ? 'typewrite-app-mobilecontainer' : 'typewrite-app-container'}`}
     >
      <h1 className={`${mobile ? 'view-mobile':'view-normal'}`}>
        <TypeWriters
          words={[ "A solution built for business",]}
          wait={2000}
        />
         {/* <p ref={helllooRef} className='hellloo'>this is laptop</p> */}
      </h1>
    </div>
  );
};

export default TypeWriter;
