import {
  createSlice,
  createAsyncThunk,
  //  current
} from "@reduxjs/toolkit";
const initialState = {
  batchUpdateIds: [],
};


const RfqResponse = createSlice({
  name: "rfqresponse",
  initialState,
  reducers: {
    addrowtobatch: (state, action) => {
      const findduplicate = state.batchUpdateIds.includes(action.payload);
      if (!findduplicate) {
        state.batchUpdateIds.push(action.payload);
      } else {
        console.error("row id already selected/exists");
      }
    },
    removefrombatch: (state, action) => {
      state.batchUpdateIds = state.batchUpdateIds.filter(
        (id) => id !== action.payload
      );
    },
    clearBatchUpdate: (state) => {
      state.batchUpdateIds = [];
    },
  },
});
const { actions, reducer } = RfqResponse;
export const { addrowtobatch, clearBatchUpdate, removefrombatch } = actions;
export default RfqResponse.reducer;
