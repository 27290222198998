import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "../../../../Dcomponents/MDBox";
import MDTypography from "../../../../Dcomponents/MDTypography";
import breakpoints from "../../../../assets/theme/base/breakpoints";
import backgroundImage from "../../../../assets/images/bg-profile.jpeg";
import { FaCamera } from "react-icons/fa"; // Example: Using the FaCamera icon from Font Awesome icons in react-icons
import axios from "../../../../../../../api/axios";
import { toast } from "react-toastify";
import { VENDOR_UPDATE_PROFILE_IMAGE } from "../../../../../../CompServices/Api_Routes_Helper";

function Header({ children, fname, image }) {
  const v_id = localStorage.getItem("v_ID");
  const { vid, role } = JSON.parse(v_id);

  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  const [images, setImage] = useState("");

  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    window.addEventListener("resize", handleTabsOrientation);

    handleTabsOrientation();

    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);
  const [isHovered, setIsHovered] = useState(false);
  const [uploading, setUploading] = useState(false);

  const handleChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      const reader = new FileReader();

      reader.onload = (event) => {
        const uploadedImage = event.target.result;
        setUploading(true);
        handleFormSubmit(uploadedImage);
      };

      reader.readAsDataURL(selectedFile);
    }
  };

  const handleFormSubmit = async (file) => {
    const updateValues = {
      image: "",
    };
    const data = new FormData();
    data.append("file", file);
    data.append("upload_preset", "MyQuotes");

    try {
      const cloudinaryResponse = await fetch(
        "https://api.cloudinary.com/v1_1/dzblzw7ll/image/upload",
        {
          method: "POST",
          body: data,
        }
      );

      const cloudinaryData = await cloudinaryResponse.json();
      updateValues.image = cloudinaryData.secure_url;
      setImage(cloudinaryData.secure_url);
      setUploading(false);

      const axiosResponse = await axios.put(
        `${VENDOR_UPDATE_PROFILE_IMAGE}${vid}`,
        updateValues
      );

      toast.success(axiosResponse.data.message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
        className: "custom-toast",
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <MDBox position="relative" mb={5}>
      <MDBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="18.75rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({
            functions: { rgba, linearGradient },
            palette: { gradients },
          }) =>
            `${linearGradient(
              rgba(gradients.info.main, 0.6),
              rgba(gradients.info.state, 0.6)
            )}, url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      />
      <Card
        sx={{
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <label
              className={`profile-pict editable ${isHovered ? "hovered" : ""}`}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <div>
                <img
                  src={images || image}
                  className="image_hover1"
                  style={{
                    width: "100px",
                    height: "100px",
                    border: "2px solid",
                    borderRadius: "100px",
                    display: "flex",
                  }}
                />
                <input
                  type="file"
                  style={{ marginTop: "30px", display: "none" }}
                  onChange={handleChange}
                  id="imageInput"
                />
              </div>

              {isHovered && (
                <div className="hover-overlay">
                  <FaCamera size={24} color="black" />
                </div>
              )}
            </label>
          </Grid>
          <Grid item>
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                {fname}
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
        {children}
      </Card>
    </MDBox>
  );
}

Header.defaultProps = {
  children: "",
};

Header.propTypes = {
  children: PropTypes.node.isRequired,
  fname: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  setImage: PropTypes.func.isRequired,
};

export default Header;
