import React from 'react'
import "./styles.css"
const HorizontalScroll = () => {

  return (
    <div className="brand-area">
           
          <h2 className="associate-title">Our Associate</h2>
    
            <div className="logos">
      <div className="logos-slide">
        <div className='logos-horizontal'>
      <div className="logo-item">
      <img
                  src="https://i.pinimg.com/originals/aa/70/8d/aa708d1f97a04f6f5a208213f89e1e67.png"
                  alt="logo"
                />   <p>company name</p>
                </div>
                 <div className="logo-item">
                 <img
                  src="https://static.vecteezy.com/system/resources/previews/022/101/041/original/hp-logo-transparent-free-png.png"
                  alt="logo"
                  
                />   <p>company name</p>
                </div>
                 <div className="logo-item">
                   <img
                  src="https://static.vecteezy.com/system/resources/previews/022/101/041/original/hp-logo-transparent-free-png.png"
                  alt="logo"
                 
                />    <p>company name</p>
                </div>
                <div className="logo-item"> <img
                src="https://i.pinimg.com/originals/aa/70/8d/aa708d1f97a04f6f5a208213f89e1e67.png"
                alt="logo"
              />   <p>company name</p>
              </div>
               <div className="logo-item">
               <img
                src="https://static.vecteezy.com/system/resources/previews/022/101/041/original/hp-logo-transparent-free-png.png"
                alt="logo"
                
              />   <p>company name</p>
              </div> 
              <div className="logo-item">
                 <img
                src="https://static.vecteezy.com/system/resources/previews/022/101/041/original/hp-logo-transparent-free-png.png"
                alt="logo"
               
              />   <p>company name</p>
              </div>
      </div>
      </div>
      <div className="logos-slide">
        <div className='logos-horizontal'>
      <div className="logo-item">
      <img
                  src="https://i.pinimg.com/originals/aa/70/8d/aa708d1f97a04f6f5a208213f89e1e67.png"
                  alt="logo"
                />   <p>company name</p>
                </div>
                 <div className="logo-item">
                 <img
                  src="https://static.vecteezy.com/system/resources/previews/022/101/041/original/hp-logo-transparent-free-png.png"
                  alt="logo"
                  
                />   <p>company name</p>
                </div>
                 <div className="logo-item">
                   <img
                  src="https://static.vecteezy.com/system/resources/previews/022/101/041/original/hp-logo-transparent-free-png.png"
                  alt="logo"
                 
                />    <p>company name</p>
                </div>
                <div className="logo-item"> <img
                src="https://i.pinimg.com/originals/aa/70/8d/aa708d1f97a04f6f5a208213f89e1e67.png"
                alt="logo"
              />   <p>company name</p>
              </div>
               <div className="logo-item">
               <img
                src="https://static.vecteezy.com/system/resources/previews/022/101/041/original/hp-logo-transparent-free-png.png"
                alt="logo"
                
              />   <p>company name</p>
              </div> 
              <div className="logo-item">
                 <img
                src="https://static.vecteezy.com/system/resources/previews/022/101/041/original/hp-logo-transparent-free-png.png"
                alt="logo"
               
              />   <p>company name</p>
              </div>
      </div>
      </div>
      
      </div> 
    </div>
  )
}

export default HorizontalScroll