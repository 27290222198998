import React, { useState } from "react";
import { View, StyleSheet } from "@react-pdf/renderer";
import InvoiceTableHeader from "./InvoiceTableHeader";
import InvoiceTableRow from "./InvoiceTableRow";
import InvoiceTableFooter from "./InvoiceTableFooter";

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 14,
    borderWidth: 1,
    borderColor: "#3778C2",
  },
});

const InvoiceItemsTable = ({ invoice, ClientInvoice }) => {
  return (
    <View style={styles.tableContainer}>
      <InvoiceTableHeader items={invoice} ClientItems={ClientInvoice} />
      <InvoiceTableRow items={invoice} ClientItems={ClientInvoice} />
      {ClientInvoice ? (
        <InvoiceTableFooter ClientItems={ClientInvoice} />
      ) : null}
    </View>
  );
};

export default InvoiceItemsTable;
