import { $CombinedState } from "redux";

// export const host = "http://localhost:80";
export const host = "http://api.quoteurprice.com";
export const Vendor_Path = "http://localhost:3000/vendorManagement/";
export const API_AUTH_URL = `${host}/api/auth/`;
export const API_ADMIN_URL = `${host}/api/admin/`;
export const API_AUTH_SIGNUP = `${host}/api/auth/signup`;
export const API_ALLUSERS_ROUTES = `${host}/api/user/signedup_email_users`;
export const sendMessageRoute = `${host}/api/auth/send_message`;
export const getMessageRoute = `${host}/api/auth/receive_message`;
export const API_AUTH_CUR_USER = `${host}/api/auth/get_currentuser/`;
export const UPDATEPROFILE_CURR_USER = `${host}/api/auth/getuser/update/profile/`;
export const CURR_USR_PRF_INFO = `${host}/api/auth/getuser/profile_info/`;
export const USER_IN_PROFILE_UPDATE = `${API_AUTH_URL}user_in/updateProfile/image/`;
//Auth Service
export const LOGIN_CLIENT = `${API_AUTH_URL}loginotp`;
//signin by email & pwd
export const SIGNIN_EMLPWD = `${API_AUTH_URL}signin/client`;
export const SIGNUP_CLIENT = `${API_AUTH_URL}signup`;
//category Services
export const GET_ALL_CATSERVICE = `${host}/api/auth/fetch/all/ServiceCategory`;
export const RFQ_UPDATE_DATA = `${host}/user_in/categoryListUpdate/`;
export const RQF_LIST_VIEW = `${host}/user_in/categoryListView/`;
//RFQ threads
export const RFQ_VIEW_THREAD = `${API_AUTH_URL}view_thread/`;

//rfq creation
export const CREATE_RFQ = `${API_AUTH_URL}create/req-for-service`;
export const GET_RECENT_RFQS = `${API_AUTH_URL}vendor/get_all_rfq`;
export const VENDOR_USERNAME = `${API_AUTH_URL}vendor/username`;
export const GET_ALL_VENDOR = `${API_AUTH_URL}vendor/getAllVendors`;
export const UPDATE_MAIN_RFQ = `${API_AUTH_URL}update/req-for-service/`;
export const CREATE_RFQ_DRAFT = `${API_AUTH_URL}create/RFQ-draft`;
export const GET_RFQS_VID = `${API_AUTH_URL}get/vendors/requests`;

//vendor specific rfq retirvee
export const Fetch_RFQ_request = `${API_AUTH_URL}get/vendor/rfqs_receive/`;
export const GET_RFQS_RESPONSES_VID = `${API_AUTH_URL}get/vendors/responses/`;
// TO get info of rfq requets and prepare quotation
export const GET_REQUEST_DETAILS = `${API_AUTH_URL}get/vendors/request_id/`;
//to activate the status of Approve quoatation
export const POST_ACTIVATE_QUOTATION = `${API_AUTH_URL}postApprovedquotation/`;
//get indiviual response details
//to make quotation for rfq
export const MAKE_QUOTATION = `${API_AUTH_URL}postQuotation`;
//to get info of rfq requests
export const GET_RESPONSE_INFO = `${API_AUTH_URL}get/response/`;
export const GET_QUOTATION_INFO = `${API_AUTH_URL}getUserQuotation/`;
export const DELETE_QUOTATION = `${API_AUTH_URL}deleteRfq/`;

//to get subject description in view page client side
export const GET_SUBJECT_DES = `${API_AUTH_URL}get/subject/`;

//create vendor account
export const CREATE_BUSSINESSVENDOR = `${API_AUTH_URL}vendor/create_VendorAccount`;
export const CREATE_BUSSINESSVENDOR_OTPBASED = `${API_AUTH_URL}post/signup`;
export const SEND_OTP_PATH = `${API_AUTH_URL}post/otp`;
export const SUBMIT_OTP_PATH = `${API_AUTH_URL}verify/otp`;
export const GET_OTP_ONSUBMIT = `${API_AUTH_URL}send_otp`;
export const CHECK_EMAIL_OTP = `${API_AUTH_URL}check_email_exists`;
export const VERIFY_ACCOUNT_TOKEN = `${API_AUTH_URL}send/acc_verifyToken`;

export const SIGNIN_BUSSINESSVENDOR = `${API_AUTH_URL}vendor/signin`;
export const VENDOR_INFO = `${API_AUTH_URL}vendor/getInfo`;

//get vendors requests
export const GET_VENDORS_REQUESTS = `${API_AUTH_URL}get/vendors/requests/`;
export const GET_VENDOR_PROFILE_INFO = `${API_AUTH_URL}get/vendorProfile/information`;
export const VENDOR_GETINFO = `${API_AUTH_URL}vendor/getInfo/`;
export const VENDOR_UPDATE_PROFILE = `${API_AUTH_URL}vendor/updateProfile/`;
export const VENDOR_UPDATE_PROFILE_IMAGE = `${API_AUTH_URL}vendor/updateProfile/image/`;
//------client side rfq view----------
// export const USER_GET_AllRFQS = `${API_AUTH_URL}getuser/rfq_reqs/`;

// export const GET_SINGLE_RESPONSE_INFO = `${API_AUTH_URL}getbyidUserQuotation/`;
export const GET_APPROVEDRFQ_VID = `${API_AUTH_URL}getApprovedList/`;
export const USER_GET_AllRFQS = `${API_AUTH_URL}getuser/rfq_reqs/`;
//to get the created draft rfq in client side
export const GET_CLIENT_DRAFTS = `${API_AUTH_URL}get/DraftRfqs/`;
//to get information of rfq requets & response together
export const RFQ_REQ_RESP_INFO = `${API_AUTH_URL}quotation/info/`;
export const RFQ_REQ_GETLINEITEM = `${API_AUTH_URL}get/quatationLineItem/`;
export const RFQ_REQ_RESP_STATS = `${API_AUTH_URL}getRFQStatistics`;

//to update the draft rfq details(sub & descp)
export const UPDATE_RFQ_SD = `${API_AUTH_URL}update/req-for-service`;
export const POST_CLIENT_DRAFTS = `${API_AUTH_URL}postdraft/`;

//to view response for rfq requets in response page
export const GET_RFQ_RESPONSE_QUOTE = `${API_AUTH_URL}getbyidUserQuotation/`;

//comments
//to view posted comments
export const VIEW_POSTED_COMMENTS = `${API_AUTH_URL}rfq/client/view_posts`;
//to post client comments
export const ADD_CLIENT_COMMENTS = `${API_AUTH_URL}client/post-comment`;
//to post vendor commnet
export const ADD_VENDOR_COMMENTS = `${API_AUTH_URL}vendor/post-comment`;
//to get/fetch vendor posted comments (vendor screen) based on vendor id
export const GET_VENDOR_COMMENTS = `${API_AUTH_URL}vendor/get/comments/`;

//admin post values

export const ADMIN_LOG = `${API_AUTH_URL}get/adminlog`;
export const ADMIN_ADD = `${API_AUTH_URL}post/homecontent`;
export const ADMIN_GET = `${API_AUTH_URL}getAllServices`;
export const ADMIN_SERVICE_CATGORY = `${API_AUTH_URL}create/ServiceCategory`;
export const ADMIN_SERVICE_CAT_DEL = `${API_AUTH_URL}delete/ServiceCategory`;
export const ADMIN_CLIENT_DATA = `${API_AUTH_URL}signedup_email_users`;
export const ADMIN_VENDOR_DATA = `${API_AUTH_URL}getVendorData`;
//to get details of rfq in complete
export const GET_RFQ_EXPLAIN = `${API_AUTH_URL}getByIdList`;

//new Admin Route Helpers

//to post draft
export const POST_DRAFT = `${API_AUTH_URL}postdraft`;
export const GET_DRAFT_DETAILS = `${API_AUTH_URL}get/draft/`;
export const DELETE_DRAFT = `${API_AUTH_URL}delete/draft/`;
export const UPDATE_DRAFT = `${API_AUTH_URL}update/draft/`;
export const HANDLE_DRAFT = `${API_AUTH_URL}rfq/rfq_detail/`;
//to approve quoatation sent  by vendor
export const APPROVE_LINEITEMS = `${API_AUTH_URL}accept/rfq/`;
// to accept line item in batch
export const APPROVE_ARRRFQ_POST = `${API_AUTH_URL}lineItems/accepted`;
export const REJECT_REJECT_POST = `${API_AUTH_URL}lineItems/rejected`;

// order creation stage
export const GET_ORDER_INFO = `${API_AUTH_URL}get/orderInfo`;
export const CREATE_ORDER_INFO = `${API_AUTH_URL}create/order-line-item`;

//approved view page
export const GET_ONE_APPROVED_USER = `${API_AUTH_URL}getoneapproveduser/`;

//REQUEST.JS
export const GET_BYID_VENDORQUOTE = `${API_AUTH_URL}getbyidVendorQuotation/`;

//pdf creation
export const PRINT_PDF = `${API_AUTH_URL}user_in/get/newpdf?data=`;
//============================||===================================
//get All rfqs for Admin
export const ADMIN_FETCH_RFQS = `${API_ADMIN_URL}qup/get/rfqs`;
//assign rfq to vendors by Admin.
export const ADMIN_ASSIGN_RFQS = `${API_ADMIN_URL}assign/rfq`;
//to login admin to qup
export const ADMIN_SIGNIN = `${API_ADMIN_URL}qup/login/admin`;
//to veiw in-detail rfq requests by admin
export const ADMIN_VIEW_RFQ = `${API_ADMIN_URL}rfq/info`;
//Admin update & edit (sub,description)
export const ADMIN_UPDATE_DETAILS = `${API_ADMIN_URL}qup/update/rfqs`;
export const ADMIN_FETCH_DETAILS = `${API_ADMIN_URL}rfq/details`;
//get vendor based on category
export const ADMIN_GET_VENDORS = `${API_ADMIN_URL}get/vendors`;
export const TOP_FIVE_CATEGORY = `${API_AUTH_URL}getTop/five`;
export const PARTICULAR_DATA = `${API_AUTH_URL}latest-requests`;

export const ADMINRFQ_DELETE = `${API_ADMIN_URL}qup/delete/rfqs`;
