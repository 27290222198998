import MDBox from "../../Dcomponents/MDBox";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";

function ResponseCreate() {
  return (
    <div>
      <h1>Notifications</h1>
    </div>
    // <DashboardLayout>
    // <DashboardNavbar />

    // </DashboardLayout>
  );
}
export default ResponseCreate;
