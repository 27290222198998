import { useEffect, useRef, useState } from "react";
import Dropdown from "./Dropdown";
import { Button, MenuItem, Typography } from "@mui/material";
import { NavLink, useLocation } from "react-router-dom";

function MenuItemsComponent({ items, depthLevel }) {
  const [dropdown, setDropdown] = useState(false);
  const ref = useRef(null);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handler = (event) => {
      if (dropdown && ref.current && !ref.current.contains(event.target)) {
        setDropdown(false);
      }
    };

    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [dropdown]);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const onMouseEnter = () => {
    if (window.innerWidth > 960) {
      setDropdown(true);
    }
  };

  const location = useLocation();

  const onMouseLeave = () => {
    if (window.innerWidth > 960) {
      setDropdown(false);
    }
  };
  const closeDropdown = () => {
    dropdown && setDropdown(false);
  };

  const validPaths = ["/", "/info"];

  const isHomePage = validPaths.includes(location.pathname);
  // const isHomePage = location.pathname === "/";
  const shouldApplyWhiteLinkClass = isHomePage && !isScrolled;
  const isDropdownItem = depthLevel > 0;

  return (
    <li
      className={`menu-items ${
        !isDropdownItem && shouldApplyWhiteLinkClass ? "white-link" : ""
      }`}
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={closeDropdown}
    >
      {items.url && items.submenu ? (
        <>
          <button
            type="button"
            aria-haspopup="menu"
            className="menu-btn"
            aria-expanded={dropdown ? "true" : "false"}
            onClick={() => setDropdown((prev) => !prev)}
          >
            {window.innerWidth < 960 && depthLevel === 0 ? (
              items.title
            ) : (
              <NavLink to={items.url}>{items.title}</NavLink>
            )}

            {depthLevel > 0 && window.innerWidth < 960 ? null : depthLevel >
                0 && window.innerWidth > 960 ? (
              <span>&raquo;</span>
            ) : (
              <span className="arrow" />
            )}
          </button>
          <Dropdown
            className="submenu-btn"
            depthLevel={depthLevel}
            submenus={items.submenu}
            dropdown={dropdown}
          />
        </>
      ) : !items.url && items.submenu ? (
        <>
          <button
            type="button"
            aria-haspopup="menu"
            className="menu-btn"
            aria-expanded={dropdown ? "true" : "false"}
            onClick={() => setDropdown((prev) => !prev)}
          >
            {items.title}{" "}
            {depthLevel > 0 ? <span>&raquo;</span> : <span className="arrow" />}
          </button>
          <Dropdown
            depthLevel={depthLevel}
            submenus={items.submenu}
            dropdown={dropdown}
          />
        </>
      ) : (
        <button className="menu-btn" type="button" style={{ fontSize: "15px" }}>
          <NavLink to={items.url}>{items.title}</NavLink>
        </button>
      )}
    </li>
  );
}

export default MenuItemsComponent;
