import React from "react";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";

const MasterTemplates = () => {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div>MasterTemplates</div>
    </DashboardLayout>
  );
};

export default MasterTemplates;
