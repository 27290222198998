import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import authReducer from "./slices/auth";
import messageReducer from "./slices/message";
import RFQstatus from "./slices/RFQstatus";
import RFQresponse from "./slices/RFQresponse";
import RfqInfo from "./slices/RfqInfo";
import addressDetails from "./slices/RFQAddress";
import authSlice from "./Components/QupAdmin/store/reducers/auth";
import uiSliceReducer from "./Components/QupAdmin/store/reducers/ui";
import { createLogger } from "redux-logger";
const rootreducer = {
  adminauth: authSlice,
  ui: uiSliceReducer,
  auth: authReducer,
  message: messageReducer,
  rfqstatus: RFQstatus,
  batchresponse: RFQresponse,
  rfqRequests: RfqInfo,
  rfqAddress: addressDetails,
};
const store = configureStore({
  reducer: rootreducer,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware().concat(createLogger()),
  ],
  devTools: true,
});
export default store;
