import React, { useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useNavigate } from "react-router-dom";
import { BsFacebook, BsTwitter, BsGoogle, BsInstagram } from "react-icons/bs";
import {
  Grid,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useMediaQuery,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Footer = () => {
  const navigate = useNavigate();
  const isMobileView = useMediaQuery("(max-width:600px)");
  return (
    <div className="text-center text-lg-start bg-white text-muted">
      <div className="footercontent">
        <div style={{ paddingTop: "45px" }}>
          <Grid container spacing={1} rowGap={1}>
            <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
              <div>
                <a href="#" className="foot-hzon" onClick={() => navigate("/")}>
                  Home
                </a>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
              <div>
                <a
                  href="#"
                  className="foot-hzon"
                  onClick={() => navigate("/info")}
                >
                  About QuoteUrPrice
                </a>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
              <div>
                <a href="#" className="foot-hzon" onClick={() => navigate("/")}>
                  Privacy Policy
                </a>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
              {" "}
              <div>
                <a href="#" className="foot-hzon" onClick={() => navigate("/")}>
                  Terms & Conditions
                </a>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
              <div>
                <a
                  href="#"
                  className="foot-hzon"
                  onClick={() => navigate("/stepper")}
                >
                  Help & Support
                </a>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
              <div>
                <a
                  href="#"
                  className="foot-hzon"
                  onClick={() => navigate("/add/component")}
                >
                  Trust & Safety
                </a>
              </div>
            </Grid>
          </Grid>
        </div>
        <Grid container className="footerrow p-4">
          {/* <Grid item xs={12} sm={6} md={3} lg={3} className="grid-main">
          {isMobileView ? (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="more-info-content"
            id="more-info-header"
          >
            <h3>Categories</h3>
            </AccordionSummary>
          <AccordionDetails>
            <div className="foot-cont">
              <a href="#" className="link-item " onClick={() => navigate("/")}>
                Digital Marketing
              </a>
            </div>
            <br />
            <div>
              <a href="#" className="link-item" onClick={() => navigate("/")}>
                Business
              </a>
            </div> */}

          {/* <div>
            {isMobileView ? (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="more-info-content"
                  id="more-info-header"
                >
                  <h3>Categories</h3>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="foot-cont">
                    <a
                      href="#"
                      className="link-item "
                      onClick={() => navigate("/")}
                    >
                      Digital Marketing
                    </a>
                  </div>
                  <br />
                  <div>
                    <a
                      href="#"
                      className="link-item"
                      onClick={() => navigate("/")}
                    >
                      Business
                    </a>
                  </div>
                  {/* <div>
              <a
                href="#"
                className="link-item"
                onClick={() => navigate("/sitemap")}
              >
                Sitemap
              </a>
            </div> */}
          {/* <br />
                  <div>
                    <a
                      href="#"
                      className="link-item"
                      onClick={() => navigate("/")}
                    >
                      Video & Animation
                    </a>
                  </div>
                  <br />
                  <div>
                    <a
                      href="#"
                      className="link-item"
                      onClick={() => navigate("/")}
                    >
                      Music & Audio
                    </a>
                  </div>{" "}
                </AccordionDetails>
              </Accordion>
            ) : (
              <>
                <h3>Categories</h3>
                <div className="foot-cont">
                  <a
                    href="#"
                    className="link-item "
                    onClick={() => navigate("/")}
                  >
                    Digital Marketing
                  </a>
                </div>
                <br />
                <div>
                  <a
                    href="#"
                    className="link-item"
                    onClick={() => navigate("/")}
                  >
                    Business
                  </a>
                </div>
                <br />
                <div>
                  <a
                    href="#"
                    className="link-item"
                    onClick={() => navigate("/")}
                  >
                    Video & Animation
                  </a>
                </div>
                <br />
                <div>
                  <a
                    href="#"
                    className="link-item"
                    onClick={() => navigate("/")}
                  >
                    Music & Audio
                  </a>
                </div>
              </>
            )}
          </Grid> */}
          {/* <Grid item xs={12} sm={6} md={3} lg={3} className="grid-main">
            {isMobileView ? (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="more-info-content"
                  id="more-info-header"
                >
                  <h3>About</h3>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="foot-cont">
                    <a
                      href="#"
                      className="link-item"
                      onClick={() => navigate("/")}
                    >
                      Careers
                    </a>
                  </div>
                  <br />
                  <div>
                    <a
                      href="#"
                      className="link-item"
                      onClick={() => navigate("/")}
                    >
                      Privacy Policy
                    </a>
                  </div>
                  <br />
                  <div>
                    <a
                      href="#"
                      className="link-item"
                      onClick={() => navigate("/")}
                    >
                      Terms & Conditions
                    </a>
                  </div>
                  <br />
                  <div>
                    <a
                      href="#"
                      className="link-item"
                      onClick={() => navigate("/")}
                    >
                      Investor Relations
                    </a>
                  </div>{" "}
                </AccordionDetails>
              </Accordion>
            ) : (
              <>
                <h3>About</h3>
                <div className="foot-cont">
                  <a
                    href="#"
                    className="link-item"
                    onClick={() => navigate("/")}
                  >
                    Careers
                  </a>
                </div>
                <br />
                <div>
                  <a
                    href="#"
                    className="link-item"
                    onClick={() => navigate("/")}
                  >
                    Privacy Policy
                  </a>
                </div>
                <br />
                <div>
                  <a
                    href="#"
                    className="link-item"
                    onClick={() => navigate("/")}
                  >
                    Terms & Conditions
                  </a>
                </div>
                <br />

                <div>
                  <a
                    href="#"
                    className="link-item"
                    onClick={() => navigate("/")}
                  >
                    Investor Relations
                  </a>
                </div>
              </>
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3} className="grid-main">
            {isMobileView ? (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="more-info-content"
                  id="more-info-header"
                >
                  <h3>Support</h3>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="foot-cont">
                    <a
                      href="#"
                      className="link-item "
                      onClick={() => navigate("/stepper")}
                    >
                      Help & Support
                    </a>
                  </div>
                  <br />
                  <div>
                    <a
                      href="#"
                      className="link-item"
                      onClick={() => navigate("/add/component")}
                    >
                      Trust & Safety
                    </a>
                  </div>

                  <br />
                  <div>
                    <a
                      href="#"
                      className="link-item"
                      onClick={() => navigate("/")}
                    >
                      Selling on QuoteUrPrice
                    </a>
                  </div>
                  <br />
                  <div>
                    <a
                      href="#"
                      className="link-item"
                      onClick={() => navigate("/")}
                    >
                      Buying on QuoteUrPrice
                    </a>
                  </div>
                </AccordionDetails>
              </Accordion>
            ) : (
              <>
                <h3>Support</h3>
                <div className="foot-cont">
                  <a
                    href="#"
                    className="link-item "
                    onClick={() => navigate("/stepper")}
                  >
                    Help & Support
                  </a>
                </div>
                <br />
                <div>
                  <a
                    href="#"
                    className="link-item"
                    onClick={() => navigate("/add/component")}
                  >
                    Trust & Safety
                  </a>
                </div>

                <br />
                <div>
                  <a
                    href="#"
                    className="link-item"
                    onClick={() => navigate("/")}
                  >
                    Selling on QuoteUrPrice
                  </a>
                </div>
                <br />
                <div>
                  <a
                    href="#"
                    className="link-item"
                    onClick={() => navigate("/")}
                  >
                    Buying on QuoteUrPrice
                  </a>
                </div>
              </>
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3} className="grid-main">
            {isMobileView ? (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="more-info-content"
                  id="more-info-header"
                >
                  <h3>More From QuoteUrPrice</h3>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="foot-cont">
                    <a
                      href="#"
                      className="link-item"
                      onClick={() => navigate("/info")}
                    >
                      RFQ's Document
                    </a>
                  </div>
                  <div className="foot-cont">
                    <a
                      href="#"
                      className="link-item"
                      onClick={() => navigate("/")}
                    >
                      QuoteUrPrice Enterprise
                    </a>
                  </div>
                  <br />
                  <div>
                    <a
                      href="#"
                      className="link-item"
                      onClick={() => navigate("/")}
                    >
                      QuoteUrPrice Business
                    </a>
                  </div>
                  <br />
                  <div>
                    <a
                      href="#"
                      className="link-item"
                      onClick={() => navigate("/")}
                    >
                      QuoteUrPrice Pro
                    </a>
                  </div>
                </AccordionDetails>
              </Accordion>
            ) : (
              // Render non-accordion content for larger screens
              <>
                <h3>More From QuoteUrPrice</h3>
                <div className="foot-cont">
                  <a
                    href="#"
                    className="link-item"
                    onClick={() => navigate("/info")}
                  >
                    RFQ's Document
                  </a>
                </div>
                <div className="foot-cont">
                  <a
                    href="#"
                    className="link-item"
                    onClick={() => navigate("/")}
                  >
                    QuoteUrPrice Enterprise
                  </a>
                </div>
                <br />
                <div>
                  <a
                    href="#"
                    className="link-item"
                    onClick={() => navigate("/")}
                  >
                    QuoteUrPrice Business
                  </a>
                </div>
                <br />
                <div>
                  <a
                    href="#"
                    className="link-item"
                    onClick={() => navigate("/")}
                  >
                    QuoteUrPrice Pro
                  </a>
                </div>
              </>
            )}
          </Grid> */}
        </Grid>
      </div>
      <Divider />
      <Grid container className="grid_footer">
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <div className="myquotes_footer">
            <h6>Copyright &copy; {new Date().getFullYear()} QuoteUrPrice</h6>
          </div>
        </Grid>
        <Grid item sm={12} md={6} lg={6}>
          <div className="social_icons">
            <a href="#" className="foot-icon" onClick={() => navigate("/")}>
              <BsFacebook className="foot-icon1" />
            </a>
            <a href="#" className="foot-icon" onClick={() => navigate("/")}>
              <BsTwitter className="foot-icon1" />
            </a>
            <a href="#" className="foot-icon" onClick={() => navigate("/")}>
              <BsGoogle className="foot-icon1" />
            </a>
            <a href="#" className="foot-icon" onClick={() => navigate("/")}>
              <BsInstagram className="foot-icon1" />
            </a>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
export default Footer;
