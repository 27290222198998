import ac1 from "../../assets/innerpage/ac1.jpg";
import ac2 from "../../assets/innerpage/ac2.jpg";
import ac3 from "../../assets/innerpage/ac3.jpg";
import ac4 from "../../assets/innerpage/ac4.jpg";
import ac5 from "../../assets/innerpage/ac5.jpg";
import ac6 from "../../assets/innerpage/ac6.jpg";
import ac7 from "../../assets/innerpage/ac7.jpg";
import ac8 from "../../assets/innerpage/ac8.jpg";

//MostPopularService
import img1 from "../../assets/innerpage/wordpress.webp";
import img2 from "../../assets/innerpage/Python.webp";
import img3 from "../../assets/innerpage/Html.webp";
import img4 from "../../assets/innerpage/Android.webp";
import img5 from "../../assets/innerpage/ios.webp";
import img6 from "../../assets/innerpage/mern.webp";
import img7 from "../../assets/innerpage/javas.webp";

//guide part
import guide from "../../assets/innerpage/guide.webp";
import guide1 from "../../assets/innerpage/guide1.webp";
import guide2 from "../../assets/innerpage/guide2.webp";

export const CategorySlideMapping = [
  {
    image1: ac1,
    // image2: ac2,
    avatar:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6eSZm1ovyXXKvzOonPb_U16AMpBj938lZlq7wf5xhtg&usqp=CAU&ec=48665698",
    name: "Ethan Hunt",
    level: "Level 2",
    para: "Professional with 17 years of experience as computer lecturer .Teaching the computer coding to students of different age groups .love this profession which gives me opportunity to share my knowledge.",
    star: "5.0",
    span: "(90)",
    rate: "₹500",
  },
  {
    image1: ac3,
    // image2: ac4,
    avatar:
      "https://static.vecteezy.com/system/resources/thumbnails/001/993/889/small/beautiful-latin-woman-avatar-character-icon-free-vector.jpg",
    name: "Hermani ",
    level: "High Qty",
    para: "Professional with 12 years of experience as Python.Teaching the computer coding to students of different age groups .love this profession which gives me opportunity to share my knowledge.",
    star: "4.8",
    span: "(1.8k)",
    rate: "₹600",
  },
  {
    image1: ac5,
    // image2: ac6,
    avatar:
      "https://media.istockphoto.com/id/1331335536/vector/female-avatar-icon.jpg?s=170667a&w=0&k=20&c=-iyD_53ZEeZPc4SmvmGB1FJXZcHy_fvbJBv6O8HblHs=",
    name: "Tamil",
    level: "Level 1",
    para: "Professional with 7 years of experience as JAVA.Teaching the computer coding to students of different age groups .love this profession which gives me opportunity to share my knowledge.",
    star: "3.9",
    span: "(800)",
    rate: "₹900",
  },
  {
    image1: ac7,
    // image2: ac8,
    avatar:
      "https://png.pngtree.com/png-vector/20191101/ourmid/pngtree-male-avatar-simple-cartoon-design-png-image_1934458.jpg",
    name: "Soniya",
    level: "Level 2",
    para: "Professional with 10 years of experience as .Net.Teaching the computer coding to students of different age groups .love this profession which gives me opportunity to share my knowledge.",
    star: "3.9",
    span: "(15)",
    rate: "₹800",
  },
  {
    image1: ac6,
    // image2: ac8,
    avatar:
      "https://png.pngtree.com/png-vector/20191101/ourmid/pngtree-male-avatar-simple-cartoon-design-png-image_1934458.jpg",
    name: "Carla",
    level: "Level 2",
    para: "Professional with 7 years of experience as MERN STACK.Teaching the computer coding to students of different age groups .love this profession which gives me opportunity to share my knowledge.",
    star: "3.9",
    span: "(15)",
    rate: "₹800",
  },
];

export const MostPopularService = [
  {
    image: img1,
    name: "WordPress Developers",
  },
  {
    image: img2,
    name: "Python Developers",
  },
  {
    image: img3,
    name: "HTML & CSS Developers",
  },
  {
    image: img4,
    name: "Android Developers",
  },
  {
    image: img7,
    name: "JavaScript Developers",
  },
  {
    image: img5,
    name: "IOS Developers",
  },
  {
    image: img6,
    name: "MERN Developers",
  },
  {
    image: img2,
    name: "Java Developers",
  },
];

export const Categoriesguideness = [
  {
    id: 1,
    image: guide,
    text: "Start an online python coaching",
    text1: "A complete guide to starting a small online class",
  },
  {
    id: 2,
    image: guide1,
    text: "online java class Made easy",
    text1: "A practical guide to understand what is java basics",
  },
  {
    id: 3,
    image: guide2,
    text: "Develop your codeing knowledge",
    text1: "A step-by-step guide to create a memorable code",
  },
];
