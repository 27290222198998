import React from "react";
import { toast } from "react-toastify";

const ToastMaker = async (message) => {
  await toast.info(message, {
    type: `default`,
    position: "top-center",

    closeButton: true,
    closeOnClick: true,
    autoClose: 1500,
  });
};

export default ToastMaker;
