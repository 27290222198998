import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import SignIn from "./Sign_in";
import VendorSign from "./Vendor_SignIn";
import { useLocation } from "react-router-dom";
import Divider from "@mui/material/Divider";
export default function SigninTaps() {
  const location = useLocation();
  const initialRfqType = location.state && location.state.tap;

  const [value, setValue] = React.useState(
    initialRfqType ? initialRfqType.toString() : "1"
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="login-section ">
      <div className="container">
        <div className="login-data">
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Box className="tabs-container">
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                  sx={{
                    "& .MuiTab-root": {
                      marginRight: "10px", // Add margin to the tabs
                    },
                  }}
                >
                  <Tab label="Client SignIn" value="1" />
                  <Divider orientation="vertical" flexItem className="divider-color" />
                  <Tab label="Vendor SignIn" value="2" />
                </TabList>
              </Box>
              <TabPanel value="1" className="box-after-tab-panel">
                <SignIn />
              </TabPanel>
              <TabPanel value="2" className="box-after-tab-panel">
                <VendorSign />
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      </div>
    </div>
  );
}
