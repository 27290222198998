export const help = [
  {
    id: 1,
    image:
      "https://images.pexels.com/photos/3184291/pexels-photo-3184291.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    text: "Start an online business and work from home",
    text1: "A complete guide to starting a small business online",
  },
  {
    id: 2,
    image:
      "https://media.istockphoto.com/id/1344872631/photo/portrait-of-a-group-of-confident-young-businesspeople-working-together-in-a-modern-office.jpg?s=612x612&w=0&k=20&c=_MdFKendqK0sN48ukiTmxPzP5GsoDn_E5Lo7RH6wFuU=",
    text: "Digital Marketing Made easy",
    text1: "A practical guide to understand what is digital marketing",
  },
  {
    id: 3,
    image:
      "https://media.istockphoto.com/id/527116353/photo/group-of-friends-with-thumbs-up.jpg?s=612x612&w=0&k=20&c=Hd3Yc14Pet7urp0Pc2zgcqN5GvpuTSpLmKuQ8UoZZsw=",
    text: "Create a Business Account",
    text1: "A step-by-step guide to create a memorable business logo",
  },
];

export const vendorCarousel = [
  {
    id: 1,
    image:
      "https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto/v1/attachments/generic_asset/asset/cea6172437d4dae638e71b4be208301c-1683032673781/Decktop%20Banner.jpg",
  },
  {
    id: 2,
    image:
      "https://fiverr-res.cloudinary.com/image/upload/q_auto,f_auto/v1/attachments/generic_asset/asset/6ece77b17e7ab1a0fa45f7b3ad59efd0-1597667380134/O2O_Wizard_Desktop.jpg",
  },
];

export const vendorCarousell = [
  {
    id: 1,
    image:
      "https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto/v1/attachments/generic_asset/asset/6606be5be38e408df403ecc52797cd97-1614845806749/Conversion%20Rate%20Optimization.jpg",
    text: "Convert web visitors into customers",
    text1: "Learn how to monetize your online presence with this course.",
  },
  {
    id: 2,
    image:
      "https://fiverr-res.cloudinary.com/image/upload/q_auto,f_auto/v1/attachments/generic_asset/asset/6ece77b17e7ab1a0fa45f7b3ad59efd0-1597667380134/O2O_Wizard_Desktop.jpg",
    text: "Facebook ads. Know it all.",
    text1: "Scale your business with best practices covered in this course.",
  },
  {
    id: 3,
    image:
      "https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto/v1/attachments/generic_asset/asset/579aa581549375f6f765229f28786684-1614853429793/Social%20Media.jpg",
    text: "Build a strong online presence.",
    text1:
      "Unlock the secrets of conversion rate optimization with this course.",
  },
];
export const data = {
  series: [15, 85],
  options: {
    chart: {
      type: "pie",
    },
    labels: ["Apples", "Oranges"],
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
  },
};

export const data1 = {
  series: [35, 55],
  options: {
    chart: {
      type: "pie",
    },
    labels: ["Apples", "Oranges"],
    colors: ["#FEB019", "#FF4560"],
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
  },
};

export const data2 = {
  series: [65, 35],
  options: {
    chart: {
      type: "pie",
    },
    labels: ["Apples", "Oranges"],
    colors: ["#216ba5", "#1dbf73"],
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
  },
};
