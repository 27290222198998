import React from "react";
import DataTable from "react-data-table-component";
import { Checkbox } from "@mui/material";
import PropTypes from "prop-types";
import { ArrowDownward } from "@mui/icons-material";
import MDBox from "../ServiceProvider/dashboard/Dcomponents/MDBox";
import MDTypography from "../ServiceProvider/dashboard/Dcomponents/MDTypography";
//custom color component for Data table
export const customStyles = {
  table: {
    style: {
      border: "1px solid silver",
      borderRadius: "5px",
    },
  },
  rows: {
    style: {
      minHeight: "50px", // override the row height
    },
  },
  headCells: {
    style: {
      paddingLeft: "8px", // override the cell padding for head cells
      paddingRight: "8px",
      border: "1px solid gray",
    },
  },
  headRow: {
    style: {
      minHeight: "52px",
      borderBottomWidth: "1px",
      borderBottomStyle: "solid",
      backgroundColor: "lightblue",
    },
    denseStyle: {
      minHeight: "32px",
    },
  },
  cells: {
    style: {
      paddingLeft: "8px", // override the cell padding for data cells
      paddingRight: "8px",
      width: "100%",
    },
  },
};
export const customizStyles = {
  table: {
    style: {
      border: "1px solid silver",
      borderRadius: "5px",
    },
  },
  rows: {
    style: {
      minHeight: "50px",
    },
  },
  headCells: {
    style: {
      paddingLeft: "8px",
      paddingRight: "8px",
      backgroundColor: "#bed4db",
      color: "#00000",
      fontSize: "13px",
      fontWeight: "600",
      fontSize: "13px",
      fontWeight: "600",
      // fontFamily: "var(--qup-font-Merriweather)",
      textTransform: "capitalize",
      textAlign: "center",
    },
  },

  headRow: {
    style: {
      minHeight: "52px",
      borderBottomWidth: "1px",
      borderBottomStyle: "solid",
      backgroundColor: "#fff",
      color: "black",
    },
    denseStyle: {
      minHeight: "42px",
    },
  },
  cells: {
    style: {
      paddingLeft: "8px",
      paddingRight: "8px",
      width: "100%",
      fontSize: "14px",
      color: "black",
    },
  },
  subHeader: {
    style: {
      backgroundColor: "rgba(246,246,246,255)",
      padding: "10px",
      borderTopLeftRadius: "5px", // Add the border radius properties here
      borderTopRightRadius: "5px",
      // Add more custom styles as needed
    },
  },
};

const sortIcon = <ArrowDownward />;
const selectProps = { indeterminate: (isIndeterminate) => isIndeterminate };
const EmptyData = () => (
  <MDBox p={2}>
    <MDTypography component="span" variant="h6" color="dark">
      No records to Display
    </MDTypography>
  </MDBox>
);

function DataTableBase(props) {
  return (
    <DataTable
      // pagination
      // columns
      // data
      // selectableRowsComponent={Checkbox}
      selectableRowsComponentProps={selectProps}
      sortIcon={sortIcon}
      striped
      pagination
      paginationPerPage={5}
      {...props}
      pointerOnHover
      // title
    />
  );
}

export default DataTableBase;
DataTableBase.defaultProps = {
  columns: [],
  data: [],
  // title:"Table Name"
};
DataTableBase.prototype = {
  columns: PropTypes.array,
  data: PropTypes.array,
  title: PropTypes.string,
};
