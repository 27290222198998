import React,{useState,useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const useStyles = makeStyles((theme) => ({
  goback: {
    right: '1%',
    bottom: '2.5%',
    position: 'fixed',
    opacity: 0,
    transform: 'translateX(100%)',
    transition: 'opacity 0.3s ease-in-out, transform 0.5s ease-in-out',
  },
  visible: {
    opacity: 1,
    transform: 'translateX(0)',
  },
  gobackicon:{
    color:"blue",fontSize:"1.8rem",border:"2px solid", borderRadius:"50%",padding:"2px",
    '&:hover': {
      color:'red',
      // fontSize:'2rem',
    },
  }
}));
const ScrollTop = () => {
    const classes = useStyles();
    const [isVisible, setIsVisible] = useState(false);
  
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth', // This provides smooth scrolling
      });
    };
    useEffect(() => {
      const handleScroll = () => {
        const scrollY = window.scrollY;
        if (scrollY > 100) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      };
  
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
  return (
    <div>
         <div  className={`${classes.goback} ${isVisible ? classes.visible : ''}`}>
        <p onClick={scrollToTop}>
          <KeyboardArrowUpIcon className={`${classes.gobackicon}`} />
        </p>
      </div>
    </div>
  )
}

export default ScrollTop