import { Button, Container, Grid } from "@mui/material";
import React from "react";
import ProductCardDesign from "./ProductCardDesign";
import MySlider from "../CardCarousel/MySlider";
import PartnerSection from "../ClientTestimonials/ParterSection";
import Testimonials from "../Testimonials/Testimonials";
import HorizontalScroll from "../Scrollerinfinite/HorizontalScroll";
import TypeWriter from "../Homepage/TypeWriter/TypeWriter";
import ScrollLine from "./ScrollLine";

const CategoryCardDesign = () => {
  const gridData = [
    {
      imageUrl:
        "https://content.jdmagicbox.com/comp/delhi/l3/011pxx11.xx11.190911112827.k6l3/catalogue/msi-ac-service-wala-shastri-park-delhi-ac-repair-and-services-dw3kcim7fy.jpg",
      title: "AC Service & Repair",
    },
    {
      imageUrl:
        "https://www.popsci.com/uploads/2022/08/25/image-2.jpeg?auto=webp&width=1440&height=1080",
      title: "Labtop Service",
    },
    {
      imageUrl:
        "https://media.istockphoto.com/id/468686480/photo/modern-generic-car-on-white-background.webp?b=1&s=170667a&w=0&k=20&c=g1yrjmmaNZQ6IL9SRSh3SDgfnRihTRKqIYQmzd-8_ws=",
      title: "Automobiles",
    },
    {
      imageUrl: "https://i.pngimg.me/thumb/f/720/5f1febfea7574b86aecf.jpg",
      title: "Clothes Vendor",
    },
    {
      imageUrl:
        "https://c4.wallpaperflare.com/wallpaper/659/385/190/white-smartphone-thin-galaxy-s5-wallpaper-preview.jpg",
      title: "Mobile Service",
    },
    {
      imageUrl:
        "https://img.freepik.com/premium-photo/volumetric-glossy-hot-orange-code-icon-isolated-white-background-3d-rendered-digital-symbol-modern-icon-website_295303-3763.jpg?size=626&ext=jpg&ga=GA1.1.1413502914.1696809600&semt=ais",
      title: "Developer",
    },
    {
      imageUrl:
        "https://static.vecteezy.com/system/resources/previews/003/791/380/non_2x/illustration-of-hanging-light-bulb-design-white-background-seamless-pattern-designs-for-wallpaper-backdrop-cover-paper-cut-and-print-on-fabric-simple-and-unique-modern-template-free-vector.jpg",
      title: "Electrical Works",
    },
    {
      imageUrl:
        "https://st4.depositphotos.com/38013370/39992/i/450/depositphotos_399921568-stock-photo-modern-comfortable-furniture-white-background.jpg",
      title: "Furniture Dealers",
    },
    {
      imageUrl:
        "https://i.pinimg.com/736x/5d/b9/23/5db92336d7f69c9c356cac9ffa752f48.jpg",
      title: "Packers & Movers",
    },
    {
      imageUrl:
        "https://img.freepik.com/premium-photo/flying-car-parts-white-background_241146-2376.jpg?w=2000",
      title: "Vehical Parts Dealers",
    },
    {
      imageUrl:
        "https://www.slideteam.net/media/catalog/product/cache/1280x720/s/o/solar_panels_with_white_background_stock_photo_Slide01.jpg",
      title: "Solar Products Dealers",
    },
    {
      imageUrl:
        "https://static.vecteezy.com/system/resources/previews/000/830/840/original/construction-site-on-white-background-vector.jpg",
      title: "Construction Works",
    },
  ];

  return (
    <>
    <ScrollLine />
      <Container className="Category-card-container-main">
        <div>
          <h2 className="testimonials-title" style={{ marginBottom: "2rem" }}>
            Categories You May Like
          </h2>
        </div>
        <div className="Category-card-container Category-card-media">
          <Grid container spacing={2} rowGap={5}>
            {gridData.map((item, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={index}>
                <div className="individual-div">
                  <img
                    src={item.imageUrl}
                    width="150px"
                    height="150px"
                    alt={item.title}
                  ></img>
                  <p className="category-card-name">{item.title}</p>
                  <Button fullWidth className="category-card-button">
                    Get Quotation
                  </Button>
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      </Container>
      <ProductCardDesign gridData={gridData} />
      <div style={{background:"#d9d4d4"}}>
      <Testimonials />
      </div>
      <Container>
      <MySlider />
      </Container>
      {/* <PartnerSection /> */}
      <Container>
      <TypeWriter />
      </Container>
      <div style={{marginTop:"1rem"}}>      <HorizontalScroll />
      </div>
    </>
  );
};

export default CategoryCardDesign;
