import React, { Suspense, useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./Components/Home";
import InnerContent from "./Components/InnerContent/InnerContent";
import Profile from "./Components/Profile/Profile";
import QupAdminProfile from "./Components/QupAdmin/pages/profile/Profile";
import ProtectedRoutes from "./Routes/ProtectedRoutes";
import PublicRoutes from "./Routes/PublicRoutes";
import PermissionDenied from "./Components/PermissionDenied";
import Signup from "./Components/SignupEmail/Signup";
import SignIn from "./Components/Sign_in/Sign_in.jsx";
import VerifyEmail from "./Components/verify_email/VerifyEmail";
import Welcome from "./Components/Welcome";
import Logout from "./Components/Logout";
import PageNotFound from "./Components/NotFound/PageNotFound";
import AdminDashboard from "./Components/Admin/AdminDBoard";
import AdminHome from "./Components/Admin/AdminHome";
import SellingBussinessInfo from "./Components/Selling_Bussiness/SellingBInfo";
import ListYourBussiness from "./Components/Selling_Bussiness/ListYourBussiness";
import CategoryTable from "./Components/CategoryList/CategoryTable";
import CategoryListUpdate from "./Components/CategoryList/CategoryListUpdate";
import CategoryListView from "./Components/CategoryList/CategoryListView";
import ServiceProvider from "./Components/ServiceProvider/index";
import ServiceInnerContent from "./Components/InnerContent/ServiceInnerContent";
import DemoNavbar from "./Components/Selling_Bussiness/DemoNavbar";
// import ProfessinalInfo from "./Components/Selling_Bussiness/ProfessinalInfo";
import VendorRequests from "./Components/ServiceProvider/Requests/VendorRequests";
import RespondsPage from "./Components/ServiceProvider/Requests/RespondsPage";
import CreateVendorAcc from "./Components/ServiceProvider/vendor_Signup/index";
import VSignin from "./Components/ServiceProvider/vendor_Signin/VSignin";
// import Responsepage from "./Components/PostAndReceiveRFQs/Responsepage";
import PostResponseViewPage from "./Components/ServiceProvider/RFQsResponse/PostResponseViewPage";

import ApprovedViewPage from "./Components/ServiceProvider/RFQsResponse/ApprovedViewPage";

import VendorDBoard from "./Components/ServiceProvider/dashboard/VendorDBoard";
import VendorProtectedRoutes from "./Routes/vendorProtectRoutes";
import AdminRoutes from "./Routes/AdminProtectedRoutes";
import ViewResponse from "./Components/CategoryList/ViewResponse";
// import RespondPage from "./Components/ServiceProvider/Requests/RespondsPage";
import ResponseCreate from "./Components/ServiceProvider/dashboard/layouts/respond";
import SubCategoryPage from "./Components/CategoryList/SubCategoryPage";
import RfqThread from "./Components/CommentsThread/RfqThread";
import UserProfilePage from "./Components/End-User/UserProfilePage";
import CreateRfq from "./Components/CategoryList/CreateRfq";
import ServiceAdd from "./Components/Admin/ServiceAdd";
import MainAdmin from "./Components/Admin/MainAdmin";
import FormComponent from "./Components/Admin/FormComponent";
import VendorView from "./Components/Admin/VendorView";
import ClientView from "./Components/Admin/ClientView";
// const LoadingPage = React.lazy(() => import("./Components/skeleton/Demo"));

// const Home = React.lazy(() => import("./Components/Home"));
// const InfoPage = React.lazy(() => import("./Components/Info/InfoPage"));
import AuthContent from "./Components/InnerContent/AuthContent";
import RespondPage from "./Components/ServiceProvider/Requests/RespondsPage";
import DraftPage from "./Components/ServiceProvider/Requests/DraftPage";
import InnerContentSidebar from "./Components/InnerContent/InnerContentSidebar";
import SigninTaps from "./Components/Sign_in/SignIn_taps";
import ViewResponseLarge from "./Components/CategoryList/viewResponseLarge/viewResponseLarge";
import RfqDrafts from "./Components/CategoryList/Drafts/RfqDraft";
import AdminLogin from "./Components/Admin/AdminLogin";
import RfqOrder from "./Components/CategoryList/RfqOrders/RfqOrder";
import ServiceProviderSignup from "./Components/ServiceProvider/ServiceProviderSignup";
import ServiceProviderResignup from "./Components/ServiceProvider/ServiceProviderResignup";
import NewPdfView from "./Components/PDF/NewPdfView";
import Main from "./Components/QupAdmin/modules/main/Main";
import Dashboard from "./Components/QupAdmin/pages/Dashboard";
import Login from "./Components/QupAdmin/modules/login/Login";
import Register from "./Components/QupAdmin/modules/register/Register";
import PublicRoute from "./Components/QupAdmin/routes/PublicRoute";
import Blank from "./Components/QupAdmin/pages/AdminRFQS";
import SubMenu from "./Components/QupAdmin/pages/SubMenu";
import AdminProtectedRoutes from "./Routes/AdminProtectedRoutes";
import PrivateRoute from "./Components/QupAdmin/routes/PrivateRoute";
import InfoPage from "./Components/Info/Info_Page";
import RecoverPassword from "./Components/QupAdmin/modules/recover-password/RecoverPassword";
import RfqProcessSteps from "./Components/Homepage/RfqProcessSteps";
import CategoryCardDesign from "./Components/CategoryDesign/CategoryCardDesign";
// import AdminRFQResponse from "./Components/QupAdmin/pages/AdminRFQResp";
import ByClick from "./Components/CategoryByClick/ByClick";
// const LoadingPage = React.lazy(() => import("./Components/skeleton/Demo"));
//new admin panel

const MainRoutes = () => (
  <>
    <Routes>
      <Route path="/" element={<PublicRoutes />} />
      <Route element={<AuthContent />}>
        <Route path="/signup" element={<Signup />} />
        <Route path="/signin" element={<SigninTaps />} />
      </Route>
      <Route path="/confirm/:token" element={<Welcome />} />

      <Route element={<InnerContent />}>
        <Route index element={<Home />} />
        <Route path="/info" element={<InfoPage />} />
        <Route path="/stepper" element={<RfqProcessSteps />} />
        <Route path="/add/component" element={<CategoryCardDesign />} />
        <Route path="/verify-email" element={<VerifyEmail />} />

        <Route path="/logout" element={<Logout />} />
        <Route
          path="/user_in/local-services/business-owners/list_your_business"
          element={<ListYourBussiness />}
        />
        {/* Bussiness-onboarding */}
        {/* <Route path="/start_selling" element={<Selling />} /> */}
        {/* <Route path="/seller_onbarding/overview" element={<SellingBussinessInfo />} /> */}
        {/* <Route
          path="/local-services/business-owners/list_your_business"
          element={<ListYourBussiness />}
        /> */}

        {/* Bussiness-onboarding */}
        <Route
          path="/user_in"
          element={<ProtectedRoutes roleRequired={["USER", "ADMIN"]} />}
        >
          <Route path="/user_in/profile" element={<UserProfilePage />} />

          <Route path="/user_in/categoryList" element={<CreateRfq />} />

          {/* route to fetch only Request requested by user */}
          <Route path="/user_in/categoryTable" element={<CategoryTable />} />
          {/* route to fetch only service requested by user */}
          <Route path="/user_in/services" element={<CategoryTable />} />
          {/* <Route path="/user_in/QUP-Drafts" element={<RfqDrafts />} /> */}
          <Route
            path="/user_in/view/VendorResponse/:id"
            element={<ViewResponseLarge />}
          />
          {/* route to get approved orders */}
          <Route path="/user_in/view/Order/:id" element={<RfqOrder />} />
          {/* Bussiness-onboarding */}
          {/* <Route path="/seller_onbarding/overview" element={<SellingBussinessInfo />} /> */}

          <Route
            path="/user_in/material-ui/getting-started/installation/"
            element={<DemoNavbar />}
          />
          <Route path="/user_in/get/newpdf" element={<NewPdfView />}></Route>
        </Route>
        <Route path="/categories/content" element={<SubCategoryPage />} />
      </Route>
      {/* Admin Panel */}
      {/* <Route path="/admin-log" element={<AdminLogin />} /> */}
      {/* <Route path="/" element={<AdminRoutes roleRequired={["ADMIN"]} />}>
        <Route element={<MainAdmin />}>
          <Route path={"/admin"} element={<AdminDashboard />} />
          <Route path="/admin/serviceadd" element={<ServiceAdd />} />
          <Route path={"/admin/profile"} element={<Profile />} />
          <Route path={"/admin/form"} element={<FormComponent />} />
          <Route path={"/admin/vendor"} element={<VendorView />} />
          <Route path={"/admin/client"} element={<ClientView />} />
        </Route>
      </Route> */}

      <Route path="/">
        <Route path="/" element={<PublicRoute />}>
          <Route path="/qup/admin/login" element={<Login />} />

          <Route path="qup/admin/register" element={<Register />} />
          <Route
            path="/qup/admin/forgot-password"
            element={<RecoverPassword />}
          />
        </Route>
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/" element={<Main />}>
            <Route path="/qup/admin/" element={<Dashboard />} />
            <Route path="/qup/admin/profile" element={<QupAdminProfile />} />
            <Route path="/qup/admin/blank" element={<Blank />} />
            <Route path="/qup/admin/sub-menu-1" element={<SubMenu />} />
          </Route>
        </Route>
      </Route>
      <Route element={<AuthContent />}>
        <Route path="/vendor/signup" element={<CreateVendorAcc />} />
        <Route path="/vendor/signin" element={<CreateVendorAcc />} />
        <Route path="/vendor/newsignup" element={<ServiceProviderSignup />} />
      </Route>
      <Route
        path="/"
        element={<VendorProtectedRoutes roleRequired={["VENDOR", "ADMIN"]} />}
      >
        <Route path="/" element={<ServiceInnerContent />}>
          <Route path="/vendorManagement/*" element={<VendorDBoard />} />
          <Route element={<InnerContentSidebar />}>
            <Route
              path="vendorManagement/get_all_requests"
              element={<RespondPage />}
            />
            <Route path="vendorManagement/draft" element={<DraftPage />} />
          </Route>
          {/* <Route
            path="/vendorManagement/get_all_requests"
            element={<Sidebar />}
          /> */}
          <Route
            path="/vendorManagement/respone/created"
            element={<ResponseCreate />}
          />
          <Route
            path="/vendorManagement/view_thread/:id"
            element={<RfqThread />}
          />
        </Route>
      </Route>
      {/* later stage can be modified */}
      <Route path="/vendor" element={<ServiceInnerContent />}>
        <Route index element={<ServiceProvider />} />
        <Route path="/vendor/get_all_requests" element={<VendorRequests />} />
        <Route
          path="/vendor/get_all_requests/respond"
          element={<RespondsPage />}
        />
        <Route
          path="/vendor/postrfqresponseView/:id"
          element={<PostResponseViewPage />}
        />
        <Route
          path="/vendor/approvedrfqresponseView/:id"
          element={<ApprovedViewPage />}
        />
      </Route>
      <Route path="*" element={<PageNotFound />} />
      <Route path="vendor/resignup" element={<ServiceProviderResignup />} />
    </Routes>
  </>
);

export default MainRoutes;
