import React, { useState, useEffect } from "react";
import TimerIcon from "@mui/icons-material/Timer";
import "./timer.css";
import { Button } from "@mui/material";
import { Send } from "@mui/icons-material";

const Timer = ({ timer }) => {
  const initialDeadline = `${timer}+00:00`; //"2023-03-02T10:55:37.493+00:00";

  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [deadline, setDeadline] = useState(initialDeadline);
  // const initialExpiration = new Date();
  // initialExpiration.setMinutes(initialExpiration.getMinutes() + 3); // Increase by 2 minutes
  // const initialDeadline = initialExpiration.toISOString();

  const getTime = () => {
    const time = Date.parse(initialDeadline) - Date.now();

    if (time > 0) {
      setMinutes(Math.floor(time / 1000 / 60));
      setSeconds(Math.floor((time / 1000) % 60));
      // setDeadline(minutes, seconds);
    } else {
      setMinutes(0);
      setSeconds(0);
      // setDeadline(new Date(Date.now()+30000));
      // window.location.reload(false);

      return;
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      getTime(deadline); //set initialdeadline
    }, 1000);
    return () => clearInterval(interval);
  }, [initialDeadline]);

  return (
    <>
      <div className="Timer">
        <div className="box">
          <p id="minutes">{minutes < 10 ? `0${minutes}` : minutes}</p>
          <span className="text">minutes</span>
        </div>
        <div className="box">
          <p id="seconds">{seconds < 10 ? `0${seconds}` : seconds}</p>
          <span className="text">seconds</span>
        </div>
        <TimerIcon sx={{ width: "25px", height: "100%", color: "blue" }} />
      </div>
      {/* <Button variant="contained" color="secondary" endIcon={<Send />}>
        RESEND OTP
      </Button> */}
    </>
  );
};

export default Timer;
