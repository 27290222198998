import React, { useEffect, useState } from "react";
import ArrowRight from "../../../assets/arrow-right.svg";
import { GET_RECENT_RFQS } from "../../CompServices/Api_Routes_Helper";
import axios from "../../../api/axios";
import { Typography } from "@mui/material";
import profileimg from "../../../assets/Homepagebackground/person.png";
import { Height } from "@mui/icons-material";
const CategoryAuto = () => {
  const [getRfq, setRfqdata] = useState([]);
  const [imgData, setImgData] = useState("");
  // const rfqsRequestRef = useRef(null);
  const fetchRequests = async () => {
    try {
      const {
        data: { data },
      } = await axios.get(`${GET_RECENT_RFQS}`);
      let reversedData = data;
      for (let i = reversedData.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [reversedData[i], reversedData[j]] = [reversedData[j], reversedData[i]];
      }
      setRfqdata(reversedData.slice(0, 4));
    } catch (error) {
      const message = error.response || error.response.data.message;
      console.error("Error fetching RFQs:", message);
    }
  };

  function handleMask(params) {
    const data = params.split("@");
    const masked = data[0] + "@" + "*".repeat(data[1].length);
    return masked;
  }

  useEffect(() => {
    fetchRequests();
  }, []);
  // useEffect(() => {
  //   const [{ image }] = getRfq?.map((data) =>
  //     (data?.itemsCollection || []).flatMap((innerArr) =>
  //       (innerArr || []).map(({ image }) => image)
  //     )
  //   );

  //   // Assuming you want an array of unique image URLs
  //   const uniqueImages = [...new Set(image)].filter(Boolean);

  //   // Assuming you want to set the state to the first image URL
  //   setImgData(uniqueImages || "");
  // }, []);
  const handleMasks = (user) => {
    if (!user) {
      return ""; 
    }
    const [username, domain] = user.split("@");
    const maskedDomain = domain ? "*".repeat(domain.length) : "";
    return `${username}@${maskedDomain}`;
  };
  return (
    <div className="col-12 " style={{ margin: "15px auto" }}>
      <div className="container ">
        <div className="category_SubDes_head mb-2">
          <h2 className="cat_SD_title">Categories Served</h2>
          <p className="cat_SD_sub">
            MyQuotes works with a variety of industries, with a focus on
            markets:
          </p>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-10">
          <section className="industries_served_sec m-auto">
            <div className="cat_Cards_MainWrap industries_served_boxes">
              {getRfq.map((data, index) => {
                const { categoryName, image } = data?.itemsCollection || {};
                return (
                  <div className="cell large-6" key={index}>
                    <div className="industries_served_wrap">
                      <a href="#" title={`${categoryName}`}>
                        <div
                          className="box_main bgImg lazyloaded"
                          style={{ backgroundImage: `url(${image})` }} 
                        >
                          <div className="half-circle"></div>
                        </div>
                        <div className="industries_served_desc">
                          <div className="industrie_profile">
                            <div className="industrie_profile_img">
                              <img src={profileimg} style={{ width: "50px" }} />
                              <Typography aria-describedby="cat_email">
                                {handleMasks(data?.user)}
                              </Typography>
                            </div>
                          </div>
                          <div>
                            <label htmlFor="cat_subject">Sub </label>
                            <p
                              id="cat_subject"
                              style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                maxWidth: "100%",
                                display: "inline-block",
                                cursor: "pointer",
                              }}
                              title={data.subject}
                            >
                              {data.subject}
                            </p>
                          </div>
                          <div>
                            <label htmlFor="cat_descp">Description</label>
                            <p id="cat_descp"  style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                maxWidth: "100%",
                                display: "inline-block",
                                cursor: "pointer",
                              }}>{data.description}</p>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <label htmlFor="cat_posted">Posted on</label>
                            <p id="cat_posted" className="cat_posted">
                              {new Date().toDateString()}
                            </p>
                          </div>

                          <div className="arrow-right">
                            <img
                              className=" ls-is-cached lazyloaded"
                              src={ArrowRight}
                              alt=""
                            />
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                );
              })}
            </div>
          </section>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default CategoryAuto;
