import React, { useEffect, useState, useRef } from "react";
import { TweenMax, Power3 } from "gsap";
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  CardActionArea,
  IconButton,
} from "@mui/material";
//choices for Navbar
import axios from "../api/axios";
import {
  ADMIN_GET,
  API_AUTH_CUR_USER,
  API_AUTH_URL,
  GET_RECENT_RFQS,
} from "./CompServices/Api_Routes_Helper";
import authHeader from "../Services/auth-header";
import { useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { Card, Carousel } from "antd";
import {
  QupFeatuers,
  services_offered_select,
} from "./End-User/User-Data/MenuItems";
import Slider from "react-slick";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import Footer from "./Footer/index";
import { Link, useNavigate } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";
// import Background from "./Homepage/Background";
import SlidingBackgroundSection from "./Homepage/SlidingBackgroundSection ";
import AutoPlaySlider from "./Homepage/AutoPlaySlider ";
import AskService from "./Homepage/QUPQueryBox/AskService";
import { onAuthStateChanged, getAuth, updateProfile } from "firebase/auth";
import { LazyLoadImage } from "react-lazy-load-image-component";
// import CardDemo from "./Homepage/Clientcard.jsx";
import "react-lazy-load-image-component/src/effects/blur.css";
import Guider from "./Homepage/Guider";
import CustomScroller, { StyledButton } from "./Syledcomponent/StyleFiled.jsx";
import InfoCard from "./Homepage/DesignerCards";
import RecentActivity from "./Homepage/RecentActivity";
import RfqProcessSteps from "./Homepage/RfqProcessSteps";
import { removeWindowClass } from "./QupAdmin/utils/helpers";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import dobaleImg_svg from "../assets/counter.jpg";
import AddContent from "./Homepage/AddContent";
import CountUp, { useCountUp } from "react-countup";

import TypeWriter from "./Homepage/TypeWriter/TypeWriter";
import ScrollTop from "./CategoryDesign/ScrollTop";
import CategoryAuto from "./Homepage/ServiceCategory";

import RecentRFQCards from "./CategoryByClick/RecentRFQCards";
import FixedbackgroundImage from "./Homepage/FixedbackgroundImage";
// import ScrollLine from "./CategoryDesign/ScrollLine";
const dotPosition = "bottom";

const title = {
  pageTitle: "QuoteUrPrice",
};

gsap.registerPlugin(ScrollTrigger);

const Home = (props) => {
  const [getRfq, setRfqdata] = useState([]);
  const [carouselData, setCarouselValue] = useState([]);
  //for selecting categories
  const [categoryData, setCategoryData] = useState([]);
  // const [email, setEmail] = useState(null);
  const navigate = useNavigate();
  const auth = getAuth();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  useEffect(() => {
    getCaroselValue();
    fetchRequests();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      // if (isLoggedIn) {
      const data = await localStorage.getItem("userNew");
      const userData = JSON.parse(data);
      let userEmail;
      if (userData?.res?.email) {
        userEmail = userData?.res?.email;
        console.log("system auth ", userEmail);
      } else {
        onAuthStateChanged(auth, async (user) => {
          if (user) {
            const { email, uid } = user;
            userEmail = email;
            // setEmail(userEmail);
            await sendUserInfo({ email, uid });
          }
        });
        // const user = auth.currentUser;

        // if (user && user.providerId === "firebase") {
        //   const { email, uid } = user;
        //   userEmail = email;
        //   await sendUserInfo({ email, uid });
        // }
      }
      // setEmail(userEmail);
    };
    fetchData();
  }, [isLoggedIn]);
  //Most Recent RFQ's form backend
  const fetchRequests = async () => {
    try {
      const { data } = await axios.get(`${GET_RECENT_RFQS}`);
      const reversedData = data.data;
      setRfqdata(reversedData);
    } catch (error) {
      console.error("Error fetching RFQs:", error);
    }
  };
  function storeUserInfo(_id: any, email: any) {
    localStorage.setItem("userID", _id);
    localStorage.setItem("email", email);
  }
  const sendUserInfo = async (user) => {
    try {
      const { data } = await axios.post(
        `${API_AUTH_URL}firebase/new/user`,
        user,
        { headers: await authHeader() }
      );
      if (data !== null) {
        const { email, _id } = data?.newUser;
        storeUserInfo(_id, email);
      }
    } catch (error) {
      console.error("error updating firebase user", error);
      if (error.response && error.response.status === 409) {
        const currentUser = error.response?.data?.currentUser;
        currentUser.forEach(({ email, _id }) => {
          storeUserInfo(_id, email);
        });
      }
    }
  };

  const getCaroselValue = async () => {
    let categoryData = {};
    try {
      const response = await axios.get(`${ADMIN_GET}`);
      const responseData = response.data.data;
      setCarouselValue(responseData);
      const serviceData = response.data.data?.map((service) => ({
        _id: service._id,
        categoryName: service.categoryName,
      }));
      setCategoryData(serviceData);
    } catch (error) {
      const { data } = error.response;
      console.error(error);
    }
  };
  // gsap.registerPlugin(ScrollTrigger);
  //animation using gsap//

  const settings = {
    dots: false,
    infinite: true,
    autoplay: false,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 2,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };
  useEffect(() => {
    removeWindowClass("login-page");
    removeWindowClass("register-page");
    removeWindowClass("hold-transition");
  }, []);

  // const textRef = useRef(null);
  const doableAnime = useRef();
  // useEffect(() => {
  //   const textElement = textRef.current;
  //   gsap.fromTo(
  //     textElement,
  //     {
  //       x: "100vw",
  //       xPercent: -100,
  //       color: "yellow",
  //       duration: 2,
  //     },
  //     {
  //       x: "0vw",
  //       xPercent: 0,
  //       duration: 2,
  //       color: "white",
  //       ease: "elastic",
  //       scrollTrigger: {
  //         trigger: textElement,
  //         once: true,
  //       },
  //     }
  //   );
  // }, []);
  //to animate doable screen component
  useEffect(() => {
    const el = doableAnime.current;
    gsap.from(el, {
      opacity: 0,
      y: 50,
      duration: 2,
      backgroundColor: "silver",
      scrollTrigger: {
        trigger: el,
      },
    });
  }, []);

  return (
    <>
      {/* <ScrollLine /> */}

      <div className="Home_fullpage">
        <FixedbackgroundImage />
        <div className="your-target-element">
          <div className="container_card ">
            <div
              className="qup_banner_design"
              role="banner"
              aria-roledescription="contains App features and banner "
            >
              <section className="budiness_part_home w-100 h-100">
                <div className="qup_mainBussinessWrap ">
                  <h4 className="extra_detailes">
                    Experience procurement at its best!
                  </h4>
                  <div
                    className="qup_mainBussinessMobile slide-up"
                    style={{ marginTop: "-70px" }}
                  >
                    <h4 className="slide-up qup-solution ">
                      A solution built for business
                    </h4>
                    <h2 className="reveal-type slide-up">
                      Upgrade to a curated experience to create your procurement
                      needs to get the best offers from your vendors.
                    </h2>
                    <ul>
                      {QupFeatuers.map((text, index) => (
                        <li key={index} className="slide-up">
                          <TaskAltIcon
                            style={{ color: "blueviolet", marginRight: "7px" }}
                          />

                          {text}
                        </li>
                      ))}
                    </ul>
                    <div className="qup_divBtn slide-up">
                      <StyledButton>Post your Requirements</StyledButton>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div
              className="myuquotes_joinnow w-100 bg_black_bck"
              ref={doableAnime}
            >
              <div className="myuquotes_joinnow_imgWrap d-block d-sm-block">
                <img src={dobaleImg_svg} alt="features description" />
              </div>
              <div className="myuquotes_joinnow_back">
                <div className="join_myquotes">
                  <h4>Suddenly it's all so doable.</h4>
                  <div className="active_members">
                    <div className="active_members_wrap">
                      <div>
                        <i className="fa-solid fa-users"></i>
                        {/* <Typography component={"h4"}> */}
                        <p className="counter-number">
                          <CountUp
                            start={0}
                            end={3000}
                            suffix="+"
                            separator=","
                            duration={3}
                            enableScrollSpy={true}
                            scrollSpyDelay={1000}
                            scrollSpyOnce={true}
                          ></CountUp>
                        </p>
                        {/* </Typography> */}
                        <p>Active users</p>
                      </div>
                      <div>
                        <i className="fa-solid fa-shop"></i>
                        {/* <Typography component={"h4"}> */}
                        <p className="counter-number">
                          <CountUp
                            start={0}
                            end={300}
                            suffix="+"
                            duration={3}
                            enableScrollSpy={true}
                            scrollSpyDelay={1500}
                            scrollSpyOnce={true}
                          ></CountUp>
                        </p>
                        {/* </Typography> */}
                        <p>Active vendors</p>
                      </div>
                      <div>
                        <i className="fa-solid fa-user"></i>
                        {/* <Typography component={"h4"}> */}
                        <p className="counter-number">
                          <CountUp
                            start={0}
                            end={1000}
                            suffix="+"
                            duration={3}
                            enableScrollSpy={true}
                            scrollSpyDelay={2000}
                            scrollSpyOnce={true}
                          ></CountUp>
                        </p>
                        {/* </Typography> */}
                        <p>RfQ Request</p>
                      </div>
                    </div>
                  </div>

                  <Link to="/signin">Join QuoteUrPrice</Link>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="bannerSection bg_black_bck">
            <SlidingBackgroundSection />
          </div> */}
        </div>
        <section className="client_homepage" id="create_Client_rfq">
          <Container maxWidth="lg">
            <div className="client_first_div ">
              <div className="qup_serviceSelect">
                <AskService categories={categoryData} />
              </div>
            </div>
          </Container>
        </section>

        <div style={{ backgroundColor: "white" }}>
          <RecentRFQCards />
        </div>

        <div className="container_card">
          <InfoCard />
        </div>

        <div className="home-page">
          <div className="container_card">
            <div className="mostly-used-div">
              <Container maxWidth="lg">
                <h4>Mostly used Categories</h4>

                <Slider {...settings}>
                  {carouselData?.map((item, index) => (
                    <div key={index}>
                      <figure className="shape-box shape-box_half">
                        <img src={item.image} alt="rfq_img" />
                        <div className="brk-abs-overlay z-index-0 bg-black opacity-60"></div>
                        <figcaption>
                          <div className="show-cont">
                            <div className="card-main-title">
                              {item.categoryName}
                            </div>
                          </div>
                          <p className="card-content">{item.paragraph}</p>
                          <a href="#" className="read-more-btn">
                            Get Quotation
                          </a>
                        </figcaption>
                        <span className="after"></span>
                      </figure>
                    </div>
                  ))}
                </Slider>
              </Container>
            </div>
          </div>
          <ScrollTop />

          <Footer />
        </div>
      </div>
    </>
  );
};

export default Home;
