import React, { useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
const Index = () => {
  ///animation delay incards////
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    gsap.utils.toArray(".rfq_card").forEach((card) => {
      gsap.from(card, {
        y: 50,
        opacity: 0,
        duration: 1,
        scrollTrigger: {
          trigger: card,
          start: "top 80%",
          end: "bottom 20%", 
          scrub: true, 
          
        },
      });
    });
  }, []);
  return (
    <div className="card_wrapper">
      <div className="card_container">
        <div className="card_row">
          <div className="rfq_card">
            <div className="info">
              <div className="sub">RFQ Requested</div>
              <div className="title">
                The title you always wanted, now you have it.
              </div>
              <div className="index_btn">
                <button className="custom-btn btn-4" style={{ color: "black" }}>
                  <span>Learn</span>
                </button>
              </div>
            </div>
            <div className="image">
              {/* <i className="fa-solid fa-pen-nib "></i> */}
              <a className="editInfo">
                <i className="fa-solid fa-pen-nib"></i>
              </a>
            </div>
          </div>
          <div className="rfq_card">
            <div className="info">
              <div className="sub">Quotation Made</div>
              <div className="title">
                Because those titles should be good-looking.
              </div>
              {/* <button className="btn">Launch</button> */}
              <div className="index_btn">
                <button className="custom-btn btn-4" style={{ color: "black" }}>
                  <span>Launch</span>
                </button>
              </div>
            </div>
            <div className="image">
              {/* <i className="fa-solid fa-bolt "></i> */}
              <a className="editInfo">
                <i className="fa-solid fa-bolt"></i>
              </a>
            </div>
          </div>
          <div className="rfq_card">
            <div className="info">
              <div className="sub">Active Users</div>
              <div className="title">
                The title you always wanted, now you have it.
              </div>
              <div className="index_btn">
                {/* <button className="btn">Learn</button> */}
                <button className="custom-btn btn-4" style={{ color: "black" }}>
                  <span>Learn</span>
                </button>
              </div>
            </div>
            <div className="image">
              {/* <i  className="fa-solid fa-user "></i> */}
              <a className="editInfo">
                <i className="fa-solid fa-user"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
