import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  titleContainer: {
    marginTop: 12,
  },
  reportTitle: {
    fontSize: 12,
    textAlign: "left",
    textTransform: "uppercase",
    color: "blue",
  },
  reportTerms: {
    fontSize: 12,
    marginTop: 12,
    textAlign: "left",
    textTransform: "uppercase",
    color: "blue",
  },
  reportNotes: {
    marginTop: 5,
    fontSize: 8,
    textAlign: "left",
    textTransform: "uppercase",
  },
});

const InvoiceNotesTerms = () => (
  <View style={styles.titleContainer}>
    <Text style={styles.reportTitle}>Notes</Text>
    <Text style={styles.reportNotes}>
      It was greate doing business with You...
    </Text>
    <Text style={styles.reportTerms}>Terms & Conditions</Text>
    <Text style={styles.reportNotes}>
      Please make the payment by the due date...
    </Text>
  </View>
);

export default InvoiceNotesTerms;
