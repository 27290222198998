import { Box, Button, Card, Container, Grid, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import "./Respond.scss";
import axios from "../../../api/axios";
import {
  DELETE_DRAFT,
  GET_DRAFT_DETAILS,
  GET_REQUEST_DETAILS,
  MAKE_QUOTATION,
} from "../../CompServices/Api_Routes_Helper";
import CategoryListTable from "../../ReuseTable/CategoryListTable";
import { submitButton } from "../../DesignTools/ButtonStyles/ButtonStyles";
import { useNavigate } from "react-router-dom";
import CreateQupResponse from "../Responses/RowResponse/CreateQupResponse";

function DraftPage() {
  const [modalOpen, setModalOpens] = useState(false);
  const handleClickOpen = () => setModalOpens(true);
  const handleClickClose = () => setModalOpens(false);
  const id = localStorage.getItem("rowid");
  const [rfqValues, setRfqValues] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  console.log("rfqValues", rfqValues);
  const vid = localStorage.getItem("vid");
  //date formate
  const [startDate, setStartDate] = useState(new Date());
  const dateString = startDate;
  const date = new Date(dateString);
  const formattedDate = date.toLocaleDateString();
  const [month, day, year] = formattedDate.split("/");
  const updatedFormattedDate = `${day}/${month}/${year}`;

  const requitements = rfqValues?.map((item) => item.requirements);
  const requires = requitements[0];
  const id_main = rfqValues?.map((item) => item._id);
  const [updatedRequires, setUpdatedRequires] = useState(requires || []);

  const requirement_ID = requires?.map((item) => item._id);
  console.log("requirement_ID", requirement_ID);
  const specialnotes = requires?.map((data) => data.specialNotes);
  const navigate = useNavigate();

  useEffect(() => {
    getValue();
  }, [id]);

  const getValue = async () => {
    try {
      const result = await axios.get(`${GET_DRAFT_DETAILS}${id}`);
      if (result) {
        setRfqValues(result.data.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleClick = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      handleSubmit();
    }, 1000);
  };
  const handleQuotaion = (data) => {
    setUpdatedRequires(data);
  };
  useEffect(() => {
    setUpdatedRequires(requires);
  }, [requires]);

  const handleSubmit = async () => {
    const postQuotation = {
      requestID: rfqValues[0].requestID,
      customer_ID: rfqValues[0].customer_ID,
      cat_ID: rfqValues[0].cat_ID,
      vendor_ID: rfqValues[0].vendor_ID,
      requirementID: requirement_ID,
      subject: rfqValues[0].subject,
      requirements: updatedRequires,
      termsAndCondition: rfqValues[0].termsAndCondition,
    };

    await axios.post(`${MAKE_QUOTATION}`, postQuotation).then(async (e) => {
      console.log("e", e);
      for (const id of id_main) {
        try {
          await axios.delete(`${DELETE_DRAFT}${id}`);
          navigate("/vendorManagement/rfq");
          console.log("RFQ successfully deleted:", id);
        } catch (error) {
          console.error("Error deleting RFQ:", error);
        }
      }
    });
  };

  const handleDeleteDraft = async (id) => {
    await axios.delete(`${DELETE_DRAFT}${id}`).then((e) => {
      console.log("e", e);
      alert("Draft Deleted Successfully");
      navigate("/vendorManagement/get_all_requests");
    });
  };

  const [popupOpen, setPopupOpen] = useState(false);

  const openPopup = () => setPopupOpen(true);
  const closePopup = () => setPopupOpen(false);

  const handleFormSubmit = () => {
    closePopup();
  };
  const handleFormClick = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      handleFormSubmit();
    }, 1000);
  };

  return (
    <div className="main_content">
      <div>
        {rfqValues.length === 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              height: "30vh",
            }}
          >
            <Card style={{ width: "30%", padding: "20px" }}>
              <p>There are no records to display</p>

              <div className="no_recordd_button">
                <Button color="error">Go Back</Button>
              </div>
            </Card>
          </div>
        ) : (
          <Grid container className="qup_respondPage_maiWrap">
            <Grid item md={10} sm={12} lg={10} style={{ padding: "20px" }}>
              <div className="respond_ui">
                {rfqValues.map((data, index) => (
                  <Card
                    className="card_componet_update card_rounded"
                    key={index}
                  >
                    <Container>
                      <h4>Draft Details</h4>
                      <Grid container>
                        <Grid container>
                          <Grid item sm={6} md={6} className="respond">
                            <label>Subject</label>
                          </Grid>
                          <Grid item sm={6} md={6} className="respond">
                            <p>{data.subject}</p>
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item sm={6} md={6} lg={6} className="respond">
                            <label>description</label>
                          </Grid>
                          <Grid item sm={6} md={6} lg={6} className="respond">
                            <div className="descriptsion">
                              <p>{data.description}</p>
                            </div>
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item sm={6} md={6} className="respond">
                            <label>Special Note</label>
                          </Grid>
                          <Grid item sm={6} md={6} className="respond">
                            <p className="">{specialnotes}</p>
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item md={6} sm={12} lg={6} className="respond">
                            <label>Terms And Condition</label>
                          </Grid>
                          <Grid item md={6} sm={12} lg={6} className="respond">
                            <Button
                              className="button_eye"
                              onClick={handleClickOpen}
                              style={{
                                color: "black",
                                marginTop: "-7px",
                                fontSize: "14px",
                              }}
                            >
                              view &nbsp;
                              <DriveFileRenameOutlineIcon
                                style={{ fontSize: "16px" }}
                              />
                            </Button>
                          </Grid>
                        </Grid>
                        <CategoryListTable
                          itemSet={requires}
                          postQuotationActive
                          popup={openPopup}
                        />
                        <Modal
                          open={popupOpen}
                          onClose={closePopup}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <Box sx={styles}>
                            <div style={{ position: "sticky", top: "0px" }}>
                              <h3 className="h3_div_res">
                                <span>Create A Quotation</span>
                                <i
                                  className="fa fa-close"
                                  onClick={closePopup}
                                ></i>
                              </h3>
                            </div>
                            <div className="main_div_res">
                              <Grid container>
                                <Grid
                                  item
                                  md={4}
                                  sm={6}
                                  lg={4}
                                  style={{ padding: "10px" }}
                                >
                                  <label
                                    className="labelodal_res"
                                    style={{
                                      padding: "10px",
                                      fontWeight: "bold",
                                      color: "royalblue",
                                    }}
                                  >
                                    Subject
                                  </label>
                                </Grid>
                                <Grid item md={6} sm={6} lg={6}>
                                  <p
                                    style={{
                                      padding: "10px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {/* {subject} */}
                                  </p>
                                </Grid>
                              </Grid>
                              <Grid container>
                                <Grid
                                  item
                                  md={4}
                                  sm={6}
                                  lg={4}
                                  style={{ padding: "10px" }}
                                >
                                  <label
                                    className="label_odl_res"
                                    style={{
                                      padding: "10px",
                                      fontWeight: "bold",
                                      color: "royalblue",
                                    }}
                                  >
                                    Description
                                  </label>
                                </Grid>
                                <Grid item md={6} sm={6} lg={6}>
                                  <p
                                    style={{
                                      padding: "10px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {/* {description} */}
                                  </p>
                                </Grid>
                              </Grid>
                              <br />
                              <Grid
                                container
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <CreateQupResponse
                                  data={requires}
                                  setResult={handleQuotaion}
                                />
                              </Grid>
                              <div className="button_modal_res">
                                <Button
                                  variant="contained"
                                  style={submitButton}
                                  onClick={handleFormClick}
                                >
                                  {isLoading ? "" : "Submit"}
                                  {/* Rolling preloader */}
                                  {isLoading && (
                                    <div className="rolling-preloader">
                                      <div className="rolling-preloader-circle"></div>
                                    </div>
                                  )}
                                </Button>
                              </div>
                            </div>
                          </Box>
                        </Modal>

                        <Modal
                          open={modalOpen}
                          onClose={handleClickClose}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <Box sx={style} className="modal_div">
                            <div className="Respond_table-form">
                              <i
                                className="fa fa-close"
                                onClick={handleClickClose}
                              ></i>
                              <div className="table-data">
                                <table id="table">
                                  <thead>
                                    <tr>
                                      <th>Terms</th>
                                      <th>Condition</th>
                                    </tr>
                                  </thead>
                                  {rfqValues.termsAndCondition?.map(
                                    (item, i) => (
                                      <tbody key={i}>
                                        <tr>
                                          <td>{item.terms}</td>
                                          <td>{item.description}</td>
                                        </tr>
                                      </tbody>
                                    )
                                  )}
                                </table>
                              </div>
                              <br />
                            </div>
                          </Box>
                        </Modal>
                      </Grid>
                      <div className="categoryInputs">
                        <Button
                          variant="contained"
                          style={submitButton}
                          onClick={() => {
                            handleClick();
                          }}
                        >
                          {isLoading ? "" : "Send Quotation"}
                          {isLoading && (
                            <div className="rolling-preloader">
                              <div className="rolling-preloader-circle"></div>
                            </div>
                          )}
                        </Button>
                        <Button
                          variant="contained"
                          style={submitButton}
                          onClick={() => {
                            handleDeleteDraft(data._id);
                          }}
                        >
                          Delete
                        </Button>
                      </div>
                    </Container>
                  </Card>
                ))}
              </div>
            </Grid>
          </Grid>
        )}
      </div>
    </div>
  );
}
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "0px solid #000",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

const styles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "calc(100% - 120px)",
  bgcolor: "background.paper",
  border: "0px solid #000",
  borderRadius: 2,
  boxShadow: 24,
  p: 2,
  height: "550px",
  overflow: "Scroll",
};
export default DraftPage;
