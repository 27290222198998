import {
  ForwardToInbox,
  MarkAsUnread,
  Notifications,
  Person,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Icon,
  Tab,
  Tabs,
  styled,
  Typography,
} from "@mui/material";
import React, { useState} from "react";
import { Link } from "react-router-dom";
import VendorRequests from "../../../Requests/VendorRequests";
import Tables from "../../layouts/tables";
import ResponseCreate from "../../layouts/respond";
import DashboardLayout from "../LayoutContainers/DashboardLayout";
import DashboardNavbar from "./DashboardNavbar";
import PropTypes from "prop-types";
import Footer from "../Footer";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="h6">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CustomTabs = styled(Tabs)({
  display: "flex",
  justifyContent: "center",
  // marginBottom: "-30px",
  marginTop: "20px",
  // width: "95%",
  // marginLeft: "20px",
  background: "transparent",
});

const tabData = [
  {
    name: "Quotation Received",
    icon: (
      <>
        <Icon>
          <MarkAsUnread />
        </Icon>
      </>
    ),
    index: 0,
  },
  {
    name: "Quotation Approved",
    icon: (
      <>
        <Icon>
          <ForwardToInbox />
        </Icon>
      </>
    ),
    index: 1,
  },
  // {
  //   name: "Notifications",
  //   icon: (
  //     <>
  //       <Icon>
  //         <Notifications />
  //       </Icon>
  //     </>
  //   ),
  //   index: 2,
  // },
];

const CustomTab = styled(Tab)(
  // ({ isActive }) => ({
  //   fontWeight: isActive ? "bold" : "normal",
  //   color: isActive ? "white" : "inherit",
  //   background: isActive ? "lightgray" : "inherit",
  // }),
  {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "4px",
    padding: "8px 16px",
    marginRight: "8px",
    marginBottom: "8px",
    fontWeight: "bold",
    fontSize: "14px",
    textTransform: "uppercase",
    cursor: "pointer",
    // color: "lightgray",
  }
);

const RfqNavigation = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [newRequestCountFromChild, setNewRequestCountFromChild] = useState(0);
  // Define a function to receive the value from the child
  const handleNewRequestCount = (count) => {
    setNewRequestCountFromChild(count);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar newrequest={newRequestCountFromChild} />
      <div className="vendor-container">
        {/* <Box>
        {rfqRoutes.map((item, index) => (
          <Button key={index} component={Link} to={item.route}>
            {item.icon}
            {item.name}
          </Button>
        ))}
      </Box> */}
        {/* <CustomTabs value={value} onChange={handleChange} centered>
          {tabData.map((item, index) => (
            <CustomTab
              key={index}
              label={
                <>
                  {item.icon}
                  <span>
  {item.name === "Quotation Received" && newRequestCountFromChild > 0 ? (
    <>
      {item.name} <span style={{ fontWeight: 'bold', color: '#fff',position:'relative',float:'right',top:'-5px',paddingTop: '1px',paddingBottom:'1px',paddingLeft:'4px',paddingRight:'4px',backgroundColor: '#dc3545',borderRadius:'5px',fontSize:'10px', }}>{newRequestCountFromChild}</span>
    </>
  ) : (
    item.name
  )}
</span>

                </>
              }
              {...a11yProps(item.index)}
              // isActive={value === item.index}
            />
          ))} */}
          {/* <CustomTab
          label={
            <>
              <Icon>
                <MarkAsUnread />
              </Icon>{" "}
              <span>RFQ Received</span>
            </>
          }
          {...a11yProps(0)}
          isActive={value === 0}
        />
        <CustomTab
          label={
            <>
              <Icon>
                <ForwardToInbox />
              </Icon>{" "}
              <span>RFQ's Sent</span>
            </>
          }
          {...a11yProps(1)}
          isActive={value === 1}
        /> */}
          {/* <CustomTab
          label={
            <>
              <Icon>
                <Notifications />
              </Icon>{" "}
              <span>Notifications</span>
            </>
          }
          {...a11yProps(2)}
        /> */}
        {/* </CustomTabs> */}
        {/* <CustomTabPanel className="box-after-tab-panel" value={value} index={0}>
          <VendorRequests onNewRequestCount={handleNewRequestCount} />
          <p>New Request Count from Child: {newRequestCountFromChild}</p>
        </CustomTabPanel> */}
        {/* <CustomTabPanel
          className="box-after-tab-panel rfq-received-panel"
          value={value}
          index={1}
        >
          <Tables />
        </CustomTabPanel> */}
        {/* <CustomTabPanel value={value} index={2}>
        <ResponseCreate />
      </CustomTabPanel> */}
       <VendorRequests onNewRequestCount={handleNewRequestCount} />
        <Footer />
      </div>
    </DashboardLayout>
  );
};

export default RfqNavigation;