import React, { useState, useEffect } from "react";
import { Grid, Paper, Typography, Box, Button } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SearchIcon from "@mui/icons-material/Search";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import { Container } from "@mui/material";
import image1 from "../../assets/Homepagebackground/bg.webp";
import image2 from "../../assets/Homepagebackground/t8.jpg";
import image3 from "../../assets/innerpage/design-delivery.jpg";
import image4 from "../../assets/innerpage/guide.webp";
import image5 from "../../assets/innerpage/guide1.webp";
import RecentActivity from "./RecentActivity";
import Clientcard from "./Clientcard";
import WhyChoice from "./WhyChoice";
import CategoryAuto from "./ServiceCategory";
import EditAddress from "../CategoryList/EditAddress";
const steps = [
  {
    label: "Search for matches",
    description: `Search and filter from millions of product and supplier offerings to find the matching ones for your business.`,
    icon: <SearchIcon />,
  },
  {
    label: "Identify the right one",
    description:
      "Evaluate product quality and supplier capabilities easily and efficiently through verified inspections and digital sourcing tools.",
    icon: <AccountBalanceIcon />,
  },
  {
    label: "Pay with confidence",
    description: `Pay for your order in over 20 currencies via 20+ secure payment methods, including flexible payment terms.`,
    icon: <ShoppingCartIcon />,
  },
  {
    label: "Fulfill with transparency",
    description: `Get your logistics needs fulfilled with real-time tracking for 26,000+ routes across 220 countries and regions powered by Alibaba.com Logistics.`,
    icon: <VerifiedUserIcon />,
  },
  {
    label: "Manage with ease  ",
    description: `Check order status, manage suppliers, track payments and shipments, and contact after-sales support all via My Alibaba.`,
    icon: <CheckCircleIcon />,
  },
];

const imageSources = [image1, image2, image3, image4, image5];

const RfqProcessSteps = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [imageSrc, setImageSrc] = useState(imageSources[0]);

  const handleNext = () => {
    setActiveStep(
      (prevActiveStep) => (prevActiveStep + 1) % imageSources.length
    );
    setImageSrc(imageSources[activeStep + 1]);
  };

  const handleBack = () => {
    setActiveStep(
      (prevActiveStep) =>
        (prevActiveStep - 1 + imageSources.length) % imageSources.length
    );
    setImageSrc(imageSources[activeStep - 1]);
  };

  // useEffect(() => {
  //   const headingElement = document.querySelector(".heading_level");

  //   const updateStyles = ({ fontSize, fontFamily }) => {
  //     headingElement.style.fontSize = fontSize;
  //     headingElement.style.fontFamily = fontFamily;
  //   };

  //   updateStyles({
  //     fontSize: "2rem",
  //     fontFamily: "Your-Default-Font-Family",
  //   });

  //   window.addEventListener("resize", () => {
  //     const screenWidth = window.innerWidth;

  //     if (screenWidth >= 1200) {
  //       updateStyles({
  //         fontSize: "2.5rem",
  //         fontFamily: " 'Poppins', sans-serif",
  //       });
  //     } else if (screenWidth >= 600) {
  //       updateStyles({
  //         fontSize: "2rem",
  //         fontFamily: "Your-Font-Family-Medium",
  //       });
  //     } else {
  //       updateStyles({
  //         fontSize: "1.5rem",
  //         fontFamily: "Your-Font-Family-Small",
  //       });
  //     }
  //   });

  //   return () => {
  //     window.removeEventListener("resize", updateStyles);
  //   };
  // }, []);

  return (
    <div>
      <section className="section_one">
        <Container>
          <div className="New_heading">
            <h1 className="heading_level">
              Streamline ordering from search to fulfillment, all in one place
            </h1>
          </div>

          <Grid container>
            <Grid item xs={12} md={6} lg={6}>
              <Box>
                <Stepper activeStep={activeStep} orientation="vertical">
                  {steps.map((step, index) => (
                    <Step key={step.label}>
                      <StepLabel
                        className="step-label"
                        optional={
                          index === 4 ? (
                            <Typography
                              variant="caption"
                              className="step-label-caption"
                            >
                              Last step
                            </Typography>
                          ) : null
                        }
                        icon={step.icon}
                      >
                        {step.label}
                      </StepLabel>
                      <StepContent className="step-content">
                        <Typography className="custom-step ">
                          {step.description}
                        </Typography>
                        <Box sx={{ mb: 2 }}>
                          <div>
                            <Button
                              variant="contained"
                              onClick={handleNext}
                              sx={{ mt: 1, mr: 1 }}
                            >
                              {index === steps.length - 1
                                ? "Finish"
                                : "Continue"}
                            </Button>
                            <Button
                              disabled={index === 0}
                              onClick={handleBack}
                              sx={{ mt: 1, mr: 1 }}
                            >
                              Back
                            </Button>
                          </div>
                        </Box>
                      </StepContent>
                    </Step>
                  ))}
                </Stepper>
                {activeStep === steps.length && (
                  <Paper square elevation={0} sx={{ p: 3 }}>
                    <Typography>
                      All steps completed - you&apos;re finished
                    </Typography>
                  </Paper>
                )}
              </Box>
            </Grid>
            <div />
            <Grid item xs={12} md={6} lg={6}>
              <div className="new_image">
                <img src={imageSrc}></img>
              </div>
            </Grid>
          </Grid>
        </Container>
      </section>
      <div>
        <RecentActivity />
      </div>
      <div>
        <Clientcard />
      </div>
      <div style={{ position: "relative", top: "70px" }}>
        <WhyChoice />
      </div>
      <div className="row container_card" style={{ marginTop: "30px" }}>
        <Container
          maxWidth="xl"
          sx={{ backgroundColor: "white", padding: "0px" }}
        >
          <CategoryAuto />
        </Container>
      </div>
      <EditAddress />
    </div>
  );
};

export default RfqProcessSteps;
