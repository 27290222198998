import React, { useMemo } from "react";
import { Card, Paper, Button } from "@mui/material";
import DataTableBase from "../../../ReuseTable/DataTable";
import MDBox from "../../dashboard/Dcomponents/MDBox";
import MDTypography from "../../dashboard/Dcomponents/MDTypography";
import "../../Requests/Respond.scss";
import Moment from "react-moment";
const VendorTableData = ({ itemSet, post, receive }) => {
  const title = post ? "Quotation Requests" : "RFQ Requests Table";
  const bgColor = post ? "info" : "success";
  const columns = useMemo(() => {
    if (receive) {
      return [
        // {
        //   name: "id",
        //   selector: (row) => row.id,
        //   sortable: true,
        // },
        // {
        //   name: "Service",
        //   selector: (row) => row.service,
        //   sortable: true,
        // },
        {
          name: <span className="QtnTable_headers">Category Name</span>,
          // selector: (row) => {
          // return (<span>{row.subject}</span>)},
          selector: (row) => row.categoryName,
          sortable: true,
        },
        {
          name: <span className="QtnTable_headers">Items</span>,
          selector: (row) => row.items,
          sortable: true,
          grow: 1,
        },
        {
          name: <span className="QtnTable_headers">Quantity</span>,
          selector: (row) => row.quantity,
          sortable: true,
          grow: 1,
        },
        {
          name: <span className="QtnTable_headers">Unit</span>,
          selector: (row) => row.unit,
          sortable: true,
          grow: 1,
        },

        {
          name: <span className="QtnTable_headers">Price</span>,
          selector: (row) => `₹ ${Number(row.price).toLocaleString("en-IN")}`,
          sortable: true,
        },
        {
          name: <span className="QtnTable_headers">Date</span>,
          selector: (row) => row.date,
          sortable: true,
          format: (row, index) => (
            <Moment format="DD/MM/YYYY">{row.date}</Moment>
          ),
        },
        // {
        //   name: <span className="QtnTable_headers">Status</span>,
        //   selector: (row) => row.status,
        //   sortable: true,
        // },
      ];
    } else if (post) {
      return [
        {
          name: <span className="QtnTable_headers">Category Name</span>,
          // selector: (row) => {
          //   return (<span>{row.subject}</span>)},
          selector: (row) => row.categoryName,
          sortable: true,
        },
        {
          name: <span className="QtnTable_headers">Quotation Quantity</span>,
          selector: (row) => row.responseQuantity,
          sortable: true,
          cell: (row, index, column) => {
            if (row.responseQuantity === null) {
              return "-";
            }
            return row.responseQuantity;
          },
        },
        {
          name: <span className="QtnTable_headers">Quotation Price</span>,
          selector: (row) => row.responsePrice,
          sortable: true,
          cell: (row, index) => {
            if (row.responsePrice !== null) {
              return `₹ ${Number(row.responsePrice).toLocaleString("en-IN")}`;
            }
            return "-";
          },
        },
        {
          name: <span className="QtnTable_headers">Quotation Tax</span>,
          selector: (row) => {
            if (row.responseTax === null) {
              return "-";
            }
            return row.responseTax + "%";
          },
          sortable: true,
        },
        {
          name: <span className="QtnTable_headers">Quotation Date</span>,
          selector: (row) => row.responseDate,
          sortable: true,
          cell: (row, index) => {
            if (row.responseDate !== null) {
              return <Moment format="DD/MM/YYYY">{row.responseDate}</Moment>;
            }
            return "-";
          },
        },
        {
          name: <span className="QtnTable_headers">Quotation Status</span>,
          selector: (row) => row.status,
          sortable: true,
          format: (row, index) => {
            if (row.status) {
              return (
                <button className={`rfq_btn_status ${row.status}`}>
                  {row.status}
                </button>
              );
            }
          },
          // } else if (row.status == "Rejected") {
          //   return (
          //     <button className="rfq_btn_status rejected">
          //       {row.status}
          //     </button>
          //   );
          // } else {
          //   return <button className="rfq_btn_status">{row.status}</button>;
          // }
        },
        // return <button className="rfq_btn_status">{row.status}</button>;
      ];
      // Define your fds JSX here
      // return fds.props.children; // Assuming fds is a JSX element with children
    }
  }, [receive, post]);

  // const fds = <></>;

  const defaultItemSet = [
    {
      categoryName: "Default Category",
      items: "Default Item",
      quantity: 1,
      unit: "pcs",
      price: "$0.00",
      createdAt: "2023-08-21T10:00:00.000Z",
      status: "Pending",
    },
  ];

  const dataToUse = itemSet || defaultItemSet;
  return (
    <Card
      className="vendor_rfq_table"
      sx={{ width: "100%", height: "auto", p: 0, m: 0, minWidth: "100%" }}
    >
      <MDBox pt={3} pb={1}>
        <MDBox>
          <MDBox
            // mx={2}
            // mt={-3}
            // p={1}
            m={1}
            p={1}
            variant="gradient"
            bgColor={bgColor}
            borderRadius="md"
            coloredShadow="light"
          >
            <MDTypography variant="h6" fontSize="13px" color="white">
              {title}
            </MDTypography>
          </MDBox>
          <Paper>
            {itemSet && itemSet.length > 0 ? (
              <>
                <DataTableBase
                  columns={columns}
                  data={itemSet}
                  dense={false}
                  responsive={true}
                  fixedHeader
                  fixedHeaderScrollHeight="450px"
                />
              </>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "20vh",
                }}
              >
                <Card style={{ width: "30%", padding: "20px" }}>
                  <p>There are no records to display</p>
                </Card>
              </div>
            )}
          </Paper>
        </MDBox>
      </MDBox>
    </Card>
  );
};

export default VendorTableData;

VendorTableData.defaultProps = {
  itemSet: null, // Set to null or define a default value here
};
