export const ServiceData = [
  {
    service: "Painting walls,Arches,Sketches",
    shortTitle: "paint",
    startingFrom: "Rs.500",
    image:
      "https://images.unsplash.com/photo-1525909002-1b05e0c869d8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8cGFpbnR8ZW58MHx8MHx8&auto=format&fit=crop&w=400&q=60",
    description:
      "We provide high-quality painting services for both residential and commercial properties. Our team of experienced painters can help you transform your space into something beautiful and unique.",
  },
  {
    service: "Music ,orchestra,Pop ,Westeren",
    shortTitle: "Musixc",
    startingFrom: "Rs.1000",
    image:
      "https://images.unsplash.com/photo-1470225620780-dba8ba36b745?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8bXVzaWN8ZW58MHx8MHx8&auto=format&fit=crop&w=400&q=60",
    description:
      "Our music services include everything from live performances to studio recordings. Our talented musicians can help you create the perfect soundtrack for your event or project.",
  },
  {
    service: "Fredidge,Ac ,Washing Mcs",
    shortTitle: "Electircals",
    startingFrom: "Rs.700",
    image:
      "https://images.unsplash.com/photo-1661781224922-a45d5ccc1639?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8cmVmcmlnZXJhZG9yfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=400&q=60",
    description:
      "We offer a range of electrical services, including installation, maintenance, and repair of appliances and systems. Our team of certified electricians can help you keep your home or business running smoothly.",
  },
  {
    service: "clothes Sweaters,hoodeies,Sketches",
    shortTitle: "Dress",
    startingFrom: "Rs.230",
    image:
      "https://images.unsplash.com/photo-1556905055-8f358a7a47b2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8Y2xvdGhlc3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=400&q=60",
    description:
      "Our clothing services include everything from custom tailoring to wardrobe consulting. Our team of fashion experts can help you look your best for any occasion.",
  },
  {
    service: "Software,HArdware,electronics",
    shortTitle: "Instruments",
    startingFrom: "Rs.500",
    image:
      "https://images.unsplash.com/photo-1577375729152-4c8b5fcda381?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OHx8aGFyZHdhcmV8ZW58MHx8MHx8&auto=format&fit=crop&w=400&q=60",
    description:
      "Our technology services include everything from software development to hardware repair. Our team of experts can help you stay up to date with the latest tech trends.",
  },
];

export const cards = [
  {
    id: 1,
    title: "AI Artists",
    desc: "Add talent to AI",
    img: "https://images.pexels.com/photos/7532110/pexels-photo-7532110.jpeg?auto=compress&cs=tinysrgb&w=1600&lazy=load",
  },
  {
    id: 2,
    title: "Logo Design",
    desc: "Build yor brand",
    img: "https://images.pexels.com/photos/11295165/pexels-photo-11295165.jpeg?auto=compress&cs=tinysrgb&w=1600&lazy=load",
  },
  {
    id: 3,
    title: "WordPress",
    desc: "Customize your site",
    img: "https://images.pexels.com/photos/4371669/pexels-photo-4371669.jpeg?auto=compress&cs=tinysrgb&w=1600&lazy=load",
  },
  {
    id: 4,
    title: "Voice Over",
    desc: "Share your message",
    img: "https://images.pexels.com/photos/7608079/pexels-photo-7608079.jpeg?auto=compress&cs=tinysrgb&w=1600&lazy=load",
  },
  {
    id: 5,
    title: "Video Explainer",
    desc: "Engage your audience",
    img: "https://images.pexels.com/photos/13388047/pexels-photo-13388047.jpeg?auto=compress&cs=tinysrgb&w=1600&lazy=load",
  },
  {
    id: 6,
    title: "Social Media",
    desc: "Reach more customers",
    img: "https://images.pexels.com/photos/11378899/pexels-photo-11378899.jpeg?auto=compress&cs=tinysrgb&w=1600&lazy=load",
  },
  {
    id: 7,
    title: "SEO",
    desc: "Unlock growth online",
    img: "https://images.pexels.com/photos/4820241/pexels-photo-4820241.jpeg?auto=compress&cs=tinysrgb&w=1600&lazy=load",
  },
  {
    id: 8,
    title: "Illustration",
    desc: "Color you dreams",
    img: "https://images.pexels.com/photos/15032623/pexels-photo-15032623.jpeg?auto=compress&cs=tinysrgb&w=1600&lazy=load",
  },
];

export const tableLabels = [
  {
    id: 1,
    categoryName: "Category Name",
    item: "Item",
    quantity: "Quantity",
    unit: "Unit",
    price: "Expected Price",
  },
];

export const units = [
  {
    value: "Kg",
    label: "Kg",
  },
  {
    value: "Quintal",
    label: "Qntl",
  },
  {
    value: "Grams",
    label: "Grams",
  },
  {
    value: "metre",
    label: "metre",
  },
  {
    value: "Litre",
    label: "Litre",
  },
  {
    value: "Pieces",
    label: "pieces",
  },
  {
    value: "Acre",
    label: "Acre",
  },
];
