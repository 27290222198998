import React, { useState } from "react";

function MyAutoComplete({ options, onChange }) {
  const [inputValue, setInputValue] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(options);
  console.log(filteredOptions);

  const handleInputChange = (event) => {
    const newInputValue = event.target.value;
    setInputValue(newInputValue);
    const filtered = options.filter(
      (option) =>
        option.name.toLowerCase().indexOf(newInputValue.toLowerCase()) !== -1
    );
    setFilteredOptions(filtered);
  };

  const handleOptionClick = (option) => {
    setInputValue(option.label);
    onChange(option);
  };

  return (
    <div>
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        placeholder="Type to search..."
      />
      <ul>
        {filteredOptions.map((option) => (
          <li key={option.id} onClick={() => handleOptionClick(option.name)}>
            {option.name}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default MyAutoComplete;
