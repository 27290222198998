import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../Header/Header";
// import BreadcrumbsWrapper from "../BreadCrumbs/BreadcrumbsWrapper";

const InnerContent = () => {
  return (
    <div className="inner-content">
      <Header />

      <main className="main_content">
        <Outlet />
      </main>
    </div>
  );
};

export default InnerContent;
