import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "../../api/axios";
import { RFQ_VIEW_THREAD } from "../CompServices/Api_Routes_Helper";

const RfqThread = () => {
  const { id } = useParams();
  const [result, setResult] = useState([]);
  useEffect(() => {
    const fetchThreadData = async () => {
      await axios
        .get(`${RFQ_VIEW_THREAD}${id}`)
        .then((response) => {
          console.log("respone", response);
          setResult(response.data.result);
        })
        .catch((err) => console.error(err));
    };
    fetchThreadData();
  }, []);
  return (
    <div>
      <h3>RfqThread</h3>
      <div className="thread_block">
        <span>{id}</span>
        <div>
          <p>{result.categoryName}</p>
          <p>{result.isApproved}</p>
          <p>{result.subject}</p>
          <p>{result.price}</p>
        </div>
      </div>
    </div>
  );
};

export default RfqThread;
