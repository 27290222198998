import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  Modal,
  Paper,
} from "@mui/material";
import axios from "axios";
import "./Respond.scss";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { submitButton } from "../../DesignTools/ButtonStyles/ButtonStyles";
import {
  ADD_VENDOR_COMMENTS,
  DELETE_DRAFT,
  GET_DRAFT_DETAILS,
  GET_REQUEST_DETAILS,
  GET_VENDOR_COMMENTS,
  MAKE_QUOTATION,
  RFQ_REQ_RESP_INFO,
  RFQ_REQ_GETLINEITEM,
  RFQ_REQ_RESP_STATS,
  UPDATE_DRAFT,
  RFQ_REQ_RESP_INFOs,
  VENDOR_INFO,
} from "../../CompServices/Api_Routes_Helper";

import DashboardLayout from "../dashboard/examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../dashboard/examples/Navbars/DashboardNavbar";
import VendorTableData from "./vendorRequest/vendorTableData";
import VendorDraftModel from "./vendorDraft/VendorDraftModel.jsx";
import UpdateQuatationModel from "./UpdateQuatationModel";
import CreateQuatationModel from "./CreateQuatationModel";
import authHeader from "../../../Services/auth-header";
import MDBox from "../dashboard/Dcomponents/MDBox";
import MDTypography from "../dashboard/Dcomponents/MDTypography";
import VendorResponseTable from "./vendorResponse/VendorResponseTable";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import PdfDocument from "./Invoice/PdfDocument";
import BasePdfDocument from "../../PDF/BasePDF";
import InvoiceTitle from "./Invoice/InvoiceTitle";
import InvoiceSubject from "./Invoice/InvoiceSubject";
import RFQModal from "../../CategoryList/RFQModal";
import { Download } from "@mui/icons-material";
import ReuseablebothSubDes from "../../ReuseTable/ReuseablebothSubDes";
import { FaArrowLeft } from "react-icons/fa";

function RespondPage() {
  const location = useLocation();
  const id = location.state;
  const [rfqValues, setRfqValues] = useState([]);
  const [requires, setRequires] = useState([]);
  const [popupOpen, setPopupOpen] = useState(false);
  const navigate = useNavigate();
  const value = useLocation();
  const route = value.pathname.split("/").slice(1);
  const [openResponseModal, setOpenResponseModal] = useState(false);
  const handleOpenResponseMenu = (id) => {
    setOpenResponseModal(true);
  };
  const onSubmit = () => {
    window.history.back();
  };

  const openPopup = () => setPopupOpen(true);
  const closePopup = () => setPopupOpen(false);
  // const fetchData = async () => {
  //   const Result = await requirementFetcher(rfqValues.requirements);
  // };
  // const Call = async (data) => {
  //   return new Promise((resolve, reject) => {
  //     return resolve({ result: data });
  //   });
  // };
  // const requirementFetcher = async (data) => {
  //   const values = await Call(data).then((res) => {
  //     console.log(res);
  //     return res;
  //   });
  //   return values;
  // };

  // const name = rfqValues.categoryName;

  // const Requirements = requires;
  const [isLoading, setIsLoading] = useState(false);

  const v_id = localStorage.getItem("v_ID");
  const { vid, role } = JSON.parse(v_id);

  //Post Requirement
  const [postRequiremnt, setPostRequirement] = useState([]);
  const handleQuotaion = (data) => {
    setPostRequirement(data);
  };
  const handleCloseResponseMenu = () => setOpenResponseModal(false);
  //vendorInfo
  const [infoName, setInfo] = useState("");
  const [categoryName, setCategoryName] = useState([]);
  //to handle posts
  const [isPost, setIsPost] = useState(false);
  const [vendorPost, setVendorPost] = useState("");
  //to get comments
  const [comments, setComments] = useState([]);
  const [commentTxt, setCommentTxt] = useState("");
  const [popupOpens, setPopupOpens] = useState(false);
  const openPopups = () => setPopupOpens(true);
  const closePopups = () => setPopupOpens(false);
  const [resData, setResponseData] = useState([]);
  const [draftLength, setDraftLength] = useState(0);
  const [showDraft, setShowDraft] = useState(false);
  //to check response submission
  const [hasSubmittedResponse, setHasSubmittedResponse] = useState(false);
  const [result, setResult] = useState([]);

  const handleClick = (event) => {
    event.preventDefault();
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      handleSubmit();
    }, 1000);
  };

  const requirements = resData?.map((item) => item.requirements);
  const [termsAndCondition, setTerms] = useState([]);
  const [specialNote, setNotes] = useState([]);
  const [quotationLineItem, setQuotationLineItem] = useState([]);

  const [requirement, setRequirements] = useState([]);
  const [vendorSubject, setVendorSubject] = useState("");
  const [vendorDescription, setVendorDescription] = useState("");
  //state to handle o/p RFQ stats
  const vendorSubjects = resData?.map((item) => item.vendorSubject);
  const vendorDescriptions = resData?.map((item) => item.vendorDescription);
  const subject = rfqValues.mainRFQ?.subject;
  const description = rfqValues.mainRFQ?.description;

  useEffect(() => {
    if (vendorSubjects && vendorSubjects.length > 0) {
      setVendorSubject(vendorSubjects[0]);
    }

    if (vendorDescriptions && vendorDescriptions.length > 0) {
      setVendorDescription(vendorDescriptions[0]);
    }
  }, [resData]);
  // useEffect(() => {
  //   checkRFQStats();
  // }, [hasSubmittedResponse]);
  // const checkRFQStats = async () => {
  //   try {
  //     const { data } = await axios.get(`${RFQ_REQ_RESP_STATS}/${id}`, {
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     });

  useEffect(() => {
    let componentMount;
    componentMount = true;
    const getValue = async () => {
      try {
        const { data } = await axios.get(`${GET_REQUEST_DETAILS}${id}`, {
          headers: await authHeader(),
        });
        if (data && componentMount) {
          setRfqValues(data.UpdatedData);
          const { requirements } = data?.UpdatedData;
          setRequires(requirements);
        }
      } catch (error) {
        console.error(error);
        componentMount = false;
      }
    };
    getValue();
    return () => (componentMount = false);
  }, [id]);

  useEffect(() => {
    fetchDraftValue();
  }, []);

  useEffect(() => {
    // Logic that depends on draftLength and hasSubmittedResponse
    if (draftLength === 1) {
      setShowDraft(true);
    } else if (hasSubmittedResponse) {
      setShowDraft(false);
    } else {
      setShowDraft(false);
    }
  }, [draftLength, hasSubmittedResponse]);

  const fetchDraftValue = async () => {
    try {
      const { data } = await axios.get(`${GET_DRAFT_DETAILS}${id}`);

      const result = data.drafts;
      if (result !== null) {
        setResponseData(data.drafts);
        const { requirements, termsAndCondition, specialNote } = data.drafts[0];

        setTerms(termsAndCondition);
        setNotes(specialNote);
        setRequirements(requirements);
        setDraftLength(data.drafts.length);

        if (draftLength === 1) {
          setShowDraft(true);
        } else if (hasSubmittedResponse) {
          setShowDraft(false);
        } else {
          setShowDraft(false);
        }
      }
      setShowDraft(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const CheckRFQResponse = async (rowid) => {
      try {
        const { data } = await axios.get(`${RFQ_REQ_RESP_INFO}${rowid}`, {
          headers: await authHeader(),
        });
        const response = data?.data;
        if (response !== null) {
          const { structuredData } = data;
          if (structuredData.length > 0) {
            setHasSubmittedResponse(true);
            setResult(structuredData);
            setShowDraft(false);
          }
        }
        setShowDraft(true);
      } catch (error) {
        console.error(error);
        setShowDraft(true);
      }
    };
    CheckRFQResponse(id);
  }, []);

  useEffect(() => {
    let componentMount;
    componentMount = true;
    const getValue = async (rowid) => {
      try {
        const { data } = await axios.get(`${RFQ_REQ_GETLINEITEM}${rowid}`, {
          headers: await authHeader(),
        });
        if (data.data.length > 0 && componentMount) {
          const {
            vendorSubject,
            vendorDescription,
            termsAndCondition,
            specialNote,
          } = data.data[0];
          setVendorDescription(vendorDescription);
          setVendorSubject(vendorSubject);
          setTerms(termsAndCondition);
          setNotes(specialNote);
          setQuotationLineItem(data.data);
        }
      } catch (error) {
        console.error(error);
        componentMount = false;
      }
    };
    if (componentMount) {
      getValue(id);
    }
    return () => (componentMount = false);
  }, []);

  //get vendor details
  useEffect(() => {
    const vendorDetail = async () => {
      try {
        const { data } = await axios.get(`${VENDOR_INFO}/${vid}`);
        const dataInfo = data?.data?.username;
        const Cname = data?.data?.category;
        setInfo(dataInfo);
        setCategoryName(Cname);
      } catch (error) {
        console.error(error);
      }
    };
    vendorDetail();
  }, []);

  const updateId = resData[0]?._id;

  const createQtn = async (Qtndata) => {
    try {
      const { data, status } = await axios.post(`${MAKE_QUOTATION}`, Qtndata, {
        headers: await authHeader(),
      });
      if (data !== null && status === 201) {
        return data?.message;
      }
    } catch (error) {
      console.error(error);
      throw new Error("Error adding RFQ Qtn data. Please try again later.");
    }
  };
  const deleteDraft = async (updateId) => {
    try {
      await axios.delete(`${DELETE_DRAFT}${updateId}`);
    } catch (error) {
      console.error("Error deleting RFQ draft:", error);
      throw new Error("Error deleting RFQ draft. Please try again later.");
    }
  };
  const handleSubmit = async () => {
    try {
      const postQuotationData = {
        requestID: rfqValues._id,
        customer_ID: rfqValues.customer_ID,
        cat_ID: rfqValues.cat_ID,
        vendor_ID: vid,
        vendorName: infoName,
        categoryName: categoryName,
        subject: vendorSubject,
        description: vendorDescription,
        requirements: requirement,
        termsAndCondition: termsAndCondition,
        specialNote: specialNote,
        requirement_ID: await postRequiremnt.map((data) => data._id),
      };
      const createQuotation = await createQtn(postQuotationData);
      toast.success(createQuotation, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
        className: "custom-toast",
        onClose: async () => {
          await deleteDraft(updateId);
          navigate("/vendorManagement/rfq");
        },
      });
    } catch (error) {
      console.error("error occured during qtn creation", error);
      toast.error(error.message);
    }
  };

  //comment///
  // function to add comments
  async function handlepost() {
    try {
      const response = await axios.post(`${ADD_VENDOR_COMMENTS}`, {
        vid: rfqValues.vendor_ID,
        message: vendorPost,
        rfqId: rfqValues._id,
        userId: rfqValues.vendor_ID,
      });
      if (response) {
        setIsPost(true);
        setVendorPost("");
      }
    } catch (error) {
      console.error(error);
    }
  }
  useEffect(() => {
    // Fetch comments on initial component mount
    getComments();
  }, []);
  useEffect(() => {
    if (isPost) {
      getComments();
      setIsPost(false);
    }
  }, [isPost]);
  const getComments = async () => {
    try {
      const response = await axios.get(`${GET_VENDOR_COMMENTS}${id}`);
      if (response && response.data) {
        setComments(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const setDatas = (data) => {
    closePopups();
    fetchDraftValue();
  };

  const setPostReq = (data) => {
    fetchDraftValue();
    closePopup();
  };
  const goBackby = () => {
    window.history.back();
  };

  return (
    <div>
      <DashboardLayout>
        <DashboardNavbar />
        <Container>
          <Grid container className="qup_respondPage_mainWrap">
            <Grid item md={12} sm={12} lg={12} style={{ padding: "10px" }}>
              <div className="respond_ui">
                <Card className="card_componet_update card_rounded">
                  <Container maxWidth="lg">
                    <FaArrowLeft className="backarrow" title="Back" onClick={goBackby}/>
                    <Box>
                     <h4 className="rfq-details-heading">Request Details</h4>
                      <Grid item sm={12} md={12} lg={12}>
                        <Grid container>
                          <Grid
                            item
                            sm={12}
                            md={12}
                            lg={12}
                            className="reusetable_alter"
                          >
                            <ReuseablebothSubDes
                              title={"Client Request"}
                              subject={subject}
                              description={description}
                            />
                          </Grid>
                        </Grid>
                        <br />
                        <Grid container>
                          <Grid
                            item
                            sm={12}
                            md={12}
                            lg={12}
                            className="reusetable_alters"
                          >
                            {(quotationLineItem &&
                              quotationLineItem.length === 1) ||
                            (draftLength && draftLength === 1) ? (
                              <>
                                <ReuseablebothSubDes
                                  title={"Vendor Response"}
                                  subject={vendorSubject}
                                  description={vendorDescription}
                                />
                              </>
                            ) : (
                              <></>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <h4 className="rfq-details-heading">
                          Requirements Requested
                        </h4>
                        <Grid item xs={12}>
                          {draftLength === 1 ? (
                            <React.Fragment>
                              <VendorDraftModel
                                itemSet={requirement}
                                popupOpens={openPopups}
                              />
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <VendorTableData itemSet={requires} receive />
                            </React.Fragment>
                          )}
                        </Grid>
                      </Grid>
                      {hasSubmittedResponse && (
                        <section>
                          <Card
                            className="vendor_rfq_table"
                            sx={{ width: "100%", height: "auto", p: 0, mt: 2 }}
                          >
                            <VendorTableData itemSet={result} post />
                          </Card>
                        </section>
                      )}
                      {termsAndCondition.length > 0 && (
                        <div style={{ marginTop: "3rem" }}>
                          <div className="border-text">
                            <h6 className="headding">Terms & Description</h6>
                            <div className="border-terms-des scrollables-content">
                              <div className="Respond_tables-form">
                                <div className="table-data">
                                  <table id="table">
                                    <thead>
                                      <tr>
                                        <th>Terms</th>
                                        <th>Description</th>
                                      </tr>
                                    </thead>
                                    {termsAndCondition?.map((item, i) => (
                                      <tbody key={i}>
                                        <tr>
                                          <td>{item.terms}</td>
                                          <td>{item.description}</td>
                                        </tr>
                                      </tbody>
                                    ))}
                                  </table>
                                </div>

                                <br />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      <>
                        {specialNote.length > 0 ? (
                          <div style={{ marginTop: "3rem" }}>
                            <div className="border-text">
                              <h6 className="headding">Special Notes</h6>
                              <div className="border-terms-des ">
                                <div className="Respond_tables-form scrollables-content">
                                  <div className="table-data">
                                    <table id="table">
                                      <thead>
                                        <tr>
                                          <th>Special Note</th>
                                        </tr>
                                      </thead>
                                      {specialNote?.map((item, i) => (
                                        <tbody key={i}>
                                          <tr>
                                            <td>{item.special}</td>
                                          </tr>
                                        </tbody>
                                      ))}
                                    </table>
                                  </div>

                                  <br />
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </>
                      <RFQModal
                        open={popupOpens}
                        onClose={closePopups}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        tableHeader={"Create a Quotation"}
                      >
                        <UpdateQuatationModel
                          resData={resData}
                          postRequiremnts={requirement}
                          setDatas={setDatas}
                          subject={subject}
                          description={description}
                          rfqValues={rfqValues}
                        />
                      </RFQModal>
                      <div className="categoryInputs">
                        {hasSubmittedResponse ? (
                          <></>
                        ) : (
                          <>
                            {draftLength === 1 ? (
                              <>
                                <Button
                                  variant="contained"
                                  style={submitButton}
                                  onClick={(e) => handleClick(e)}
                                  disabled={isLoading}
                                >
                                  {" "}
                                  {/* <IoSend /> */}
                                  &nbsp;
                                  {isLoading ? "" : "Send Quotation"}
                                  {isLoading && (
                                    <div className="rolling-preloader">
                                      <div className="rolling-preloader-circle"></div>
                                    </div>
                                  )}
                                </Button>
                              </>
                            ) : (
                              <Button
                                variant="contained"
                                style={submitButton}
                                onClick={openPopup}
                                disabled={hasSubmittedResponse}
                              >
                                Create Quotation
                              </Button>
                            )}
                          </>
                        )}
                      </div>
                      <Box className="invoicequation">
                        <div
                          style={{
                            textAlign: "center",
                            marginRight: "100px",
                          }}
                        >
                          <div className="download-link">
                            <PDFDownloadLink
                              className="qup_pdf_mainWrap"
                              document={<BasePdfDocument qtndata={result} />}
                              fileName={"QUP-Quotation.pdf"}
                            >
                              <Download />
                              &nbsp;&nbsp;Download Quotation
                            </PDFDownloadLink>
                          </div>
                        </div>
                      </Box>
                      <Button
                        style={submitButton}
                        onClick={handleOpenResponseMenu}
                        sx={{ marginBottom: "1rem" }}
                      >
                        {/* <IoEye /> */}
                        &nbsp; View Quotation
                      </Button>

                      <RFQModal
                        open={openResponseModal}
                        onClose={handleCloseResponseMenu}
                        tableHeader="Quick View of Quotation"
                      >
                        <PDFViewer
                          width={"100%"}
                          height={"200%"}
                          showToolbar={false}
                        >
                          <BasePdfDocument qtndata={result}></BasePdfDocument>
                        </PDFViewer>
                      </RFQModal>
                      <RFQModal
                        open={popupOpen}
                        onClose={closePopup}
                        aria-labelledby="modal-modal-title"
                        tableHeader={"Create a Quotation"}
                        aria-describedby="modal-modal-description"
                      >
                        <CreateQuatationModel
                          resData={resData}
                          handleQuotaion={handleQuotaion}
                          postRequiremnt={postRequiremnt}
                          subject={subject}
                          description={description}
                          requires={requires}
                          rfqValues={rfqValues}
                          setPostReq={setPostReq}
                        />
                      </RFQModal>
                    </Box>
                  </Container>
                </Card>
                {/* {showToast && <ToastMessage onClose={handleToastClose} />} */}
                <ToastContainer />
              </div>
            </Grid>

            {/* <Grid item md={5} sm={12} lg={5}>
          <div className="comment_part">
            <Grid container>
              <Grid item lg={12}>
                {
                  <>
                    <h4>Post's Appear here</h4>
                    {comments.map((comment, index) => {
                      return (
                        <Grid container key={index}>
                          <Grid item sm={12} md={10} lg={10}>
                            <Card>
                              <Box
                                sx={{
                                  display: "block",
                                  padding: "20px",
                                  alignItems: "center",
                                  width: "100%",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyItems: "center",
                                    flexDirection: "row",
                                    gap: "20px",
                                  }}
                                >
                                  <Avatar variant="rounded" alt="user-avatar" />
                                  <TextField
                                    multiline
                                    minRows={3}
                                    id="outlined-multilined"
                                    placeholder="Add a comment"
                                    value={comment.message}
                                    onChange={(e) => {
                                      setCommentTxt(e.target.value);
                                    }}
                                  />
                                  <Button
                                    size="large"
                                    sx={{
                                      bgcolor: "custom.moderateBlue",
                                      color: "neutral.white",
                                      p: "8px 25px",
                                      "&:hover": {
                                        bgcolor: "custom.lightGrayishBlue",
                                      },
                                    }}
                                    onClick={(e) => {
                                      e.preventDefault();

                                      setCommentTxt("");
                                    }}
                                  >
                                    Send
                                  </Button>
                                </div>
                              </Box>
                            </Card>
                          </Grid>
                        </Grid>
                      );
                    })}
                    {comments.length === 0 && <p>No Comments available</p>}
                  </>
                }
              </Grid>
              <Grid item lg={6} md={12} sm={12}>
                <Box>
                  <Typography component={"span"} variant="h6">
                    Comments
                  </Typography>
                  <div
                    className="comment_container"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      gap: "20px ",
                    }}
                  >
                    <textarea
                      aria-label="vendor_comments"
                      rows={8}
                      onChange={(e) => setVendorPost(e.target.value)}
                      value={vendorPost}
                      placeholder="Post a Comment here"
                      style={{
                        padding: "15px 10px",
                        width: "420px",
                        height: "100px",
                      }}
                    ></textarea>
                    <Button
                      // style={{
                      //   display: "inline-block",
                      //   backgroundColor: "whitesmoke",
                      //   color: "black",
                      //   padding: "10px 7px",
                      //   width: "420px",
                      // }}
                      className="comment_button"
                      style={submitButton}
                      onClick={handlepost}
                    >
                      Submit
                    </Button>
                  </div>
                </Box>
              </Grid>
            </Grid>
          </div>
        </Grid> */}
          </Grid>
        </Container>
      </DashboardLayout>
    </div>
  );
}
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "calc(100% - 400px)",
  bgcolor: "background.paper",
  border: "0px solid #000",
  borderRadius: 2,
  boxShadow: 24,
  // p: 2,
  height: "550px",
  overflow: "Scroll",
};

export default RespondPage;
