import { UserManager, UserManagerSettings } from "oidc-client-ts";
import { sleep } from "./helpers";
import axios from "axios";
import { ADMIN_SIGNIN, host } from "../../CompServices/Api_Routes_Helper";

declare const FB: any;

const GOOGLE_CONFIG: UserManagerSettings = {
  authority: "https://accounts.google.com",
  client_id:
    "533830427279-cspigijdu0g50c7imca5pvdbrcn2buaq.apps.googleusercontent.com",
  client_secret: "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",
  redirect_uri: `${window.location.protocol}//${window.location.host}/callback`,
  scope: "openid email profile",
  loadUserInfo: true,
};

export const GoogleProvider = new UserManager(GOOGLE_CONFIG);

export const facebookLogin = () => {
  return new Promise((res, rej) => {
    let authResponse: any;
    FB.login(
      (r: any) => {
        if (r.authResponse) {
          authResponse = r.authResponse;
          FB.api(
            "/me?fields=id,name,email,picture.width(640).height(640)",
            (profileResponse: any) => {
              authResponse.profile = profileResponse;
              authResponse.profile.picture = profileResponse.picture.data.url;
              res(authResponse);
            }
          );
        } else {
          console.log("User cancelled login or did not fully authorize.");
          rej(undefined);
        }
      },
      { scope: "public_profile,email" }
    );
  });
};

export const getFacebookLoginStatus = () => {
  return new Promise((res, rej) => {
    let authResponse: any = {};
    FB.getLoginStatus((r: any) => {
      if (r.authResponse) {
        authResponse = r.authResponse;
        FB.api(
          "/me?fields=id,name,email,picture.width(640).height(640)",
          (profileResponse: any) => {
            authResponse.profile = profileResponse;
            authResponse.profile.picture = profileResponse.picture.data.url;
            res(authResponse);
          }
        );
      } else {
        res(undefined);
      }
    });
  });
};
export async function adminReg(dataBody: any) {
  try {
    const { data } = await axios.post(
      `${host}/api/admin/qup/add/admin`,
      dataBody
    );

    return data;
  } catch (error: any) {
    console.error(error);
    //for 409 status code
    if (error?.response && error?.response.status === 409) {
      const errdata = error?.response?.data;
      throw new Error(errdata.message);
    } else {
      //for status code 500
      throw new Error(error?.response?.data?.message);
    }
  }
}
export const authLogin = async (email: string, password: string) => {
  try {
    const details = {
      email: email,
      password: password,
    };
    const { data, status } = await axios.post(ADMIN_SIGNIN, details);
    if (status === 200 && data !== null) {
      const auth = data?.info;
      localStorage.setItem(
        "authentication",
        JSON.stringify({
          profile: { email: auth, role: "ADMIN", isAdmin: true },
        })
      );
      return { profile: { email: auth, role: "ADMIN", isAdmin: true } };
    }
  } catch (error: any) {
    console.error(error);
    const { message } = error?.response?.data;
    if (error?.response && error?.response?.status === 404) {
      throw new Error(message);
    } else if (error?.response && error?.response?.status === 401) {
      throw new Error(message);
    } else {
      throw new Error(error.message);
    }
  }
};

export const getAuthStatus = () => {
  return new Promise(async (res, rej) => {
    await sleep(500);
    try {
      let authentication = localStorage.getItem("authentication");
      if (authentication) {
        authentication = JSON.parse(authentication);
        return res(authentication);
      }
      return res(undefined);
    } catch (error) {
      return res(undefined);
    }
  });
};
