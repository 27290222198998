import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import newQupLogo from "../../assets/Homepagebackground/qup-new2.png";
const AuthHeader = () => {
  const lastScrollTop = useRef(0);

  const [isNavbarVisible, setIsNavbarVisible] = useState(true);

  useEffect(() => {
    const scrollfunc = () => {
      var { scrollY } = window;
      if (scrollY > lastScrollTop.current) {
        // downward scroll
        setIsNavbarVisible(false);
      } else if (scrollY < lastScrollTop.current) {
        // upward scroll
        setIsNavbarVisible(true);
      } // else was horizontal scroll
      lastScrollTop.current = scrollY <= 0 ? 0 : scrollY;
    };

    window.addEventListener("scroll", scrollfunc);
    return () => {
      window.removeEventListener("scroll", scrollfunc);
    };
  }, []);

  return (
    <>
      <nav className={`auth_nav ${isNavbarVisible ? "visible" : ""}`}>
        <Link to={"/"} className="qup_logo_link">
          <div className="qup_logo">
            <span className="qup_logo_wrap d-flex">
              <img
                src={newQupLogo}
                alt="QuoteUrPrice"
                style={{
                  objectFit: "contain",
                  backgroundPosition: "center",
                  borderRadius: 10,
                }}
              />
            </span>
          </div>
        </Link>
      </nav>
    </>
  );
};

export default AuthHeader;
