import React, { useState, useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import styled, { css } from "styled-components";
import { FaSearch } from "react-icons/fa";
const GoBtn = styled.button`
  width: fit-content;
  min-width: 200px;
  height: 55px;
  font-size: 14px;
  background-color: red;
  outline: none;
  border: 0px solid transparent;
  color: black;
  cursor: pointer;
  margin-top: 17px;
  display: inline-block;
  container-type: inline-size;
  -webkit-box-align: center;
  align-items: center;
  font-weight: 500;
  padding: 0.6rem 0px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  transition: all 300ms ease-in-out 0s;
  // /* border-radius: 20px;
  &:hover {
    filter: brightness(0.85);
    background-color: var(--blue-4);
    color: white;
  }
  &:active {
    backround-color: var(--blue-5);
    color: white;
  }
  @media screen and (max-width: 790px) {
    width: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
`;

const AskService = ({ categories }) => {
  const [selectedID, setSelectedId] = useState(null);
  const [showPlaceholder, setShowPlaceholder] = useState(true);
  const [inputData, setInputData] = useState({
    cat_Id: selectedID,
    categoryName: "",
    requirement: "",
  });
  const [values, setValues] = useState([]);

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    if (name === "categoryName") {
      const selectedCategory = categories.find(
        (option) => option.categoryName === value
      );
      const selectedId = selectedCategory ? selectedCategory._id : null;
      setSelectedId(selectedId);
      setInputData({ ...inputData, cat_Id: selectedId, categoryName: value });
    } else {
      setInputData({
        ...inputData,
        requirement: value,
      });
    }
  };
  const customTextFieldStyles = {
    "&:hover fieldset": {
      borderColor: "initial", // Set the border color to its default
    },
  };
  const navigate = useNavigate();
  const handleSearch = () => {
    navigate(
      `/user_in/categoryList?s=${inputData.cat_Id}&q=${
        inputData.requirement
      }&categoryName=${encodeURIComponent(inputData.categoryName)}`
    );
  };
  const [labelStyles, setLabelStyles] = useState({
    color: "#333",
    fontSize: "16px",
    fontWeight: "bold",
  });

  const handleTextFieldClick = () => {
    // Change label styles when the TextField is clicked
    setLabelStyles({
      color: "red", // Change the color to red
      fontSize: "18px", // Change the font size to 18px
      fontWeight: "normal", // Change the font weight to normal
    });
  };
  ///animation in text///
  const onClickPlaceholderHandler = () => {
    setShowPlaceholder(false);
    document.querySelector(".new_inputbox").focus();
  };
  const handleResize = () => {
    const minWidthToShowPlaceholder = 1200;
    setShowPlaceholder(window.innerWidth > minWidthToShowPlaceholder);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  ///animation in text///

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    gsap.from(".edit-label", {
      opacity: 0,
      y: 20,
      duration: 1,
      scrollTrigger: {
        trigger: ".edit-label",
        start: "top 80%",
        end: "bottom 20%",
        scrub: true,
      },
    });
  }, []);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <div className="my_developing_1">
        <p className="edit-label edit-label-2 ">
          Request here for Quotations and choose from the best offers...
        </p>
      </div>
      <div className="my_developing">
        <Container maxWidth={"lg"} style={{ paddingBottom: "7px" }}>
          {/* <Grid container>
            <div
              className="my_develop_GridWrap"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            ></div>
          </Grid> */}
          <Grid container>
            <Grid item lg={12} xs={12} md={12}>
              <div className="ask_service_Wrap">
                <input
                  type="text"
                  name="qup_search"
                  placeholder="What are you looking for ?"
                  // placeholder={
                  //   showPlaceholder ? "" : "What are you looking for?"
                  // }
                  value={inputData.requirement}
                  onChange={onChangeHandler}
                  className="new_inputbox new_inputts"
                />
                <TextField
                  name="categoryName"
                  // label="Select Category Service"
                  InputLabelProps={{
                    className: "custom-label",
                    style: {
                      color: "gray",
                      fontSize: "14px",
                      fontWeight: "100",
                      // Add more styles as needed
                    },
                  }}
                  select
                  value={inputData.categoryName}
                  onChange={onChangeHandler}
                  className="select-catt"
                  style={
                    {
                      // width: "100%",
                      // backgroundColor: "white",
                      // minHeight: "45px",
                      // marginTop: "10px",
                      // // borderRadius: "2rem",
                      // textAlign: "center",
                      // padding: "15.5px 14px"
                    }
                  }
                  SelectProps={{
                    MenuProps: {
                      style: { maxHeight: "320px" },
                    },
                    displayEmpty: true,
                    renderValue: (value) => (
                      <span
                        style={{
                          color: value === "" ? "gray" : "black",
                          fontWeight: value === "" ? "500" : "500",
                          fontSize: value === "" ? "14px" : "14px",
                        }}
                      >
                        {value === "" ? "Select Category Service" : value}
                      </span>
                    ),
                    // borderRadius: "25px",
                  }}
                  InputProps={{
                    className: "qup_CatSelect custom-textfield",
                    sx: customTextFieldStyles,
                  }}
                >
                  {categories.length > 0 ? (
                    categories.map((option) => (
                      <MenuItem key={option._id} value={option.categoryName}>
                        {option.categoryName}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="">No categories available</MenuItem>
                  )}
                </TextField>

                {/* <FaSearch className="search-icon" /> */}
                <div className="post-but-mobile">
                  {" "}
                  <GoBtn onClick={handleSearch}>
                    <span className="GoBtn_text">Continue</span>
                    <span className="GoBtn_icon">
                      <i
                        className="fa-solid fa-arrow-right"
                        style={{ marginLeft: "5px" }}
                      ></i>
                    </span>
                  </GoBtn>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

AskService.defaultProps = {
  categories: [],
};

AskService.propTypes = {
  categories: PropTypes.array,
};

export default AskService;
