import React from "react";
import "./hero.css";
import { Container, Button } from "react-bootstrap";
import TypeWriter from "./TypeWriter/TypeWriter";

const FixedbackgroundImage = () => {
  const handlePost = () => {
    const ele = document.getElementById("create_Client_rfq");
    ele.scrollIntoView();
  };
  return (
    <>
      <section className="main_homepage_background_img">
        <div className="try_values">
          <div className="container">
            <h1>Experience procurement at its best!</h1>
            <TypeWriter />
            <p>
              Upgrade to a curated experience to create your procurement needs
              to get the best offers from your vendors.
            </p>
            <Button onClick={handlePost}>Post Your Requriment</Button>
          </div>
        </div>
      </section>
    </>
  );
};

export default FixedbackgroundImage;
