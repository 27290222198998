import styled from "styled-components";
const QupCustomCheckBox = styled("div")`
  font-size: 13px;
  color: white;
`;
export const QupCheckBox = ({ label, value, onChange }) => {
  return (
    <QupCustomCheckBox>
      <div className="checkBox-wrapper">
        <label>
          <input type="checkbox" checked={value} onChange={onChange} />
          {label}
        </label>
      </div>
    </QupCustomCheckBox>
  );
};
