import React, { useEffect, useRef, useState } from "react";
import NavbarReuse from "../End-User/NavbarComponent/NavbarReuse";
import { useLocation, Link, useNavigate } from "react-router-dom";
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  // ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
  ListItem,
  Backdrop,
} from "@mui/material";
import { Modal, Button, Form } from "react-bootstrap";
import { FaTwitter, FaFacebook, FaLinkedin } from "react-icons/fa";
import { settings } from "../End-User/User-Data/MenuItems";
import { ArrowDropDown, Menu as MenuIcon } from "@mui/icons-material";
import { userNavItems } from "../End-User/User-Data/MenuItems";
import { useSelector, useDispatch } from "react-redux";
import { logoutUser } from "../../slices/auth";
import newQupLogo from "../../assets/Homepagebackground/qup-new2.png";
// import { Button, Modal } from "react-bootstrap";
// import { makeStyles } from "@material-ui/core/styles";
import { auth } from "../../Services/Firebase";

import JoinLogin from "../Sign_in/JoinLogin";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import ClientLogin from "../Sign_in/ClientLogin";
import VendorLogin from "../Sign_in/VendorLogin";
const Header = (props) => {
  const location = useLocation();
  const [userImage, setUserImage] = useState("");
  const userLoggedIn = auth.currentUser;
  useEffect(() => {
    if (userLoggedIn) {
      const unsubscribe = auth.onAuthStateChanged((user) => {
        if (user !== null && user.providerId === "firebase") {
          console.log(user);
          // setUserImage(userLoggedIn.photoURL);
        } else {
          console.log("Found no users.");
        }
      });
      return () => {
        unsubscribe();
      };
    }
  }, [userLoggedIn]);

  const validPaths = ["/", "/info"];
  const isHomePage = validPaths.includes(location.pathname);
  const isLoggedIn = useSelector((state) => state.auth?.isLoggedIn);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [scrolled, setScrolled] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [activeMenu, setActiveMenu] = useState(null);
  const [logoClass, setLogoClass] = useState("logo");
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  ///navbar textname scrooling function
  const [pageScrolled, setPageScrolled] = useState(false); // State variable to track scrolling
  const dropdownRef = useRef(null);
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      setScrolled(prevScrollPos > currentScrollPos || currentScrollPos === 0);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  useEffect(() => {
    setPageScrolled(scrolled);
  }, [scrolled]);

  useEffect(() => {
    if (isHomePage && !scrolled) {
      setLogoClass("logo homepage-logo");
    } else {
      setLogoClass("logo");
    }
  }, [isHomePage, scrolled]);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.target);
  };
  function handleCloseUserMenu() {
    setAnchorElUser(null);
  }
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        handleCloseUserMenu();
      }
    };
    document.addEventListener("mousedown", handleClickOutside, false);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside, false);
    };
  }, []);
  const handleMenuClick = (index) => {
    if (activeMenu === index) {
      setActiveMenu(null);
    } else {
      setActiveMenu(index);
    }
  };
  ///join button click able time show the modal////
  const [showClientLogin, setShowClientLogin] = useState(false);
  const [showVendorLogin, setShowVendorLogin] = useState(false);

  const handleShowClientLogin = () => {
    setShowClientLogin(true);
  };

  const handleCloseClientLogin = () => {
    setShowClientLogin(false);
  };

  const handleShowVendorLogin = () => {
    setShowVendorLogin(true);
  };

  const handleCloseVendorLogin = () => {
    setShowVendorLogin(false);
  };
  const [show, setShow] = useState(false);

  const { windows } = props;

  const container =
    windows !== undefined ? () => windows().document.body : undefined;

  // Logout Function
  const handleLogout = async (url) => {
    if (url !== "/logout") {
      navigate(url);
    } else {
      await dispatch(logoutUser(url));
      // console.log(msg);
      // setTimeout(() => {
      window.location.replace("/");
      // }, 1000);
    }
  };
  /////

  const drawer = (
    <Box sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        QuoteUrPrice
      </Typography>
      <Divider />
      <List>
        {userNavItems.map((item, index) => {
          const isActive = activeMenu === index;
          if (item.submenu) {
            return (
              <React.Fragment key={item.title}>
                <ListItemButton onClick={() => handleMenuClick(index)}>
                  <ListItemText primary={item.title} />
                  <ListItemIcon>
                    <ArrowDropDown color={isActive ? "primary" : "inherit"} />
                  </ListItemIcon>
                </ListItemButton>
                {isActive && (
                  <List>
                    {item.submenu.map((submenuItem, submenuIndex) => (
                      <ListItemButton
                        className="navbar-dropdown"
                        component={Link}
                        to={submenuItem.url}
                        key={submenuItem.title}
                      >
                        <ListItemText primary={submenuItem.title} />
                      </ListItemButton>
                    ))}
                  </List>
                )}
              </React.Fragment>
            );
          } else {
            return (
              <ListItemButton
                key={item.title}
                component={Link}
                to={item.url}
                // onClick={() => setMobileOpen(false)}
              >
                <ListItemText primary={item.title} />
              </ListItemButton>
            );
          }
        })}
      </List>
    </Box>
  );

  return (
    <header>
      <Toolbar
        component="nav"
        position="fixed"
        className={scrolled ? "custom-navbar-scroll" : "custom-navbar"}
      >
        <div className="nav-area">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: "none" } }}
          >
            <MenuIcon style={{ fontSize: "2rem" }} />
          </IconButton>
          <div className="navbar_logo">
            <span
              className={logoClass}
              sx={{ flexGrow: 1 }}
              onClick={() =>
                navigate("/", { preventScrollReset: true, replace: true })
              }
            >
              <img
                src={scrolled ? newQupLogo : newQupLogo}
                alt="qup_log"
                className="navbar_logoimg"
              />{" "}
              {/* <span
                className={`logo-name ${
                  pageScrolled ? "gray-text" : "white-text"
                }`}
              >
                QuoteUrPrice
              </span> */}
            </span>
          </div>

          <Box className="nav_settings_wrapper" p={0} m={0}>
            <NavbarReuse />
            {isLoggedIn ? (
              <>
                <Box>
                  <Tooltip title="Open settings">
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                      <Avatar
                        className="responsive-avatar"
                        sx={{
                          backgroundColor: userLoggedIn
                            ? "blueviolet"
                            : "-moz-initial",
                        }}
                      />
                      {/* )} */}
                    </IconButton>
                  </Tooltip>
                  <Menu
                    ref={dropdownRef}
                    sx={{ mt: "45px" }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    {settings.map((setting, index) => (
                      <MenuItem key={index} onClick={handleCloseUserMenu}>
                        <ListItemButton
                          onClick={() => handleLogout(setting.url)}
                          className="sub_menu_li"
                        >
                          <Typography
                            textAlign="center"
                            fontFamily={"sans-serif,monospace"}
                            fontWeight={"light"}
                          >
                            <setting.icon className="icon_header" />
                            &nbsp;&nbsp;
                            {setting.name}
                          </Typography>
                        </ListItemButton>
                        {/* </Link> */}
                      </MenuItem>
                    ))}
                  </Menu>
                </Box>
              </>
            ) : (
              <div>
                {/* <button
                  onClick={() => navigate("/signin")}
                  className="login_button_home"
                >
                  Join
                </button> */}
                <div className="login-options-container dropdown">
                  <button className="login_button_home dropbtn">Login</button>
                  <div className="dropdown-content">
                    <ul>
                      <li>
                        <a onClick={handleShowClientLogin}>Client Login</a>
                      </li>
                      <li>
                        <a onClick={handleShowVendorLogin}>Vendor Login</a>
                      </li>
                    </ul>
                  </div>
                </div>

                {/* ClientLogin Modal */}
                <Modal
                  show={showClientLogin}
                  onHide={handleCloseClientLogin}
                  centered
                >
                  <Modal.Header
                    style={{
                      borderBottom: "none",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "1rem",
                      marginTop: "-10px",
                    }}
                  >
                    <img
                      src={newQupLogo}
                      alt="qup_log"
                      className="navbars_logoimg"
                    />
                    {/* <Modal.Title>QuoteUrPrice</Modal.Title> */}
                    <div
                      className="closeModal"
                      onClick={handleCloseClientLogin}
                    ></div>
                  </Modal.Header>
                  <Modal.Body>
                    <ClientLogin />
                  </Modal.Body>
                </Modal>

                {/* VendorLogin Modal */}
                <Modal
                  show={showVendorLogin}
                  onHide={handleCloseVendorLogin}
                  centered
                >
                  <Modal.Header
                    style={{
                      borderBottom: "none",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "1rem",
                      marginTop: "-10px",
                    }}
                  >
                    <img
                      src={newQupLogo}
                      alt="qup_log"
                      className="navbars_logoimg"
                    />
                    {/* <Modal.Title>QuoteUrPrice</Modal.Title> */}
                    <div
                      className="closeModal"
                      onClick={handleCloseVendorLogin}
                    ></div>
                  </Modal.Header>
                  <Modal.Body>
                    <VendorLogin />
                  </Modal.Body>
                </Modal>
              </div>
            )}
          </Box>
        </div>
      </Toolbar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "flex", md: "flex", lg: "flex" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: "220px",
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </header>
  );
};

export default Header;
