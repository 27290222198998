import React, { useEffect, useRef, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { PfDropdown, PfImage } from "@profabric/react-components";
import {
  // clearAuthentication,
  setAuthentication,
} from "../../../../store/reducers/auth";
import { GoogleProvider } from "../../../../utils/oidc-providers";
import { Button, Menu, Tooltip } from "@mui/material";

const StyledSmallUserImage = styled(PfImage)`
  margin-top: 3px;
  --pf-box-shadow: 0 3px 6px #00000029, 0 3px 6px #0000003b !important;
`;

const StyledBigUserImage = styled(PfImage)`
  --pf-box-shadow: 0 3px 6px #00000029, 0 3px 6px #0000003b !important;
  --pf-border: 3px solid #fff3;
  background-color: Blue;
`;

const UserHeader = styled.li`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 175px;
  padding: 10px;
  text-align: center;
  img {
    z-index: 5;
    height: 90px;
    width: 90px;
    border: 3px solid;
    border-color: transparent;
    border-color: rgba(255, 255, 255, 0.2);
  }
  p {
    z-index: 5;
    font-size: 17px;
    margin-top: 10px;
    small {
      display: block;
      font-size: 12px;
    }
  }
`;

const UserBody = styled.li`
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-bottom: 1px solid #495057;
  border-top: 1px solid #dee2e6;
  padding: 15px;
  &::after {
    display: block;
    clear: both;
    content: "";
  }

  @media (min-width: 576px) {
    a {
      background: #ffffff !important;
      color: #495057 !important;
    }
  }
`;

const UserFooter = styled.li`
  background-color: #f8f9fa;
  padding: 10px;
  &::after {
    display: block;
    clear: both;
    content: "";
  }
  .btn-default {
    color: #6c757d;
  }

  @media (min-width: 576px) {
    .btn-default:hover {
      background-color: #f8f9fa;
    }
  }
`;

export const StyledDropdown = styled(PfDropdown)`
  border: none;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  --pf-dropdown-menu-min-width: 280px;

  .dropdown-item {
    padding: 0.5rem 1rem;
  }

  .text-sm {
    margin-bottom: 0;
  }
  .dropdown-divider {
    margin: 0;
  }
`;

declare const FB: any;

const UserDropdown = () => {
  const navigate = useNavigate();
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const authentication = useSelector(
    (state: any) => state.adminauth.authentication
  );
  const [dropdownOpen, setDropdownOpen] = React.useState<null | HTMLElement>(
    null
  );
  const open = Boolean(dropdownOpen);
  const dropdownRef = useRef(null);
  const handleMenuOpen = (event: React.MouseEvent<HTMLPfButtonElement>) => {
    setDropdownOpen(event.currentTarget);
  };
  const handleMenuOptClose = () => {
    setDropdownOpen(null);
  };
  const handleClickOutside = (event: unknown) => {
    if (dropdownRef && !dropdownRef.current) {
      handleMenuOptClose();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside, false);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside, false);
    };
  }, []);

  let className = "dropdown-menu dropdown-menu-lg dropdown-menu-right";

  if (dropdownOpen) {
    className += " show";
  }
  const logOut = async () => {
    setDropdownOpen(null);
    if (authentication.profile.first_name) {
      await GoogleProvider.signoutPopup();
      dispatch(setAuthentication(undefined));
      navigate("/qup/admin/login");
    } else if (authentication.userID) {
      FB.logout(() => {
        dispatch(setAuthentication(undefined));
        navigate("/qup/admin/login");
      });
    } else {
      dispatch(setAuthentication(undefined));
      navigate("/qup/admin/login");
    }
    localStorage.removeItem("authentication");
  };

  const navigateToProfile = (event: any) => {
    event.preventDefault();
    setDropdownOpen(null);
    navigate("/qup/admin/profile");
  };
  return (
    <>
      <StyledDropdown hideArrow isOpen={open}>
        <Tooltip title={"account Settings"}>
          <StyledSmallUserImage
            slot="button"
            src={authentication?.profile?.picture}
            fallbackSrc="/img/default-profile.png"
            alt="User"
            width={25}
            height={25}
            rounded
            onClick={(e: any) => handleMenuOpen(e)}
          />
        </Tooltip>
      </StyledDropdown>
      <Menu
        ref={dropdownRef}
        anchorEl={dropdownOpen}
        open={open}
        onClose={handleMenuOptClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <UserHeader>
          <StyledBigUserImage
            src={authentication.profile.picture}
            fallbackSrc="/img/default-profile.png"
            alt="User"
            width={60}
            height={60}
            rounded
          />
          <p>{authentication.profile.email}</p>
        </UserHeader>
        <UserBody>
          <div className="row">
            <div className="col-4 text-center">
              <Link to="/">{t("header.user.followers") as string}</Link>
            </div>
            <div className="col-4 text-center">
              <Link to="/">{t("header.user.sales") as string}</Link>
            </div>
            <div className="col-4 text-center">
              <Link to="/">{t("header.user.friends") as string}</Link>
            </div>
          </div>
        </UserBody>
        <UserFooter>
          <button
            type="button"
            className="btn btn-sm btn-outline-primary"
            onClick={navigateToProfile}
          >
            {t("header.user.profile") as string}
          </button>
          <button
            type="button"
            className="btn btn-default btn-flat float-right"
            onClick={logOut}
          >
            {t("login.button.signOut") as string}
          </button>
          {/* <button onClick={logOut}>logout</button> */}
        </UserFooter>
      </Menu>
    </>
  );
};

export default UserDropdown;
