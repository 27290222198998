import React, { useEffect, useState } from "react";
import { Controller, useForm, useFormContext } from "react-hook-form";
import Man from "../../assets/man.jpg";

import { Grid, Box } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { TextFieldValidation, AddressInputField } from "../CompServices/Helper";
const useStyles = makeStyles({
  root: {
    "&:hover": {
      backgroundcolor: "red",
    },
  },
});
const Details = ({ onChange, setAddItem, urlSearch }) => {
  const classes = useStyles();
  const {
    watch,
    register,
    control,
    formState: { errors },
    trigger,
  } = useFormContext();
  const location = useLocation();
  const initialRfqType = location.state?.rfq_type || "Request";
  const [selectedImage, setSelectedImage] = useState(null);
  const [rfqType, setRfqType] = useState(initialRfqType);
  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");
  const getSubject = useSelector((state) => state?.rfqRequests.subject);
  const getDescription = useSelector((state) => state?.rfqRequests.description);

  useEffect(() => {
    setSubject(urlSearch);
  }, [urlSearch]);

  useEffect(() => {
    if (!urlSearch) {
      setSubject(getSubject);
    }
    setDescription(getDescription);
  }, [getSubject, getDescription]);
  useEffect(() => {
    setRfqType(initialRfqType);
  }, [initialRfqType]);
  useEffect(() => {
    handleFormChange();
  }, [subject, description, rfqType]);

  const handleFormChange = () => {
    onChange({ Subject: subject, description: description, rfq_type: rfqType });
    // dispatch(getSubDescp({ sub:subject, descp:description, rfqType:rfqType }));
    if (rfqType === "Request") {
      setAddItem(true);
    } else {
      setAddItem(false);
    }
  };

  return (
    <div className="details-main-container">
      <Grid container>
        <h3 className="rfq-details-heading">
          Where Services Meet Savings: Discover the Perfect Quote.
        </h3>
        <Grid item md={6} xs={12}>
          <Box sx={{ alignItems: "center", justifyContent: "center" }}>
            <div className="image-container" style={{ marginLeft: "100px" }}>
              <img
                //  src={Man}
                src="https://images.template.net/82316/free-corporate-office-illustration-nod48.jpg"
                alt="bg-image"
                className="rfq-image"
              />
            </div>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} className="details-grid">
          <div className="details-rights-sub">
            <Grid container>
              <Grid item md={12} xs={12} lg={12}>
                <div className="textField_Wrap">
                <label htmlFor="rfq-services">Requirement types :</label>
                  <br />
                  &nbsp;&nbsp;
                  <label htmlFor="rfq-requests" style={{fontWeight:'500',fontFamily:'poppins'}}>
                    <input
                      type="radio"
                      id="rfq-requests"
                      {...register("rfq_type")}
                      checked={rfqType === "Request"}
                      value="Request"
                      onChange={() => setRfqType("Request")}
                      name="qup_requirement_type"
                    />
                    &nbsp;Products
                  </label>
                  &nbsp;&nbsp;&nbsp;
                  <label htmlFor="rfq-services" style={{fontWeight:'500',fontFamily:'poppins'}}>
                    <input
                      type="radio"
                      id="rfq-services"
                      {...register("rfq_type")}
                      checked={rfqType === "Service"}
                      value="Service"
                      onChange={() => setRfqType("Service")}
                      name="qup_requirement_type"
                    />
                    &nbsp;Service Requests
                  </label>
                </div>
                <div className="textField_Wrap">
                  <label htmlFor="subject">What are you looking for ?</label>
                  <Controller
                    name="subject"
                    control={control}
                    defaultValue=""
                    rules={{ required: "subject is required" }}
                    render={({ field }) => (
                      <TextFieldValidation
                        className={classes.root}
                        onChange={(e) => setSubject(e.target.value)}
                        fullWidth
                        value={subject}
                        label="subject"
                        required
                      />
                    )}
                  />
                </div>

                <div className="textField_Wrap">
                  <label htmlFor="description">Provide more details !</label>

                  <Controller
                    name="description"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Description is required" }}
                    render={({ field }) => (
                      <TextFieldValidation
                        fullWidth
                        className="textField_details"
                        label="Description"
                        placeholder="Enter details here"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        multiline
                        minRows={4}
                        required
                      />
                    )}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Details;
