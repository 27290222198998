import React from "react";
import MenuItemsComp from ".";
import "../navstyles.css";
import { Box, Menu, MenuItem, Tooltip } from "@mui/material";

const Dropdown = ({ submenus, dropdown, depthLevel }) => {
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  depthLevel = depthLevel + 1;

  const dropdownClass = depthLevel > 1 ? "dropdown-submenu" : "";
  return (
    <>
      {" "}
      <Box sx={{ flexGrow: 0 }}>
        <ul className={`dropdown ${dropdownClass} ${dropdown ? "show" : ""}`}>
          {submenus.map((submenu, index) => (
            <MenuItemsComp
              items={submenu}
              key={index}
              depthLevel={depthLevel}
            />
          ))}
        </ul>
      </Box>
    </>
  );
};

export default Dropdown;
