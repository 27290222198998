import React, { useEffect, useState } from "react";
import "./ProfileSection.scss"; // Import the CSS file for styling (e.g., ProfileSection.css)
import axios from "../../api/axios";
import {
  GET_RFQS_VID,
  GET_VENDORS_REQUESTS,
  GET_VENDOR_PROFILE_INFO,
} from "../CompServices/Api_Routes_Helper";
import authHeader from "../../Services/auth-header";
import { Card, Grid } from "@mui/material";
import { useCookies } from "react-cookie";

function ProfileSection({ userId }) {
  const [data, setData] = useState([]);
  const [vid, setVid] = useState("");
  const [name, setName] = useState("");
  const [category, setCategory] = useState([]);
  const [requestrecieved, setRequestReceived] = useState("");
  const [cookies, setCookie] = useCookies(["myCookie"]);
  const quickInfo = async () => {
    try {
      const resdata = await axios.post(
        `${GET_VENDOR_PROFILE_INFO}`,
        { _id: userId },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const details = resdata.data;
      // console.log(details);
      // setVendorid(details[0].vendorId._id);

      setVid(details[0].vendorId._id);
      localStorage.setItem("vId", vid);
      setData(details);
      setName(details[0].vendorId.displayName);
      setCategory(details[0].vendorId.category.join(", "));
      console.log("safasfd", details);
      setVid(details[0].vendorId._id);

      // setData(details);
      // setName(details[0].vendorId.fullname || details[0].vendorId.firstName);
      // setCategory(details[0].vendorId.category.join(", "));
    } catch (error) {
      console.error(error);
    }
  };

  const vidd = localStorage.getItem("vendorID");
  console.log(vidd);
  useEffect(() => {
    quickInfo();
  }, []);
  useEffect(() => {
    if (vidd) {
      localStorage.setItem("vendorId", vidd);
      setCookie("VendorID", vidd);
    }
  }, [vidd]);

  const getNoRequests = async () => {
    if (!vidd) {
      return;
    }
    try {
      const response = await axios(`${GET_VENDORS_REQUESTS}${vidd}`, {
        method: "GET",
        headers: await authHeader(),
      });
      console.log("response", response);

      // console.log("dsgsdgsd", response.data);
      const { data } = response;
      const { vendorRequests } = data[0];
      if (Array.isArray(data)) {
        const length = vendorRequests.length;
        // console.log(length);
        setRequestReceived(length);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getNoRequests();
  }, [vidd]);
  return (
    <div className="profile_section">
      <h2>Vendor Details:</h2>

      <Grid container className="profile_row">
        <Grid item md={5} sm={6} className="profile_label">
          Name:
        </Grid>
        <Grid item md={5} sm={6} className="profile_value">
          {name}
        </Grid>
      </Grid>
      <Grid container className="profile_row">
        <Grid item md={5} sm={6} className="profile_label">
          Categories:
        </Grid>
        <Grid item md={5} sm={6} className="profile_value">
          {category}
        </Grid>
      </Grid>
      <Grid container className="profile_row">
        <Grid item md={5} sm={6} className="profile_label">
          Requests received:
        </Grid>
        <Grid item md={5} sm={6} className="profile_value">
          {requestrecieved}
        </Grid>
      </Grid>
      <Grid container className="profile_row">
        <Grid item md={5} sm={6} className="profile_label">
          Responses sent:
        </Grid>
        <Grid item md={5} sm={6} className="profile_value">
          5(hard coded)
        </Grid>
      </Grid>

      {/* Add more rows for additional fields */}
    </div>
  );
}

export default ProfileSection;
