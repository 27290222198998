import React from "react";
import { Page, Document, StyleSheet, Image } from "@react-pdf/renderer";
import peermelogo from "../../assets/peermelogo.png";
import InvoiceTitle from "../ServiceProvider/Requests/Invoice/InvoiceTitle";
import InvoiceNo from "../ServiceProvider/Requests/Invoice/InvoiceNo";
import BillTo from "../ServiceProvider/Requests/Invoice/BillTo";
import InvoiceSubject from "../ServiceProvider/Requests/Invoice/InvoiceSubject";
import TermsTable from "../ServiceProvider/Requests/Invoice/InvoicetermTable";
import SpecialNoteTable from "../ServiceProvider/Requests/Invoice/InvoiceSpecialNoteTable";
import InvoiceThankQuotation from "../ServiceProvider/Requests/Invoice/InvoiceThankQuotaion";
import InvoiceItemsTable from "../ServiceProvider/Requests/Invoice/InvoiceItemTable";

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
    fontFamily: "Helvetica",
    fontSize: 11,
    paddingTop: 30,
    paddingLeft: 50,
    paddingRight: 50,
    lineHeight: 1.5,
    flexDirection: "column",
  },
  logo: {
    width: 84,
    height: 70,
    marginLeft: "auto",
    marginRight: "auto",
  },
});

const BasePdfDocument = ({ qtndata }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Image style={styles.logo} src={peermelogo} />
        <InvoiceTitle title={"Quotation"} />
        <InvoiceItemsTable invoice={qtndata} ClientInvoice={""} />
      </Page>
    </Document>
  );
};

export default BasePdfDocument;
