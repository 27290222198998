import {
  Grid,
  Paper,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Container,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import React, { useState, useEffect } from "react";
import data from "../ServiceProvider/dashboard/layouts/dashboard/components/Projects/data";

const EditAddress = () => {
  const [addressDetails, setAddressDetails] = useState({
    name: "",
    phoneNumber: "",
    pincode: "",
    locality: "",
    address: "",
    city: "",
    state: "",
    landmark: "",
    alternativeNumber: "",
    home: false,
    work: false,
  });

  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    localStorage.removeItem("addressDetails");
  }, []);

  const handleChange = (event) => {
    const { name, value, checked, type } = event.target;
    setAddressDetails((prevDetails) => ({
      ...prevDetails,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSave = () => {
    localStorage.setItem("addressDetails", JSON.stringify(addressDetails));
    setFormSubmitted(true);
  };
  const fieldList = [
    { label: "Name", name: "name" },
    { label: "Phone Number", name: "phoneNumber" },
    { label: "Pincode", name: "pincode" },
    { label: "Locality", name: "locality" },
    { label: "Address", name: "address" },
    { label: "City", name: "city" },
    { label: "State", name: "state" },
    { label: "Landmark", name: "landmark" },
    { label: "Alternative Number", name: "alternativeNumber" },
  ];
  const data = [
    {
      name: "Username",
      address:
        "1, BWSSB, Block 9 Anjanapura Further Extension, Anjanapura Township, Bengaluru, Karnataka - 560083",
    },
    {
      name: "Username",
      address:
        "1, BWSSB, Block 9 Anjanapura Further Extension, Anjanapura Township, Bengaluru, Karnataka - 560083",
    },
    {
      name: "Username",
      address:
        "1, BWSSB, Block 9 Anjanapura Further Extension, Anjanapura Township, Bengaluru, Karnataka - 560083",
    },
  ];
  const [accordionOpen, setAccordionOpen] = useState(true);

  const handleCloseAccordion = () => {
    setAccordionOpen(false);
  };

  return (
    <>
      <div className="Edit_Address_page">
        <Container>
          <Grid container spacing={2}>
            {/* <Grid item xs={12} md={4}>
              <Paper elevation={3} style={{ padding: "20px" }}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    <AccountCircleIcon fontSize="large" />
                  </Grid>
                  <Grid item>
                    <Typography variant="h6">Hello</Typography>
                    <Typography variant="body1">Username</Typography>
                  </Grid>
                </Grid>
              </Paper>
              <br />
              <Paper elevation={3} style={{ padding: "20px" }}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    <AccountCircleIcon fontSize="large" />
                  </Grid>
                  <Grid item>
                    <Typography variant="h6">Hello</Typography>
                    <Typography variant="body1">Username</Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid> */}
            <Grid item xs={12} md={12}>
              <Paper elevation={3} style={{ padding: "20px" }}>
                <Typography variant="h6" style={{ padding: "10px" }}>
                  Manage Addresses
                </Typography>
                <Accordion>
                  <AccordionSummary>
                    <Grid container alignItems="center">
                      <Grid item>
                        <AddIcon
                          style={{ marginRight: "8px", color: "blue" }}
                        />
                      </Grid>
                      <Grid item>
                        <Typography
                          variant="subtitle1"
                          style={{ color: "blue" }}
                        >
                          ADD A NEW ADDRESS
                        </Typography>
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <div style={{ padding: "10px" }}>
                    {" "}
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<LocationOnIcon />}
                    >
                      Use my current location
                    </Button>
                  </div>
                  <Container>
                    <Grid container spacing={2}>
                      {fieldList.map((field) => (
                        <Grid item xs={12} md={6} key={field.name}>
                          <TextField
                            label={field.label}
                            variant="outlined"
                            fullWidth
                            name={field.name}
                            value={addressDetails[field.name]}
                            onChange={handleChange}
                          />
                        </Grid>
                      ))}

                      <Grid>
                        <Grid item xs={12} style={{ padding: "20px" }}>
                          <Typography variant="subtitle2" color="textSecondary">
                            Select Address Type
                          </Typography>{" "}
                          <Grid style={{ display: "flex" }}>
                            <Grid item xs={6}>
                              <FormControlLabel
                                control={<Checkbox color="primary" />}
                                label="Home"
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <FormControlLabel
                                control={<Checkbox color="primary" />}
                                label="Work"
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      columnSpacing={2}
                      style={{ padding: "10px" }}
                    >
                      <Grid item>
                        <button className="hhhhhhhhhh" onClick={handleSave}>
                          SAVE
                        </button>
                      </Grid>
                      <Grid item>
                        <button className="gggggg">CLOSE</button>
                      </Grid>
                    </Grid>
                  </Container>
                </Accordion>
                <div>
                  {formSubmitted && (
                    <Grid item xs={12} style={{ padding: "20px" }}>
                      <Typography variant="h6">Entered Details:</Typography>
                      <pre>{JSON.stringify(addressDetails, null, 2)}</pre>
                    </Grid>
                  )}
                </div>
                <br />
                <div>
                  <Grid container alignItems="center" spacing={2}>
                    {data.map((item, index) => (
                      <React.Fragment key={index}>
                        {/* <Grid item xs={1}>
                          <Checkbox />
                        </Grid> */}
                        <Grid item xs={12}>
                          <Paper
                            elevation={3}
                            style={{
                              padding: "10px",
                              marginBottom: "10px",
                              border: "1px solid #ddd", // Add outline to each Paper
                              borderBottom:
                                index !== data.length - 1
                                  ? "1px solid #ddd"
                                  : "none", // Add bottom border to create lines
                            }}
                          >
                            <Typography variant="h5">{item.name}</Typography>
                            <Typography variant="body1">
                              {item.address}
                            </Typography>
                          </Paper>
                        </Grid>
                      </React.Fragment>
                    ))}
                  </Grid>
                </div>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default EditAddress;
