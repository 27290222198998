import React, { useState } from "react";
import MDBox from "../../../../../Dcomponents/MDBox";
import MDTypography from "../../../../../Dcomponents/MDTypography";

import DataTableBase from "../../../../../../../ReuseTable/DataTable";
import { Download } from "@mui/icons-material";
import button from "../../../../../assets/theme/components/button";
import { useNavigate } from "react-router-dom";

const RecentRfqsTable = ({ recentrfqs }) => {
  const customStyles = {
    rows: {
      style: {
        minHeight: "55px", // override the row height``
      },
    },
    columns: {
      style: {
        maxWidth: "140px",
      },
    },
    headCells: {
      style: {
        textAlign: "center",
        color: "black",
        fontSize: "15px",
        fontWeight: "500",
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        width: "auto",
        whiteSpace: "nowrap",
        overflow: "auto",
        maxWidth: "250px",
        textOverflow: "ellipsis",
        wordBreak: "break-word", // Add word break to handle long content
        flexGrow: 1, // Conditionally set flexGrow based on columnIndex
      },
    },
  };
  const navigate = useNavigate();
  const columns = [
    // {
    //   name: "id",
    //   selector: (row) => row.id,
    //   sortable: true,
    // },
    // {
    //   name: "Service",
    //   selector: (row) => row.service,
    //   sortable: true,
    //   maxWidth: "300px",
    //   cell: (row) => (
    //     <div className="table-sub-des-width">{row.}</div>
    //   ),
    // },
    {
      name: "Subject",
      selector: (row) => row.subject,
      sortable: true,
      maxWidth: "300px",
      cell: (row) => <div className="table-sub-des-width1">{row.subject}</div>,
    },
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
      maxWidth: "350px",
      cell: (row) => (
        <div className="table-sub-des-width1">{row.description}</div>
      ),
    },
    {
      name: "createdAt",
      selector: (row) => row.createdAt,
    },
    // {
    //   name: "Action",
    //   selector: (row) => row.view,
    // },
  ];
  const currentTime = new Date();
  let data;
  if (recentrfqs !== null) {
    data = recentrfqs
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) // Sort the data by createdAt in descending order
      .slice(0, 3)
      .map((item) => {
        const createdAt = new Date(item.createdAt);
        // const timeDifference = currentTime.getTime() - createdAt.getTime();
        return {
          service: item.requirements?.map((data) => data.categoryName),
          subject: item.mainRFQ?.subject,
          description: item.mainRFQ?.description,
          service:item.mainRFQ?.categoryName,
          createdAt: createdAt.toLocaleTimeString(),
        };
      });
  } else {
    data = [];
  }

  // view: (
  //   <div className="btn_space_bw">
  //     <button
  //       variant="outlined"
  //       color="success"
  //       onClick={() => onSubmit(item._id)}
  //     >
  //       Respond
  //     </button>
  //   </div>
  // ),

  const MyExpander = (props) => {
    return (
      <MDBox p={1}>
        <MDTypography
          variant="div"
          sx={{
            fontSize: "14px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <span>{props.data.description}</span>
          <button
            style={button}
            onClick={() => navigate("/vendorManagement/rfq")}
          >
            view
          </button>
        </MDTypography>
      </MDBox>
    );
  };
  return (
    <MDBox p={3}>
      <DataTableBase
        columns={columns}
        data={data}
        customStyles={customStyles}
        responsive={true}
        expandableRows
        expandableRowsComponent={MyExpander}
        // conditionalRowStyles={conditionalRowStyles}
      />
    </MDBox>
  );
  //   const Progress = ({ color, value }) => (
  //     <MDBox display="flex" alignItems="center">
  //       <MDTypography variant="caption" color="text" fontWeight="medium">
  //         {value}%
  //       </MDTypography>
  //       <MDBox ml={0.5} width="9rem">
  //         <MDProgress variant="gradient" color={color} value={value} />
  //       </MDBox>
  //     </MDBox>
  //   );
  //   return (

  //    {
  //     columns: [

  //       { Header: "budget", accessor: "budget", align: "left" },
  //       { Header: "status", accessor: "status", align: "center" },
  //       { Header: "completion", accessor: "completion", align: "center" },
  //       { Header: "action", accessor: "action", align: "center" },
  //     ],

  //     rows: [
  //       {

  //         budget: (
  //           <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
  //             $2,500
  //           </MDTypography>
  //         ),
  //         status: (
  //           <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
  //             working
  //           </MDTypography>
  //         ),
  //         completion: <Progress color="info" value={60} />,
  //         action: (
  //           <MDTypography component="a" href="#" color="text">
  //             <Icon>more_vert</Icon>
  //           </MDTypography>
  //         ),
  //       },
  //     ]

  //   }
  // )
};
export default RecentRfqsTable;
