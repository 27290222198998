import React, { useEffect, useMemo, useState } from "react";
import axios from "../../../../api/axios";
import DataTableBase, { customizStyles } from "../../../ReuseTable/DataTable";
import {
  ADMIN_ASSIGN_RFQS,
  ADMIN_GET_VENDORS,
  ADMIN_VIEW_RFQ,
  host,
} from "../../../CompServices/Api_Routes_Helper";
import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Typography,
  Select,
  MenuItem,
  Stack,
  Card,
  CardActions,
  CardContent,
  CardActionArea,
  CardMedia,
} from "@mui/material";
import ReuseablebothSubDes from "../../../ReuseTable/ReuseablebothSubDes";
import PropTypes from "prop-types";
import authHeader from "../../../../Services/auth-header";
import Moment from "react-moment";
import ContentHeader from "../content-header/ContentHeader";
import {
  DataGrid,
  GridColDef,
  GridValueFormatterParams,
  GridActionsCellItem as MuiGridActionsCellItem,
  GridToolbar,
  GridRowParams,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import debounce from "lodash/debounce";
import AdminActions from "./AdminActions";
import { ToastContainer, toast } from "react-toastify";
import BlankProfile from "../../../../assets/blank-profile.webp";

const vendorcolumns = [
  { field: "items", headerName: "Item" },
  {
    field: "quantity",
    headerName: "quantity",
    type: "number",
    // editable: true,
  },
  {
    field: "unit",
    headerName: "Unit",
    type: "singleSelect",
  },
  {
    field: "price",
    headerName: "Expected Price",
    valueFormatter: (params) => {
      const price = params.value;
      if (price !== null) {
        const newPrice = new Intl.NumberFormat("en-IN", {}).format(price);
        return newPrice;
      }
      return "---NA---";
    },
  },
  {
    field: "responseQuantity",
    headerName: "Quatation Quantity",
  },
  {
    field: "responsePrice",
    headerName: "Quatation Price",
    valueFormatter: (params: GridValueFormatterParams) => {
      const responsePrice = params.value;
      if (responsePrice !== null) {
        return `₹ ${responsePrice}`;
      }
      return "---NA---";
    },
  },
  {
    field: "responseTax",
    headerName: "Quatation Tax",
    valueFormatter: (params) => {
      if (params.value === null) {
        return "";
      }
      return params.value + "%";
    },
  },
  {
    field: "responseDate",
    headerName: "Quatation Date",
    renderCell: (params) => {
      const responseDate = params.row.responseDate;

      if (responseDate) {
        return <Moment format="DD/MM/YYYY">{responseDate}</Moment>;
      } else {
        return <span>{"---NA---"}</span>;
      }
    },
  },
  {
    field: "status",
    headerName: "status",
    // renderCell: (params) => {
    //   const { _id } = params.row;
    //   const rowAcceptanceStatus = Currstatus[_id];
    //   return rowAcceptanceStatus;
    // },
  },
];

const ModifyRequests = ({ reqId, rowInfo }) => {
  //state to fetch sub & descp & other fields.
  const [selectedOptions, setSelectedOptions] = useState({});
  const [values, setValues] = useState([]);
  const [request, setRequests] = useState([]);

  const [waitforData, setWaitForData] = useState(false);
  //category found
  const [categoryInc, setCategoryInc] = useState([]);

  //available vendors selection
  const [sysVendors, setSysVendors] = useState([]);

  //state to maintain response from vendors
  const [respVen, setRespVen] = useState([]);
  //qtn data from vendor (on fetch)
  const [cardTableData, setCardTableData] = useState({});
  //loader
  const [loader, setLoader] = React.useState(false);
  const columns = useMemo(
    () => [
      {
        name: <span className="QtnTable_headers">Category Name</span>,

        selector: (row: { categoryName: any }) => row.categoryName,
        sortable: true,
      },
      {
        name: <span className="QtnTable_headers">Items</span>,
        selector: (row: { items: any }) => row.items,
        sortable: true,
        grow: 1,
      },
      {
        name: <span className="QtnTable_headers">Quantity</span>,
        selector: (row: { quantity: any }) => row.quantity,
        sortable: true,
        // grow: 1,
      },
      {
        name: <span className="QtnTable_headers">Unit</span>,
        selector: (row: { unit: any }) => row.unit,
        sortable: true,
        grow: 1,
      },

      {
        name: <span className="QtnTable_headers">Price</span>,
        selector: (row: { price: any }) => row.price,
        format: (row, index) => {
          const amt = row?.price;
          const Ind = new Intl.NumberFormat("en-IN", {
            maximumSignificantDigits: 3,
          }).format(amt);
          return `₹ ${Ind}`;
        },
        sortable: true,
      },

      // },
      {
        name: <span className="QtnTable_headers">Date</span>,
        selector: (row) => row.date,
        sortable: true,
        format: (row, index) => <Moment format="DD/MM/YYYY">{row.date}</Moment>,
      },
      // {
      //   name: "Status",
      //   selector: (row) => row.status,
      //   sortable: true,
      // },
    ],
    [rowInfo]
  );
  useEffect(() => {
    if (values.length > 0 && sysVendors.length > 0) {
      setWaitForData(true);
    } else {
      setWaitForData(false);
    }
  }, [sysVendors, values]);
  const handleApporval = async () => {
    if (waitforData) {
      setLoader(true);
      debouncedHandleActionSelect(selectedOptions);
    }
  }

  const viewVendorResponses = async () => {
    const getVendors = {
      mainRFQ: rowInfo?.mainRFQ,
      isAdmin: true,
    };
    const queryString = Object.keys(getVendors)
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(getVendors[key])}`
      )
      .join("&");
    const { data, status } = await axios.get(
      `${host}/api/admin/get/vendor/responses?${queryString}`
    );
    if (data !== null && status === 200) {
      setRespVen(data.getVendors);
    }
  };
  useEffect(() => {
    viewVendorResponses();
  }, []);
  const GridToolBarCustom = () => {
    return (
      <React.Fragment>
        <GridToolbarContainer>
          <Grid container>
            <Grid item xs={12}>
              <GridToolbarQuickFilter style={{ position: 'relative', float: 'right' }} />
              <Box display={"inline-flex"} columnGap={{ xs: 2, md: 2, sm: 2 }}>
                {/* <Button
                  color="success"
                  aria-label="Approval Action button"
                  size="small"
                  onClick={handleApporval}
                  variant="contained"
                  title="approve rfq to vendor"
                >
                  Submit
                </Button> */}
                {/* <button
                  type="button"
                  className="btn btn-secondary btn-sm"
                  disabled
                >
                  Reject
                </button>
                <button
                  type="submit"
                  className="btn btn-primary btn-sm"
                  aria-label="vendor response view"
                  onClick={(e) => viewVendorResponses(e)}
                >
                  view Response
                </button> */}
              </Box>
            </Grid>
          </Grid>
        </GridToolbarContainer>
      </React.Fragment>
    );
  };
  const debouncedHandleActionSelect = debounce(async () => {
    try {
      await handleAssignent(selectedOptions);
    } catch (error) {
      console.error("error debouncing", error);
      const { message } = error?.response?.data;
      setLoader(false);
      toast.error("Error occured while assigning RFQs", {
        theme: "light",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });
    }
  }
    , 300);
  const handleAssignent = async (selectedOptions) => {
    const customer_ID = rowInfo.customer_ID;
    const requirementIds = rowInfo?.requirements;
    const mainRFQId = rowInfo?.mainRFQ;
    // const v_id = sysVendors?.map((vid)=>vid);
    const rowData = {
      mainRFQ: mainRFQId,
      vendors: selectedOptions,
      requirementIds: requirementIds,
      customer_ID: customer_ID,
    };

    const { data } = await axios.post(`${ADMIN_ASSIGN_RFQS}`, rowData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (data !== null) {
      const message = data?.data;
      setLoader(false);
      toast.success(message, {
        theme: "light",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });
      return message;
    }
  }
  useEffect(() => {
    try {
      const getValue = async () => {
        const { data } = await axios.get(`${ADMIN_VIEW_RFQ}/${reqId}`, {
          headers: { "Content-Type": "application/json" },
        });
        if (data !== null) {
          const { data: requestedData } = data;
          setValues([requestedData?.mainRFQ]);
          const foundCategory = requestedData?.requirements.map(
            (request) => request.categoryName
          );
          setCategoryInc(foundCategory);
          setRequests(requestedData?.requirements);
        }
      };

      if (reqId !== null) {
        getValue();
      }
    } catch (error) {
      console.error("Error fetching data", error);
    }
  }, [reqId]);
  useEffect(() => {
    const getAvailabelVendors = async () => {
      //converting array to a string and passing it as a parameter in the query string
      if (categoryInc.length == 0) {
        console.log("Category is empty. Cannot fetch vendors.");
        return;
      }
      const queryString = "?category[]=" + encodeURIComponent(categoryInc);
      const { data } = await axios.get(`${ADMIN_GET_VENDORS}${queryString}`);
      if (data.type === "success") {
        setSysVendors(data?.getVendors);
      }
    };
    getAvailabelVendors();
  }, [categoryInc]);
  const vendorinfo: GridColDef[] = React.useMemo(
    () => [
      {
        field: "_id",
        headerName: "uid",
      },
      {
        field: "email",
        headerName: "Email",
      },
      {
        field: "category",
        headerName: "Category",
        flex: 0.8,
      },
      {
        field: "companyname",
        headerName: "Company",
        flex: 0.5,
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Action",
        getActions: (params: GridRowParams) => [
          <AdminActions
            vId={params.row._id}
            onActTaken={(action, vid) => {
              setSelectedOptions((previousOpt) => ({
                ...previousOpt,
                // [params.row._id]: { action },
                [params.row._id]: action,
              }));
            }}
          />,
        ],
      },
    ],
    [reqId]
  );

  const CustomNoRowsOverlay = () => {
    return (
      <Box sx={{ mt: 1, width: "100%" }}>
        <Stack
          height={"100%"}
          alignContent={"center"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <span
            className="p-2"
            style={{
              color: "GrayText",
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >
            No Vendors Available
          </span>
        </Stack>
      </Box>
    );
  };
  function findvendorInfo(id: String) {
    const vendorData = sysVendors.find((vendor) => vendor["_id"] === id);
    if (vendorData) {
      return vendorData.email;
    }
    return "Vendor not Found";
  }

  const fetchVendorData = async (id) => {
    try {
      const { data } = await axios.get(
        `${host}/api/admin/view/vendor/response/${id}`,
        {
          headers: await authHeader(),
        }
      );
      return data?.structuredData;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  const fetchAndSetTableData = async (QuotationLineItemId) => {
    try {
      const newTableData = await fetchVendorData(QuotationLineItemId);
      setCardTableData((prevData) => ({
        ...prevData,
        [QuotationLineItemId]: newTableData,
      }));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    // Assuming initial respVen state is an array of objects with responses.QuotationLineItemId
    const initialTableData = respVen.reduce((acc, data) => {
      acc[data.responses.QuotationLineItemId] = [];
      return acc;
    }, {});
    setCardTableData(initialTableData);
  }, [respVen]);

  const handleClose = (QuotationLineItemId) => {
    setCardTableData((prevData) => {
      const newData = Object.keys(prevData).reduce((acc, key) => {
        if (key !== QuotationLineItemId) {
          acc[key] = prevData[key];
        }
        return acc;
      }, {});
      return newData;
    });
  };
  return (
    <>

      <Container>
        {!loader ? (<div>
          <Grid item container>
            <Grid item lg={12}>
              {values.length > 0 &&
                values.map((data, index) => (
                  <div className="categoryview-card" key={index}>
                    <div className="categoryview_QtnView"></div>
                    <div className="reusetable_alter">
                      {reqId && (
                        <ReuseablebothSubDes
                          title={"Client Request"}
                          subject={data?.subject}
                          description={data?.description}
                          isAdminEdit={true}
                          id={reqId}
                        />
                      )}
                    </div>
                    <div className="view-cat-grid mt-10">
                      <Paper
                        sx={{
                          backgroundColor: "rgba(246,246,246,255)",
                          boxShadow:
                            "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
                          // marginLeft: "5px",
                          // marginRight: "5px",
                          marginTop: "20px",
                        }}
                      >
                        <Typography
                          component={"span"}
                          variant={"body2"}
                          className="headding"
                          sx={{
                            fontFamily: "var(--qup-heading-font)",
                            fontWeight: "bold",
                            fontSize: "18px",
                          }}
                        >
                          Request Details
                        </Typography>
                        <DataTableBase
                          columns={columns}
                          data={request}
                          dense={false}
                          responsive={true}
                          customStyles={customizStyles}
                          fixedHeader
                          fixedHeaderScrollHeight="450px"
                        />
                      </Paper>
                    </div>
                  </div>
                ))}
            </Grid>
            <Grid item xs={12} p={1} mb={1}>
              <Box maxHeight={500} aria-roledescription="Available vendors table">
                <Typography component={"span"} variant="h6">
                  Select From Available vendors:
                </Typography>
                <div style={{ padding: 1 }}>
                  {sysVendors.length > 0 ? (
                    <DataGrid
                      rows={sysVendors}
                      columns={vendorinfo}
                      getRowId={(row) => row._id}
                      aria-labelledby="vendor Selection"
                      sx={{
                        boxShadow: 2,
                        border: 1,
                        borderColor: "#b7b7b7",
                      }}
                      slots={{
                        noRowsOverlay: CustomNoRowsOverlay,
                        toolbar: GridToolBarCustom,
                      }}
                      slotProps={{
                        panel: {
                          placement: "bottom-end",
                        },
                        filterPanel: {
                          sx: {
                            float: "right",
                            display: "inline-block",
                          },
                        },
                        toolbar: { sx: { backgroundColor: "red" } },
                      }}
                    />

                  ) : (
                    <CustomNoRowsOverlay />
                  )}
                  {sysVendors.length > 0 && (<Button
                    color="success"
                    aria-label="Approval Action button"
                    className="float-right mt-2"
                    size="small"
                    onClick={handleApporval}
                    variant="contained"
                    title="approve rfq to vendor"
                  >
                    Submit
                  </Button>)}

                </div>
              </Box>
            </Grid>
          </Grid>
          <Grid item md={8}>

            {respVen.length > 0 && (
              <Grid item xs={12}>
                <h4>Vendor Responses Created</h4>
                {respVen.map((data, index) => {
                  const { QuotationLineItemId } = data?.responses;
                  return (
                    <Box flexShrink={1} key={index} mb={2} p={3}>
                      <div
                        className="cardWrap"
                        style={{ width: "auto", minHeight: "100px" }}
                      >
                        <Card variant="outlined" sx={{ maxWidth: 240 }}>
                          <CardMedia
                            image={BlankProfile}
                            sx={{ height: 140 }}
                            title="vendor"
                          />
                          <CardContent
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <Typography gutterBottom variant="h6" component="span">
                              Vendor Information
                            </Typography>
                            <Typography component="span" variant="p">
                              Vendor Name : {findvendorInfo(data.vendor_ID)}
                            </Typography>
                          </CardContent>

                          <CardActionArea>
                            <div
                              className="vendor_btnWrap"
                              style={{
                                display: "flex",
                                justifyContent: "space-evenly",
                                maxWidth: "180px",
                                margin: "10px",
                              }}
                            >
                              <button
                                className="btn btn-primary btn-sm"
                                onClick={() =>
                                  fetchAndSetTableData(QuotationLineItemId)
                                }
                              >
                                view
                              </button>
                              <button
                                className="btn btn-sm btn-danger"
                                onClick={() => handleClose(QuotationLineItemId)}
                              >
                                close
                              </button>
                            </div>
                          </CardActionArea>
                        </Card>
                      </div>
                      {cardTableData[QuotationLineItemId] &&
                        cardTableData[QuotationLineItemId].length > 0 && (
                          <DataGrid
                            columns={vendorcolumns}
                            rows={cardTableData[QuotationLineItemId] || []}
                            getRowId={(row) => row._id}
                          />
                        )}
                    </Box>
                  );
                })}
              </Grid>
            )}
          </Grid>
        </div>) :
          (
            <>
              {/* <div
                className="qup_loader_wrap"
                style={{

                }}
              >
                <span
                  className=" p-1 m-auto d-block"
                  style={{
                    fontSize: 25,
                    fontWeight: "700",
                    color: "goldenrod",
                  }}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div className="lds-facebook" style={{ right: "0rem" }}>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                </span>
              </div> */}
                {/* <div className="multy_loader_wrap">
          <div className="loading_spinnner">
            <div className="multy_clr_loader"></div>
          </div>
        </div> */}
        <div className="simplebody">
        <div className="simpleloader"></div>
        <h5 style={{textAlign:'center'}}>Loading ...</h5>
        </div>
            </>
          )}
        <ToastContainer />
      </Container>
    </>
  );
};
// ModifyRequests.propTypes = {
//   reqId: PropTypes.string,
//   isAdmin: PropTypes.bool,
//   rowInfo: PropTypes.object,
// };
export default ModifyRequests;
