import { Card, Container, Grid } from "@mui/material";
import React, { useRef } from "react";
import {
  BsArrowRight,
  BsArrowLeftCircle,
  BsArrowRightCircle,
} from "react-icons/bs";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";
import { ArrowBackIosNew, ArrowForwardIos } from "@mui/icons-material";
import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";
import {
  Categoriesguideness,
  CategorySlideMapping,
  MostPopularService,
} from "./Datas";

function SubCategoryPage() {
  const innersettings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: (
      <button className="slick-prev">
        <ArrowBackIosNew />
      </button>
    ),
    nextArrow: (
      <button className="slick-next">
        <ArrowForwardIos />
      </button>
    ),
  };

  const settings = {
    dots: false,
    infinite: true,
    autoplay: false,
    speed: 5000,
    slidesToShow: 4,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const levelColor = (level) => {
    switch (level) {
      case "Level 2":
        return "level2";
      case "Level 1":
        return "level1";
      case "Top Quty":
        return "top";
      case "High Qty":
        return "high";
      default:
        return "";
    }
  };
  const navigate = useNavigate();
  return (
    <div>
      <div className="sub_categoryPage_main">
        <div className="sub_main_div">
          <h1>Developers</h1>
          <h3>You Think it. A Programmer develops it.</h3>
          <div className="sub_button_div">
            <button onClick={() => navigate("/user_in/categoryList")}>
              Create RFQ'S
            </button>
          </div>
        </div>
      </div>
      <div>
        <div className="mostPopular_div">
          <h2>Most Popular in Programming & Tech</h2>
          <div>
            <Grid container spacing={2}>
              {MostPopularService.map((item, key) => (
                <Grid item md={3} sm={12} lg={3} key={key}>
                  <Card
                    className="main_subcategoryCard"
                    onClick={() => navigate("/user_in/categoryList")}
                  >
                    <div className="subcategoryCard">
                      <img src={item.image} />
                      <p>{item.name}</p>
                      <div className="arrow_icon">
                        <span>
                          <BsArrowRight size={20} />
                        </span>
                      </div>
                    </div>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
      </div>
      <div className="Related_Subcategory">
        <h2>Related Vendors</h2>
        <div
          className="carousel-wrapper"
          style={{ marginTop: "15px", cursor: "pointer" }}
        >
          <Slider {...settings}>
            {CategorySlideMapping.map((slide, index) => (
              <div key={index} className="slide">
                <div>
                  <Slider {...innersettings} className="in-carousel">
                    <div className="image1">
                      <img src={slide.image1} alt="Image 1" className="1-img" />
                    </div>
                    {/* <div className="image1">
                      <img src={slide.image2} alt="Image 2" className="1-img" />
                    </div> */}
                  </Slider>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ display: "flex" }}>
                    <img className="avatar-img" src={slide.avatar} alt="suma" />
                    <h3>{slide.name}</h3>
                  </div>
                  <div>
                    <h6 className={`level ${levelColor(slide.level)}`}>
                      {slide.level}
                    </h6>
                  </div>
                </div>
                <p className="para_views">{slide.para}</p>
                <div className="star-span">
                  <StarRateRoundedIcon style={{ color: "orange" }} />
                  <span className="star-rate">{slide.star}</span>
                  <span className="star-bracket">{slide.span}</span>
                </div>
                <div>
                  <span className="rate">
                    <span className="from-rate">From </span>
                    {slide.rate}
                  </span>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <div>
        <div className="guide_lines">
          <h2>Guides to help you grow</h2>
          <br />
          <Grid container>
            {Categoriesguideness.map((name, i) => (
              <Grid
                item
                key={i}
                md={4}
                sm={12}
                lg={4}
                onClick={() => navigate("/user_in/categoryList")}
              >
                <img src={name.image} />
                <h4>{name.text}</h4>
                <p>{name.text1}</p>
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default SubCategoryPage;
