import React, { useRef, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../CardCarousel/styles.css";
// import IconButton from "@mui/material/IconButton";
// import VolumeUpRoundedIcon from "@mui/icons-material/VolumeUpRounded";
// import VolumeOffRoundedIcon from "@mui/icons-material/VolumeOffRounded";
// import PauseCircleFilledRoundedIcon from "@mui/icons-material/PauseCircleFilledRounded";
// import PlayCircleFilledRoundedIcon from "@mui/icons-material/PlayCircleFilledRounded";
// import { SlideMapping } from "../CardCarousel/SlideMapping";

const SimpleCarousel = () => {
  //   const videoRefs = useRef([]);

  //   const [isPlayingList, setIsPlayingList] = useState(
  //     SlideMapping.map(() => false)
  //   );
  //   const [isMutedList, setIsMutedList] = useState(SlideMapping.map(() => false));

  //   const handlePlayPause = (videoIndex) => () => {
  //     const video = videoRefs.current[videoIndex];
  //     const newIsPlayingList = [...isPlayingList];
  //     newIsPlayingList[videoIndex] = !newIsPlayingList[videoIndex];
  //     setIsPlayingList(newIsPlayingList);

  //     if (newIsPlayingList[videoIndex]) {
  //       video.play();
  //     } else {
  //       video.pause();
  //     }
  //   };

  //   const handleMuteUnmute = (videoIndex) => () => {
  //     const video = videoRefs.current[videoIndex];
  //     const newIsMutedList = [...isMutedList];
  //     newIsMutedList[videoIndex] = !newIsMutedList[videoIndex];
  //     setIsMutedList(newIsMutedList);

  //     video.muted = newIsMutedList[videoIndex];
  //   };

  return (
    <div className="inner-carousel">
      {/* {SlideMapping.map((slide, index) => ( */}
      <Carousel autoPlay infiniteLoop>
        <div className="inner-carousel-img">
          <img
            //   src={slide.image1}
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT_dcgP3vJzlpLFG3NLIRUC3op3foCuzcYmPnT_wz_e-yL7LiVhb1hnn_fi-nNtOiKGpbRpsPbUbVI&usqp=CAU&ec=48665698"
            alt="Image 1"
            width="230px"
            height="280px"
          />
        </div>
        {/* <div className="carousel-vid">
            <video
              style={{ objectFit: "cover" }}
              ref={(el) => (videoRefs.current[index] = el)}
              src={slide.video}
              width="230px"
              height="155px"
            ></video>
            <div className="icon-button">
              <IconButton onClick={handlePlayPause(index)}>
                {isPlayingList[index] ? (
                  <PauseCircleFilledRoundedIcon className="icon" />
                ) : (
                  <PlayCircleFilledRoundedIcon className="icon" />
                )}
              </IconButton>
              <IconButton onClick={handleMuteUnmute(index)}>
                {isMutedList[index] ? (
                  <VolumeOffRoundedIcon className="icon" />
                ) : (
                  <VolumeUpRoundedIcon className="icon" />
                )}
              </IconButton>
            </div>
          </div> */}
        <div className="inner-carousel-img">
          <img
            //   src={slide.image2}
            src="https://oss.ejet.com/2021/07/Sending-the-RFQ-request.jpg"
            alt="Image 2"
            width="230px"
            height="280px"
          />
        </div>
        <div className="inner-carousel-img">
          <img
            //   src={slide.image2}
            src="https://pmstudycircle.com/wp-content/uploads/2022/01/request-for-quotation-rfq.jpg"
            alt="Image 2"
            width="230px"
            height="280px"
          />
        </div>
        <div className="inner-carousel-img">
          <img
            //   src={slide.image2}
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSw8Wy7CIdpezaukQuH34TyMNAccKn3oGGE_L6bokykRIQEq5F-bgjXnA79rbHTYn8ozHVHF70gzw&usqp=CAU&ec=48665698"
            alt="Image 2"
            width="230px"
            height="280px"
          />
        </div>
      </Carousel>
      {/* ))} */}
    </div>
  );
};

export default SimpleCarousel;
