// import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
// import { useState } from "react";

// const languages = [
//   { name: "English", code: "en" },
//   { name: "Español", code: "es" },
//   { name: "Français", code: "fr" },
//   { name: "Deutsch", code: "de" },
//   { name: "Italiano", code: "it" },
//   { name: "Português", code: "pt" },
//   { name: "日本語", code: "ja" },
//   { name: "中文", code: "zh" },
// ];
// const ITEM_HEIGHT = 50;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       width: "auto",
//     },
//   },
// };

// function LanguageSelector(props) {
//   const [selectedLanguages, setSelectedLanguages] = useState([]);
//   props.onChildData(selectedLanguages);
//   console.log("selectedLanguages", selectedLanguages);
//   const handleLanguageChange = (event) => {
//     const {
//       target: { value },
//     } = event;
//     setSelectedLanguages(typeof value === "string" ? value.split(",") : value);
//   };

//   return (
//     <FormControl sx={{ m: 1, minWidth: 350 }}>
//       <InputLabel id="language-select-label">Language</InputLabel>
//       <Select
//         labelId="language-select-label"
//         id="language-select"
//         multiple
//         value={selectedLanguages}
//         onChange={handleLanguageChange}
//         // renderValue={(selected) =>
//         //   selected.map(
//         //     (code) => languages.find((language) => language.code === code)?.name
//         //   )
//         // }
//         sx={{ fontSize: "14px", fontWeight: "400" }}
//         MenuProps={MenuProps}
//       >
//         <MenuItem value="none">None</MenuItem>
//         {languages.map((language) => (
//           <MenuItem key={language.code} value={language.code}>
//             {language.name}
//           </MenuItem>
//         ))}
//       </Select>
//     </FormControl>
//   );
// }

// export default LanguageSelector;
import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = ["English", "Tamil", "Kannada", "Malayalam", "Hindi"];

export default function LanguageSelector(props) {
  const [selectedLanguages, setSelectedLanguages] = React.useState([]);
  props.onChildData(selectedLanguages);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedLanguages(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <div>
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="demo-multiple-checkbox-label">Languages</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={selectedLanguages}
          onChange={handleChange}
          input={<OutlinedInput label="Languages" />}
          renderValue={(selected) => selected.join(", ")}
          MenuProps={MenuProps}
        >
          {names.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={selectedLanguages.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
