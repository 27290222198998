import {
  Autocomplete,
  Box,
  Button,
  Divider,
  Grid,
  TextField,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import "./SellStyles.scss";
import { useForm } from "react-hook-form";
import { message } from "antd";
import axios from "../../api/axios";
import { v4 as uuidv4 } from "uuid";
import { API_AUTH_URL } from "../CompServices/Api_Routes_Helper";

const AccountInfo = ({
  finalData,
  handleFinalData,
  steps,
  current,
  prev,
  next,
}) => {
  const [email, setEmail] = useState("");

  const { register, getValues, handleSubmit } = useForm({
    defaultValues: finalData,
  });
  const onSubmit = () => {
    handleFinalData({ ...finalData, phone: getValues().phone });
    handleFormSubmit();
  };
  const id = uuidv4();
  const handleFormSubmit = async () => {
    const createValues = {
      id: id,
      firstName: finalData.firstName,
      lastName: finalData.lastName,
      displayName: finalData.displayName,
      languages: finalData.languages,
      occupation: finalData.occupation,
      category: finalData.category,
      website: finalData.website,
      phone: finalData.phone,
    };
    await axios.post(`${API_AUTH_URL}postProfile`, createValues).then((res) => {
      console.log("res", res);
    });
  };
  return (
    <div className="seller_onboarding_info">
      <div className="onboarding-introduction">
        <div id="personal_info" className="start_form">
          <div className="onboarding-step personal-info">
            <div className="step-header">
              <h2>Account Info</h2>
              <p>
                This is your time to shine. Let potential buyers know what you
                do best and how you gained your skills, certifications and
                experience.
              </p>
              <p className="mandatory">
                <small>* mandatory Fields</small>
              </p>
            </div>
            <Divider />
            <Box component={"div"} aria-labelledby="person_information">
              <form className="personal_form">
                <div id="full_name" className="onboarding-field is-required">
                  <aside>
                    <h3 className="font-accent">
                      <span>Email</span>
                      <small>Private</small>
                    </h3>
                  </aside>
                </div>
                <div id="display_name" className="onboarding-field is-required">
                  <aside>
                    <h3 className="font-accent">
                      <span>Phone number</span>
                    </h3>
                    <div className="education-text">
                      To help build credible and authentic connections with
                      customers, they’ll now see your display name.
                      <br />
                      We suggest using your first name and first initial of your
                      last name.
                    </div>
                  </aside>
                  <div className="field-content">
                    <div className="display-name-container">
                      <div className="WvIqLXU eXfr7qm name-input">
                        <div className="+T1b6wH">
                          <input
                            className="GD3asS+"
                            type="text"
                            name="display_name"
                            maxLength="35"
                            placeholder="Phone number"
                            onChange={(e) => setEmail(e.target.value)}
                            {...register("phone", { required: true })}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </Box>
          </div>
        </div>
      </div>
      <div
        style={{
          marginTop: 24,
          display: "flex",
          justifyContent: "end",
          marginBottom: "30px",
        }}
      >
        {current > 0 && (
          <Button
            style={{
              margin: "0 8px",
            }}
            onClick={() => prev()}
          >
            Previous
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button type="primary" onClick={() => onSubmit()}>
            Done
          </Button>
        )}
      </div>
    </div>
  );
};

export default AccountInfo;
