import React, { useEffect, useState } from "react";
import UserNavbar from "../End-User/UserNavbar";
import "./CategoryList.scss";
import { Autocomplete, Box, Button, Grid } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../../api/axios";
import { useForm } from "react-hook-form";
import {
  GET_ALL_CATSERVICE,
  GET_RFQ_EXPLAIN,
  HANDLE_DRAFT,
  UPDATE_MAIN_RFQ,
} from "../CompServices/Api_Routes_Helper";
import { SaveAlt } from "@mui/icons-material";
import {
  aLink,
  removeButton,
  submitButton,
} from "../DesignTools/ButtonStyles/ButtonStyles";
import CategoryListTable from "../ReuseTable/CategoryListTable";
import LoadingBars from "../PageLoader/LoadingBars";
import DemoTable from "../ReuseTable/DemoTable";
import { v4 as uuidv4 } from "uuid";
import StartEditButtonGrid from "./RfqEdit/Component/DataGrid";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function CategoryListUpdate({ userId, onBack, OnRefreshTable }) {
  const [vendorSubject, setSubject] = useState("");
  const [vendorDescription, setdescription] = useState("");
  const [options, setOptions] = useState([]);
  const [file, setFile] = useState();

  useEffect(() => {
    getValue();
    getOptions();
    getspecificRFQ();
  }, []);

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();

  const [loading, setLoading] = useState(true);
  const [RfqType, setRfqType] = useState("");

  const [requires, setRequires] = useState([]);

  const mainID = requires.map((item) => item.mainRFQId);
  const mainrfqid = mainID[0];
  console.log("mainrfqid:", mainrfqid);

  const updateRequires = (updatedRows) => {
    setRequires(updatedRows);
  };

  //getVAlues
  const combinefn = async (array) => {
    return new Promise((resolve, reject) => {
      try {
        const newData = array.map((item) => ({ ...item, id: uuidv4() }));
        resolve(newData);
      } catch (error) {
        console.error(error);
        reject(error);
      }
    });
  };

  //getting all values
  const getValue = async () => {
    try {
      const result = await axios.get(`${GET_RFQ_EXPLAIN}/${userId}`);
      if (result.data) {
        const { data } = result.data;
        const { requests } = result.data.data;
        const newData = await combinefn(requests);
        setRequires(newData);

        setSubject(data.subject);
        setdescription(data.description);
        setFile(data.image);
        setLoading(false);
        setRfqType(data.RFQtype);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getspecificRFQ = async () => {
    await axios.get(`${HANDLE_DRAFT}/${userId}`).then(async (res) => {
      const { data } = res.data;
      // setRfqType(data.RFQtype);
    });
  };

  //getOptions code
  const getOptions = async () => {
    await axios
      .get(GET_ALL_CATSERVICE)
      .then((res) => {
        console.log(res.data.data);
        setOptions(res.data.data);
      })

      .catch((error) => console.error(error));
  };

  //update code
  const handleFormSubmit = async () => {
    const createValues = {
      subject: getValues().Subject,
      description: getValues().description,
      requests: requires,
    };
    await axios
      .put(`${UPDATE_MAIN_RFQ}${mainrfqid._id}`, createValues)
      .then((res) => {
        console.log("res", res.data.data);
        const data = res.data;
        toast.success(data.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
          className: "custom-toast",
          onClose: () => {
            OnRefreshTable();
            onBack();
          },
        });
      });
  };

  return (
    <div>
      {loading ? (
        <LoadingBars />
      ) : (
        <div className="container_value" style={{background: 'rgb(224, 224, 224)',marginTop:'-10px'}}>
          <Box
            sx={{
              minWidth: "300px", 
              height: "auto",
              margin: "auto",
            }}
          >
            <form onSubmit={handleSubmit(handleFormSubmit)}>
              <div>
                <div className="border-text1 border-text">
                  <h6 className="headding">Client Request</h6>
                  <Grid container columnSpacing={2}>
                    <Grid item sm={6} md={4} lg={4} className="respond res">
                      <label className="viewlable">Subject</label>
                    </Grid>
                    <Grid item sm={6} md={8} lg={8} className="respond clientborder">
                      <input
                        type="text"
                        className="categoryListUpdatee"
                        id="inputEmail4"
                        placeholder="Subject"
                        name="Subject"
                        {...register("Subject", { required: true })}
                        value={vendorSubject}
                        onChange={(e) => setSubject(e.target.value)}
                      ></input>
                    </Grid>
                  </Grid>
                  <Grid container columnSpacing={2} style={{marginTop:'15px'}}>
                    <Grid item sm={6} md={4} lg={4} className="respond res">
                      <label className="viewlable">Description</label>
                    </Grid>
                    <Grid item sm={6} md={8} lg={8} className="respond clientborder">
                      <input
                        type="text"
                        className="categoryListUpdatee"
                        id="inputEmail4"
                        placeholder="description"
                        name="description"
                        {...register("description", { required: true })}
                        value={vendorDescription}
                        onChange={(e) => setdescription(e.target.value)}
                      ></input>
                    </Grid>
                  </Grid>
                </div>
                <div className="tablecatgirory">
                  <DemoTable
                    dataValue={requires}
                    options={options}
                    updateRequires={updateRequires}
                    RfqType={RfqType}
                  />
                </div>

                <div className="categoryInputt">
                  <Button
                    type="submit"
                    variant="contained"
                    style={submitButton}
                  >
                    Submit
                  </Button>
                </div>
              </div>
              <ToastContainer />
            </form>
          </Box>
        </div>
      )}
    </div>
  );
}

export default CategoryListUpdate;
