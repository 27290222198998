import { createSlice } from "@reduxjs/toolkit";
import { User } from "oidc-client-ts";

export interface AuthState {
  authentication?: User;
}

const initialState: AuthState = {
  authentication: undefined,
};

export const authSlice = createSlice({
  name: "adminauth",
  initialState,
  reducers: {
    setAuthentication: (state: any, { payload }) => {
      state.authentication = payload;
    },
  },
});
export const { setAuthentication } = authSlice.actions;
const { reducer } = authSlice;
export default reducer;
