import React, { useEffect, useState } from "react";
import "./styles.css";
import { Card, Container, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  CREATE_BUSSINESSVENDOR,
  GET_ALL_CATSERVICE,
} from "../../CompServices/Api_Routes_Helper";
import axios from "../../../api/axios";
import authHeader from "../../../Services/auth-header";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import ToastMaker from "../../CompServices/ToastMaker";
import TapsComponent from "./TapsComponent";

const vendorSchema = Yup.object().shape({
  category: Yup.string().required("Service Category Cannot be Empty"),
  // country: Yup.string().required("Country is required"),
  email: Yup.string()
    .required("email is required")
    .email("Email not matching criteria")
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      "Invalid email address"
    ),
  username: Yup.string().required("username is required"),
  companyname: Yup.string().required("companyname is required"),
  // phone: Yup.string().required("phone is required"),
  password: Yup.string()
    .required("Password is required")
    .min(4, "must contain min of 3 characters"),
  confirmPassword: Yup.string().required("Password is required. "),
  // .max(8, "can contain upto 8 only"),
  role: Yup.string()
    .test("role-test", "Invalid role", function (value) {
      const allowedRoles = ["supplier", "buyer", "both"];

      if (!allowedRoles.includes(value)) {
        return false;
      }

      return true;
    })
    .required("Role is required"),
});
const Index = () => {
  const {
    register,
    handleSubmit,
    getValues,
    reset,
    control,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      category: "",

      email: "",
      username: "",
      password: "",
      // confirmPassword: "",
      companyname: "",
      // password: "",
      role: "",
    },
    resolver: yupResolver(vendorSchema),
  });
  const initialValues = {
    email: "",
    username: "",
    companyname: "",

    // confirmPassword: "",
    // password: "",
  };
  const [alertResponse, setAlertResponse] = useState("");

  const [formErrors, setFormErrors] = useState({});
  const [formValues, setFormValues] = useState(initialValues);

  //to fetch and display services
  const [values, setValues] = useState([]);
  const navigate = useNavigate();
  const inlineMessageElement = document.getElementsByClassName(
    "input-validation-error"
  );
  const inputElement = document.getElementsByClassName("pm-form-control");
  useEffect(() => {
    const servicesCategories = async () => {
      try {
        const response = await axios.get(GET_ALL_CATSERVICE, {
          headers: await authHeader(),
        });
        if (response !== null) {
          const { data } = response.data;
          setValues(data);
        } else {
          throw new Error("Services not found.");
        }
      } catch (error) {
        console.error(error);
      }
    };
    servicesCategories();
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormValues({ ...formValues, [name]: value });
  };

  const handleFormSubmit = async () => {
    try {
      const vendordata = getValues();
      const notifier = document.getElementById("notification-div");
      // console.log(vendordata);
      const postData = await axios
        .post(
          CREATE_BUSSINESSVENDOR,

          vendordata,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          // console.log(response.data);

          const signup_response = response.data;
          console.log(signup_response);
          const { type, message } = signup_response;
          if (type === "success") {
            console.log("working :>>");
            setAlertResponse(message);
            ToastMaker(message);
            notifier.classList.add("notify-success");
          }
          // setTimeout(() => {
          //   navigate("/vendorManagement/dashboard");
          // }, 2000);

          reset();
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status === 400) {
            const response = error.response;
            const errormessage = response.data;
            notifier.classList.add("notify-failure");
            console.log(errormessage);
            setAlertResponse(errormessage.message);
            ToastMaker(errormessage.message);
          }
          reset();
        });
    } catch (error) {
      console.error(error);
    }
  };

  const onErrors = (errors) => console.error(errors);

  const handleFormErrors = (data) => {
    const errors = {};
    let isValid = true;
    if (!formValues.email) {
      errors.email = "Email is required";
      isValid = false;
    }
    if (!formValues.username) {
      errors.username = "Username is required";
      isValid = false;
    }
    if (!formValues.companyname) {
      errors.companyname = "companyname is required";
      isValid = false;
    }
    if (!formValues.category) {
      errors.category = "category is required";
      isValid = false;
    }

    if (!formValues.phone) {
      errors.phone = "phone is required";
      isValid = false;
    }
    // if (!formValues.password) {
    //   errors.password = "Password is required";
    //   isValid = false;
    // }
    if (!isValid) {
      console.error(errors);
    }
    setFormErrors(errors);
    return isValid;
  };

  return (
    <div className="seller_page_start">
      {/* <div className="inner-Authcontent">
        <AuthHeader />
      </div> */}
      <Container className="seller-page">
        <center>
          <h2 className="seller-page-head">Create Your Bussiness Account</h2>
        </center>
        <Grid container className="vendor-signup">
          {/* <Grid item className="vendorsingup-ba-img" md={6}>
            <img
              src="https://img.freepik.com/free-photo/white-tulips-pastel-blue-background-with-space-right-side_24972-145.jpg?w=740&t=st=1687851060~exp=1687851660~hmac=94728fbb2ef7ecac3493faee4c50295640cb02f5c15487aad66b848e830d1f99"
              width="100%"
              height="auto"
            />
          </Grid> */}
          <Grid item md={4}>
            <Card className="vendorsignuprework">
              <TapsComponent />
            </Card>
          </Grid>
        </Grid>
      </Container>

      <ToastContainer />
    </div>
  );
};

export default Index;
