import React from "react";
import { Button, Container, Grid } from "@mui/material";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
const ProductCardDesign = ({ gridData }) => {
  return (
    <>
      {/* <Container className="Category-card-container-main"> */}
      <div className="Category-card-container category-card-product-container">
        <div>
          <h2
            className="testimonials-title"
            style={{ marginBottom: "2rem", marginTop: "2rem" }}
          >
            Products You May Like
          </h2>
        </div>
        <Grid container spacing={2} rowGap={2}>
          {gridData.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} lg={2} xl={2} key={index}>
              <div className="individual-div product-div">
                <img
                  src={item.imageUrl}
                  width="150px"
                  height="150px"
                  alt={item.title}
                ></img>
                <p className="category-card-name">{item.title}</p>
                <p className="category-company-name">thaejf solution</p>

                <p className="category-company-location category-light-color">
                  Erode,TamilNadu
                </p>
                <p className="category-company-price">
                  $50 <span className="category-light-color">/peice</span>
                </p>

                <Button fullWidth className="category-card-button-phone">
                  <PhoneInTalkIcon
                    style={{ color: "black", marginRight: "2px" }}
                  />{" "}
                  Phone No
                </Button>
                <Button fullWidth className="category-card-button">
                  Get Best Price
                </Button>
              </div>
            </Grid>
          ))}
        </Grid>
      </div>
      {/* </Container> */}
    </>
  );
};

export default ProductCardDesign;
