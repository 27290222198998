import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyALj1dXl43zwbjpFSxA_523XNpyb6YuyXg",
  authDomain: "quoteyourpprice.firebaseapp.com",
  projectId: "quoteyourpprice",
  storageBucket: "quoteyourpprice.appspot.com",
  messagingSenderId: "617209430473",
  appId: "1:617209430473:web:7c2da27d097d9078db2818",
  measurementId: "G-SVPL2QLFRK",
};
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

export const provider = new GoogleAuthProvider();
