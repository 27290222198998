import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Snackbar,
  Button,
  Typography,
  Grid,
  TextField,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";

import LoadingButton from "@mui/lab/LoadingButton";
import { Box } from "@mui/system";
import { debounce } from "lodash";
import React, {
  useState,
  useEffect,
  useRef,
  Fragment,
  useContext,
} from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import axios from "../../api/axios";
import {
  BACKEND_URL,
  CHECK_EMAIL_OTP,
  GET_OTP_ONSUBMIT,
  VERIFY_ACCOUNT_TOKEN,
} from "../CompServices/Api_Routes_Helper";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Timer from "./Timer";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../slices/auth";
import InputAdornment from "@mui/material/InputAdornment";
import EmailIcon from "@mui/icons-material/Email";
import { clearMessage } from "../../slices/message";
import { useCookies } from "react-cookie";
import SignInEmailPwd from "./SignEP";
import signInWithGoogle from "../../Services/SigninWithGoogle";
import { useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { otpInputStyle } from "./Vendor_SignIn";
import JoinLogin from "./JoinLogin";
import Otplogin from "./Otplogin";
import { MdOutlineMailOutline, MdLock } from "react-icons/md";
const ClientLogin = () => {
  //initial  values for email and password
  const navigate = useNavigate;

  const { isLoggedIn } = useSelector((state) => state.auth);
  // console.log(isLoggedIn);
  const { message } = useSelector((state) => state.message);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showOtpField, setShowOtpField] = useState(false);
  //make form disapper
  const [reqOtpForm, setReqOtpForm] = useState(true);
  const [reqemail, setReqEmail] = useState("");
  //css loader
  const [onreqLoad, setOnreqLoad] = useState(false);
  //token not verify
  const [tokenVerifyErr, setTokenVerifyErr] = useState(false);
  //capture otp expiry time
  const [otpexptime, setExpTime] = useState(0);
  //send otp regenrate link
  const [reotpLink, setReotpLink] = useState(false);
  const [otpdeadline, setOtpDeadline] = useState(null);
  //for displaying error form server
  const [showErr, setshowErr] = React.useState(false);
  const [errMsg, setErrMsg] = useState("");
  //for hiding input fields.
  const [expanded, setExpanded] = React.useState(false);
  const dispatch = useDispatch();
  //cookies

  const handleChangeAcc = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  //google Signin
  const handleSignInWithGoogle = async (res) => {
    try {
      signInWithGoogle()
        .then((res) => {
          console.log(res);
          if (res) {
            localStorage.setItem(
              "userNew",
              JSON.stringify({ res, role: "ADMIN" })
            );
            window.location.assign("/");
          }
        })
        .catch((err) => console.error(err));
    } catch (error) {
      console.log(error.message);
    }
  };

  const formSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email cannot be empty")
      .email("Invalid email-format"),

    otp: Yup.string().max(6, "otp cannot exceed 6 digits."),
    // password: Yup.string().required("Password Field cannot be Empty!"),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,

    watch,
    formState: { isSubmitSuccessful },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(formSchema),
    defaultValues: {
      email: "",

      otp: "",
    },
  });

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  const handleClosePopUpErr = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setshowErr(false);
  };

  const onErrors = (errors) => console.error(errors);
  //send otp if emial is valid
  async function sendOtp(email) {
    try {
      const response = await axios.post(
        `${GET_OTP_ONSUBMIT}`,
        { email: email },
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      // console.log(response);

      var otpexpiry = response?.data?.otpttl;
      otpexpiry = otpexpiry.slice(0, -1);
      setExpTime(otpexpiry);
      // console.log(otpexpiry);
    } catch (error) {
      console.error(error.response?.data?.message || error.message);
      // throw error;
    }
  }
  //submission of form (emial,otp)
  const onVerifyOtp = async (data) => {
    try {
      const { email, otp } = data;
      // console.log(email,otp);
      if (!email || !otp) {
        throw new Error("All fields are required");
      }

      dispatch(login(data))
        .unwrap()

        .then((message) => {
          setIsSubmitting(true);
        })
        .catch((error) => {
          console.log(error);
          const { type, message } = error?.data;
          switch (error?.status) {
            case 401:
              setshowErr(true);
              setErrMsg(message);
              setIsSubmitting(false);

              break;
            case 419:
              setshowErr(true);
              setErrMsg(message);
              setIsSubmitting(false);
              setReotpLink(true);
              break;
            default:
              setshowErr(true);
              setErrMsg("An UnExpected Error Occurred");
              setIsSubmitting(false);

              break;
          }
        });
    } catch (error) {
      console.error(error);
    }
  };
  if (isSubmitting) {
    return <Navigate to="/" />;
  }

  const onRequestOtp = async (data) => {
    let { email } = data;
    // console.log({ email });
    email = email.trim().toLowerCase();
    // console.log(email);
    try {
      if (!email) throw new Error("Email is required.");
      setOnreqLoad(true);
      await axios
        .get(
          `${CHECK_EMAIL_OTP}`,
          {
            params: { email: email },
          },
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        )
        .then(async (response) => {
          if (response?.data.success === true) {
            // console.log(response?.data);
            await sendOtp(email);
            setReqEmail(email);
            setOnreqLoad(false);
            setReqOtpForm(false);
            setShowOtpField(true);
            setErrMsg("");
          }
        })
        .catch((err) => {
          console.error(err);

          if (err.response) {
            const { data } = err.response;
            switch (data.type) {
              case "ACTIVATION REQUIRED":
                setShowOtpField(false);
                setshowErr(true);
                setTokenVerifyErr(true);
                setErrMsg("User Found,Account Not Verified");
                setOnreqLoad(false);
                break;
              case "NOT FOUND":
                setOnreqLoad(false);
                setShowOtpField(false);
                setTokenVerifyErr(false);
                setshowErr(true);
                setErrMsg(data.message);
                break;
              default:
                setOnreqLoad(false);
                setshowErr(true);
                setErrMsg("An UnExpected Error Occurred");

                break;
            }
          }
        });
    } catch (error) {
      console.log(error);
      setshowErr(true);
      setErrMsg(error.message);
      setOnreqLoad(false);
    }
  };

  const reqOtpResend = async (event) => {
    event.preventDefault();
    toast.success("OTP has been Resent", {
      position: toast.POSITION.TOP_CENTER,
    });

    // console.log(event);
    // console.log(reqemail);
    try {
      await sendOtp(reqemail);
      setOtpDeadline(otpexptime);
      // alert("otp has been resent");
      setReotpLink(false);
    } catch (error) {
      console.error(error);
    }
  };

  const getOTPComp = () => {
    // console.log("Reset the timer: ", otpexptime);

    return (
      <>
        <div className="form login-data-form">
          <input
            id="otp"
            type="text"
            className="form-input new-log-input"
            // placeholder="Enter received OTP"
            name="otp"
            {...register("otp")}
            required
            // style={{ textAlign: "center" }}
          />
          <label htmlFor="otp" className="form-label new-form-label">
            Enter OTP
          </label>
          <br />
        </div>
        <Timer timer={otpexptime} /> {/* hide verify if req to resend otp */}
        <div style={{ display: "flex", justifyContent: "center" }}>
          {!reotpLink && (
            <button type="submit" className="otp-btn new-button-style">
              VERIFY OTP
            </button>
          )}
          {reotpLink && (
            <button
              type="submit"
              onClick={reqOtpResend}
              style={{
                fontSize: "14px",
                cursor: "pointer",
              }}
              className="new-button-style"
            >
              RESEND OTP
            </button>
          )}
        </div>
        <ToastContainer />
      </>
    );
  };
  const createAccVerifyToken = async (data) => {
    await axios
      .post(
        `${VERIFY_ACCOUNT_TOKEN}`,
        { email: data },
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      )
      .then((response) => {
        console.log("response sent successfully", response);
      })
      .catch((err) => console.error(err));
    // const {result}=response.data;
    // console.log(result);
  };

  const getEmailFormComp = () => {
    return (
      <div className="email-login-data" style={{ display: "block" }}>
        <form
          className="login-form"
          onSubmit={handleSubmit(onRequestOtp, onErrors)}
        >
          <div className="form">
            <input
              type="email"
              className="form-input new-log-input"
              placeholder=" "
              label="Email Id"
              name="email"
              id="email"
              {...register("email")}
              onChange={(e) => setReqEmail(e.target.value)}
              required
            />
            <i className="input-iconss">
              <MdOutlineMailOutline />
            </i>
            <label htmlFor="email" className="form-label new-form-label">
              Email-ID
            </label>
          </div>
          {errors?.email && (
            <span className="form-error">{errors.email.message}</span>
          )}

          {tokenVerifyErr ? (
            <p className="form-error">
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  createAccVerifyToken(reqemail);
                }}
                to="/"
              >
                Please click here to resend account verification Link.
              </Link>
            </p>
          ) : (
            <span className="form-error">{errMsg}</span>
          )}
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              variant="outlined"
              type="submit"
              className="v-em-btn new-button-style "
            >
              REQUEST OTP
            </Button>
          </div>
          {onreqLoad && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div className="lds-facebook" style={{ right: "0rem" }}>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          )}
        </form>
      </div>
    );
  };
  const alertBar = (
    <Snackbar
      open={showErr}
      autoHideDuration={2500}
      onClose={handleClosePopUpErr}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      className="qup_custom_SnackBar"
    >
      <Alert
        onClose={handleClosePopUpErr}
        severity={!isSubmitting ? "error" : "info"}
        sx={{ width: "100%", fontSize: 16 }}
      >
        {typeof errMsg === "boolean" ? errMsg.toString() : errMsg}
      </Alert>
    </Snackbar>
  );

  return (
    <React.Fragment>
      <div className="new-log-design">
        <p className="new-title-style">Login with Email-ID & Password</p>
        <JoinLogin />
      </div>
      {/* <br /> */}
      <p className="common-para">or</p>
      {/* <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChangeAcc("panel2")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography component="span" variant="caption" sx={otpInputStyle}>
            Login with your Email and OTP
          </Typography>
        </AccordionSummary> */}
      {/* <AccordionDetails> */}
      <div className="new-log-design">
        <p className="new-title-style">Login with your Email-ID & OTP</p>
        <Box className="email_otp_cont">
          {reqOtpForm && getEmailFormComp()}

          {showOtpField && (
            <div className="email-login-data" style={{ display: "block" }}>
              <form
                className="login-form"
                onSubmit={handleSubmit(onVerifyOtp, onErrors)}
              >
                <div className="form">
                  <input
                    id="email"
                    type="email"
                    className="form-input new-log-input"
                    placeholder=""
                    name="email"
                    value={reqemail}
                    required
                    disabled
                  />
                  <i className="input-iconss" style={{ marginLeft: "10px" }}>
                    <MdOutlineMailOutline />
                  </i>
                  <label htmlFor="email" className="form-label new-form-label">
                    Email-ID
                  </label>
                </div>

                {showOtpField && getOTPComp()}
              </form>
            </div>
          )}
        </Box>
      </div>
      {/* </AccordionDetails> */}
      {/* </Accordion> */}
      {alertBar}
      {/* <p className="common-para">or</p> */}
      {/* <p className="common-para">login with your social network account</p> */}
      {/* <div className="social-btn login-btn">
        <i className="fa-brands fa-facebook-f"></i>
        <i
          className="fa-brands fa-google-g fa-g"
          onClick={handleSignInWithGoogle}
        ></i>
        <i className="fa-brands fa-linkedin-in"></i>
      </div> */}
      <Typography
        component="p"
        variant="p"
        className="common-para"
        // sx={{ marginTop: "1rem", marginBottom: "1rem" }}
      >
        Not yet joined QuoteUrPrice?
        <Typography
          variant="p"
          className="common-para"
          component={Link}
          // to="/signup"
          to="#"
          sx={{ cursor: "pointer", color: "blue" }}
        >
          Sign Up
        </Typography>{" "}
        here
      </Typography>
    </React.Fragment>
  );
};

export default ClientLogin;
