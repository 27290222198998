import React from "react";
import { Card, Container } from "@mui/material";

import ServiceProviderResignup from "./ServiceProviderResignup";
const ServiceProviderSignup = () => {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="seller_page_start">
      <Container className="seller-page" maxWidth={"lg"}>
        <Card>
          <ServiceProviderResignup />
        </Card>
      </Container>
    </div>
  );
};

export default ServiceProviderSignup;
