import React, { useEffect, useState, useMemo } from "react";
import "./CategoryList.scss";
import axios from "../../api/axios";
import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  Grid,
  Modal,
  Paper,
  Typography,
  Skeleton,
} from "@mui/material";
import {
  removeButton,
  submitButton,
} from "../DesignTools/ButtonStyles/ButtonStyles";
import {
  API_AUTH_URL,
  CURR_USR_PRF_INFO,
  GET_RFQ_EXPLAIN,
  PRINT_PDF,
} from "../CompServices/Api_Routes_Helper";
import PropTypes from "prop-types";
import { DataGrid, GridRowsProp, GridColDef } from "@mui/x-data-grid";
import authHeader from "../../Services/auth-header";
import RFQModal from "./RFQModal";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import ReactPDF from "@react-pdf/renderer";
import PdfDocument from "../ServiceProvider/Requests/Invoice/PdfDocument";
import DownloadIcon from "@mui/icons-material/Download";
import DataTableBase from "../ReuseTable/DataTable";
import Moment from "react-moment";
import ReuseablebothSubDes from "../ReuseTable/ReuseablebothSubDes";
import { customizStyles } from "../ReuseTable/DataTable";
import LoadingBars from "../PageLoader/LoadingBars";
const TableHeaderSkeleton = ({ columns,height }) => {
  return (
    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
      <thead>
        <tr>
          {columns.map((width, index) => (
            <th key={index} style={{ width: `${100 / columns.length}%`, textAlign: 'center' }}>
              <Skeleton variant="text" animation="wave" width={width} height={height} />
            </th>
          ))}
        </tr>
      </thead>
      </table>
  );
};
function CategoryListView({ userId, onBack, dataGrid, editrfq }) {
  const columns = useMemo(
    () => [
      {
        name: <span className="QtnTable_headers">Category Name</span>,

        selector: (row) => row.categoryName,
        sortable: true,
      },
      {
        name: <span className="QtnTable_headers">Items</span>,
        selector: (row) => row.items,
        sortable: true,
        grow: 1,
      },
      {
        name: <span className="QtnTable_headers">Quantity</span>,
        selector: (row) => row.quantity,
        sortable: true,
        // grow: 1,
      },
      {
        name: <span className="QtnTable_headers">Unit</span>,
        selector: (row) => row.unit,
        sortable: true,
        grow: 1,
      },

      {
        name: <span className="QtnTable_headers">Price</span>,
        selector: (row) => row.price,
        format: (row, index) => {
          return `${Number(row.price).toLocaleString("en-IN")}`;
        },
        sortable: true,
      },

      // },
      {
        name: <span className="QtnTable_headers">Date</span>,
        selector: (row) => row.date,
        sortable: true,
        format: (row, index) => <Moment format="DD/MM/YYYY">{row.date}</Moment>,
      },
      // {
      //   name: "Status",
      //   selector: (row) => row.status,
      //   sortable: true,
      // },
    ],
    []
  );
  const [values, setValues] = useState([]);

  const invoiceData = values && values[0];

  const [userData, setUserData] = useState();

  const email = localStorage.getItem("email");
  const [request, setRequests] = useState([]);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [isLoading, setIsLoading] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);

  const [openResponseModal, setOpenResponseModal] = useState(false);
  const handleOpenResponseMenu = async (id) => {
    setOpenResponseModal(true);
    setIsLoading(true);
    try {
      const pdfdata = new URLSearchParams(invoiceData).toString();
      const pdfurl = `${PRINT_PDF}${pdfdata}`;

      await new Promise((resolve) => setTimeout(resolve, 2000));

      setPdfUrl(pdfurl);
      setIsLoading(false);
    } catch (error) {
      console.error("Error:", error);
      setIsLoading(false);
    }
  };
  const handleCloseResponseMenu = () => setOpenResponseModal(false);
  const fileName = "Invoice.pdf";
  useEffect(() => {
    try {
      const getValue = async () => {
        const { data } = await axios.get(`${GET_RFQ_EXPLAIN}/${userId}`, {
          headers: await authHeader(),
        });
        if (data !== null) {
          const { data: requestedData } = data;
          setValues([requestedData]);
          setRequests(requestedData?.requests);
        }
      };

      if (userId !== null) {
        getValue();
      }
    } catch (error) {
      console.error("Error fetching data", error);
    }
  }, [userId]);

  useEffect(() => {
    const getProfileINfo = async () => {
      try {
        const response = await axios.get(CURR_USR_PRF_INFO + `${email}`, {
          headers: await authHeader(),
        });
        setUserData(response.data[0]);
      } catch (error) {
        console.error(error.message);
        alert("Failed to retrieve Profile Information");
      }
    };
    getProfileINfo();
  }, []);

  const [isLoadingSkeleton, setIsLoadingSkeleton] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoadingSkeleton(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);
  const columnWidths = [150, 150, 150, 150, 150, 150];
  return (
    <div>
      <Container className="grid-main-container" maxWidth={"lg"}>
        <Grid item className="grid_comp">
          {userId &&
            values.length > 0 &&
            values.map((data, index) => (
              <div className="categoryview_mainWap" key={`uniqueKey_${index}`}>
                <div className="categoryview-card">
                  <div className="categoryview_QtnView">
                  {isLoadingSkeleton ? (
                  <Skeleton animation="wave" width={80} height={60} style={{float:'right'}} />
                  // <div className="loading-skeleton">
                  //   <Button style={{background:'gray',color:'gray'}} className="view-btn view-btns">
                  //     view PDF
                  //     <div className="loading-skeleton-animation"></div>
                  //     </Button>
                  // </div>
                ) : (
                  /* Actual button when not loading */
                  <Button
                    style={submitButton}
                    className="view-btn view-btns"
                    onClick={handleOpenResponseMenu}
                  >
                    View PDF
                  </Button>
                )}
                   
                  </div>
                  <div>
                    {isLoadingSkeleton ? (
                      <Skeleton
                      sx={{
                        height: 190,
                        width: '100%', 
                        borderRadius: '4px', 
                        marginTop:'-60px',
                        border: '1px solid #ccc',
                      }}
                      animation="wave"
                      variant="rectangular"
                    />
                      // <div>
                      //   <div style={{width:"100%",height:"210px",background:"gray",borderRadius:'5px'}}>
                      //     <div className="loading-skeleton-animation"></div>
                      //     </div>
                      // </div>
                    ):(
                  <div className="reusetable_alter addition_sty">
                    <ReuseablebothSubDes
                      title={"Client Request"}
                      subject={data.subject}
                      description={data.description}
                    />
                  </div>)}
                </div>
                </div>
               <div>
                {isLoadingSkeleton ? (
                  <>
                  <Skeleton variant="text" animation="wave" width={200} height={60}  style={{ marginBottom: 6 }} />
                  
    <TableHeaderSkeleton columns={columnWidths} height={50} />
    <TableHeaderSkeleton columns={columnWidths} height={30} />
    <TableHeaderSkeleton columns={columnWidths} height={30} />    
                </>
                //   <div>
                //   <div style={{width:"100%",height:"210px",background:"gray",borderRadius:'5px'}}>
                //     <div className="loading-skeleton-animation"></div>
                //     </div>
                // </div>
                ):(
                <div className="view-cat-grid">
                  <Paper
                    sx={{
                      // backgroundColor: "rgba(246,246,246,255)",
                      background:"transparent",
                      // boxShadow:
                      //   "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
                      marginLeft: "5px",
                      marginRight: "5px",
                      marginTop:'30px',
                      boxShadow:"none",
                    }}
                  >
                    <h2
                      style={{
                        color: "#007bff",
                        fontFamily: "var(--qup-heading-font)",
                        fontSize: "18px",
                        fontWeight: "bold",
                        marginLeft: "3px",
                        padding:"10px"
                      }}
                    >
                      Category View Table
                    </h2>
                    <DataTableBase
                      columns={columns}
                      data={request}
                      dense={false}
                      responsive={true}
                      customStyles={customizStyles}
                      fixedHeader
                      fixedHeaderScrollHeight="450px"
                      
                    />
                  </Paper>
                </div>
                )}
                </div>
                <RFQModal
                  open={openResponseModal}
                  onClose={handleCloseResponseMenu}
                  tableHeader={"Quick View of Quotation Invoice"}
                >
                  <Box sx={{ minWidth: "700px", overflowX: "auto" }}>
                    <br />

                    <div className="download-link">
                      <PDFDownloadLink
                        className="pdflink"
                        document={
                          <PdfDocument
                            Clientinvoicedata={invoiceData}
                            ClientDetails={userData}
                            tableValue={invoiceData.requests}
                          />
                        }
                        fileName={fileName}
                      >
                        <DownloadIcon
                          style={{ position: "relative", top: "7px" }}
                        />
                        &nbsp;&nbsp;Download Quotation
                      </PDFDownloadLink>
                    </div>

                    <br />
                    <br />

                    {isLoading ? (
                      <LoadingBars />
                    ) : pdfUrl ? (
                      <PDFViewer width={1150} height={500} showToolbar={false}>
                        <PdfDocument
                          Clientinvoicedata={invoiceData}
                          ClientDetails={userData}
                          tableValue={invoiceData.requests}
                        />
                      </PDFViewer>
                    ) : (
                      <div>No PDF available</div>
                    )}
                  </Box>
                </RFQModal>
              </div>
            ))}
        </Grid>

        <br />
      </Container>
    </div>
  );
}

CategoryListView.propTypes = {
  userId: PropTypes.string,
  onBack: PropTypes.func,
  dataGrid: PropTypes.bool,
  editrfq: PropTypes.bool,
};
export default CategoryListView;
