/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router components
import { useLocation, Link, useNavigate, NavLink } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "../../../Dcomponents/MDBox";
import MDInput from "../../../Dcomponents/MDInput";

// Material Dashboard 2 React example components
import Breadcrumbs from "../../Breadcrumbs";
import NotificationItem from "../../Items/NotificationItem";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
  customNavbarMenu,
  MenuBtn,
  MenuBox,
  MenubtnText,
} from "./styles";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "../../../context";
import MDButton from "../../../Dcomponents/MDButton";
import Dashboard from "../../../layouts/dashboard";
import MasterTemplates from "../../../layouts/templates/MasterTemplates";
import Profile from "../../../layouts/profile/ProfilePage";
import RfqNavigation from "../../../examples/Navbars/RfqNavigation";
import {
  Box,
  Button,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { FaScroll } from "react-icons/fa";
import {
  AccountCircle,
  Article,
  Autorenew,
  DashboardRounded,
  ForwardToInbox,
  Menu as MenuIcon,
  Person,
} from "@mui/icons-material";
import Tables from "../../../layouts/tables";

const navRoutes = [
  {
    name: "Dashboard",
    key: "dashboard",
    icon: (
      <Icon fontSize="small">
        <DashboardRounded />
      </Icon>
    ),
    route: "/vendorManagement/dashboard",
    component: <Dashboard />,
  },
  {
    name: "RFQ",
    key: "rfq",
    icon: (
      <Icon fontSize="small">
        <FaScroll />
      </Icon>
    ),
    route: "/vendorManagement/rfq",
    component: <RfqNavigation />,
  },
  {
    name: "Quotation Sent",
    key: "quotationsent",
    icon: (
      <Icon fontSize="small">
        <FaScroll />
      </Icon>
    ),
    route: "/vendorManagement/quotaionsent",
    component: <Tables />,
  },
  {
    name: "Profile",
    key: "profile",
    icon: (
      <Icon fontSize="small">
        <Person />
      </Icon>
    ),
    route: "/vendorManagement/profile",
    component: <Profile />,
  },
  {
    name: "Templates",
    key: "templates",
    icon: (
      <Icon fontSize="small">
        <Article />
      </Icon>
    ),
    route: "/vendorManagement/Quotation/templates",
    component: <MasterTemplates />,
  },
];

const drawerWidth = 240;

function DashboardNavbar(props) {
  const { windows, absolute, light, isMini } = props;
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const {
    // miniSidenav,
    transparentNavbar,
    fixedNavbar,
    openConfigurator,
    darkMode,
  } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);

  // Mobile App Drawer
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const container =
    windows !== undefined ? () => window().document.body : undefined;

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center", m: 0 }}>
      <Typography sx={{ m: 2 }} className="navbar_logo nav-vendor-logo">
        <Typography
          component="span"
          className="logo"
          onClick={() => navigate("/vendorManagement/dashboard")}
        >
          QuoteUrPrice
        </Typography>
      </Typography>
      <Divider />
      <List>
        {navRoutes.map((item, index) => (
          <ListItem key={item.key} disablePadding>
            <ListItemButton
              component={Link}
              to={item.route}
              sx={{ textAlign: "center" }}
            >
              {item.icon}
              <ListItemText primary={item.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(
        dispatch,
        (fixedNavbar && window.scrollY === 0) || !fixedNavbar
      );
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  // const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  const navigate = useNavigate();
  const handleSubmit = () => {
    navigate("/vendorManagement/profile");
  };
  const handleLogout = () => {
    localStorage.removeItem("v_info");
    localStorage.removeItem("v_ID");
    navigate("/signin");
  };

  const handelRefresh = () => {
    window.location.reload();
  };

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem
        onClick={handleSubmit}
        icon={<Icon>person</Icon>}
        title="Update Profile"
      />
      <NotificationItem
        onClick={handleLogout}
        icon={<Icon>logout</Icon>}
        title="Logout"
      />
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({
    palette: { dark, white, text },
    functions: { rgba },
  }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
    fontSize: "30px !important",
  });

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) =>
        navbar(theme, { transparentNavbar, absolute, light, darkMode })
      }
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{
            mr: 2,
            display: { md: "none" },
          }}
        >
          <MenuIcon />
        </IconButton>
        <MDBox
          color="inherit"
          mb={{ xs: 1, md: 0 }}
          sx={(theme) => navbarRow(theme, { isMini })}
        >
          <Typography className="navbar_logo">
            <Typography
              component="span"
              className="logo"
              sx={{
                flexGrow: 1,
                // display: { xs: "none", sm: "block" }
              }}
              onClick={() => navigate("/vendorManagement/dashboard")}
            >
               QuoteUrPrice
            </Typography>
          </Typography>

          {/* <Breadcrumbs
            icon="home"
            title={route[route.length - 1]}
            route={route}
            light={light}
          /> */}
          {/* <IconButton
            size="small"
            color="inherit"
            sx={customNavbarMenu}
            onClick={handleMiniSidenav}
          >
            <Icon sx={iconsStyle} fontSize="medium">
              {miniSidenav ? "menu_open" : "menu"}
            </Icon>
          </IconButton> */}
        </MDBox>

        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            <MenuBox sx={{ display: { xs: "none", sm: "none", md: "flex" } }}>
              {navRoutes.map((item, index) => (
                <NavLink
                  className="vendor-nav-menu"
                  to={item.route}
                  key={item.key}
                >
                  {/* <Icon className="vendor-nav-icon">{item.icon}</Icon> */}
                  <MenubtnText component="span" className="vendor-nav-item">
                    {item.name}
                  </MenubtnText>
                </NavLink>
                // <MenuBtn
                //   className="vendor-nav-menu"
                //   component={NavLink}
                //   to={item.route}
                //   key={item.key}
                //   activeClassName="active"
                // >
                //   {/* <Icon className="vendor-nav-icon">{item.icon}</Icon> */}
                //   <MenubtnText component="span" className="vendor-nav-item">
                //     {item.name}
                //   </MenubtnText>
                // </MenuBtn>
              ))}
            </MenuBox>
            {/* <MDBox pr={1}>
              <MDInput label="Search here" />
            </MDBox> */}
            <MDBox sx={{ display: "flex" }} color={light ? "white" : "inherit"}>
              {/* <Link to="http://localhost:3000/sign-in/"> */}
              <IconButton
                sx={navbarIconButton}
                size="large"
                disableRipple
                onClick={(event) => handleOpenMenu(event)}
              >
                <Icon sx={iconsStyle}>
                  <AccountCircle sx={{fontSize:'3rem !important'}} />
                </Icon>
              </IconButton>
              <Button
                startIcon={<Autorenew />}
                size="large"
                onClick={handelRefresh}
                // sx={{ display: { xs: "none", sm: "none", md: "flex" } }}
              >
                Refresh
              </Button>
              {/* </Link> */}
              {/* <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton> */}
              {/* <IconButton
                size="medium"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                onClick={handleConfiguratorOpen}
              >
                <Icon sx={iconsStyle}>settings</Icon>
              </IconButton> */}
              {/* <IconButton
                size="medium"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
              >
                <Icon sx={iconsStyle}>notifications</Icon>
              </IconButton> */}
              {renderMenu()}
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
      <Box sx={{ margin: "0px" }} component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            margin: "0px",
            display: { sm: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
