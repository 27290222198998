import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, Card, Container, Grid, Modal } from "@mui/material";
import { Carousel } from "antd";
import axios from "../../../api/axios";
import { CarouselLists } from "../../End-User/User-Data/MenuItems";
import {
  registerButton,
  removeButton,
  submitButton,
} from "../../DesignTools/ButtonStyles/ButtonStyles";
import { GET_RFQ_RESPONSE_QUOTE } from "../../CompServices/Api_Routes_Helper";

function PostResponseViewPage() {
  const [Values, setValues] = useState([]);
  const navigate = useNavigate();

  const { id } = useParams();

  useEffect(() => {
    getValue();
  }, []);

  const getValue = async () => {
    await axios.get(`${GET_RFQ_RESPONSE_QUOTE}${id}`).then((res) => {
      setValues(res.data.data);
    });
  };
  const onSubmit = () => {
    navigate("/vendor/get_postOrders");
  };

  console.log("Values", Values);
  const termsAndCondition = Values.termsAndCondition;
  const specialNote = Values.specialNote;

  const handleSubmit = (e) => {
    console.log("e", e);
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [view, setView] = React.useState(false);
  const handleView = () => setView(true);
  const handleViewClose = () => setView(false);

  return (
    <div>
      <Container>
        <Grid container className="grid_main_viewpage">
          <Grid item md={12} sm={12} className="grid_comp">
            <Card className="card-comp-view">
              <div className="respond">
                <h4>Category View</h4>
                <Grid container>
                  <Grid sm={12} md={5} className="viewPart">
                    <label>CategoryName</label>
                  </Grid>
                  <Grid sm={12} md={7}>
                    <p>{Values.categoryName}</p>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid sm={12} md={5} className="viewPart">
                    <label>Subject</label>
                  </Grid>
                  <Grid sm={12} md={7}>
                    <p className="">{Values.subject}</p>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid sm={12} md={5} className="viewPart">
                    <label>Price</label>
                  </Grid>
                  <Grid sm={12} md={7}>
                    <p className="">{Values.price}</p>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid sm={12} md={5} className="viewPart">
                    <label>DeliveryDate</label>
                  </Grid>
                  <Grid sm={12} md={7}>
                    <p className="">{Values.deliveryDate}</p>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid sm={12} md={5} className="viewPart">
                    <label>Terms And Condition</label>
                  </Grid>
                  <Grid sm={12} md={7}>
                    <button onClick={handleOpen} className="button_tag">
                      Click to Open
                    </button>
                  </Grid>
                  <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style} className="modal_div">
                      {termsAndCondition?.map((item, i) => (
                        <div key={i}>
                          <i className="fa fa-close" onClick={handleClose}></i>
                          <Grid container>
                            <Grid item md={4} sm={12}>
                              <label>Terms</label>
                            </Grid>
                            <Grid item md={8} sm={12}>
                              <p className="">{item.terms}</p>
                            </Grid>
                          </Grid>
                          <Grid container style={{ marginTop: "8px" }}>
                            <Grid item md={4} sm={12}>
                              <label>Description</label>
                            </Grid>
                            <Grid item md={8} sm={12}>
                              <p>{item.description}</p>
                            </Grid>
                          </Grid>
                          <br />
                        </div>
                      ))}
                    </Box>
                  </Modal>
                </Grid>
                <Grid container>
                  <Grid sm={12} md={5} className="viewPart">
                    <label>SpecialNote</label>
                  </Grid>
                  <Grid sm={12} md={7}>
                    <button onClick={handleView} className="button_tag">
                      Click to Open
                    </button>
                  </Grid>
                </Grid>
                <Modal
                  open={view}
                  onClose={handleViewClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={stylee} className="modal_div">
                    {specialNote?.map((item, i) => (
                      <div key={i}>
                        <i
                          className="fa fa-close"
                          onClick={handleViewClose}
                        ></i>
                        <Grid container>
                          <Grid item md={5} sm={12}>
                            <label>Special Notes</label>
                          </Grid>
                          <Grid item md={7} sm={12}>
                            <p className="">{item.special}</p>
                          </Grid>
                        </Grid>
                        <br />
                      </div>
                    ))}
                  </Box>
                </Modal>
              </div>
              <div className="categoryInputs">
                <Button
                  variant="contained"
                  style={submitButton}
                  onClick={handleSubmit}
                >
                  Update
                </Button>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Button
                  variant="contained"
                  style={removeButton}
                  // className={registerButton}
                  onClick={onSubmit}
                >
                  Back
                </Button>
              </div>
            </Card>
          </Grid>
          {/* <Grid item className="grid-componet-sec"> */}
          {/* <Carousel autoplay className="carouse_list">
              {CarouselLists.map((list, i) => (
                <div key={i}>
                  <img
                    className="carousel_image"
                    src={list.image}
                    alt="carousel"
                  />
                </div>
              ))}
            </Carousel> */}
          {/* <img src="https://cdn.pixabay.com/photo/2018/03/10/12/00/teamwork-3213924__340.jpg" /> */}
          {/* </Grid> */}
        </Grid>
      </Container>
    </div>
  );
}
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};
const stylee = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};
export default PostResponseViewPage;
