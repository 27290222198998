import { Container, Card } from "@mui/material";
import React, { useEffect, useState } from "react";
import SimpleCarousel from "./Simple_Carousel";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Button, CardActionArea, CardActions } from "@mui/material";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import {
  removeButton,
  submitButton,
} from "../DesignTools/ButtonStyles/ButtonStyles";
// import image1 from "./image1.jpg";
// import image2 from "./image2.jpg";
import image1 from "../../images/image1.jpg";
import image2 from "../../images/image2.jpg";
import mobile1 from "../../images/mobile1.jpg";
import mobile2 from "../../images/mobile2.jpg";

import rfq from "../../images/rfq.jpg";
import process from "../../images/process.webp";
import Footer from "../Footer";
import ScrollLine from "../CategoryDesign/ScrollLine";

const InfoPage = () => {
  const [bgIndex, setBgIndex] = useState(0);
  const backgroundImages = [image1, image2];
  const backgroundImagesSmall = [mobile1, mobile2];
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 400);

  const handleResize = () => {
    setIsSmallScreen(window.innerWidth <= 400);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      // Change the background image index every 5 seconds
      setBgIndex((prevIndex) => (prevIndex + 1) % backgroundImages.length);
    }, 5000); // Change image every 5 seconds

    // Listen for window resize events
    window.addEventListener("resize", handleResize);

    return () => {
      clearInterval(interval);
      window.removeEventListener("resize", handleResize);
    };
  }, [isSmallScreen]);

  const selectedBackgroundImages = isSmallScreen
    ? backgroundImagesSmall
    : backgroundImages;

  return (
    <>
    <ScrollLine />
      <section>
        <div className="info-app">
          <div
            className="info-background-image"
            style={{
              backgroundImage: `url(${selectedBackgroundImages[bgIndex]})`,
            }}
          >
            <div className="info-container" >
              <h2 className="rfq-name">What Is a Request for Quote (RFQ)?</h2>
              <br />
              <p>
                A request for quote (RFQ) is a solicitation for goods or
                services in which a company invites vendors to submit price
                quotes and bid on the job.
              </p>

              <a className="box__link buttons-animations" href="#">
                More Info...
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </a>
              <div className="hide-carousel">
                <SimpleCarousel />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        {/* <div className="info-img"> */}
        {/* <div className="text-onimg">
          <p>Info-view</p>
        </div> */}
        {/* </div> */}
        {/* <Container style={{ marginTop: "-7rem" }}>
            <Card
              variant="outlined"
              className="rfq"
              style={{ padding: "1rem" }}
            >
              <div className="rfq-text" item xs={6}>
                <h1 className="rfq-name">What Is a Request for Quote (RFQ)?</h1>
                <p>
                  A request for quote (RFQ), also known as an invitation for bid
                  (IFB), is a process in which a company solicits select
                  suppliers and contractors to submit price quotes and bids for
                  the chance to fulfill certain tasks or projects. The RFQ
                  process is especially important to businesses that need a
                  consistent supply of a specific number of standard products.
                  Companies may send RFQs alone or before a request for proposal
                  (RFP).{" "}
                </p>
                <p>
                  A request for a quote (RFQ) is a business process in which a
                  business solicits quotes from select suppliers and contractors
                  for a specific task or project.
                </p>
              </div>
              <div className="rfq-carousel">
                <SimpleCarousel />
              </div>
            </Card>
          </Container> */}
      </section>
      <section className="rfq-slide">
        <Container>
          <p className="four-step">
            The Request for Quotation (RFQ) Process in 4 Steps
          </p>
        </Container>

        <div className="inforow">
          <p className="rfq-title">
            A request for quote (RFQ) is a solicitation for goods or services in
            which a company invites vendors to submit price quotes and bid on
            the job.
          </p>
          <img
            src="https://icons.veryicon.com/png/o/commerce-shopping/alibaba-international-station-icon-library/rfq-filling-1.png "
            alt="vvv"
            width="300px"
            height="300px"
          />
        </div>
        <div className="infofourpoint-4">
          <div className="number" style={{ color: "#3b5998" }}>
            04
          </div>

          <div>
            <h1 className="text-blue">Advise unsuccessful suppliers</h1>
            <p className="slide-text">
              Lorem ipsum, iure soluta aliquid, doloremque veniam quisquam harum
              expedita! Dolore omnis laborum repellat. Lorem ipsum dolor sit
              amet.
            </p>
          </div>
        </div>
        <div className="infofourpoint-3">
          <div className="number" style={{ color: " rgb(247, 141, 42)" }}>
            03
          </div>

          <div>
            <h1 className="text-orange">Select the successful supplier</h1>
            <p className="slide-text">
              Lorem ipsum, iure soluta aliquid, doloremque veniam quisquam harum
              expedita! Dolore omnis laborum repellat. Lorem ipsum dolor sit
              amet consectetur adipisicing elit. Quam, natus.
            </p>
          </div>
        </div>
        <div className="infofourpoint-2">
          <div className="number" style={{ color: "#3b5998" }}>
            02
          </div>

          <div>
            <h1 className="text-blue">Define the supplier list</h1>
            <p className="slide-text">
              Lorem ipsum, iure soluta aliquid, doloremque veniam quisquam harum
              expedita! Dolore omnis laborum repellat. so is that project over
            </p>
          </div>
        </div>
        <div className="infofourpoint-1">
          <div className="number" style={{ color: "rgb(247, 141, 42)" }}>
            01
          </div>

          <div>
            <h1 className="text-orange">Preparation of the document</h1>
            <p className="slide-text">
              Lorem ipsum, iure soluta aliquid, doloremque veniam quisquam harum
              expedita! Dolore omnis laborum repellat.
            </p>
          </div>
        </div>
      </section>

      {/* four steps for rfq process  */}
      <section className="step">
        <Container>
          <div className="step-list">
            <div className="list-card">
              <p style={{ marginLeft: "25px", paddingTop: "20px",fontSize:"24px",color:"black" }}>
                The RFQ process in 4 Steps
              </p>
              <div className="infolist">
                <p>
                  <a href="#1">1. Preparation of the document</a>
                </p>
                <p>
                  <a href="#2">2. Define the supplier list</a>
                </p>
                <p>
                  <a href="#3">3. Select the successful supplier</a>
                </p>
                <p>
                  <a href="#4">4. Advise unsuccessful suppliers</a>
                </p>
              </div>
              <div className="l-card">
                <Card sx={{ maxWidth: 345 }}>
                  <CardMedia
                    component="img"
                    height="270"
                    src={rfq}
                    // image="https://a.storyblok.com/f/52352/1112x943/20b4dd4572/what-is-an-rfq-request-for-quote-scam.jpg"
                    alt="green iguana"
                  />

                  {/* <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          RFQs
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          Lorem ipsum dolor sit amet consectetur, adipisicing
                          elit. Iure alias assumenda blanditiis modi ipsum
                          dolorum voluptatibus voluptas dicta aliquid maiores
                          facere, sequi temporibus asperiores ea aut quis
                          eveniet vitae iste.
                        </Typography>
                      </CardContent>
                   
                    <CardActions>
                      <Button size="small" color="primary">
                        MORE
                      </Button>
                    </CardActions> */}
                </Card>
              </div>
            </div>
            <div className="list-text">
              <div id="1">
                {" "}
                <h1 style={{ paddingTop: "20px" }}>
                  The RFQ process in 4 Steps :
                </h1>
                <p className="list-text-p">
                  While requests for quotes come in all shapes and sizes, they
                  generally follow six key steps. Let's review the process from
                  the point of view of a game of snakes and ladders.
                </p>
                <img
                  // src="https://sievo.com/hs-fs/hubfs/RFQ%20Process.jpg?width=1572&height=650&name=RFQ%20Process.jpg"
                  src={process}
                  alt="dd"
                  width="90%"
                  height="250px"
                />
              </div>
              <div>
                <h1>1. Preparation of the document</h1>
                <div className="list-text-p">
                  A well-constructed RFQ should open with an introduction to the
                  company and its business and a need statement. It then allows
                  suppliers to express their offers clearly to fulfill that need
                  and provides the buyer with a fair way to select the best
                  solution. An efficient way to do this is to provide bidders
                  with a template designed to elicit the complete information
                  required in a format that allows for easy comparison. Time
                  spent in preparation will save time and frustration later.
                  Start your RFQ by preparing the relevant documentation.
                  Identify clear requirements with input from internal
                  stakeholders to make sure you’ve included all the
                  requirements, which are at least these:
                  <ul style={{ marginLeft: "3rem" }}>
                    <li>Delivery requirements</li>
                    <li>Quantities</li>
                    <li>Payment Terms</li>
                    <li>The proposed method of evaluation</li>
                    <li>Decision timeline and review process</li>
                    <li>Contract terms and conditions</li>
                    <li>Submission requirements</li>
                  </ul>
                </div>
              </div>
              <div id="2">
                <h1>2. Define the supplier list </h1>
                <p className="list-text-p">
                  The RFQ could be open to all possible bidders. The global
                  market is dynamic, and there are always new entrants. To
                  streamline the process, it may be necessary to run a
                  pre-selection event before sending your RFQ to a closed list
                  of approved bidders. This is the point at which you need to
                  assess both financial and operational risks.
                </p>
              </div>
              <div id="3">
                <h1>3. Select the successful supplier</h1>
                <p className="list-text-p">
                  The evaluation process is easier and faster if an efficient,
                  well-thought-out template is used. The successful bidder will
                  be the one offering the lowest price for the goods or services
                  specified while complying with all selection criteria. A
                  clarification process may be necessary, and minor negotiations
                  are needed to reach the correct result. The deal is only
                  completed when the contract is signed.
                </p>
              </div>
              <div id="4">
                <h1>4. Advise unsuccessful suppliers</h1>
                <p className="list-text-p">
                  Notify the other bidders that the contract has been awarded.
                  Thank them for their participation; you may need them again at
                  a future date. Lorem ipsum dolor sit amet consectetur
                  adipisicing elit. Eligendi similique odio nobis perspiciatis
                  veritatis debitis, voluptates in cum iure ipsum, sequi
                  consequatur voluptatibus? Consectetur saepe harum atque
                  praesentium sed explicabo? Lorem, ipsum dolor sit amet
                  consectetur adipisicing elit. Fuga ex non illum odio
                </p>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <div  className="goback">
        <a href="/user_in/categoryList">
          <Fab style={removeButton} variant="extended">
            Back
          </Fab>
        </a>
      </div>
     <div style={{borderTop:"1px solid grey"}}>
      <Footer />
      </div>
    </>
  );
};

export default InfoPage;
