import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const PublicRoute = () => {
  const isLoggedIn = useSelector(
    (state: any) => state.adminauth.authentication
  );
  return isLoggedIn ? <Navigate to="/qup/admin/" /> : <Outlet />;
};

export default PublicRoute;
