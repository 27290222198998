import { Grid, Typography } from "@mui/material";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "../CategoryList/CategoryList.scss";
import PropTypes from "prop-types";
import { json } from "react-router-dom";
import axios from "../../api/axios";
import {
  ADMIN_FETCH_DETAILS,
  ADMIN_UPDATE_DETAILS,
  host,
} from "../CompServices/Api_Routes_Helper";

function ReuseablebothSubDes({
  title,
  subject,
  description,
  isAdminEdit,
  id,
  // vendorDescription,
  // vendorSubject,
}) {
  const [containerHeight, setContainerHeight] = useState("auto");
  const containerRef = useRef(null);

  //usestate to update details
  const [newSub, setNewSub] = useState("");
  const [newDesc, setNewDesc] = useState("");
  const [editStart, setEditStart] = useState(false);
  const [prefill, setPrefill] = useState(true);
  const [btnName, setBtnName] = useState("Close");

  useEffect(() => {
    // Calculate the height based on the content
    if (containerRef.current) {
      const contentHeight = containerRef.current.scrollHeight;
      // Set a minimum height to avoid being too small
      const minHeight = 200; // Adjust this value as needed
      setContainerHeight(
        contentHeight > minHeight ? "auto" : `${containerHeight}`
      );
      // setContainerHeight(`${Math.max(contentHeight, minHeight)}px`);
    }
  }, [subject, description]);

  useEffect(() => {
    const handleEdit = async (rowid) => {
      const { data } = await axios.get(`${ADMIN_FETCH_DETAILS}/${rowid}`);
      //in case if subject and descp stil not yet edited
      if (data !== null && data?.responses.length > 0) {
        const { subject, description } = data?.responses[0];
        setPrefill(true);
        // setTimeout(() => {
        setNewSub(subject);
        setNewDesc(description);
        // }, 500);
      }
    };
    if (isAdminEdit && id) {
      handleEdit(id);
    }
  }, [id]);
  const handleAdminUpdate = async () => {
    try {
      const dataForm = {
        subject: newSub,
        description: newDesc,
      };
      const { data } = await axios.put(
        `${ADMIN_UPDATE_DETAILS}/${id}`,
        dataForm
      );
      if (data) {
        const { responses } = data;
        setNewDesc(responses?.description);
        setNewSub(responses?.subject);
      }

      setEditStart(false);
    } catch (error) {
      console.error(error);
      setEditStart(false);
    }
  };
  // const handleButtonClick = (e) => {
  //   e.preventDefault();

  //   setBtnName((prevBtnName) => (prevBtnName === "Close" ? "Edit" : "Close"));
  //   setEditStart((prevEditStart) => !prevEditStart);
  // };
  const handleButtonClick = (e) => {
    e.preventDefault();
  
    // Toggle edit mode
    setEditStart((prevEditStart) => !prevEditStart);
  
    // Prefill values if entering edit mode
    if (!editStart && isAdminEdit) {
      const handleEdit = async (rowid) => {
        const { data } = await axios.get(`${ADMIN_FETCH_DETAILS}/${rowid}`);
        //in case if subject and desc still not yet edited
        if (data !== null && data?.responses.length > 0) {
          const { subject, description } = data?.responses[0];
          setPrefill(true);
          setNewSub(subject);
          setNewDesc(description);
        }
      };
      if (id) {
        handleEdit(id);
      }
    }
  
    // Toggle button name
    setBtnName((prevBtnName) => (prevBtnName === "Close" ? "Edit" : "Close"));
  };
  
  
  useEffect(() => {
    const ele = document.querySelector(".admin_subject_Add");
    if (isAdminEdit && ele) {
      if(newSub && newSub.length === 0) {
        ele.classList.add("d-none");
      } else {
        ele.classList.remove("d-none");
      }
    }
  }, [newSub,isAdminEdit]);
  useEffect(() => {
    const ele = document.querySelector(".admin_Desc_Add");
    if (isAdminEdit && ele) {
      if (newDesc && newDesc.length === 0) {
        ele.classList.add("d-none");
      } else {
        ele.classList.remove("d-none");
      }
    }
  }, [newDesc, isAdminEdit]);
  // useEffect(() => {
  //   if (newSub.length == 0 || newDesc.length == 0) {
  //     setBtnName((prev) => (prev === "Close" ? "Edit" : "Edit"));
  //   }
  // }, []);
  return (
    <React.Fragment>
      <Grid container>
        <Grid
          item
          xs={12}
          pb={1}
          sx={{ width: "100%", minWidth: "600px", margin: "10px 0px" }}
        >
          <div
            // className="borders-text"
            ref={containerRef}
            style={{ height: containerHeight }}
          >
            <h2 className="headding ml-1">{title}</h2>

            <Grid
              container
              style={{
                marginTop: "10px",
                marginBlockEnd: 15,
                alignItems: "center",
              }}
            >
              <Grid item sm={6} md={4} lg={2} xs={10} className="respond res">
                <p className="subject-text">Subject</p>
              </Grid>
              <Grid item sm={6} xs={6} className="respond clientborder">
                <Typography
                  className="scrollable-content text-center"
                  style={{ textIndent: "5px", fontSize: "16px" }}
                  component={"p"}
                  variant="body1"
                >
                  {subject}
                </Typography>
              </Grid>
            </Grid>
            {editStart ||
              (prefill && isAdminEdit && (
                <Grid
                  container
                  style={{ marginTop: "-25px" }}
                  className="admin_subject_Add"
                >
                  <Grid
                    item
                    sm={6}
                    md={4}
                    lg={2}
                    xs={10}
                    className="respond res"
                  >
                    <p className="subject-text">New subject</p>
                  </Grid>
                  <Grid item sm={6} xs={6} className="respond clientborder">
                    <input
                      id="updateSub"
                      type="text"
                      className="form-control mb-1"
                      onChange={(e) => setNewSub(e.target.value)}
                      value={newSub}
                    />
                  </Grid>
                </Grid>
              ))}

            <Grid container style={{ marginTop: "15px" }}>
              <Grid item sm={6} md={4} lg={2} xs={10} className="respond res">
                <p className="subject-text">Description</p>
              </Grid>
              <Grid item sm={6} xs={6} className="respond clientborder">
                <Typography
                  className="scrollable-content"
                  component={"p"}
                  style={{ textIndent: "5px", fontSize: "16px" }}
                  variant="body1"
                >
                  {description}
                </Typography>
              </Grid>
            </Grid>
            {editStart ||
              (prefill && isAdminEdit && (
                <Grid
                  container
                  style={{ marginTop: "-10px" }}
                  className="admin_Desc_Add"
                >
                  <Grid
                    item
                    sm={6}
                    md={4}
                    lg={2}
                    xs={10}
                    className="respond res"
                  >
                    <p className="subject-text">New description</p>
                  </Grid>
                  <Grid item sm={6} xs={6} className="respond clientborder">
                    <textarea
                      className="form-control mb-1"
                      onChange={(e) => setNewDesc(e.target.value)}
                      rows={3}
                      cols={30}
                      value={newDesc}
                    />
                  </Grid>
                </Grid>
              ))}

            {isAdminEdit && (
              <div className="col-lg-12">
                <div className=" d-flex float-right ">
                  <button
                    type="button"
                    id="adminEdtCLS"
                    className="btn-outline-secondary btn btn-md"
                    onClick={handleButtonClick}
                  >
                    {btnName}
                  </button>
                  <button
                    type="submit"
                    className="btn btn-outline-success btn-md"
                    onClick={handleAdminUpdate}
                  >
                    update
                  </button>
                </div>
              </div>
            )}
          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
export default ReuseablebothSubDes;
ReuseablebothSubDes.defaultProps = {
  title: "Client Request",
  subject: "",
  description: "",
  isAdminEdit: false,
};
ReuseablebothSubDes.propTypes = {
  title: PropTypes.string,
  subject: PropTypes.string,
  description: PropTypes.string,
  isAdminEdit: PropTypes.bool,
};
