import { SmallBox } from "../components";
import React, { useState, useEffect } from "react";
import { ContentHeader } from "../components";
import axios from "../../../api/axios";
import {
  ADMIN_CLIENT_DATA,
  ADMIN_FETCH_RFQS,
  ADMIN_VENDOR_DATA,
} from "../../CompServices/Api_Routes_Helper";
import {
  DataGrid,
  GridActionsCellItem,
  GridRowParams,
  GridToolbar,
} from "@mui/x-data-grid";
import { Box } from "@mui/material";
import { AdminGreenIcon,AdminBlueIcon, AdminRedIcon } from "../../Syledcomponent/StyleFiled";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReuseDataGrid from "../../ReuseTable/ReuseDataGrid";
interface Data{
  _id:string,
  email:string,
  category:any
}
const Dashboard = () => {
  const [vendorData, setVendorData] = useState<Data[]>([]);
  const [clientData, setClientData] = useState<Data[]>([]);
  const [adminrfq, setAdminRfqData] = useState([]);
  const [activeDataGrid, setActiveDataGrid] = useState<string | null>(null);
  const countOfActionTaken = adminrfq.filter(
    (item:any) =>
      item.responseSubmissionStatus &&
      item.responseSubmissionStatus.includes("Action Taken")
  ).length;

  useEffect(() => {
    fetchVendorData();
    fetchClientData();
    fetchAdminrfqData();
  }, []);

  const fetchVendorData = async () => {
    try {
      const { data } = await axios.get(`${ADMIN_VENDOR_DATA}`);
      setVendorData(data?.data);
    } catch (error) {
      console.error("Error:", error);
      // Handle error cases
    }
  };
  const fetchClientData = async () => {
    try {
      const response = await axios.get(`${ADMIN_CLIENT_DATA}`);
      setClientData(response.data);
    } catch (error) {
      console.error("error", error);
    }
  };
  const fetchAdminrfqData = async () => {
    try {
      const rfqdetail = await axios.get(`${ADMIN_FETCH_RFQS}`);
      setAdminRfqData(rfqdetail.data);
    } catch (error) {
      console.error("error", error);
    }
  };
  const handleDeleteRow = (id: string) => {
    setClientData((prevClientData) =>
      prevClientData.filter((row) => row?._id !== id)
    );
  };
  const handleDeleteRowVendor = (id: string) => {
    setVendorData((prevClientData) =>
      prevClientData.filter((row) => row?._id !== id)
    );
  };
  const columnsclient = [
    { field: "email", headerName: "Email", flex: 1 },
    { field: "firstName", headerName: "First Name", flex: 1 },
    { field: "lastName", headerName: "Last Name", flex: 1 },
    { field: "phone", headerName: "Phone", flex: 1 },
    { field: "address", headerName: "Address", flex: 1 },
    {
      field: "action",
      headerName: "Action",
      type: "actions",
      flex: 1,
      getActions: (params: GridRowParams) => [
        <>
          <GridActionsCellItem
            className="admin-styleicon"
            label="Accept"
            icon={<AdminBlueIcon />}
            onClick={() => window.alert("accept")}
            title="Edit"
          />
          <GridActionsCellItem
            className="admin-styleicon"
            label="Reject"
            icon={<AdminRedIcon />}
            // onClick={(e) => console.log("Reject")}
            onClick={() => handleclientConfirmation(params.id as string)}
            title="Reject"
          />
        </>,
      ],
    },
    // Add more columns as needed
  ];
  const columnsvendor = [
    { field: "email", headerName: "Email", flex: 1 },
    { field: "username", headerName: "User Name", flex: 1 },
    { field: "companyname", headerName: "Company Name", flex: 1 },
    { field: "category", headerName: "Category Name", flex: 1 },
    // { field: "location", headerName: "Location", flex: 1 },
    {
      field: "action",
      headerName: "Action",
      type: "actions",
      flex: 1,
      getActions: (params: GridRowParams) => [
        <>
          <GridActionsCellItem
            className="admin-styleicon"
            label="Accept"
            icon={<AdminBlueIcon />}
            onClick={() => window.alert("accept")}
            title="Edit"
          />
          <GridActionsCellItem
            className="admin-styleicon"
            label="Reject"
            icon={<AdminRedIcon />}
            onClick={() => handlevendorConfirmation(params.id as string)}
            title="Reject"
          />
        </>,
      ],
    },
  ];
  const handlevendorConfirmation = (id: string) => {
    const isConfirmed = window.confirm("Are you sure you want to reject?");

    if (isConfirmed) {
      handleDeleteRowVendor(id);
    } else {
    }
  };
  const handleclientConfirmation = (id: string) => {
    const clientconfirm = window.confirm("Are you sure you want to reject");
    if (clientconfirm) {
      handleDeleteRow(id);
    } else {
    }
  };
  const handleDataGridToggle = (dataKey: string) => {
    setActiveDataGrid(dataKey);
  };
  const handleRemoveButtonClick = () => {
    setActiveDataGrid(null);
  };

  return (
    <div>
      <ToastContainer />
      <ContentHeader title="Dashboard" />
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3 col-6">
              <div className="small-box bg-warning">
                <div className="inner">
                  <h3>{clientData.length}</h3>
                  <p>Aggregate clientele count</p>
                </div>
                <div className="icon">
                  <i className="ion ion-person-add" />
                </div>
                <a
                  href="#"
                  onClick={() => handleDataGridToggle("clientData")}
                  className="small-box-footer"
                >
                  More info <i className="fas fa-arrow-circle-right" />
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div className="small-box bg-info">
                <div className="inner">
                  <h3>{vendorData.length}</h3>
                  <p>Valuable vendor count</p>
                </div>
                <div className="icon">
                  <i className="ion ion-bag" />
                </div>
                <a
                  href="#"
                  onClick={() => handleDataGridToggle("vendorData")}
                  className="small-box-footer"
                >
                  More info <i className="fas fa-arrow-circle-right" />
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div className="small-box bg-success">
                <div className="inner">
                  <h3>{adminrfq.length}</h3>
                  <p>No of RFQ's received</p>
                </div>
                <div className="icon">
                  <i className="ion ion-stats-bars" />
                </div>
                <a href="#" className="small-box-footer">
                  More info <i className="fas fa-arrow-circle-right" />
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div className="small-box bg-danger">
                <div className="inner">
                  <h3>{countOfActionTaken}</h3>

                  <p>No of RFQ's respond</p>
                </div>
                <div className="icon">
                  <i className="ion ion-pie-graph" />
                </div>
                <a href="#" className="small-box-footer">
                  More info <i className="fas fa-arrow-circle-right" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {activeDataGrid && (
        <section className={`content ${activeDataGrid ? "detailsshow" : ""}`}>
          <div className="container-fluid">
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">
                  {activeDataGrid === "clientData"
                    ? "Client Details"
                    : "Vendor Details"}
                </h3>
                <div className="card-tools">
                  <button
                    type="button"
                    className="btn btn-tool"
                    data-widget="collapse"
                    data-toggle="tooltip"
                    title="Collapse"
                  >
                    <i className="fa fa-minus" />
                  </button>
                  <button
                    type="button"
                    className="btn btn-tool"
                    data-widget="remove"
                    data-toggle="tooltip"
                    title="Remove"
                    onClick={handleRemoveButtonClick}
                  >
                    <i className="fa fa-times" />
                  </button>
                </div>
              </div>
              {/* <div className="card-body ">
                <Box height={"auto"} p={1}>
                  <DataGrid
                    // columns={activeDataGrid === "clientData" ? columnsclient : columnsvendor}
                    columns={
                      activeDataGrid === "clientData"
                        ? columnsclient
                        : columnsvendor.map((column) => ({
                            ...column,
                            headerClassName: "custom-datagrid-header",
                          }))
                    }
                    rows={
                      activeDataGrid === "clientData" ? clientData : vendorData
                    }
                    getRowId={(row: any) => row._id}
                    initialState={{
                      pagination: {
                        paginationModel: {
                          pageSize: 5,
                        },
                      },
                      columns: {
                        columnVisibilityModel: {
                          _id: false,
                          responseSubmissionStatus: true,
                          updatedAt: false,
                        },
                      },
                    }}
                    slots={{
                      toolbar: GridToolbar,
                    }}
                    slotProps={{
                      columnsPanel: {
                        disableHideAllButton: true,
                        disableShowAllButton: true,
                      },
                    }}
                    localeText={{ noRowsLabel: "Found no Vendors" }}
                    pageSizeOptions={[5]}
                  />
                </Box>
              </div> */}
               <div className="card-body ">
              <ReuseDataGrid columns={activeDataGrid === "clientData" ? columnsclient : columnsvendor.map(column => ({
                  ...column,
                  headerClassName: 'custom-datagrid-header',
                }))}
                rows={activeDataGrid === "clientData" ? clientData : vendorData} />
            </div>
            </div>           
          </div>
        </section>
      )}
    </div>
  );
};

export default Dashboard;
