/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Checkbox, IconButton } from "@mui/material";
// Material Dashboard 2 React components
import MDBox from "../../Dcomponents/MDBox";
import MDTypography from "../../Dcomponents/MDTypography";

import { BiSolidCloudUpload } from "react-icons/bi";
// Material Dashboard 2 React example components
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Footer from "../../examples/Footer";
import ProfileInfoCard from "../../examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "../../examples/Lists/ProfilesList";
import DefaultProjectCard from "../../examples/Cards/ProjectCards/DefaultProjectCard";

// Overview page components
import Header from "./components/Header/index";
import PlatformSettings from "./components/PlatformSettings/index";

// Data
import profilesListData from "./data/profilesListData";
// import {IconButton} from '@mui/material/IconButton';
// Images
import homeDecor1 from "../../assets/images/home-decor-1.jpg";
import homeDecor2 from "../../assets/images/home-decor-2.jpg";
import homeDecor3 from "../../assets/images/home-decor-3.jpg";
import homeDecor4 from "../../assets/images/home-decor-4.jpeg";
import team1 from "../../assets/images/team-1.jpg";
import team2 from "../../assets/images/team-2.jpg";
import team3 from "../../assets/images/team-3.jpg";
import team4 from "../../assets/images/team-4.jpg";
import { useEffect, useState } from "react";
import axios from "../../../../../api/axios";

import {
  Box,
  TextField,
  Autocomplete,
  Button,
  Container,
  Card,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import "./data/profile.scss";
import { submitButton } from "../../../../DesignTools/ButtonStyles/ButtonStyles";
import {
  GET_ALL_CATSERVICE,
  VENDOR_GETINFO,
} from "../../../../CompServices/Api_Routes_Helper";
import RFQModal from "../../../../CategoryList/RFQModal";
import authHeader from "../../../../../Services/auth-header";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IconName } from "react-icons/bi";
import ProfileField from "./ProfileField";
function Overview() {
  const [details, setDetails] = useState("");
  const v_id = localStorage.getItem("v_ID");
  const { vid, role } = JSON.parse(v_id);

  const [values, setValues] = useState([]);

  const name = details.username;
  const email = details.email;
  const phone = details.phone;
  const description = details.description;

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [image, setImage] = useState("");

  useEffect(() => {
    const servicesCategories = async () => {
      try {
        const response = await axios.get(GET_ALL_CATSERVICE, {
          headers: await authHeader(),
        });
        if (response !== null) {
          const { data } = response.data;
          setValues(data);
        } else {
          throw new Error("Services not found.");
        }
      } catch (error) {
        console.error(error);
      }
    };
    servicesCategories();
  }, []);

  useEffect(() => {
    if (vid) {
      getVendorDetails(vid);
    }
  }, [vid]);
  const getVendorDetails = async (vendorId) => {
    if (!vendorId) {
      return;
    }
    try {
      const response = await axios.get(`${VENDOR_GETINFO}${vendorId}`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response) {
        const vendorholdings = response.data.data;
        setDetails(vendorholdings);
        setImage(vendorholdings.image);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const setPostReq = (data) => {
    getVendorDetails(vid);
    handleClose();
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header fname={name} image={image}>
        <MDBox mt={5} mb={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} xl={6}>
              <PlatformSettings />
            </Grid>
            <Grid item xs={12} md={6} xl={6} sx={{ display: "flex" }}>
              <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
              <ProfileInfoCard
                title="profile information"
                description={
                  description ? (
                    <span style={{ width: "75vh" }}>{description}</span>
                  ) : (
                    <Button onClick={handleOpen}>Update Profile</Button>
                  )
                }
                info={{
                  fullName: name,
                  mobile: phone,
                  email: email,
                  // location: {location ? location : <Button>fssd</Button>},
                }}
                social={[
                  {
                    link: "https://www.facebook.com/CreativeTim/",
                    icon: <FacebookIcon />,
                    color: "facebook",
                  },
                  {
                    link: "https://twitter.com/creativetim",
                    icon: <TwitterIcon />,
                    color: "twitter",
                  },
                  {
                    link: "https://www.instagram.com/creativetimofficial/",
                    icon: <InstagramIcon />,
                    color: "instagram",
                  },
                ]}
                action={{
                  // style={{width:"10px"}}
                  route: "",
                  tooltip: "Edit Profile",
                  value: () => {
                    handleOpen();
                  },
                }}
                shadow={false}
              />
              <Divider orientation="vertical" sx={{ mx: 0 }} />
            </Grid>
            <RFQModal
              open={open}
              onClose={handleClose}
              tableHeader={"Profile Update"}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <ProfileField setPostReq={setPostReq} />
            </RFQModal>
            <ToastContainer />
          </Grid>
        </MDBox>
      </Header>
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
