import React from "react";
import { View, StyleSheet } from "@react-pdf/renderer";
import InvoiceTableHeader from "./InvoiceTableHeader";
import InvoiceTableRow from "./InvoiceTableRow";
import InvoiceTableFooter from "./InvoiceTableFooter";
import Termscondition from "./TermsCondition";
import TermsHeader from "./TermsHeader";

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 14,
    borderWidth: 1,
    borderColor: "#3778C2",
  },
});

const SpecialNoteTable = ({ invoice }) => (
  <View style={styles.tableContainer}>
    <TermsHeader special />
    <Termscondition special={invoice[0].specialNote} />
  </View>
);

export default SpecialNoteTable;
