import { ThemeProvider } from "@emotion/react";
import { Box, Button, Typography } from "@mui/material";
import { Send } from "@mui/icons-material";

import React from "react";
import "./styles.css";

const VerifyEmail = () => {
  return (
    <div className="container_email">
      <header className="header"></header>
      <div className="container_body">
        <Typography
          component="h3"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
        >
          Email Confirmation
        </Typography>
        <p className="email_body">
          Congratulations! Your email has been confirmed and your account is
          ready. Please close this page and go back to the Email app to Activate
          account.
        </p>
       
          <Button
            variant="contained"
            color="primary"
            endIcon={<Send />}
            sx={{
              p: 1,
              m: 0,
              maxWidth: "240px",
              width: "100%",
              borderRadius: 10,
              "&:hover": { backgroundColor: "green" },
            }}
          >
            <a href="https://mail.google.com/mail/u/example@gmail.com">Activate</a> 
          </Button>{" "}
       
      </div>
    </div>
  );
};

export default VerifyEmail;
