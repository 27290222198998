import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "#3778C2";
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderBottomColor: "#3778C2",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    fontSize: 12,
    fontStyle: "bold",
  },
  description: {
    width: "85%",
    textAlign: "right",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingRight: 8,
  },
  total: {
    width: "15%",
    textAlign: "right",
    paddingRight: 8,
  },
});

const InvoiceTableFooter = ({ items, ClientItems }) => {
  const taxRate = 0.1; // Assuming a tax rate of 10%
  console.log("items", items);
  const responseQuantitys = 1;

  const calculateTotal = (itemList) => {
    return itemList
      ? itemList.reduce(
          (accumulator, currentValue) =>
            accumulator + responseQuantitys * currentValue.price,
          0
        )
      : 0;
  };
  const calculateTotals = (itemList) => {
    return itemList
      ? itemList.reduce(
          (accumulator, currentValue) =>
            accumulator + responseQuantitys * currentValue.responsePrice,
          0
        )
      : 0;
  };

  const itemsTotal = calculateTotals(items);
  const clientItemsTotal = calculateTotal(ClientItems);

  const itemsTax = itemsTotal * taxRate;
  const clientItemsTax = clientItemsTotal * taxRate;

  const total = itemsTotal + itemsTax;
  const clientTotal = clientItemsTotal + clientItemsTax;

  return (
    <View>
      {/* {items && (
        <View style={styles.row}>
          <Text style={styles.description}>Items Total</Text>
          <Text style={styles.total}>
            {Number.parseFloat(itemsTotal).toFixed(2)}
          </Text>
        </View>
      )}
      {items && (
        <View style={styles.row}>
          <Text style={styles.description}>Tax (10%)</Text>
          <Text style={styles.total}>
            {Number.parseFloat(itemsTax).toFixed(2)}
          </Text>
        </View>
      )} */}
      {/* {ClientItems && (
        <View style={styles.row}>
          <Text style={styles.description}>Items Total</Text>
          <Text style={styles.total}>
            {Number.parseFloat(clientItemsTotal).toFixed(2)}
          </Text>
        </View>
      )} */}
      {/* {ClientItems && (
        <View style={styles.row}>
          <Text style={styles.description}>Tax (10%)</Text>
          <Text style={styles.total}>
            {Number.parseFloat(clientItemsTax).toFixed(2)}
          </Text>
        </View>
      )} */}

      {items && (
        <View style={styles.row}>
          <Text style={styles.description}>Total Including Tax</Text>
          <Text style={styles.total}>
            {Number.parseFloat(itemsTotal).toFixed(2)}
          </Text>
        </View>
      )}
      {ClientItems && (
        <View style={styles.row}>
          <Text style={styles.description}>Total</Text>
          <Text style={styles.total}>
            {Number.parseFloat(clientItemsTotal).toFixed(2)}
          </Text>
        </View>
      )}
    </View>
  );
};

export default InvoiceTableFooter;
