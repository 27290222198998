import React, { useState, useEffect } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {
  DataGrid,
  GridCellModes,
  GridFooter,
  useGridApiContext,
  useGridApiEventHandler,
  useGridApiRef,
  useGridNativeEventListener,
} from "@mui/x-data-grid";
import { Alert, Card, Grid, Typography, gridClasses } from "@mui/material";
// import Preloader from "../../../DesignTools/CSSLoaders/Preloader";
import { grey } from "@mui/material/colors";
// import { RFQ_REQ_RESP_INFO } from "../../../CompServices/Api_Routes_Helper";
import Moment from "react-moment";
import {
  GET_RFQ_RESPONSE_QUOTE,
  RFQ_REQ_RESP_INFO,
  RFQ_REQ_GETLINEITEM,
} from "../../CompServices/Api_Routes_Helper";
import authHeader from "../../../Services/auth-header";
import MDBox from "../dashboard/Dcomponents/MDBox";
import { Container } from "@mui/system";
import RFQModal from "../../CategoryList/RFQModal";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import MDTypography from "../dashboard/Dcomponents/MDTypography";
import boxShadow from "../dashboard/assets/theme/functions/boxShadow";
import PdfDocument from "../Requests/Invoice/PdfDocument";
import { submitButton } from "../../DesignTools/ButtonStyles/ButtonStyles";
import DownloadIcon from "@mui/icons-material/Download";
import ReuseableClientSubDes from "../../ReuseTable/ReuseableClientSubDes";
export const columns = [
  // {
  //   field: "categoryName",
  //   headerName: "Category",
  //   width: 140,
  // },
  { field: "items", headerName: "Item", width: 120, align: "left" },
  {
    field: "quantity",
    headerName: "Quantity",
    width: 80,
    type: "number",
    align: "left",
    // editable: true,
  },
  {
    field: "unit",
    headerName: "Unit",
    width: 100,
    align: "left",
    // editable: true,

    type: "singleSelect",

    valueOptions: ["kg", "grams", "Tonnes", "Pieces"],
  },
  {
    field: "price",
    headerName: "Expected Price",
    width: 140,
    align: "left",
    valueFormatter: (params) => {
      const ExPrice = params.value;
      if (ExPrice == null) {
        return " ";
      }
      const formattedPrice = new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
        // maximumSignificantDigits:3
      }).format(ExPrice);
      return formattedPrice;
    },
  },
  // {
  //   field: "createdAt",
  //   headerName: "Date",
  //   width: "220px !important",
  //   type: "datetime",
  //   renderCell: (params) =>
  //     moment(params.row.createdAt).format("YYYY-MM-DD HH:MM:SS"),
  // },
  // {
  //   field: "actions",
  //   type: "actions",
  //   headerName: "Actions",
  //   width: 100,
  //   cellClassName: "actions",
  //   getActions: ({ id }) => {
  //     return [
  //       <GridActionsCellItem
  //         icon={<Save />}
  //         label="Save"
  //         sx={{
  //           color: "primary.main",
  //         }}
  //         onClick={handleSaveClick(id)}
  //       />,
  //     ];
  //   },
  //   // renderCell: (params) => <DataGrid_btn />,
  // },
  {
    field: "responseQuantity",
    headerName: "Quotation Qty",
    width: 140,
  },
  {
    field: "responsePrice",
    headerName: "Quotation Price",
    type: Number,
    width: 170,
    valueFormatter: (params) => {
      const formattedPrice = params.value;
      if (formattedPrice == null) {
        return " ";
      }
      const priceValue = new Intl.NumberFormat("en-IN", {
        currency: "INR",
        style: "currency",
      }).format(formattedPrice);
      return priceValue;
    },
  },
  {
    field: "responseTax",
    headerName: "Quotation Tax",
    width: 140,
    valueFormatter: (params) => {
      if (params.value === null) {
        return "";
      }
      return params.value + "%";
    },
  },
  {
    field: "responseDate",
    headerName: "Quotation Date",
    width: 170,
    renderCell: (params) => {
      const date = params.value;
      if (date !== null) {
        return <Moment format="DD/MM/YYYY">{date}</Moment>;
      }
      return null;
    },
  },

  {
    field: "status",
    headerName: "status",
    width: 170,
  },
];
const VendorResponses = ({ rowid }) => {
  const [tableData, setTableData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [selectedCellParams, setSelectedCellParams] = React.useState(null);
  const [cellModesModel, setCellModesModel] = React.useState({});
  const [resData, setResData] = useState([]);
  console.log({ tableData, resData });

  const cellMode = React.useMemo(() => {
    if (!selectedCellParams) {
      return "view";
    }
    const { id, field } = selectedCellParams;
    return cellModesModel[id]?.[field]?.mode || "view";
  }, [cellModesModel, selectedCellParams]);

  const handleCellKeyDown = React.useCallback(
    (params, event) => {
      if (cellMode === "edit") {
        event.defaultMuiPrevented = true;
      }
    },
    [cellMode]
  );

  const useFakeMutation = () => {
    return React.useCallback(
      (user) =>
        new Promise((resolve, reject) => {
          setTimeout(() => {
            if (user.items?.trim() === "") {
              console.log("reject");

              reject(
                new Error("Error while saving data: name can't be empty.")
              );
            } else {
              console.log("resolved");

              resolve({ ...user, name: user.items?.toUpperCase() });
            }
          }, 200);
        }),
      []
    );
  };
  const mutateRow = useFakeMutation();
  const processRowUpdate = React.useCallback(
    async (newRow) => {
      const response = await mutateRow(newRow);
      console.log("Updated row:", response);
      // if (response) {
      //   setRows((newRow) => {
      //     // Create a new array by spreading the prevRows array to avoid mutation
      //     const updatedRows = [...newRow];

      //     // Find the index of the row with the matching _id in the updatedRows array
      //     const rowIndex = updatedRows.find(
      //       (row) => row._id === newRow._id
      //     );

      //     if (rowIndex !== 1) {
      //       // If the row is found, update it with the edited data from newRow.updated
      //       console.log("row index ");

      //       updatedRows[rowIndex] = {
      //         ...updatedRows[rowIndex],
      //         ...newRow.updated,
      //       };
      //     }
      //     console.log(updatedRows);

      //     return updatedRows;
      //   });
      // }
      return newRow;
    },

    [mutateRow]
  );

  const handleSaveClick = (rowId) => () => {
    const { id, field } = selectedCellParams;

    setCellModesModel({
      ...cellModesModel,
      [id]: { ...cellModesModel[id], [field]: { mode: GridCellModes.View } },
    });
  };

  const handleProcessRowUpdateError = React.useCallback((error) => {
    console.log(error);
    // ({ children: error.message, severity: 'error' });
  }, []);
  const apiRef = useGridApiRef();
  function Footer() {
    const [message, setMessage] = React.useState("");
    const apiRef = useGridApiContext();

    const handleRowClick = (params) => {
      setMessage(`Status "${params.row.status}" clicked`);
    };

    useGridApiEventHandler(apiRef, "rowClick", handleRowClick);

    return (
      <React.Fragment>
        <GridFooter />
        {message && <Alert severity="info">{message}</Alert>}
      </React.Fragment>
    );
  }
  // function AddIcon(){
  //   const apiref=useGridApiContext();

  // }
  useEffect(() => {
    let isMounted;
    isMounted = true;
    const fetchQLIResponse = async () => {
      try {
        // setLoader(true);
        const { data } = await axios(`${RFQ_REQ_RESP_INFO}${rowid}`, {
          headers: await authHeader(),
        });
        if (data && isMounted) {
          setTableData(data.structuredData);
        } else {
          setLoader(false);
        }
      } catch (error) {
        console.error(error);
        setLoader(false);
      }
    };

    fetchQLIResponse();
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    const fetchRequests = async () => {
      if (!rowid) {
        return;
      }
      const { data } = await axios.get(`${RFQ_REQ_GETLINEITEM}${rowid}`, {
        headers: await authHeader(),
      });
      console.log("RESPONSE made", data);
      setResData(data.data);
    };
    fetchRequests();
  }, []);

  console.log("resData", resData, tableData);
  const mergedData = [...(resData || []), ...(tableData || [])];

  const [openResponseModal, setOpenResponseModal] = useState(false);
  const handleOpenResponseMenu = (id) => {
    setOpenResponseModal(true);
  };
  const handleCloseResponseMenu = () => setOpenResponseModal(false);
  const fileName = "Invoice.pdf";

  return (
    <>
      <section>
        <Grid item xs={12} lg={12} md={12}>
          <RFQModal
            open={openResponseModal}
            onClose={handleCloseResponseMenu}
            tableHeader={"Quick View of Quotation Invoice"}
          >
            <Box className="invoicequation">
              <div style={{ textAlign: "center", marginRight: "100px" }}>
                <div className="download-link">
                  <PDFDownloadLink
                    className="qup_pdf_mainWrap"
                    document={
                      <PdfDocument
                        invoicedata={resData}
                        invoicedatas={tableData}
                      />
                    }
                    fileName={fileName}
                  >
                    <DownloadIcon />
                    &nbsp;&nbsp;Download Quotation
                  </PDFDownloadLink>
                </div>
              </div>
              <br />
              <PDFViewer width={1200} height={400} showToolbar={false}>
                <PdfDocument invoicedata={resData} invoicedatas={tableData} />
              </PDFViewer>
            </Box>
          </RFQModal>
          <MDBox p={1}>
            <div style={{ textAlign: "end", marginRight: "100px" }}>
              <Button style={submitButton} onClick={handleOpenResponseMenu}>
                View Quotation
              </Button>
            </div>
            {resData?.map((item, key) => (
              <div key={key}>
                <ReuseableClientSubDes
                  subject={item.vendorSubject}
                  description={item.vendorDescription}
                  title={"Vendor Request"}
                />
              </div>
            ))}

            <MDBox>
              <DataGrid
                rows={tableData}
                getRowId={(row) => row.lineItemId}
                columns={columns}
                density="compact"
                onCellKeyDown={handleCellKeyDown}
                cellModesModel={cellModesModel}
                // onCellEditStart={handleCellKeyDown}
                onProcessRowUpdateError={handleProcessRowUpdateError}
                onCellModesModelChange={(model) => setCellModesModel(model)}
                getDetailPanelContent={(row) => console.log(row)}
                loading={!tableData.length > 0}
                processRowUpdate={processRowUpdate}
                apiRef={apiRef}
                sx={{
                  [`& .${gridClasses.row}`]: {
                    bgcolor: (theme) => theme.palette.background.paper,
                  },

                  fontSize: "15px",
                }}
                slots={{ footer: Footer }}
              />
            </MDBox>
          </MDBox>
        </Grid>
      </section>
    </>
  );
};

export default VendorResponses;

function EditToolbar(props) {
  const { selectedCellParams, cellMode, cellModesModel, setCellModesModel } =
    props;
  const handleSaveOrEdit = () => {
    if (!selectedCellParams) {
      return;
    }
    const { id, field } = selectedCellParams;

    if (cellMode === "edit") {
      setCellModesModel({
        ...cellModesModel,
        [id]: { ...cellModesModel[id], [field]: { mode: GridCellModes.View } },
      });
    } else {
      setCellModesModel({
        ...cellModesModel,
        [id]: { ...cellModesModel[id], [field]: { mode: GridCellModes.Edit } },
      });
    }
  };

  const handleCancel = () => {
    if (!selectedCellParams) {
      return;
    }
    const { id, field } = selectedCellParams;
    setCellModesModel({
      ...cellModesModel,
      [id]: {
        ...cellModesModel[id],
        [field]: { mode: GridCellModes.View, ignoreModifications: true },
      },
    });
  };

  const handleMouseDown = (event) => {
    // Keep the focus in the cell
    event.preventDefault();
  };
  const handleRequirementsSave = (e, row) => {
    e.preventDefault();
    // console.log(rows);
  };

  return (
    <Box
      sx={{
        borderBottom: 1,
        borderColor: "divider",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Box p={0} m={1}>
        <Button
          onClick={handleSaveOrEdit}
          onMouseDown={handleMouseDown}
          disabled={!selectedCellParams}
          variant="outlined"
        >
          {cellMode === "edit" ? "Save" : "Edit"}
        </Button>

        <Button
          onClick={handleCancel}
          onMouseDown={handleMouseDown}
          disabled={cellMode === "view"}
          variant="outlined"
          sx={{ ml: 1 }}
        >
          Cancel
        </Button>
      </Box>
      <div>
        <Button
          onMouseDown={handleMouseDown}
          variant="contained"
          disabled={cellMode === "view"}
          sx={{ m: 1 }}
          color="inherit"
        >
          Add New RFQ
        </Button>

        <Button
          onClick={handleRequirementsSave}
          // onMouseDown={handleMouseDown}
          variant="contained"
          disabled={cellMode === "edit"}
          sx={{ m: 1 }}
        >
          Update
        </Button>
      </div>
    </Box>
  );
}

///set status
{
  /* <span>
                              {rfqResponse.isApproved === "Approved" ? (
                                <React.Fragment>
                                  <div
                                    style={{
                                      display: "flex",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        width: "195px",
                                        borderRadius: "7px",
                                        background: "#32cd9cf0",
                                        color: "white",
                                        padding: "0px 10px 0px 10px",
                                      }}
                                    >
                                      <p
                                        style={{
                                          fontSize: "20px",
                                        }}
                                      >
                                        Status :
                                      </p>
                                      <span
                                        style={{
                                          color: "white",
                                          fontSize: "20px",
                                        }}
                                      >
                                        &nbsp;&nbsp;Approved
                                      </span>
                                    </div>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Link
                                      to={`/vendorManagement/view_thread/${rfqResponse.requestID}`}
                                    >
                                      <MDButton type="submit" color="primary">
                                        See Comments
                                      </MDButton>
                                    </Link>
                                  </div>
                                </React.Fragment>
                              ) : (
                                <div
                                  style={{
                                    display: "flex",
                                    width: "185px",
                                    borderRadius: "7px",
                                    background: "#32cd9cf0",
                                    color: "white",
                                    padding: "0px 10px 0px 10px",
                                  }}
                                >
                                  <p
                                    style={{
                                      fontSize: "20px",
                                    }}
                                  >
                                    Status :
                                  </p>
                                  <span
                                    style={{
                                      color: "white",
                                      fontSize: "20px",
                                    }}
                                  >
                                    &nbsp;&nbsp; Pending
                                  </span>
                                </div>
                              )}
                            </span> */
}
