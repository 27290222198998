import React, { useEffect, useState, useMemo } from "react";
import DataTableBase, { ExpandableComponent } from "../../ReuseTable/DataTable";
import axios from "../../../api/axios";
import { useNavigate } from "react-router-dom";
import authHeader from "../../../Services/auth-header";
import { Badge, Card } from "@mui/material";
import "../styles.scss";
import { useCookies } from "react-cookie";
import Preloader from "../RFQsResponse/Preloader";
import DashboardLayout from "../dashboard/examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../dashboard/examples/Navbars/DashboardNavbar";
import MDBox from "../dashboard/Dcomponents/MDBox";
import { Grid } from "antd";
import MDTypography from "../dashboard/Dcomponents/MDTypography";
import RowMenu from "./RowMenu";
import { Fetch_RFQ_request } from "../../CompServices/Api_Routes_Helper";
import format from "../../_helpers/formatTime";
import LoadingBars from "../../PageLoader/LoadingBars";
import Moment from "react-moment";
import Review from "../../CategoryList/Review";
import MDButton from "../dashboard/Dcomponents/MDButton";
import MDBadge from "../dashboard/Dcomponents/MDBadge";
import { PDFViewer } from "@react-pdf/renderer";
import PdfDocument from "./Invoice/PdfDocument";
import { submitButton } from "../../DesignTools/ButtonStyles/ButtonStyles";

const customStyles = {
  rows: {
    style: {
      minHeight: "55px", // override the row height
      maxWidth: "100%",
      minWidth: "100%",
      textOverflow: "ellipsis",
      backgroundColor: "white",
     
    },
  },
  columns: {
    style: {
      minWidth: "400px", 
      
    },
  },
  headCells: {
    style: {
      textAlign: "left",
      color: "black",
   
      fontSize: "16px",
      fontWeight: "900",
      paddingLeft: "10px", // override the cell padding for head cells
      paddingRight: "10px",
      padding: "6px",
      margin: "10px 0",
      textTransform: "capitalize",
      fontFamily: "Poppins",
    },
  },
  cells: {
    style: {
      paddingLeft: "5px",
      paddingRight: "5px",
      width: "auto",
      whiteSpace: "wrap",
      overflow: "auto",
      maxWidth: "170px",
      textOverflow: "ellipsis",
      wordBreak: "break-word",
      flexGrow: 0,
      backgroundColor: "none",
      fontSize: "14px",
      fontWeight: "600",
      position: "relative",
      boxShadow: "inset 0 -1px 0 0 rgba(100,121,143,0.122)",
      backgroundColor: "rgba(0,0,0,0.09)",
      // borderRight: "1px solid #ffff",
    },
  },
};

const VendorRequests = (props) => {
  const [resdata, setResData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dataLoad, setDataLoad] = useState(true);
  const [shouldFetch, setShouldFetch] = useState(true);
  //for action with seleceted rows
  const columns = [
    // {
    //   name: "id",
    //   selector: (row) => row.id,
    //   sortable: true,
    // },
    // {
    //   name: "Service",
    //   selector: (row) => row.service,
    //   sortable: true,
    // },
    {
      name: "Subject",
      // selector: (row) => {
      // return (<span>{row.subject}</span>)},
      selector: (row) => row.subject,
      sortable: true,
      maxWidth: "310px",
      cell: (row) => <div className="table-sub-des-width">{row.subject}</div>,
    },
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
      maxWidth: "350px",
      cell: (row) => (
        <div className="table-sub-des-width">{row.description}</div>
      ),
    },

    {
      name: "Date",
      selector: (row) => row.createdDate,
      sortable: true,
      maxWidth: "160px",
    },
    {
      name: "CreatedAt",
      selector: (row) => row.createdTime,
      sortable: true,
      maxWidth: "160px",
    },

    //to show the current status .to check position of response made
    {
      name: "Status",
      maxWidth: "150px",

      selector: (row) => {
        // console.log(row);
        return (
          <MDBox>
            <MDBadge
              size="xs"
              variant="contained"
              circular={true}
              color="info"
              border={true}
              // children={row.status.length > 0 ? row.status : "Pending"}
              children={row.status ? row.status : "Pending"}
            ></MDBadge>
          </MDBox>
        );
      },
    },
    {
      name: "Action",
      selector: (row) => row.Action_btns,
      sortable: true,
      maxWidth: "200px",
    },

    {
      selector: (row) => (row.isRead = row.read === true),
      omit: true,
    },
    // {
    //   name: "Action",
    //   selector: (row) => row.Action_btns,
    //   sortable: true,
    // },
  ];

  const conditionalRowStyles = [
    {
      when: (row) => !row.isRead, // Check if the row is not read
      style: {
        backgroundColor: "white", // Set background color for even rows to blue
        color: "#5a5555",
        fontSize: "12px",
        fontWeight: "500",
        height: "60px",
      },
    },
    {
      when: (row) => row.isRead, // Check if the row is read
      style: {
        backgroundColor: "lightgrey", // Set background color for odd rows to green
        color: "#5a5555",
        fontSize: "12px",
        fontWeight: "500",
        height: "60px",
      },
    },
  ];

  //to retrive vendor id from local storage
  const v_id = localStorage.getItem("v_ID");
  const { vid, role } = JSON.parse(v_id);
  useEffect(() => {
    let isMounted;
    isMounted = true;
    const fetchRequests = async (vendorId) => {
      try {
        if (!vendorId) {
          return;
        }
        const response = await axios.get(`${Fetch_RFQ_request}${vendorId}`, {
          headers: await authHeader(),
        });
        if (isMounted && response.data !== null) {
          const timeout = setTimeout(() => {
            setDataLoad(false);
            setLoading(false);
          }, 500);
          const vendorRfqs = response.data;
          setResData(vendorRfqs);
          return () => clearTimeout(timeout);
        } else {
          setLoading(false);
          setDataLoad(false);
        }
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };
    if (dataLoad) {
      fetchRequests(vid);
      setShouldFetch(false);
    }
    return () => {
      isMounted = false;
    };
  }, [vid, shouldFetch]);

  let data = [];
  if (resdata) {
    data = resdata.map((item) => {
      const createdTime = item.createdAt
        ? new Date(item.createdAt).toLocaleTimeString()
        : null;

      return {
        subject: item.mainRFQ?.subject,
        description: item.mainRFQ?.description,
        createdDate: <Moment format="DD/MM/YYYY">{item.createdAt}</Moment>,
        // createdDate: format(new Date(item.createdAt)),
        createdTime: createdTime ? createdTime : "--NA--",
        // status: item.status,
        status:
          //to be implemented in later stage(working)

          // item.responses?.length > 0
          //   ? item.responses?.map((item) => {
          //       return item.CurrentStatus;
          //     })
          //   : item.responseSubmissionStatus,
          item.responseSubmissionStatus,
        Action_btns: <RowMenu id={item._id} />,
        isRead: item.read,
      };
    });
  }
  const navigate = useNavigate();

  const handleRowClick = (row) => {
    if (
      row &&
      row.Action_btns &&
      row.Action_btns.props &&
      row.Action_btns.props.id
    ) {
      const id = row.Action_btns.props.id;
      navigate(`/vendorManagement/rfq/edit`, { state: id });
    }
  };
  const { onNewRequestCount } = props;

  const newRequestCount = data.filter(
    (row) => row.status === "Requests received" && !row.isRead
  ).length;

  onNewRequestCount(newRequestCount);
  const reversedData = [...data].reverse();

  // const [cookies] = useCookies(["VendorID"]);
  // const id = cookies["VendorID"];

  return (
    <MDBox pt={6} pb={3} variant="contained">
      {/* <Grid container spacing={6}> */}
      {/* <Grid item xs={12}> */}
      {/* <Card> */}
      <MDBox p={1}>
        <MDBox
          // m={1}
          // p={1}
          // mx={2}
          // mt={-3}
          // py={3}
          // px={2}
          style={{ padding: "25px 9px 13px 20px" }}
          // variant="gradient"
          bgColor=" rgba(69, 67, 67, 0.7490196078)"
          borderRadius="md"
          coloredShadow="light"
        >
        <Badge badgeContent={newRequestCount} color="success">
          <MDTypography variant="h6" color="white">
            RFQ Requests Table
            {/* {newRequestCount > 0 && (
              <div
                className="notification"
                style={{ color: "#fff", float: "right" }}
              >
                {newRequestCount}
              </div>
            )} */}
          </MDTypography>
          </Badge>
        </MDBox>

        <DataTableBase
          columns={columns}
          data={reversedData}
          customStyles={customStyles}
          responsive={true}
          conditionalRowStyles={conditionalRowStyles}
          progressPending={loading}
          progressComponent={<LoadingBars />}
          // selectableRows
          dense={true}
          fixedHeader
          // fixedHeaderScrollHeight="450px"
          onRowClicked={(row) => handleRowClick(row)}
        />
      </MDBox>
      {/* </Card> */}
    </MDBox>
  );
};

export default VendorRequests;
