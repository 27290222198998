import React from "react";
import Styled from "styled-components";
import { Close, Edit } from "@mui/icons-material";
import DoneIcon from "@mui/icons-material/Done";

export const AdminRedIcon = Styled(Close)`
  font-size: 20px;
  padding :0px;
  border-radius:50%;  
  transform-origin: center;
  cursor: pointer;
  transition:transform  background 0.5s ease-in-out;

  &:hover {
    transform:scale(1.1);
    background:red;
  }
  &:after{
    content:"";
    outline:2px solid black;
    color:white !important;
  }
`;
export const AdminGreenIcon = Styled(DoneIcon)`

font-size: 20px;
  border-radius:50%;
  padding :0px;  
  transform-origin: center;
  cursor: pointer;
  transition:transform  background 0.5s ease-in-out;

  &:hover {
    background:green;
    transform:scale(1.1);
  }
`;

export const AdminBlueIcon = Styled(Edit)`
background:blue;
font-size: 20px;
  border-radius:50%;
  padding :2px;  
  transform-origin: center;
  cursor: pointer;
  color:white !important;
  transition:transform 0.3s;

  &:hover {
    transform:scale(1.2);
  }
`;

export const Label = Styled.label`
    font-size: 15px;
    font-weight: 600;
    margin-bottom: -21px;
    display:flex;
    
  `;

export const InputField = Styled.input`
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
  
    &::placeholder {
      color: #999;
    }
  
    &:focus {
      border-color: green;
    }
  `;
export const StyledButton = Styled.button`
   margin: 0;
  padding: 0;
  border: none;
  background: none;
  font: inherit;
  color: inherit;
  cursor: pointer;
  outline: none;
  display: inline-block;
  padding: 10px 12px;
  font-size: 16px;
  border-radius: 6px;
  background: rgb(0, 92, 150);
  color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
 
  &:hover {
    background-color: gray;
  }

  &:active {
    background-color: #1c6389;
  }
`;
export const TableContainer = Styled.div`
  overflow-x: auto;
`;
export const Table = Styled.table`
 border: 1px solid #ccc;
 padding: 8px;
 background-color: #f2f2f2; /* Light Gray Background for Header */
 color: #333; /* Text color */
`;
export const Th = Styled.th`
 border: 1px solid #ccc;
 padding: 8px;
 text-align: center; 
 background-color:#cfbfbe;
 /* Center align table data */
`;
export const Td = Styled.td`
  border: 1px solid #ccc;
  padding: 8px;
  text-align:start;
  font-weight: lighter;
  font-size:15px;
`;
export const Thead = Styled.thead`
 
`;
export const TbodyTr = Styled.tr`
  background-color: ${(props) =>
    props.even ? "#f9f9f9" : "#ffffff"}; /* Alternating Row Colors */
  &:hover {
    background-color: #e6e6e6; /* Hover Effect */
  }
`;
export const TbodyTrEven = Styled.tr`
 background-color: #f9f9f9;
`;

export const TbodyTrHover = Styled.tr`
background-color: #e6e6e6;
`;
export const ResponsiveTd = Styled.td`
border: none;
border-bottom: 1px solid #ccc;
position: relative;
padding-left: 50%;
`;

export const RedAsterisk = Styled.span`
color:red;
`;

//  export const DataLabel = Styled.td :: before`
//   content: attr(data-label);
//   position: absolute;
//   left: 0;
//   width: 50%;
//   padding-left: 8px;
//   font-weight: bold;
// `;

export const StarIcon = Styled.div`
color: red;
font-size: 18px;
position: relative;
display: inline-block;


`;
export const DivSpaceTB = Styled.div`
  padding: 0px;
  margin: 20px 0px;
`;
export const GoBtn = Styled.button`
width: auto;
min-width:100px;
height: 100%;
max-height:50px;
font-size: 18px;
background-color: #381cb7c2;
outline: none;
border: 0px solid transparent;
border-radius: 10px;
color: wheat;
cursor: pointer;
margin-top: 5px;
display: flex;
container-type: inline-size;
flex-direction: row;
flex-wrap: nowrap;
align-content: center;
align-items: center;
justify-content: center;
padding:10px;
  
`;
export default function CustomScroller({ children }) {
  return (
    <React.Fragment>
      <div id="wrapper_id">
        <div className="scrollbar" id="style-7">
          <div className="force-overflow">{children}</div>
        </div>
      </div>
    </React.Fragment>
  );
}
