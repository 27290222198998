import React, { useState } from "react";
import styled from "@emotion/styled";
import "react-datepicker/dist/react-datepicker.css";
import { HiPencilAlt, HiBadgeCheck } from "react-icons/hi";
// import { HiPencilAlt } from "react-icons/hi";
import Moment from "react-moment";

const Input = styled.input`
  padding: 0.5em;
  margin: 0.5em;
  color: #00000;
  background: #b3e9e978;
  border: 1px solid lightgrey;
  border-radius: 5px;
  outline: none;
  width: auto;
  max-width:90px;
  font-size:12px;
  font-weight:600px
`;

function CategoryListTable({
  itemSet,
  addQuoatationActivate,
  onSubmit,
  postQuotationActive,
  popup,
  receiveQuotation,
  currentStatus,
  PendingQuotation,
  addQuoatationActivates,
}) {
  const handleOnChange = (event, index) => {
    const { name, value } = event.target;
    const updatedItemSet = [...itemSet];
    updatedItemSet[index][name] = value;
    onSubmit(updatedItemSet);
  };

  const handleOnDateChange = (event, index) => {
    const { name, value } = event.target;
    const updatedItemSet = [...itemSet];
    updatedItemSet[index][name] = value;
    onSubmit(updatedItemSet);
  };

  return (
    <div className="table-form">
      <div className="table-data">
        <table id="table">
          <thead>
            {itemSet && itemSet.length > 0 && (
              <tr>
                <th>Item</th>
                <th>
                  Expected <br />
                  Quantity
                </th>
                <th>Unit</th>
                <th>
                  Expected <br /> Price
                </th>
                <th>Expected <br />Date</th>
                {/* <th>status</th> */}
                {receiveQuotation && (
                  <React.Fragment>
                    <th>Quantity</th>
                    <th>Price</th>
                    <th>
                      Delivery <br /> Date
                    </th>
                    <th>Action</th>
                  </React.Fragment>
                )}
                {postQuotationActive && (
                  <React.Fragment>
                    <th>Quantity</th>
                    <th>Price</th>
                    <th>
                      Delivery <br /> Date
                    </th>
                    <th>Action</th>
                  </React.Fragment>
                )}

                {addQuoatationActivate && (
                  <React.Fragment>
                    <th>
                      Quotation <br /> Quantity
                    </th>
                    <th>
                      Quotation <br /> Price
                    </th>
                    <th>
                      Quotation <br /> Tax
                    </th>
                    <th>
                      Delivery <br /> Date
                    </th>
                  </React.Fragment>
                )}
                {addQuoatationActivates && (
                  <React.Fragment>
                    <th>
                      Quotation <br /> Quantity
                    </th>
                    <th>
                      Quotation <br /> Price
                    </th>
                    <th>
                      Quotation <br /> Tax
                    </th>
                    <th>
                      Delivery <br /> Date
                    </th>
                  </React.Fragment>
                )}
                {PendingQuotation && (
                  <React.Fragment>
                    <th>Quantity</th>
                    <th>
                      Quotation
                      <br /> Price
                    </th>
                    <th>
                      Delivery
                      <br /> Date
                    </th>
                  </React.Fragment>
                )}
              </tr>
            )}
          </thead>

          <tbody>
            {itemSet &&
              itemSet.length > 0 &&
              itemSet.map((data, index) => {
                const indices = Object.keys(data)
                  .filter((key) => key.startsWith("price_"))
                  .map((key) => parseInt(key.split("_")[1], 10));

                return (
                  <tr key={index} style={{ backgroundColor: "#fff" }}>
                    <td>{data.items}</td>
                    <td>{data.quantity} </td>
                    <td>{data.unit}</td>
                    <td>{data.price}</td>
                    <td>{<Moment format="DD/MM/YYYY">{data.date}</Moment>}</td>
                    {/* <td>{currentStatus.map((data) => data.status)}</td> */}
                    {receiveQuotation && (
                      <React.Fragment>
                        <td>{data.responseQuantity}</td>
                        <td>{data.responsePrice}</td>
                        <td>{data.responseDate}</td>
                        <td>
                          <button
                            className="rfq_edit_btn"
                            // onClick={() => popup()}
                            style={{ cursor: "pointer" }}
                          >
                            <HiBadgeCheck />
                          </button>
                        </td>
                      </React.Fragment>
                    )}
                    {postQuotationActive && (
                      <React.Fragment>
                        <td>{data[`quantity_${indices}`]}</td>
                        <td>{data[`price_${indices}`]}</td>
                        <td>{data[`date_${indices}`]}</td>
                        <td>
                          <button
                            className="rfq_edit_btn"
                            onClick={() => popup()}
                            style={{ cursor: "pointer" }}
                          >
                            <HiPencilAlt />
                          </button>
                        </td>
                      </React.Fragment>
                    )}
                    {PendingQuotation && (
                      <React.Fragment>
                        <td>{data.responseQuantity}</td>
                        <td>{data.responsePrice}</td>
                        <td>{data.responseDate}</td>
                      </React.Fragment>
                    )}
                    {addQuoatationActivate && (
                      <React.Fragment>
                        <td>
                          {addQuoatationActivate && (
                            <Input
                              type="number"
                              name={`quantity_${index}`}
                              onChange={(event) =>
                                handleOnChange(event, index, data)
                              }
                              value={data[`quantity_${index}`] || ""}
                            />
                          )}
                        </td>

                        <td>
                          {addQuoatationActivate && (
                            <Input
                              type="number"
                              name={`price_${index}`}
                              onChange={(event) =>
                                handleOnChange(event, index, data)
                              }
                              value={data[`price_${index}`] || ""}
                            />
                          )}
                        </td>
                        <td>
                          {addQuoatationActivate && (
                            <Input
                              type="number"
                              name={`responseTax`}
                              onChange={(event) =>
                                handleOnChange(event, index, data)
                              }
                              value={data[`responseTax`] || ""}
                            />
                          )}
                        </td>
                        <td>
                          {addQuoatationActivate && (
                            <input
                              type="date"
                              name={`date_${index}`}
                              onChange={(event) =>
                                handleOnDateChange(event, index, data)
                              }
                              value={data[`date_${index}`] || ""}
                            />
                          )}
                        </td>
                      </React.Fragment>
                    )}
                    {addQuoatationActivates && (
                      <React.Fragment>
                        <td>
                          {addQuoatationActivates && (
                            <Input
                              type="number"
                              name={`responseQuantity`}
                              onChange={(event) =>
                                handleOnChange(event, index, data)
                              }
                              value={data[`responseQuantity`] || ""}
                            />
                          )}
                        </td>

                        <td>
                          {addQuoatationActivates && (
                            <Input
                              type="number"
                              name={`responsePrice`}
                              onChange={(event) =>
                                handleOnChange(event, index, data)
                              }
                              value={data[`responsePrice`] || ""}
                            />
                          )}
                        </td>
                        <td>
                          {addQuoatationActivates && (
                            <Input
                              type="number"
                              name={`responseTax`}
                              onChange={(event) =>
                                handleOnChange(event, index, data)
                              }
                              value={data[`responseTax`] || ""}
                            />
                          )}
                        </td>
                        <td>
                          {addQuoatationActivates && (
                            <input
                              type="date"
                              name={`responseDate`}
                              onChange={(event) =>
                                handleOnDateChange(event, index, data)
                              }
                              value={
                                data[`responseDate`]
                                  ? data[`responseDate`].split("T")[0]
                                  : ""
                              }
                            />
                          )}
                        </td>
                      </React.Fragment>
                    )}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default CategoryListTable;
