import { Button, Card, Container, Grid, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import CreateQupResponse from "../Responses/RowResponse/CreateQupResponse";
import { submitButton } from "../../DesignTools/ButtonStyles/ButtonStyles";
import { UPDATE_DRAFT } from "../../CompServices/Api_Routes_Helper";
import axios from "../../../api/axios";

function UpdateQuatationModel({
  resData,
  postRequiremnts,
  setDatas,
  rfqValues,
  subject,
  description,
}) {
  const [vendorSubject, setVendorSubject] = useState("");
  const [vendorDescription, setVendorDescription] = useState("");
  const vendorSubjects = resData?.map((item) => item.vendorSubject);
  const vendorDescriptions = resData?.map((item) => item.vendorDescription);

  //Terms and condition prefilling
  useEffect(() => {
    if (resData.length > 0) {
      const termsAndConditions = resData[0].termsAndCondition;
      if (termsAndConditions && termsAndConditions.length > 0) {
        setItemSet(termsAndConditions);
      }
    }
  }, [resData]);

  //specialnote prefilling
  useEffect(() => {
    if (resData.length > 0) {
      const specialNote = resData[0].specialNote;
      if (specialNote && specialNote.length > 0) {
        setItemSetNote(specialNote);
      }
    }
  }, [resData]);

  const [inputData, setInputData] = useState({
    terms: "",
    description: "",
  });
  const onChangeHandler = (e) => {
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  };
  const add = () => {
    if (!inputData) {
    } else {
      setItemSet([...itemSet, inputData]);
      setInputData({ terms: "", description: "" });
    }
  };

  const [itemSet, setItemSet] = useState([]);
  //changing table value for quotation

  const deleteData = (value) => {
    const deleteValue = itemSet.filter((data) => data.terms !== value.terms);
    setItemSet(deleteValue);
  };
  const [inputDataNote, setInputDataNote] = useState({
    special: "",
  });
  const onChangeHandlers = (e) => {
    setInputDataNote({ ...inputDataNote, [e.target.name]: e.target.value });
  };
  const addSpecialNote = () => {
    if (!inputDataNote) {
    } else {
      setItemSetNote([...itemSetNote, inputDataNote]);
      setInputDataNote({ special: "" });
    }
  };
  const [itemSetNote, setItemSetNote] = useState([]);
  const deleteDataNote = (value) => {
    const deleteValuee = itemSetNote.filter(
      (data) => data.special !== value.special
    );
    setItemSetNote(deleteValuee);
  };

  useEffect(() => {
    if (vendorSubjects && vendorSubjects.length > 0) {
      setVendorSubject(vendorSubjects[0]);
    }
    if (vendorDescriptions && vendorDescriptions.length > 0) {
      setVendorDescription(vendorDescriptions[0]);
    }
  }, [resData]);

  const [postRequiremnt, setPostRequirement] = useState([]);
  const handleQuotaion = (data) => {
    setPostRequirement(data);
  };

  const [isLoading, setIsLoading] = useState(false);
  const [note, setNote] = useState(false);
  const [verify, setVerify] = useState(false);

  const handleDraftChange = () => {
    handleDraftUpdate();
  };
  const handleDraftFormClick = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      handleDraftChange();
    }, 1000);
  };
  const updateId = resData[0]?._id;

  const v_id = localStorage.getItem("v_ID");
  const { vid, role } = JSON.parse(v_id);
  const handleDraftUpdate = async () => {
    const postQuotation = {
      requestID: rfqValues._id,
      customer_ID: rfqValues.customer_ID,
      cat_ID: rfqValues.cat_ID,
      vendor_ID: vid,
      requirements: postRequiremnts,
      vendorSubject: vendorSubject,
      vendorDescription: vendorDescription,
      termsAndCondition: itemSet,
      specialNote: itemSetNote,
    };
    await axios.put(`${UPDATE_DRAFT}${updateId}`, postQuotation).then((e) => {
      console.log("e", e);
      setDatas();
    });
  };

  return (
    <div>
      <div className="main_div_res">
        <Grid container>
          <Grid item sm={12} md={12} lg={6} style={{ padding: "10px" }}>
            <div className="borders-text">
              <h6 className="headding">Client Request</h6>
              <Grid container style={{ marginTop: "10px" }}>
                <Grid item sm={6} md={4} lg={4} className="respond res">
                  <p className="subject-text">Subject</p>
                </Grid>
                <Grid item sm={6} md={8} lg={8} className="respond">
                  <p
                    style={{
                      padding: "10px",
                      fontSize: "16px",
                    }}
                    className="scrollable-content"
                  >
                    {subject}
                  </p>
                </Grid>
              </Grid>
              <Grid
                container
                style={{ marginTop: "10px", marginBottom: "10px" }}
              >
                <Grid item sm={6} md={4} lg={4} className="respond res">
                  <p className="subject-text"> Description</p>
                </Grid>
                <Grid item sm={6} md={8} lg={8} className="respond">
                  <div>
                    <p
                      style={{
                        padding: "10px",
                        fontSize: "16px",
                        // fontWeight: "600",
                        // height: "13vh",
                      }}
                      className="scrollable-content"
                    >
                      {description}
                    </p>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item sm={12} md={12} lg={6} style={{ padding: "10px" }}>
            <>
              <div className="borders-text">
                <h6 className="headding">Vendor Request</h6>

                <Grid container style={{ marginTop: "10px" }}>
                  <Grid item sm={6} md={4} lg={4} className="respond res">
                    <label
                      style={{
                        padding: "10px",
                        fontSize: "16px",
                        textTransform: "capitalize",
                        fontWeight: "600",
                        color: "royalblue",
                      }}
                    >
                      Subject
                    </label>
                  </Grid>
                  <Grid
                    item
                    sm={6}
                    md={8}
                    lg={8}
                    className="respond"
                    style={{ padding: "10px" }}
                  >
                    <TextField
                      placeholder="Example : Create a Wordpress for my company"
                      fullWidth
                      className="textField_details"
                      onChange={(e) => setVendorSubject(e.target.value)}
                      value={vendorSubject}
                      name="Subject"
                      defaultValue=""
                      required
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  style={{ marginTop: "10px", marginBottom: "10px" }}
                >
                  <Grid item sm={6} md={4} lg={4} className="respond res">
                    <label
                      style={{
                        padding: "10px",
                        fontSize: "16px",
                        textTransform: "capitalize",
                        fontWeight: "600",
                        color: "royalblue",
                      }}
                    >
                      Description
                    </label>
                  </Grid>
                  <Grid
                    item
                    sm={6}
                    md={8}
                    lg={8}
                    className="respond"
                    style={{ padding: "10px" }}
                  >
                    <TextField
                      name="description"
                      placeholder="I need to...."
                      onChange={(e) => setVendorDescription(e.target.value)}
                      value={vendorDescription}
                      fullWidth
                      required
                      multiline
                      rows={2}
                      defaultValue=""
                    />
                  </Grid>
                </Grid>
              </div>
            </>
          </Grid>
        </Grid>
        {/* <Grid container>
          <Grid item md={4} sm={6} lg={4} style={{ padding: "10px" }}>
            <label
              className="labelodal_res"
              style={{
                padding: "10px",
                fontWeight: "bold",
                color: "royalblue",
              }}
            >
              Subject
            </label>
          </Grid>
          <Grid item md={6} sm={6} lg={6}>
            <TextField
              placeholder="Example : Create a Wordpress for my company"
              fullWidth
              className="textField_details"
              onChange={(e) => setVendorSubject(e.target.value)}
              value={vendorSubject}
              name="Subject"
              defaultValue=""
              required
              style={{ width: "60vh" }}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={4} sm={6} lg={4} style={{ padding: "10px" }}>
            <label
              className="label_odl_res"
              style={{
                padding: "10px",
                fontWeight: "bold",
                color: "royalblue",
              }}
            >
              Description
            </label>
          </Grid>
          <Grid item md={6} sm={6} lg={6}>
            <TextField
              name="description"
              placeholder="I need to...."
              onChange={(e) => setVendorDescription(e.target.value)}
              value={vendorDescription}
              fullWidth
              required
              multiline
              rows={4}
              defaultValue=""
              style={{ width: "60vh" }}
            />
          </Grid>
        </Grid> */}
        <br />
        <Grid style={{ width: "100%", overflowX: "auto" }}>
          <CreateQupResponse
            data={postRequiremnts}
            setResult={handleQuotaion}
            addQuoatationActivates
          />
        </Grid>
        <div className="terms_condition">
          <button className="button_value" onClick={() => setVerify(!verify)}>
            Terms and Conditions
          </button>
          <br />
          <div>
            {verify ? (
              <div className="dropdown_terms">
                <Container className="inside_content">
                  <Grid container>
                    <Grid item md={3} sm={12}>
                      <label>Terms:</label>
                    </Grid>
                    <Grid item md={7} sm={12}>
                      <textarea
                        name="terms"
                        value={inputData.terms}
                        onChange={onChangeHandler}
                      />
                    </Grid>
                  </Grid>
                  <br />
                  <Grid container>
                    <Grid item md={3} sm={12}>
                      <label>Description:</label>
                    </Grid>
                    <Grid item md={7} sm={12}>
                      <textarea
                        name="description"
                        value={inputData.description}
                        onChange={onChangeHandler}
                      />
                    </Grid>
                    <Grid item md={2} sm={12} className="button">
                      <button onClick={add}>Add</button>
                    </Grid>
                  </Grid>
                  <div className="table-form">
                    {itemSet.length > 0 && (
                      <div className="table-data">
                        <table id="table">
                          <thead>
                            <tr>
                              <th>Terms</th>
                              <th>Description</th>
                              <th className="last_row"></th>
                            </tr>
                          </thead>
                          {itemSet &&
                            itemSet.map((data, index) => (
                              <tbody key={index}>
                                <tr>
                                  <td>{data.terms}</td>
                                  <td>{data.description}</td>
                                  <td onClick={() => deleteData(data)}>
                                    <i className="fas fa-trash-alt" />
                                  </td>
                                </tr>
                              </tbody>
                            ))}
                        </table>
                      </div>
                    )}
                  </div>
                </Container>
              </div>
            ) : null}
          </div>
        </div>
        <div className="specialNote">
          <button className="button_value" onClick={() => setNote(!note)}>
            Special Notes
          </button>
          {note ? (
            <div className="dropdown_terms">
              <Container className="inside_content">
                <Grid container>
                  <Grid item md={3} sm={12}>
                    <label>Special Note:</label>
                  </Grid>
                  <Grid item md={7} sm={12}>
                    <textarea
                      name="special"
                      value={inputDataNote.special}
                      onChange={onChangeHandlers}
                    />
                  </Grid>
                  <Grid item md={2} sm={12} className="button">
                    <button onClick={addSpecialNote}>Add</button>
                  </Grid>
                </Grid>
                <br />
                <div className="table-form">
                  {itemSetNote.length > 0 && (
                    <div className="table-data">
                      <table id="table">
                        <thead>
                          <tr>
                            <th>Special</th>
                            <th className="last_row"></th>
                          </tr>
                        </thead>
                        {itemSetNote &&
                          itemSetNote.map((data, index) => (
                            <tbody key={index}>
                              <tr>
                                <td>{data.special}</td>
                                <td onClick={() => deleteDataNote(data)}>
                                  <i className="fas fa-trash-alt" />
                                </td>
                              </tr>
                            </tbody>
                          ))}
                      </table>
                    </div>
                  )}
                </div>
              </Container>
            </div>
          ) : null}
        </div>
        <div className="button_modal_res">
          <Button
            variant="contained"
            style={submitButton}
            onClick={handleDraftFormClick}
          >
            {isLoading ? "" : "Submit"}
            {/* Rolling preloader */}
            {isLoading && (
              <div className="rolling-preloader">
                <div className="rolling-preloader-circle"></div>
              </div>
            )}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default UpdateQuatationModel;
