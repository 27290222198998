import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "#3778C2";
const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    borderBottomColor: "#3778C2",
    backgroundColor: "#3778C2",
    color: "#fff",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    textAlign: "center",
    fontStyle: "bold",
    flexGrow: 1,
  },
  categoryName: {
    width: "30%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "center",
  },
  items: {
    width: "40%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "center",
  },
  quantity: {
    width: "20%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "center",
  },

  unit: {
    width: "20%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "center",
  },
  price: {
    width: "20%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "center",
  },
  responseDate: {
    width: "25%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "center",
  },
  rate: {
    width: "15%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "center",
  },
  amount: {
    width: "20%",
    textAlign: "center",
  },
  ClientcategoryName: {
    width: "30%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "center",
  },
  Clientitems: {
    width: "40%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "center",
  },
  Clientquantity: {
    width: "20%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "center",
  },
  Clientunit: {
    width: "20%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "center",
  },
  Clientprice: {
    width: "20%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "center",
  },
  Clientamount: {
    width: "20%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "center",
  },
});

const InvoiceTableHeader = ({ items, ClientItems }) => (
  <View style={styles.container}>
    {items ? (
      <>
        <Text style={styles.categoryName}>Category Name</Text>
        <Text style={styles.items}>Item</Text>
        <Text style={styles.quantity}>Ex Quantity</Text>
        <Text style={styles.unit}>Unit</Text>
        <Text style={styles.price}>Ex Price</Text>
        {/* <Text style={styles.responseDate}>Delivery Date</Text> */}
        <Text style={styles.rate}>Quantity</Text>
        <Text style={styles.rate}>Price</Text>
        <Text style={styles.rate}>Tax</Text>
        <Text style={styles.amount}>Amount</Text>
      </>
    ) : null}
    {ClientItems ? (
      <>
        <Text style={styles.ClientcategoryName}>Category Name</Text>
        <Text style={styles.Clientitems}>Item</Text>
        <Text style={styles.Clientquantity}>Ex Quantity</Text>
        <Text style={styles.Clientunit}>Unit</Text>
        <Text style={styles.Clientprice}>Ex Price</Text>
        <Text style={styles.Clientamount}>Amount</Text>
      </>
    ) : null}
  </View>
);

export default InvoiceTableHeader;
