import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Button,
  Divider,
  Grid,
  Snackbar,
  Typography,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link, useNavigate } from "react-router-dom";
// import axios from "../../api/axios";
import { useDispatch, useSelector } from "react-redux";

import { registerUser } from "../../slices/auth";

import { clearMessage } from "../../slices/message";
import SignInWithGoogle from "../../Services/SigninWithGoogle";
import SignupEP from "./SignupEP";
import CircularProgress from "@mui/material/CircularProgress";
import { Cookies, useCookies } from "react-cookie";

const Signup = () => {
  const [successful, setSuccessful] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [existuserMsg, setExistUserMsg] = useState("");
  const [open, setOpen] = React.useState(false);
  const [showErr, setshowErr] = React.useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [privacy, SetPrivacy] = useState(false);
  // console.log(privacy);
  const [expanded, setExpanded] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const navigate = useNavigate();
  const [cookie, setCookie] = useCookies();

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 0 : prevProgress + 10
      );
    }, 800);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const { message } = useSelector((state) => state.message);
  // console.log(message);
  const dispatch = useDispatch();

  let password;
  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);
  const formSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email cannot be empty")
      .email("Invalid email-format"),
    // phone: Yup.string().max(10, "Enter the correct Mobile no.. "),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    formState: { isSubmitSuccessful },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(formSchema),
    defaultValues: {
      email: "",
      privacy: false,

      // phone: "",
    },
  });
  // password = watch("password", "");
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const handleClosePopUpErr = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setshowErr(false);
  };

  const onSubmit = (data) => {
    try {
      console.log(data);
      dispatch(registerUser(data))
        .unwrap()
        .then((res) => {
          console.log(res);
          setInterval(() => {
            <CircularProgress variant="determinate" value={40} />;
          }, 1000);
          setSuccessMsg("User Registration Successfull !");
          // setSuccessMsg(message);
          // console.log(res);
          setOpen(true);
          setSuccessful(true);
          setTimeout(() => {
            navigate("/signin");
          }, 3000);
        })
        .catch((err) => {
          setSuccessful(false);
          // console.log(err);
          setErrMsg(message);
          setExistUserMsg(err.message);
          setOpen(true);
        });
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        console.log("no response from server");
      }
    }
  };
  const handleSignInWithGoogle = async (res) => {
    try {
      SignInWithGoogle()
        .then((res) => {
          console.log(res);
          if (res) {
            localStorage.setItem(
              "userNew",
              JSON.stringify({ res, role: "ADMIN" })
            );
            window.location.assign("/");
          }
        })
        .catch((err) => console.error(err));
    } catch (error) {
      console.log(error.message);
    }
  };

  const onErrors = (errors) => console.error(errors);

  const handleButtonClick = () => {
    navigate("/signin", {
      state: { tap: 1 },
    });
  };

  return (
    <section className="signup-sec">
      <Grid justifyContent={"center"} alignItems={"center"}>
        <div className="register-section">
          <div className="container">
            {!successful ? (
              <Typography
                variant="h3"
                component={"h5"}
                className="bold-text"
                sx={{
                  color: "blueviolet",
                  textAlign: "center",
                  margin: "auto",
                }}
              >
                Create Your Account!
              </Typography>
            ) : (
              <Typography
                variant="h3"
                component={"h5"}
                className="bold-text"
                sx={{ color: "green", textAlign: "center", margin: "auto" }}
              >
                Registration Successfull !
              </Typography>
            )}
            <p className="common-para register-para">
              {!successful
                ? "Join the most trusted platform and get Served instantly."
                : "Congratulations! Your email has been confirmed and your account is ready.Please close this page and go back to the Email app to account."}
            </p>

            {/* <div className="register-data"> */}
            <Box
              sx={{ width: "100%", display: "flex", flexDirection: "column" }}
            >
              {/* {!successful && (
                <Accordion
                  expanded={expanded === "panel1"}
                  onChange={handleChange("panel1")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography sx={{ width: "100%", flexShrink: 0 }}>
                      SignUp with Email & OTP
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box
                      component={"form"}
                      onSubmit={handleSubmit(onSubmit, onErrors)}
                      sx={{
                        margin: "10px auto",
                        width: "auto",
                        maxWidth: "min-content",
                      }}
                    >

                      <div className="form">
                        <input
                          type="text"
                          className="form-input"
                          id="email"
                          placeholder=" "
                          name="email"
                          {...register("email")}
                          autoComplete="false"
                        />
                        <label htmlFor="email" className="form-label">
                          Email
                        </label>
                      </div>
                      {errors?.email && (
                        <span className="form-error">
                          {errors?.email.message}
                        </span>
                      )}

                      <div className="checkbox">
                        <input
                          type="checkbox"
                          checked={privacy}
                          name="privacy"
                          {...register("privacy")}
                          onChange={(event) => {
                            SetPrivacy(event.target.checked);
                            // console.log(privacy)
                          }}
                        />
                        <p className="common-para">
                          By clicking the checkbox, I agree to Playwise{" "}
                          <Link to="#"> Terms & Conditions </Link>
                        </p>
                      </div>

                      <Button
                        type="submit"
                        value="REGISTER"
                        className="btn register-btn"
                        variant="contained"
                        sx={{ fontSize: 15, backgroundColor: "goldenrod" }}
                      >
                        Register
                      </Button>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              )} */}

              {/* <p className="common-para">or</p> */}
              {!successful && (
                <>
                  <Accordion
                    expanded={expanded === "panel2"}
                    onChange={handleChange("panel2")}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Typography sx={{ width: "100%", flexShrink: 0 }}>
                        SignUp with Email & Password
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <SignupEP />
                    </AccordionDetails>
                  </Accordion>

                  {/* <div className="other-signUps">
                    <p className="common-para">
                      or join us with your social network
                    </p>
                    <div className="social-btn">
                      <i className="fa-brands fa-facebook-f"></i>

                      <i
                        className="fa-brands fa-google-g fa-g"
                        onClick={handleSignInWithGoogle}
                      ></i>
                      <i className="fa-brands fa-linkedin-in"></i>
                    </div>
                  </div> */}

                  <Typography
                    variant="p"
                    component="span"
                    className="common-para"
                  >
                    Already have an account with us? {""}
                    <Typography
                      variant="p"
                      className="common-para"
                      // component={Link}
                      onClick={handleButtonClick}
                      style={{
                        color: "blue",
                        fontWeight: "500",
                        cursor: "pointer",
                      }}
                    >
                      &nbsp;SignIn
                    </Typography>
                  </Typography>
                </>
              )}
            </Box>

            {/* </div> */}
            {open ? (
              <Snackbar
                open={open}
                autoHideDuration={1500}
                onClose={handleClose}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
              >
                <Alert
                  onClose={handleClose}
                  severity={successful ? "info" : "warning"}
                  sx={{ width: "100%", fontSize: 18 }}
                >
                  {/* Registration Successfull ! */}
                  {successMsg.toString() || existuserMsg.toString()}
                </Alert>
              </Snackbar>
            ) : (
              ""
            )}
            {showErr ? (
              <Snackbar
                open={showErr}
                autoHideDuration={1500}
                onClose={handleClosePopUpErr}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
              >
                <Alert
                  onClose={handleClosePopUpErr}
                  severity="warning"
                  sx={{ width: "100%", fontSize: 18 }}
                >
                  {errMsg}
                </Alert>
              </Snackbar>
            ) : (
              ""
            )}
          </div>
        </div>
      </Grid>
    </section>
  );
};

export default Signup;
