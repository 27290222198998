import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import AuthHeader from "../Header/AuthHeader";
import styled from "@emotion/styled";
const AuthContent = () => {
  const CustomButton = styled.button({
    padding: "8px",
    marginRight: "20px",
    marginBottom: "10px",
    display: "flex",
    width: "2rem",
    height: "2rem",
    justifyContent: "center",
    alignItems: "center",
    outline: "none",
    border: "1px solid transparent",
    borderRadius: "50%",
    cursor: "pointer",
    position: "absolute",
    right: 0,
    bottom: "0",
    backgroundColor: "rgb(255 35 52)",
    color: "HighlightText",

    visibility: "visible",
  });

  return (
    <>
      <div className="inner-Authcontent">
        <AuthHeader />
        <main className="main_Authcontent">
          <Outlet/>
          <CustomButton
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            className="qup_ScrollTop"
          >
            Top
          </CustomButton>
        </main>
      </div>
    </>
  );
};
export default AuthContent;
