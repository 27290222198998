import React, { useState, useEffect } from 'react';

const ScrollLine = () => {
  const [scrollY, setScrollY] = useState(0);
  const [lineLength, setLineLength] = useState(0);

  const handleScroll = () => {
    const scrolled = window.scrollY;
    const totalHeight = document.documentElement.scrollHeight - window.innerHeight;
    const scrollPercentage = (scrolled / totalHeight) * 100;
    setScrollY(scrollPercentage);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    // Adjust line length based on scroll position and total height
    const newLength = Math.min(100, scrollY); // You can adjust the maximum length based on your preference
    setLineLength(newLength);
  }, [scrollY]);

  return (
    <div>
      {/* Your other content goes here */}
      <div
        style={{
          height: '4px', // Set the height of the line
          backgroundColor: 'blue',
          width: `${lineLength}%`, // Set the width of the line based on scroll position
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: 1000,
          // transition: 'width 0.5s ease',
        }}
      />
      {/* Your other content goes here */}
    </div>
  );
};

export default ScrollLine;
