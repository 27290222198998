import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRoute = () => {
  const isLoggedIn = useSelector(
    (state: any) => state.adminauth?.authentication
  );
  return isLoggedIn ? <Outlet /> : <Navigate to="/qup/admin/login" />;
};

export default PrivateRoute;
