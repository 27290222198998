// import "./CategoryTable.scss";
import axios from "../../api/axios";
import React, { useEffect, useState } from "react";
import {
  GET_RFQ_EXPLAIN,
  POST_CLIENT_DRAFTS,
  USER_GET_AllRFQS,
} from "../CompServices/Api_Routes_Helper";
import { Input, Table } from "antd";
import "./DataListTable.scss";
import { Box, Container, MenuItem, Tooltip, Typography } from "@mui/material";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { HiEye, HiPencilAlt } from "react-icons/hi";
import CategoryListView from "./CategoryListView";
import CategoryListUpdate from "./CategoryListUpdate";
import ViewResponse from "./ViewResponse";
import RFQModal from "./RFQModal";
import LoadingBars from "../PageLoader/LoadingBars";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import authHeader from "../../Services/auth-header";
import { Modal } from "react-bootstrap";
import StatusTracker from "./StatusTracker";

function CategoryTable(props) {
  const [companyList, setCompanyList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [search, setSearch] = useState([]);
  /////modal in react-bootstrap/////
  const [viewPageOpen, setViewPageOpen] = useState(false);
  const [selectedViewId, setSelectedViewId] = useState(null);
  const [openResponseModal, setOpenResponseModal] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [selectedIdd, setSelectedIdd] = useState(null);
  const [buttonClicked, setButtonClicked] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  // const reversedData = Array.isArray(companyList)
  //   ? [...companyList].reverse()
  //   : [];

  const myQueryParam = new URLSearchParams(location.search);
  const rfqtype = myQueryParam.get("rfq_type") || "Request";

  const handleButtonClick = () => {
    navigate("/user_in/categoryList", {
      state: { rfq_type: rfqtype },
    });
  };

  const handleChange = (e) => {
    setSearchText(e.target.value);
  };

  // const dataCount = companyList ? companyList.length : 0;

  const handleViewPageOpen = (event, id) => {
    event.preventDefault();
    setViewPageOpen(true);
    setSelectedId(id);
  };
  const handleViewPageClose = () => setViewPageOpen(false);

  const [EditPageOpen, setEditPageOpen] = useState(false);
  const handleEditPageOpen = (id) => {
    setEditPageOpen(true);
    setSelectedIdd(id);
  };
  const handleEditPageClose = () => setEditPageOpen(false);

  const handleOpenResponseMenu = (id) => {
    console.log(id);
    setOpenResponseModal(true);
    setSelectedViewId(id);
  };
  const handleCloseResponseMenu = () => setOpenResponseModal(false);
  const handleSendRfqRequest = async (id) => {
    try {
      const getrfqinfo = await axios.get(`${GET_RFQ_EXPLAIN}/${id}`);
      if (getrfqinfo) {
        console.log(getrfqinfo.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  function SimplePopper({ stages }) {
    const [anchorEl, setAnchorEl] = React.useState(false);

    const handleClick = () => {
      setAnchorEl(true);
    };

    const handleClosePopOver = () => {
      setAnchorEl(false);
    };

    return (
      <div>
        <button
          type="button"
          className="btn btn-float btn-default btn-sm"
          onMouseDown={handleClick}
        >
          Track
        </button>
        {/* <ClickAwayListener onClickAway={handleClosePopOver}> */}
        <Modal
          id={id}
          show={anchorEl}
          onHide={handleClosePopOver}
          dialogClassName="custom-modal"
          aria-labelledby="modal-rfq-status"
          centered
          backdrop="static"
        >
          <Modal.Header
            style={{ background: "lightblue" }}
            id="modal-rfq-status"
          >
            <Modal.Title>RFQ status View</Modal.Title>

            <div className="closeModal" onClick={handleClosePopOver}></div>
          </Modal.Header>
          <Modal.Body style={{ padding: 0 }}>
            {stages.length > 0 ? (
              <StatusTracker stages={stages} />
            ) : (
              <Box>
                <div className="container-fluid">
                  <div className="row mb-2">
                    <div className="col-sm-6">
                      <div className=" no_stage_track p-2">
                        <Typography variant="body2" component={"h6"}>
                          Tracker not available at the moment
                        </Typography>
                      </div>
                    </div>
                  </div>
                </div>
              </Box>
            )}
          </Modal.Body>
        </Modal>
        {/* </ClickAwayListener> */}
      </div>
    );
  }
  const columns = [
    // {
    //   title: "Service Requested",
    //   dataIndex: "cat_Id",
    // },
    {
      title: "Subject",
      dataIndex: "subject",
      width: 250,
      sorter: (a, b) => a.subject.localeCompare(b.subject),
    },
    {
      title: "Description",
      dataIndex: "description",
      width: 300,
      sorter: (a, b) => a.description.localeCompare(b.description),
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 100,
      align: "right",
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (text, record) => {
        // return (
        //   <span className="p-1" style={{ maxWidth: 60 }}>
        //     <button
        //       type="submit"
        //       id="rfq_btn_Status"
        //       className="btn btn-sm btn-outline-primary"
        //       onClick={() => {
        //         const statusText = document.querySelector("#rfq_btn_Status");
        //         statusText.textContent =
        //           text?.charAt(0).toUpperCase() + text.slice(1);
        //       }}
        //     >
        //       Track
        //     </button>
        //   </span>
        // );

        return (
          <div className="row justify-content-center">
            <span className="text-wrap" style={{ fontSize: "13px" }}>
              {text?.charAt(0).toUpperCase() + text.slice(1)}
            </span>
          </div>
        );
      },
    },
    {
      title: "Track",
      dataIndex: "stages",
      width: 80,
      align: "right",
      render: (stages) => {
        return <SimplePopper stages={stages} />;
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 85,
      align: "left",
      // sorter: (a, b) => a.action.localeCompare(b.action),

      render: (_, record) => (
        <div className="row">
          <div className="container mx-auto">
            <div className="btn_space_bw d-flex justify-content-center">
              {record.status === "Saved as Draft" && (
                <MenuItem className="rfq-btn-box">
                  <Tooltip title="Edit">
                    <button className="rfq_edit_btn">
                      <HiPencilAlt
                        onClick={() => handleEditPageOpen(record._id)}
                      />
                    </button>
                  </Tooltip>
                </MenuItem>
              )}
              {/* {record.status === "pending" && ( */}
              {record.status && (
                <MenuItem className="rfq-btn-box">
                  <Tooltip title="View">
                    <button
                      className="rfq_view_btnn"
                      onClick={(e) => handleViewPageOpen(e, record._id)}
                    >
                      <HiEye />
                    </button>
                  </Tooltip>
                </MenuItem>
              )}
            </div>
          </div>
        </div>
      ),
    },
    {
      title: "Response",
      dataIndex: "action",
      // sorter: (a, b) => a.action.localeCompare(b.action),
      width: 100,
      render: (_, record) => (
        <div className="btnspace_bw">
          {record.status === "Saved as Draft" ? (
            <SendDraft record={record} title={"Send"} />
          ) : (
            <button
              onClick={() => handleOpenResponseMenu(record._id)}
              className="liquid-button"
            >
              View
              <div className="liquid"></div>
            </button>
          )}
        </div>
      ),
    },
  ];

  const id = localStorage.getItem("userID");

  const getRfqRequests = async () => {
    const { data } = await axios.get(
      `${USER_GET_AllRFQS}${id}/?rfqtype=${rfqtype}`,
      {
        headers: await authHeader(),
      }
    );
    if (data) {
      const timeoutLoad = setTimeout(() => {
        setLoading(false);
      }, 500);
      setCompanyList(data.data);
      return () => clearTimeout(timeoutLoad);
    } else {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (id !== null) {
      getRfqRequests();
    }
  }, [rfqtype]);

  useEffect(() => {
    if (companyList !== null) {
      const results = companyList.filter(
        (item) =>
          item?.subject
            ?.toLowerCase()
            .includes(searchText.toLowerCase().trim()) ||
          item?.RegistrationId?.toLowerCase().includes(
            searchText.toLowerCase().trim()
          ) ||
          item?.email
            ?.toLowerCase()
            .includes(searchText.toLowerCase().trim()) ||
          item?.phone?.toString().includes(searchText)
      );
      setSearch(results);
    }
  }, [searchText]);

  const pagination = {
    pageSize: 5,
  };
  const OnRefreshTable = () => {
    getRfqRequests();
  };

  const handleSendDraftRfqRequest = async (id) => {
    if (buttonClicked) return;
    try {
      const { status, data } = await axios.post(`${POST_CLIENT_DRAFTS}${id}`);
      if (data !== null) {
        toast.success(data.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
        getRfqRequests();
      }
    } catch (err) {
      console.error(err);
      const { data, status } = err?.response;
      if (status === 500) {
        toast.error(data?.error, { position: toast.POSITION.TOP_CENTER });
      }
    }
  };
  const SendDraft = ({ record, title }) => {
    return (
      <button
        className="liquid-button"
        type="submit"
        onClick={() => handleSendDraftRfqRequest(record._id)}
      >
        {title}
        <div className="liquid draft"></div>
      </button>
    );
  };
  return (
    <div>
      {loading ? (
        <LoadingBars />
      ) : (
        <div>
          <Container>
            <div className="data-list-main">
              <div className="data-list-box">
                <h4 className="data-list-header">{`My ${rfqtype}s`}</h4>
                <div className="Request-button">
                  <button
                    // className="custom-btn btn-7"
                    className="liquid-button"
                    onClick={handleButtonClick}
                    style={{ padding: "6px 8px" }}
                  >
                    <span>
                      {rfqtype === "Request"
                        ? "Add New Request"
                        : "Add New Service"}
                    </span>
                    <div className="liquid"></div>
                  </button>
                </div>
              </div>

              <div className="data-list-top">
                <Input
                  className="data-list-search ps-2"
                  placeholder="search here"
                  onChange={handleChange}
                />
                {/* <h3 className="pages-title">
                  <span>
                    {rfqtype === "Request"
                      ? "Total Requests:"
                      : "Total Services:"}
                    &nbsp;&nbsp;
                  </span>
                  <span style={{ fontWeight: "bold" }}>{dataCount}</span>
                </h3> */}
              </div>
              {searchText ? (
                <div className="data-list-table">
                  <Table
                    scroll={{ x: true }}
                    columns={columns}
                    dataSource={search}
                    pagination={pagination}
                    className="table-striped-rows"
                    locale={{
                      emptyText: "No records found",
                    }}
                    size="middle"
                  />
                </div>
              ) : (
                <div className="data-list-table">
                  <Table
                    scroll={{ x: true }}
                    columns={columns}
                    dataSource={companyList}
                    pagination={pagination}
                    className="table-striped-rows"
                    locale={{
                      emptyText: "No records found",
                    }}
                    bordered
                    size="middle"
                    rowKey="_id"
                    // key={(reversedData) => reversedData._id}
                  />
                </div>
              )}
            </div>
          </Container>
        </div>
      )}
      {/* <RFQModal
        open={ViewPageOpen}
        onClose={handleViewPageClose}
        tableHeader={"Category View"}
      >
        <CategoryListView userId={selectedId} onBack={handleViewPageClose} />
      </RFQModal> */}
      <Modal
        show={viewPageOpen}
        onHide={handleViewPageClose}
        dialogClassName="custom-modal"
      >
        <Modal.Header style={{ background: "lightblue" }}>
          <Modal.Title>Category View</Modal.Title>

          <div className="closeModal" onClick={handleViewPageClose}></div>
        </Modal.Header>
        <Modal.Body style={{ background: "#e0e0e0" }}>
          <CategoryListView userId={selectedId} onBack={handleViewPageClose} />
        </Modal.Body>
      </Modal>
      <RFQModal
        open={EditPageOpen}
        onClose={handleEditPageClose}
        tableHeader={"Category Update"}
      >
        <CategoryListUpdate
          userId={selectedIdd}
          onBack={handleEditPageClose}
          OnRefreshTable={OnRefreshTable}
        />
      </RFQModal>
      <RFQModal
        open={openResponseModal}
        onClose={handleCloseResponseMenu}
        tableHeader={"Quick View of Quotation received"}
      >
        <ViewResponse
          sendId={selectedViewId}
          onBack={handleCloseResponseMenu}
        />
      </RFQModal>
      <ToastContainer />
    </div>
  );
}

export default CategoryTable;
