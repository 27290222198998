import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Snackbar,
  Button,
  Typography,
  Grid,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";

import LoadingButton from "@mui/lab/LoadingButton";
import { Box } from "@mui/system";
import { debounce } from "lodash";
import React, {
  useState,
  useEffect,
  useRef,
  Fragment,
  useContext,
} from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import axios from "../../api/axios";
import {
  API_AUTH_URL,
  SEND_OTP_PATH,
  SUBMIT_OTP_PATH,
} from "../CompServices/Api_Routes_Helper";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Timer from "./Timer";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../slices/auth";
import { clearMessage } from "../../slices/message";
import { useCookies } from "react-cookie";
import SignInEmailPwd from "./SignEP";
import signInWithGoogle from "../../Services/SigninWithGoogle";
import { useLocation } from "react-router-dom";
import VendorSignInEP from "./Vendor_SignInEP";
export const otpInputStyle = {
  cursor: "pointer",
  fontSize: "1rem",
  color: "black",
};
const VendorSign = () => {
  //initial  values for email and password
  const navigate = useNavigate;

  const { isLoggedIn } = useSelector((state) => state.auth);
  // console.log(isLoggedIn);
  const { message } = useSelector((state) => state.message);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showOtpField, setShowOtpField] = useState(false);
  //make form disapper
  const [reqOtpForm, setReqOtpForm] = useState(true);
  const [reqemail, setReqEmail] = useState("");
  //css loader
  const [onreqLoad, setOnreqLoad] = useState(false);
  //token not verify
  const [tokenVerifyErr, setTokenVerifyErr] = useState(false);
  //capture otp expiry time
  const [otpexptime, setExpTime] = useState(0);
  //send otp regenrate link
  const [reotpLink, setReotpLink] = useState(false);
  const [otpdeadline, setOtpDeadline] = useState(null);
  //for displaying error form server
  const [showErr, setshowErr] = React.useState(false);
  const [errMsg, setErrMsg] = useState("");
  //for hiding input fields.
  const [expanded, setExpanded] = React.useState(false);
  const dispatch = useDispatch();
  //cookies

  const handleChangeAcc = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  //google Signin
  const handleSignInWithGoogle = async (res) => {
    try {
      signInWithGoogle()
        .then((res) => {
          console.log(res);
          if (res) {
            localStorage.setItem(
              "userNew",
              JSON.stringify({ res, role: "ADMIN" })
            );
            window.location.assign("/");
          }
        })
        .catch((err) => console.error(err));
    } catch (error) {
      console.log(error.message);
    }
  };

  const formSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email cannot be empty")
      .email("Invalid email-format"),

    otp: Yup.string().max(6, "otp cannot exceed 6 digits."),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,

    watch,
    formState: { isSubmitSuccessful },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(formSchema),
    defaultValues: {
      email: "",

      otp: "",
    },
  });

  const [successful, setSuccessful] = useState(false);

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  const handleClosePopUpErr = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setshowErr(false);
  };

  const onErrors = (errors) => console.error(errors);
  //send otp if emial is valid
  async function sendOtp(email) {
    // const em = email.email;
    try {
      setOnreqLoad(true);
      const postData = await axios.post(`${SEND_OTP_PATH}`, email, {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });
      setSuccessful(true);
      setShowOtpField(true);
      setReqOtpForm(false);
      setshowErr(true);
      setTokenVerifyErr(true);
      setErrMsg(postData.data.message);

      const signup_response = postData.data;
      console.log(signup_response);
      const otpexpiry = postData.data.otpttl;
      const slicedExpiry = otpexpiry.slice(0, -1);
      setExpTime(slicedExpiry);
    } catch (error) {
      if (error.response.status === 400) {
        const response = error.response;
        const errormessage = response.data.message;
        setShowOtpField(false);
        setshowErr(true);
        setTokenVerifyErr(true);
        setErrMsg(errormessage);
        setOnreqLoad(false);
      }
    }
  }

  //submission of form (emial,otp)
  const onVerifyOtp = async (data) => {
    const { email, otp } = data;
    const OTP = Number(otp);
    console.log("data", OTP);
    console.log(email, OTP);
    try {
      if (!email || !OTP) {
        throw new Error("All fields are required");
      }
      const { data, status } = await axios.post(
        SUBMIT_OTP_PATH,
        { email, OTP },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (status === 200 && data !== null) {
        // console.log(message);
        setIsSubmitting(true);
        const signup_response = data?.responseDetails;
        const vendor = {
          vid: signup_response._id,
          role: "VENDOR",
        };
        localStorage.setItem("v_ID", JSON.stringify(vendor));
        localStorage.setItem("v_info", signup_response.email);
      }
    } catch (error) {
      console.error(error);
      if (error.status === 401) {
        setshowErr(true);
        setErrMsg(message);
        setIsSubmitting(false);
        setReqOtpForm(false);
      } else if (error.status === 419) {
        setshowErr(true);
        setErrMsg(message);
        setIsSubmitting(false);
        setReotpLink(true);
        setReqOtpForm(false);
      }
    }
  };
  if (isSubmitting) {
    return <Navigate to="/vendorManagement/dashboard" />;
  }

  const reqOtpResend = async (event) => {
    event.preventDefault();

    // console.log(event);
    // console.log(reqemail);
    try {
      await sendOtp(reqemail);
      setOtpDeadline(otpexptime);
      alert("otp has been resent");
      setReotpLink(false);
    } catch (error) {
      console.error(error);
    }
  };

  const getOTPComp = () => {
    // console.log("Reset the timer: ", otpexptime);

    return (
      <>
        <div className="form login-data-form">
          <input
            id="otp"
            type="text"
            className="form-input"
            // placeholder="Enter received OTP"
            name="otp"
            {...register("otp")}
            required
            // style={{ textAlign: "center" }}
          />
          <label htmlFor="otp" className="form-label">
            Enter OTP
          </label>
          <br />
        </div>
        <Timer timer={otpexptime} /> {/* hide verify if req to resend otp */}
        {!reotpLink && (
          <button type="submit" className="otp-btn">
            VERIFY OTP
          </button>
        )}
        {reotpLink && (
          <button
            type="submit"
            className="resend-otp-btn"
            onClick={reqOtpResend}
            style={{ backgroundColor: "Highlight", fontSize: "14px" }}
          >
            RESEND OTP
          </button>
        )}
      </>
    );
  };
  const createAccVerifyToken = async (data) => {
    console.log(data);

    await axios
      .post(
        `${API_AUTH_URL}send/acc_verifyToken`,
        { email: data },
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      )
      .then((response) => {
        console.log("response sent successfully", response);
      })
      .catch((err) => console.error(err));
  };

  const getEmailFormComp = () => {
    return (
      <div className="email-login-data">
        <form className="login-form" onSubmit={handleSubmit(sendOtp, onErrors)}>
          <div className="form">
            <input
              type="email"
              className="form-input"
              placeholder=" "
              name="email"
              id="email"
              {...register("email")}
              onChange={(e) => setReqEmail(e.target.value)}
              required
            />
            <label htmlFor="email" className="form-label">
              Email
            </label>
          </div>
          {errors?.email && (
            <span className="form-error">{errors.email.message}</span>
          )}

          {tokenVerifyErr ? (
            <p className="form-error">
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  createAccVerifyToken(reqemail);
                }}
                to="/"
              >
                Please click here to resend account verification Link.
              </Link>
            </p>
          ) : (
            <span className="form-error">{errMsg}</span>
          )}

          <Button variant="outlined" type="submit" className="v-em-btn">
            REQUEST OTP
          </Button>
          {onreqLoad && (
            <Grid item xs={12} alignItems={"center"}>
              <div className="lds-facebook">
                <div></div>
                <div></div>
                <div></div>
              </div>
            </Grid>
          )}
        </form>
      </div>
    );
  };
  const alertBar = (
    <Snackbar
      open={showErr}
      autoHideDuration={2500}
      onClose={handleClosePopUpErr}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert
        onClose={handleClosePopUpErr}
        severity="info"
        sx={{ width: "100%", fontSize: 16 }}
      >
        {typeof errMsg === "boolean" ? errMsg.toString() : errMsg}
      </Alert>
    </Snackbar>
  );

  return (
    <Fragment>
      <h2 className="bold-text">QuoteUrPrice!</h2>

      <VendorSignInEP />

      <p className="common-para">or</p>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChangeAcc("panel2")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography component="span" sx={otpInputStyle} variant="caption">
            Login with your Email and OTP
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box className="email_otp_cont">
            {reqOtpForm && getEmailFormComp()}

            {showOtpField && (
              <div className="email-login-data">
                <form
                  className="login-form"
                  onSubmit={handleSubmit(onVerifyOtp, onErrors)}
                >
                  <div className="form">
                    <input
                      id="email"
                      type="email"
                      className="form-input"
                      placeholder=" "
                      name="email"
                      value={reqemail}
                      required
                      disabled
                    />
                    <label htmlFor="email" className="form-label">
                      Email
                    </label>
                  </div>

                  {showOtpField && getOTPComp()}
                </form>
              </div>
            )}
          </Box>
        </AccordionDetails>
      </Accordion>
      {alertBar}
      <p className="common-para">or</p>
      <p className="common-para">login with your social network account</p>
      <div className="social-btn login-btn">
        <i className="fa-brands fa-facebook-f"></i>
        <i
          className="fa-brands fa-google-g fa-g"
          onClick={handleSignInWithGoogle}
        ></i>
        <i className="fa-brands fa-linkedin-in"></i>
      </div>
      <Typography component="p" variant="p" className="common-para">
        Not yet joined QuoteUrPrice?
        <Typography
          variant="p"
          className="common-para"
          component={Link}
          to="#"
          // to="/vendor/signup"
          color="blue"
          sx={{ cursor: "pointer" }}
        >
          Sign Up
        </Typography>{" "}
        here
      </Typography>

      {message && <p>{message}</p>}
    </Fragment>
  );
};

export default VendorSign;
