import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
// import TextField from "@mui/material/TextField";
import {
  Alert,
  Button,
  // Typography
} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { auth } from "../../Services/Firebase";
import { useDispatch } from "react-redux";
import { registerUser } from "../../slices/auth";
import { clearMessage } from "../../slices/message";
const SignupEP = () => {
  const dispatch = useDispatch();
  const [toggle1, setToggle1] = useState(false);
  const [toggle2, setToggle2] = useState(false);

  const [open, setOpen] = React.useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  let password;

  const formSchema = Yup.object().shape({
    // username: Yup.string()
    //   .required("username is required")
    //   .min(3, "username length should be at least 3 characters"),
    email: Yup.string()
      .required("Email cannot be empty")
      .email("Invalid email-format"),
    // phone: Yup.string()
    //   .required("Phone no. is required ")
    //   .max(10, "Enter the correct Mobile no.. "),
    password: Yup.string()
      .required("Password is required")
      .min(4, "Password length should be at least 4 characters")
      .max(12, "Password cannot exceed more than 12 characters"),

    confirmpassword: Yup.string()
      .required("Confirm Password is required")
      .min(4, "Password length should be at least 4 characters")
      .max(12, "Password cannot exceed more than 12 characters")
      .oneOf([Yup.ref("password")], "Passwords do not match"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    reset,
    watch,
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(formSchema),
    defaultValues: {
      // username: "",
      email: "",

      password: "",
      confirmpassword: "",
    },
  });
  password = watch("password", "");
  const onSubmit = async () => {
    const userData = {
      email: getValues("email"),
      password: getValues("password"),
      confirmpassword: getValues("confirmpassword"),
    };
    dispatch(registerUser(userData))
      .unwrap()
      .then((res) => {
        setSuccessMsg("User registartion Successfull !");
        setOpen(true);
        reset({
          email: "",
          password: "",
          confirmpassword: "",
        });
        dispatch(clearMessage());
        // await sendEmailVerification(authUser.user);
      })
      .catch((error) => {
        console.log(error);
        const { message } = error;
        if (error?.response) {
          setErrMsg("No response from Server");
        } else {
          setErrMsg(message);
        }
        setOpen(true);
        dispatch(clearMessage());
      });
  };

  const onErrors = (errors) => {
    console.error(errors);
  };
  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);
  return (
    <div className="EP-register-section ">
      <div className="container">
        {open && (
          <Snackbar
            open={open}
            autoHideDuration={1500}
            onClose={handleClose}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={handleClose}
              severity={errMsg ? "error" : "success"}
              sx={{ width: "100%", fontSize: 14 }}
            >
              {errMsg || successMsg}
            </Alert>
          </Snackbar>
        )}

        <Box component={"form"} onSubmit={handleSubmit(onSubmit, onErrors)}>
          {/* for email */}
          <div className="form">
            <input
              type="text"
              className={`form-input`}
              id="email"
              placeholder=" "
              autoComplete="none"
              // name="email"
              {...register("email")}
            />
            <label htmlFor="email" className="form-label">
              email
            </label>
          </div>
          {errors?.email && (
            <span className="form-error">{errors?.email.message}</span>
          )}
          <div className="form">
            <input
              type={toggle1 ? "text" : "password"}
              id="password"
              className={`form-input`}
              placeholder=" "
              // name="password"
              {...register("password")}
            />
            <i
              id="showpass"
              // className="fa fa-eye icon"
              className={`fa ${toggle1 ? "fa-eye" : "fa-eye-slash"} icon`}
              onClick={() => {
                setToggle1(!toggle1);
              }}
            ></i>
            <label htmlFor="password" className="form-label">
              Password
            </label>
          </div>
          {errors?.password && (
            <span className="form-error">{errors?.password.message}</span>
          )}
          <div className="form">
            <input
              type={toggle2 ? "text" : "password"}
              id="cnfpassword"
              className={`form-input`}
              placeholder=" "
              // name="password"
              {...register("confirmpassword")}
            />
            <i
              id="showpass"
              // className="fa fa-eye icon"
              className={`fa ${toggle2 ? "fa-eye" : "fa-eye-slash"} icon`}
              onClick={() => {
                setToggle2(!toggle2);
              }}
            ></i>
            <label htmlFor="cnfpassword" className="form-label">
              Confirm Password
            </label>
          </div>

          {errors?.confirmpassword && (
            <span className="form-error">
              {errors?.confirmpassword.message}
            </span>
          )}

          <Button
            type="submit"
            className="btn register-btn"
            variant="contained"
            sx={{ fontSize: 15, backgroundColor: "GrayText" }}
          >
            SignUp
          </Button>
        </Box>
      </div>
    </div>
  );
};

export default SignupEP;
