import React, { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import MDBox from "../../../../../Dcomponents/MDBox";

import MDTypography from "../../../../../Dcomponents/MDTypography";

import axios from "../../../../../../../../api/axios";
import {
  Fetch_RFQ_request,
  GET_RFQS_VID,
} from "../../../../../../../CompServices/Api_Routes_Helper";

import RecentRfqsTable from "./MostRecentRFQs";
import authHeader from "../../../../../../../../Services/auth-header";

function RecievedRFQs({ vendorIdProps }) {
  const [menu, setMenu] = useState(null);

  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);
  const [rfqdata, setRfqdata] = useState([]);

  useEffect(() => {
    const fetchRequests = async (vendorId) => {
      if (!vendorId) {
        return;
      }
      const response = await axios.get(`${Fetch_RFQ_request}${vendorId}`, {
        headers: await authHeader(),
      });
      if (response) {
        // console.log("vendor rfqs", response.data);
        setRfqdata(response.data);
      }

      // console.log(rfqdata);
    };
    fetchRequests(vendorIdProps);
  }, [vendorIdProps]);
  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem
        onClick={() => {
          window.location.reload();
        }}
      >
        Fetch
      </MenuItem>
      <MenuItem onClick={closeMenu}>Another action</MenuItem>
      <MenuItem onClick={closeMenu}>Something else</MenuItem>
    </Menu>
  );

  return (
    <Card>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={3}
      >
        <MDBox>
          <MDTypography variant="h6" gutterBottom>
            RFQ Requests
          </MDTypography>
          <MDBox display="flex" alignItems="center" lineHeight={0}>
            <Icon
              sx={{
                fontWeight: "bold",
                color: ({ palette: { info } }) => info.main,
                mt: -0.5,
              }}
            >
              <ArrowDownwardIcon />
            </Icon>
            <MDTypography variant="button" fontWeight="regular" color="text">
              &nbsp;<strong>Received</strong> this month
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox color="text" px={2}>
          <Icon
            sx={{ cursor: "pointer", fontWeight: "bold" }}
            fontSize="small"
            onClick={openMenu}
          >
            more_vert
          </Icon>
        </MDBox>
        {renderMenu}
      </MDBox>
      <MDBox display="flex" alignItems="center" p={3}>
        <MDTypography
          variant="h6"
          sx={{ fontSize: 14 }}
          component="span"
          gutterBottom
        >
          Most Recent RFQ's
        </MDTypography>
      </MDBox>
      <RecentRfqsTable recentrfqs={rfqdata}></RecentRfqsTable>
    </Card>
  );
}

export default RecievedRFQs;
