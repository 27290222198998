import React, { useEffect, useState } from "react";
import CreateQupResponse from "../Responses/RowResponse/CreateQupResponse";
import { Button, Card, Container, Grid, TextField } from "@mui/material";
import { submitButton } from "../../DesignTools/ButtonStyles/ButtonStyles";
import { POST_DRAFT } from "../../CompServices/Api_Routes_Helper";
import axios from "../../../api/axios";

function CreateQuatationModel({
  resData,
  handleQuotaion,
  postRequiremnt,
  requires,
  rfqValues,
  setPostReq,
  subject,
  description,
}) {
  const [vendorSubject, setVendorSubject] = useState("");
  const [vendorDescription, setVendorDescription] = useState("");
  const vendorSubjects = resData?.map((item) => item?.vendorSubject);
  const vendorDescriptions = resData?.map((item) => item?.vendorDescription);

  const [inputData, setInputData] = useState({
    terms: "",
    description: "",
  });
  const onChangeHandler = (e) => {
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  };
  const add = () => {
    if (!inputData) {
    } else {
      setItemSet([...itemSet, inputData]);
      setInputData({ terms: "", description: "" });
    }
  };

  const [itemSet, setItemSet] = useState([]);
  //changing table value for quotation

  const deleteData = (value) => {
    const deleteValue = itemSet.filter((data) => data.terms !== value.terms);
    setItemSet(deleteValue);
  };
  const [inputDataNote, setInputDataNote] = useState({
    special: "",
  });
  const onChangeHandlers = (e) => {
    setInputDataNote({ ...inputDataNote, [e.target.name]: e.target.value });
  };
  const addSpecialNote = () => {
    if (!inputDataNote) {
    } else {
      setItemSetNote([...itemSetNote, inputDataNote]);
      setInputDataNote({ special: "" });
    }
  };
  const [itemSetNote, setItemSetNote] = useState([]);
  const deleteDataNote = (value) => {
    const deleteValuee = itemSetNote.filter(
      (data) => data.special !== value.special
    );
    setItemSetNote(deleteValuee);
  };
  const v_id = localStorage.getItem("v_ID");
  const { vid } = JSON.parse(v_id);

  useEffect(() => {
    if (vendorSubjects && vendorSubjects.length > 0) {
      setVendorSubject(vendorSubjects[0]);
    }
    if (vendorDescriptions && vendorDescriptions.length > 0) {
      setVendorDescription(vendorDescriptions[0]);
    }
  }, [resData]);

  const [isLoading, setIsLoading] = useState(false);
  const [note, setNote] = useState(false);
  const [verify, setVerify] = useState(false);

  const [openTable, setOpenTable] = useState(false);

  const handleFormSubmit = () => {
    handledraftSubmit();
  };

  const handleFormClick = () => {
    setIsLoading(true);
    setOpenTable(true);
    setTimeout(() => {
      setIsLoading(false);
      handleFormSubmit();
    }, 1000);
  };

  const handledraftSubmit = async () => {
    const postDraft = {
      requestID: rfqValues._id,
      customer_ID: rfqValues.customer_ID,
      cat_ID: rfqValues.cat_ID,
      vendor_ID: vid,
      requirements: requires,
      vendorSubject: vendorSubject,
      vendorDescription: vendorDescription,
      termsAndCondition: itemSet,
      specialNote: itemSetNote,
      isDraft: true,
    };
    await axios
      .post(`${POST_DRAFT}`, postDraft)
      .then((e) => {
        setPostReq();
      })
      .catch((error) => console.error(error));
  };
  return (
    <div>
      <div className="main_div_res">
        <Grid container>
          <Grid item sm={12} md={12} lg={6} style={{ padding: "10px" }}>
            <div className="borders-text">
              <h6 className="headding">Client Request</h6>
              <Grid container mt={2}>
                <Grid item sm={6} md={4} lg={4} className="respond res">
                  <p className="subject-text">Subject</p>
                </Grid>
                <Grid item sm={6} md={8} lg={8} className="respond">
                  <p
                    className="scrollable-content"
                    style={{
                      padding: "10px",
                      fontSize: "16px",
                      // fontWeight: "600",
                      // height: "10vh",
                    }}
                  >
                    {subject}
                  </p>
                </Grid>
              </Grid>
              <Grid
                container
                style={{ marginTop: "10px", marginBottom: "10px" }}
              >
                <Grid item sm={6} md={4} lg={4} className="respond res">
                  <p className="subject-text">Description</p>
                </Grid>
                <Grid item sm={6} md={8} lg={8} className="respond">
                  <div>
                    <p
                      style={{
                        padding: "10px",
                        fontSize: "16px",
                        // fontWeight: "600",
                        // height: "13vh",
                      }}
                      className="scrollable-content"
                    >
                      {description}
                    </p>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item sm={12} md={12} lg={6} style={{ padding: "10px" }}>
            <>
              <div className="borders-text">
                <h6 className="headding">Vendor Request</h6>

                <Grid container mt={2}>
                  <Grid item sm={6} md={4} lg={4} className="respond res">
                    <p className="subject-text">Subject</p>
                  </Grid>
                  <Grid
                    item
                    sm={6}
                    md={8}
                    lg={8}
                    className="respond"
                    style={{ padding: "10px" }}
                  >
                    <TextField
                      placeholder="Example : Create a Wordpress for my company"
                      fullWidth
                      className="textField_details"
                      label="Subject"
                      onChange={(e) => setVendorSubject(e.target.value)}
                      name="Subject"
                      defaultValue=""
                      required
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  style={{ marginTop: "10px", marginBottom: "10px" }}
                >
                  <Grid item sm={6} md={4} lg={4} className="respond res">
                    <p className="subject-text">Description</p>
                  </Grid>
                  <Grid
                    item
                    sm={6}
                    md={8}
                    lg={8}
                    className="respond"
                    style={{ padding: "10px" }}
                  >
                    <TextField
                      name="description"
                      label="Description"
                      placeholder="I need to...."
                      onChange={(e) => setVendorDescription(e.target.value)}
                      fullWidth
                      required
                      multiline
                      rows={2}
                      defaultValue=""
                    />
                  </Grid>
                </Grid>
              </div>
            </>
          </Grid>
        </Grid>

        <br />
        <Grid style={{ width: "100%", overflowX: "auto" }}>
          <CreateQupResponse
            data={requires}
            setResult={handleQuotaion}
            addQuoatationActivate
          />
        </Grid>

        <div className="terms_condition">
          <button className="button_value" onClick={() => setVerify(!verify)}>
            Terms and Conditions
          </button>
          <br />
          <div>
            {verify ? (
              <div className="dropdown_terms">
                <Container className="inside_content">
                  <Grid container>
                    <Grid item md={3} sm={12}>
                      <label>Terms:</label>
                    </Grid>
                    <Grid item md={7} sm={12}>
                      <textarea
                        name="terms"
                        value={inputData.terms}
                        onChange={onChangeHandler}
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item md={3} sm={12}>
                      <label>Description:</label>
                    </Grid>
                    <Grid item md={7} sm={12}>
                      <textarea
                        name="description"
                        value={inputData.description}
                        onChange={onChangeHandler}
                      />
                    </Grid>
                    <Grid item md={2} sm={12}>
                      <Button
                      //  className="custom_btna" 
                      variant="contained"
            style={{...submitButton, marginLeft: '42px' }} 
                      onClick={add}>
                        Add
                      </Button>
                    </Grid>
                  </Grid>
                  <div className="table-form">
                    {itemSet.length > 0 && (
                      <div className="table-data">
                        <table id="table">
                          <thead>
                            <tr>
                              <th>Terms</th>
                              <th>Description</th>
                              <th className="last_row"></th>
                            </tr>
                          </thead>
                          {itemSet &&
                            itemSet.map((data, index) => (
                              <tbody key={index}>
                                <tr>
                                  <td>{data.terms}</td>
                                  <td>{data.description}</td>
                                  <td onClick={() => deleteData(data)}>
                                    <i className="fas fa-trash-alt" />
                                  </td>
                                </tr>
                              </tbody>
                            ))}
                        </table>
                      </div>
                    )}
                  </div>
                </Container>
              </div>
            ) : null}
          </div>
        </div>
        <div className="specialNote">
          <button className="button_value" onClick={() => setNote(!note)}>
            Special Notes
          </button>
          {note ? (
            <div className="dropdown_terms">
              <Container className="inside_content">
                <Grid container>
                  <Grid item md={3} sm={12}>
                    <label>Special Note:</label>
                  </Grid>
                  <Grid item md={7} sm={12}>
                    <textarea
                      name="special"
                      value={inputDataNote.special}
                      onChange={onChangeHandlers}
                    />
                  </Grid>
                  <Grid item md={2} sm={12}>
                  <Button
            variant="contained"
            style={{...submitButton, marginLeft: '42px' }}  onClick={addSpecialNote}>
                      Add
                      </Button>
                  </Grid>
                </Grid>
                <div className="table-form">
                  {itemSetNote.length > 0 && (
                    <div className="table-data">
                      <table id="table">
                        <thead>
                          <tr>
                            <th>Special Note</th>
                            <th className="last_row"></th>
                          </tr>
                        </thead>
                        {itemSetNote &&
                          itemSetNote.map((data, index) => (
                            <tbody key={index}>
                              <tr>
                                <td>{data.special}</td>
                                <td onClick={() => deleteDataNote(data)}>
                                  <i className="fas fa-trash-alt" />
                                </td>
                              </tr>
                            </tbody>
                          ))}
                      </table>
                    </div>
                  )}
                </div>
              </Container>
            </div>
          ) : null}
        </div>
        <div className="button_modal_res">
          <Button
            variant="contained"
            style={submitButton}
            onClick={handleFormClick}
          >
            {isLoading ? "" : "Submit"}
            {/* Rolling preloader */}
            {isLoading && (
              <div className="rolling-preloader">
                <div className="rolling-preloader-circle"></div>
              </div>
            )}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default CreateQuatationModel;
