import { Card, Container, Grid } from "@mui/material";
import React from "react";
import { AiTwotoneStar } from "react-icons/ai";
import { BsWhatsapp } from "react-icons/bs";
import "./hero.css";
const RecentActivity = () => {
  const cardData = [
    {
      name: "National Telesystems",
      location: "Thane West, Thane",
      imageURL:
        "https://images.pexels.com/photos/18833176/pexels-photo-18833176/free-photo-of-a-payphone-with-a-circular-plastic-cover-attached-to-a-wall.jpeg?auto=compress&cs=tinysrgb&w=600",
      profileImageURL:
        "https://pps.whatsapp.net/v/t61.24694-24/304161904_208322558281008_1128846910581197012_n.jpg?ccb=11-4&oh=01_AdSsjSNltctKOYGTETMT_hq-KpvI9K0FA4a6K_1inElADg&oe=654DEA87&_nc_sid=000000&_nc_cat=101",
      reviewAuthor: "Anbarasan",
      reviewType: "Wrote a review",
      reviewText:
        "I recently had an excellent experience with this CCTV dealer. The sales team was very knowledgeable and helped me choose the right system for my business.",
    },
    {
      name: "Anshika Enterprises",
      location: "Dahisar East, mumbai",
      imageURL:
        "https://images.pexels.com/photos/1108101/pexels-photo-1108101.jpeg?auto=compress&cs=tinysrgb&w=600",
      reviewAuthor: "vijay",
      profileImageURL:
        "https://media-tir3-1.cdn.whatsapp.net/v/t61.24694-24/65321781_389864715060735_1007484329892249600_n.jpg?ccb=11-4&oh=01_AdTQ6QKuPWj8a_fjqz06D8KBr9H8mS3V5gCUBH0hdtsKJg&oe=6551D1E9&_nc_sid=e6ed6c&_nc_cat=109",
      reviewType: "Wrote a review",
      reviewText:
        "I recently had an excellent experience with this CCTV dealer. The sales team was very knowledgeable and helped me choose the right system for my business.",
    },
    {
      name: "How would you...",
      location: "Thane West, Thane",
      imageURL:
        "https://images.pexels.com/photos/2965258/pexels-photo-2965258.jpeg?auto=compress&cs=tinysrgb&w=600",
      profileImageURL:
        "https://bcciplayerimages.s3.ap-south-1.amazonaws.com/ipl/IPLHeadshot2023/2.png",
      reviewAuthor: "virat",
      reviewType: "Wrote a review",
      reviewText:
        "I recently had an excellent experience with this CCTV dealer. The sales team was very knowledgeable and helped me choose the right system for my business.",
    },
    {
      name: "Croma (Phoenix...",
      location: "Thane West, Thane",
      imageURL:
        "https://images.pexels.com/photos/16129705/pexels-photo-16129705/free-photo-of-man-sitting-at-desk-working-on-computers.jpeg?auto=compress&cs=tinysrgb&w=600",
      profileImageURL:
        "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=600",
      reviewAuthor: "Jackson",
      reviewType: "Wrote a review",
      reviewText:
        "I recently had an excellent experience with this CCTV dealer. The sales team was very knowledgeable and helped me choose the right system for my business.",
    },
    {
      name: "Croma",
      location: "Thane West, Thane",
      imageURL:
        "https://images.pexels.com/photos/3965543/pexels-photo-3965543.jpeg?auto=compress&cs=tinysrgb&w=600",
      profileImageURL:
        "https://images.pexels.com/photos/34534/people-peoples-homeless-male.jpg?auto=compress&cs=tinysrgb&w=600",
      reviewAuthor: "Samuel L",
      reviewType: "Wrote a review",
      reviewText:
        "I recently had an excellent experience with this CCTV dealer. The sales team was very knowledgeable and helped me choose the right system for my business.",
    },
    {
      name: "Croma (Galleria....",
      location: "Thane West, Thane",
      imageURL:
        "https://images.pexels.com/photos/2927523/pexels-photo-2927523.jpeg?auto=compress&cs=tinysrgb&w=600",
      profileImageURL:
        "https://images.pexels.com/photos/3763188/pexels-photo-3763188.jpeg?auto=compress&cs=tinysrgb&w=600",
      reviewAuthor: "Scarlett joshansso",
      reviewType: "Wrote a review",
      reviewText:
        "I recently had an excellent experience with this CCTV dealer. The sales team was very knowledgeable and helped me choose the right system for my business.",
    },
  ];

  return (
    <>
      <section className="Recent_section">
        <Container>
          <h5 className="Recent_main_heading">Recent Activity</h5>
        </Container>
        <Grid item lg={12} style={{ padding: "20px " }}>
          <Grid container spacing={6}>
            {cardData.map((data, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card className="Recent_card">
                  <div className="Recent_first_row">
                    <div className="Recent_name">
                      <p className="Recent_heading">{data.name}</p>
                      <span className="Recent_span">{data.location}</span>
                    </div>
                    <div className="Recent_first_rows">
                      <i className="Recent_whatapp">
                        <BsWhatsapp />
                      </i>
                      &nbsp;&nbsp;
                      <span className="Recent_span_chat">CHAT</span>
                    </div>
                  </div>
                  <div className="Recent_image">
                    <img
                      className="Recent_images_center"
                      src={data.imageURL}
                      alt={data.name}
                    />
                  </div>
                  <div className="Recent_info">
                    <div className="Recent_details">
                      <img
                        className="Recent_images-profile"
                        src={data.profileImageURL}
                      />
                      <div className="Rec_name">
                        <p className="Recent_heading">{data.reviewAuthor}</p>
                        <span className="Recent_span">Wrote a review</span>
                      </div>
                    </div>
                    <div className="Recent_star">
                      <ul className="Recent_ul">
                        <li className="Recent_li">
                          <AiTwotoneStar />
                        </li>
                        <li>
                          <AiTwotoneStar />
                        </li>
                        <li>
                          <AiTwotoneStar />
                        </li>
                        <li>
                          <AiTwotoneStar />
                        </li>
                        <li>
                          <AiTwotoneStar />
                        </li>
                      </ul>
                    </div>
                    <div className="Recet_paragraph">
                      <span className="Recent_spans">{data.reviewText}</span>
                    </div>
                  </div>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </section>
    </>
  );
};

export default RecentActivity;
