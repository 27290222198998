import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  TextareaAutosize,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Delivery from "../../.././assets/innerpage/design-delivery.jpg";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { CloudUploadOutlined, Delete } from "@mui/icons-material";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import DeliveryComponent from "./DeliveryComponent";
import { AddNewAddress } from "./AddNewAddress";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const schema = yup.object().shape({
  recepientame: yup.string().required("Name is required !"),
  mobileNumber: yup.string().required("mobileNumber is required !"),
  pincode: yup.string().required(" pincode is required !"),
  location: yup.string().required("location is required !"),
  city: yup.string().required("city is required !"),
  state: yup.string().required(" state is required !"),
  // alternativeNumber: yup.string().required(" alternativeNumber  is required !"),
  AdtnlDlvGdlns: yup.string(),
});
const DeliveryAddress = ({
  onChange,
  deliverydata,
  setAddressAdded,
  selectedImage,
  imageChoosen,
  triggerUpload,
}) => {
  const {
    control,
    register,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      landMark: "",
      recepientname: "",
      mobileNumber: "",
      pincode: "",
      roadAreaCol: "",
      HBNo: "",
      city: "",
      state: "",
      AdditionalDlvGdlns: "",
      // alternativeNumber: "",
    },
  });
  const userId = localStorage.getItem("userID");
  const StoredAddress = useSelector((state) => {
    const { addressDetails } = state.rfqAddress;
    return addressDetails;
  });
  //state to update address upon filling form
  const [address, SetAddress] = useState({
    landMark: StoredAddress?.landMark || "",
    recepientname: StoredAddress?.recepientname || "",
    mobileNumber: StoredAddress?.mobileNumber || "",
    pincode: StoredAddress?.pincode || "",
    HBNo: StoredAddress?.HBNo || "",
    roadAreaCol: StoredAddress?.roadAreaCol || "",
    city: StoredAddress?.city || "",
    state: StoredAddress?.state || "",
    AdditionalDlvGdlns: StoredAddress?.AdditionalDlvGdlns || "",
    // alternativeNumber: StoredAddress?.StoredAddress.alternativeNumber || "",
  });
  const [selectedAddresses, setSelectedAddresses] = useState([]);
  //to fetch back values from stored Address
  const [currentAddress, setCurrentAddress] = useState([]);
  const [expanded, setExpanded] = useState(false);
  //to handle image Uploads
  const [rfqImg, setRfqImg] = useState([]);
  const [imageDataUrl, setImageDataUrl] = useState(imageChoosen);
  const [uploaded, setUploaded] = useState(triggerUpload);
  //to toggle image upload process
  useEffect(() => {
    setImageDataUrl(imageChoosen);
  }, [imageDataUrl, uploaded]);
  //handle chnages in image display behaviour
  useEffect(() => {
    setUploaded(true);
  }, []);

  const handleImages = (e) => {
    const files = e.target.files;
    setRfqImg(files);
  };
  const handleFileSelect = (e) => {
    e.preventDefault();
    setUploaded(true);
    selectedImage(rfqImg);
  };
  const handleDeleteImage = async (value) => {
    console.log(value);
    const findInfo = imageDataUrl[value];
    const cloudName = "dp7bersiq";
  };

  const handleSelect = (address, isSelected) => {
    setSelectedAddresses((prevAddresses) => {
      if (isSelected) {
        // Add the selected address to the array
        return [address];
      } else {
        // Remove all occurrences of the unselected address from the array
        const updatedAddresses = prevAddresses.filter((selected) => {
          const isMatch = selected._id === address._id;
          console.log(
            `Comparing ${selected._id} with ${address._id}: ${isMatch}`
          );
          return !isMatch;
        });

        return updatedAddresses;
      }
    });
  };
  useEffect(() => {
    const fetchAddresses = async () => {
      const { status, data } = await axios.get(
        `http://localhost:80/api/auth/get/otherDeliveryAddress/${userId}`
      );
      if (status === 200 && data !== null) {
        const { address } = data?.addresses;
        setCurrentAddress(address);
      }
    };
    fetchAddresses();
  }, []);
  const handleNewAddrSubmit = (AddedNewAddress, Boolean, editedAddress) => {
    onChange(AddedNewAddress);
    setAddressAdded(Boolean);
    console.log(AddedNewAddress, "editedAddress dummy");
  };
  const handleNewAddressChange = () => {
    setExpanded(!expanded);
  };
  return (
    <Container maxWidth="lg">
      <Grid container columnSpacing={{ xs: 2, lg: 1 }}>
        <Grid item md={6} sm={12} lg={6} flexShrink={1}>
          <div style={{ width: "auto", maxWidth: "100%", maxHeight: "400px" }}>
            <Box
              sx={{
                width: "100%",
                maxWidth: "min-content",
                height: "auto",
                display: { xs: "none", sm: "block" },
              }}
            >
              <div className="rfq-image_Wrap">
                <img
                  src={Delivery}
                  alt="delivery address"
                  className="rfq-image"
                />
              </div>
            </Box>
          </div>
          <div className="address-img-upload">
            {/* <Grid item md={12} lg={12} xs={12}>
              <Typography className="summary-content-head">
                Upload Images
              </Typography>
              <br />
              <Box sx={{ width: "100%", maxWidth: "350px" }}>
                <Button
                  variant="contained"
                  component="label"
                  color="secondary"
                  fullWidth
                >
                  <CloudUploadOutlined />
                  &nbsp; Select Images
                  <input
                    type="file"
                    id="add_image"
                    onChange={handleImages}
                    hidden
                    multiple
                  />
                </Button>
                <br />
                <br />
                <Button
                  onClick={handleFileSelect}
                  color={"primary"}
                  variant="contained"
                >
                  Upload
                </Button>
              </Box>
              <br />
              <Container maxWidth="lg">
                {imageDataUrl &&
                  imageDataUrl.map((data, index) => (
                    <Box key={index} className="image-box">
                      <div
                      // className="image-container"
                      // style={{ margin: "0px" }}
                      >
                        {!uploaded ? (
                          <div className="loading-text">Loading</div>
                        ) : (
                          <LazyLoadImage
                            src={data}
                            alt="uploaded"
                            className="uploaded-image"
                          />
                        )}
                      </div>
                      <Typography className="image-name" variant="caption">
                        Image Name
                      </Typography>
                      <Button
                        endIcon={<Delete />}
                        className="delete-button"
                        onClick={() => handleDeleteImage(index, data)}
                      >
                        Delete
                      </Button>
                    </Box>
                  ))}
              </Container>
            </Grid> */}
          </div>
        </Grid>
        <Grid item md={6} lg={6} sm={12}>
          <h3 className="rfq-details-heading">Enter Delivery Location.</h3>
          <p className="rfq-details-content">You've made progress.</p>
          <Grid container>
            <Grid item md={12} sm={12}>
              <Typography variant="subtitle2" component={"h6"}>
                Select From Available Addresses.
              </Typography>
              <div
                className="selectable_address_wrap"
                style={{
                  minHeight:
                    currentAddress.length > 0 ? 300 : "-webkit-fill-available",
                  border: "2px solid lightgray",
                  boxShadow: " rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
                }}
                onClick={(event) => {
                  // Check if the click is on the li element or its children
                  const isListItem = event.target.closest(".address_ul > li");
                  if (isListItem) {
                    // Get the radio button within the clicked li element
                    const radioButton = isListItem.querySelector(
                      'input[type="radio"]'
                    );
                    if (radioButton) {
                      // Trigger a click event on the radio button
                      radioButton.click();
                    }
                  }
                }}
              >
                <div className="addresses_scrollable_area">
                  <ul className="address_ul">
                    {currentAddress.length > 0 ? (
                      currentAddress.map((address, index) => (
                        <DeliveryComponent
                          key={index}
                          address={address}
                          onSelect={handleSelect}
                          isSelected={selectedAddresses.includes(address)}
                          onsubmit={handleNewAddrSubmit}
                          setAddressAdded={() => setAddressAdded(true)}
                        />
                      ))
                    ) : (
                      <li>
                        <span>
                          <strong>No Saved Addresses Found.</strong>
                        </span>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <h3 className="rfq-details-heading">Selected Addresses</h3>
                {selectedAddresses.map((selectedAddress, index) => (
                  <div key={index}>
                    {selectedAddress.recepientname} - {selectedAddress.HBNo},{" "}
                    {selectedAddress.roadAreaCol}, {selectedAddress.landMark},{" "}
                    {selectedAddress.city}, {selectedAddress.state} -{" "}
                    {selectedAddress.pincode}
                  </div>
                ))}
              </div>
            </Grid>
          </Grid>
          <Grid item lg={12}>
            <Accordion
              expanded={expanded}
              onChange={handleNewAddressChange}
              disableGutters
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="Addresspanel-summary"
              >
                {" "}
                <Typography
                  component={"span"}
                  variant="h6"
                  sx={{
                    fontWeight: 500,
                    fontSize: 17,
                    textTransform: "uppercase",
                  }}
                >
                  Add new Delivery Adress
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <AddNewAddress
                  onSubmit={handleNewAddrSubmit}
                  setAddressAdded={() => setAddressAdded(true)}
                />
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default DeliveryAddress;
{
  /* <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d15551.856400168783!2d77.50460955000001!3d12.974148!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1706181647184!5m2!1sen!2sin"
            width="400px"
            height="300px"
            style={{ border: 0 }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe> */
}
