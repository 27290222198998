import Grid from "@mui/material/Grid";

import { BiSolidCloudUpload } from "react-icons/bi";

import { useEffect, useState } from "react";
import axios from "../../../../../api/axios";
import { RedAsterisk } from "../../../../Syledcomponent/Style";
import {
  Box,
  TextField,
  Autocomplete,
  Button,
  Container,
  IconButton,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import "./data/profile.scss";
import { submitButton } from "../../../../DesignTools/ButtonStyles/ButtonStyles";
import {
  GET_ALL_CATSERVICE,
  VENDOR_GETINFO,
  VENDOR_UPDATE_PROFILE,
} from "../../../../CompServices/Api_Routes_Helper";
import {
  InputField,
  StarIcon,
} from "../../../../Syledcomponent/StyleFiled.jsx";
import authHeader from "../../../../../Services/auth-header";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_AUTH_URL } from "../../../../CompServices/Helper";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
// import  StarIcon from "../../../../Syledcomponent/StyleFiled";
function ProfileField({ setPostReq }) {
  const rfqSchema = Yup.object().shape({
    Description: Yup.string().required("Description is Required"),
    FullName: Yup.string().required("Full Name is Required"),
    PhoneNumber: Yup.string().required("Phone number is Required"),
    lName: Yup.string().required("Last Name is required"),
    pNum: Yup.string().required("Phone number is required"),
    loc: Yup.string().required("Location is required"),
    des: Yup.string().required("Description is required"),
    address: Yup.string().required("Address is required"),
    // password: Yup.string()
    //   .required("Password is required")
    //   .min(8, "Password must be at least 8 characters")
    //   .matches(
    //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+/,
    //     "Password must contain at least one lowercase letter, one uppercase letter, and one number"
    //   )
    //   ,
    // confirmPassword: Yup.string()
    //   .required("Confirm Password is required")
    //   .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });
  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleTogglePasswordVisibilitys = () => {
    setShowConPassword(!showConPassword);
  };
  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(rfqSchema),
    defaultValues: {
      Description: "",
      FullName: "",
      PhoneNumber: "",
      lName: "",
      pNum: "",
      loc: "",
      des: "",
      address: "",
      cName: "",
      password: "",
      confirmPassword: "",
    },
  });

  const [details, setDetails] = useState("");
  const [values, setValues] = useState([]);
  const v_id = localStorage.getItem("v_ID");
  const { vid, role } = JSON.parse(v_id);
  const [selectedImage, setSelectedImage] = useState(null);
  const [image, setImage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConPassword, setShowConPassword] = useState(false);
  const initialSlide = {
    des: "",
    fName: "",
    lName: "",
    cName: "",
    sCategory: [],
    sRole: "",
    pNum: "",
    loc: "",
    address: "",
    password: "",
    confirmPassword: "",
  };
  const [formData, setFormData] = useState(initialSlide);

  const {
    des,
    fName,
    lName,
    cName,
    sCategory,
    sRole,
    pNum,
    loc,
    address,
    password,
    confirmPassword,
  } = formData;

  const data = [
    { value: "", name: "Select Option" },
    { value: "India", name: "India" },
    { value: "USA", name: "USA" },
    { value: "China", name: "China" },
    { value: "Indonesia", name: "Indonesia" },
    { value: "Brazil", name: "Brazil" },
    { value: "Russia", name: "Russia" },
    { value: "Japan", name: "Japan" },
    { value: "Germany", name: "Germany" },
    { value: "Thailand", name: "Thailand" },
    { value: "United Kingdom", name: "United Kingdom" },
    { value: "Others", name: "Others" },
  ];

  const email = details.email;

  useEffect(() => {
    const servicesCategories = async () => {
      try {
        const response = await axios.get(GET_ALL_CATSERVICE, {
          headers: await authHeader(),
        });
        if (response !== null) {
          const { data } = response.data;
          setValues(data);
        } else {
          throw new Error("Services not found.");
        }
      } catch (error) {
        console.error(error);
      }
    };
    servicesCategories();
  }, []);

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
  };
  useEffect(() => {
    if (vid) {
      getVendorDetails(vid);
    }
  }, [vid]);
  const getVendorDetails = async (vendorId) => {
    if (!vendorId) {
      return;
    }
    try {
      const response = await axios.get(`${VENDOR_GETINFO}${vendorId}`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response) {
        const vendorholdings = response.data.data;
        setDetails(vendorholdings);
        setImage(vendorholdings.image);
        setFormData((prevData) => ({
          ...prevData,
          fName: vendorholdings.username,
          lName: vendorholdings.lastname,
          cName: vendorholdings.companyname,
          pNum: vendorholdings.phone,
          des: vendorholdings.description,
          sRole: vendorholdings.role,
          loc: vendorholdings.location,
          sCategory: vendorholdings.category,
          email: vendorholdings.email,
          address: vendorholdings.address,
          password: vendorholdings.password,
          confirmPassword: vendorholdings.confirmPassword,
        }));
        console.log("response", response.data.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const handleFormChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));

    const requiredFields = [
      "Description",
      "FullName",
      "PhoneNumber",
      "lName",
      "pNum",
      "loc",
      "des",
      "address",
    ];
    const isAnyRequiredFieldEmpty = requiredFields.some(
      (fieldName) => !value || value.trim() === ""
    );

    // Update the button state based on whether any required field is empty
    setIsButtonDisabled(isAnyRequiredFieldEmpty);
  };

  const commonToastOptions = {
    position: toast.POSITION.TOP_CENTER,
    autoClose: 2000,
    className: "custom-toast",
  };

  const handleFormSubmit = async () => {
    console.log(formData);

    if (!password || !confirmPassword) {
      toast.error("Password fields cannot be empty", commonToastOptions);
      return;
    }
    if (password !== confirmPassword) {
      toast.error(
        "Password and Confirm Password do not match",
        commonToastOptions
      );
      return;
    }
    // else if (!password || !confirmPassword) {
    //   toast.error("Password fields cannot be empty", commonToastOptions);
    //   return;
    // } else if (password !== confirmPassword) {
    //   toast.error(
    //     "Password and Confirm Password do not match",
    //     commonToastOptions
    //   );
    //   return;
    // }

    const updateValues = {
      username: fName,
      lastname: lName,
      description: des,
      companyname: cName,
      category: sCategory,
      phone: pNum,
      role: sRole,
      location: loc,
      address: address,
      password: password,
      confirmPassword: confirmPassword,
      image: "",
    };
    const data = new FormData();
    data.append("file", selectedImage);
    data.append("upload_preset", "MyQuotes");

    try {
      try {
        const cloudinaryResponse = await fetch(
          "https://api.cloudinary.com/v1_1/dynbagilk/image/upload",
          {
            method: "POST",
            body: data,
          }
        );
        const cloudinaryData = await cloudinaryResponse.json();

        updateValues.image = cloudinaryData.secure_url;
        setImage(cloudinaryData.secure_url);
      } catch (error) {
        console.error("cloudinary error ", error);
      }

      const axiosResponse = await axios.put(
        `${VENDOR_UPDATE_PROFILE}${vid}`,
        updateValues
      );
      console.log(axiosResponse);

      toast.success(axiosResponse.data.message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
        className: "custom-toast",
      });
      setPostReq();
      getVendorDetails(vid);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCategoryChange = (event, newValue) => {
    setFormData((prevData) => ({
      ...prevData,
      sCategory: newValue.map((item) => item.categoryName),
    }));
  };

  return (
    <>
      <Container>
        <div className="profile_update_card">
          <Box className="Profile_update">
            <Grid container sx={{ mt: 3 }} columnGap={10} rowGap={2}>
              <Grid item md={10} lg={5} sm={12}>
                <Grid container columnSpacing={1}>
                  <Grid item md={4} lg={4} sm={12} className="label_div">
                    <label>User Name</label>
                  </Grid>
                  <Grid item md={7} lg={8} sm={12}>
                    <InputField
                      fullWidth
                      // {...register("FullName", { required: true })}
                      value={fName}
                      onChange={(e) =>
                        handleFormChange("fName", e.target.value)
                      }
                    />
                    <br />
                    {/* {errors?.FullName && (
                      <span className="error_msg">
                        {errors.FullName.message}
                      </span>
                    )} */}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={10} lg={5} sm={12}>
                <Grid container columnSpacing={1}>
                  <Grid item md={4} lg={4} sm={12} className="label_div">
                    <label>Email</label>
                  </Grid>
                  <Grid item md={7} lg={8} sm={12}>
                    <InputField
                      fullWidth
                      placeholder="ex@email.com"
                      value={email}
                    />
                    <br />
                    {/* {errors?.lName && (
                            <span className="error_msg">
                              {errors.lName.message}
                            </span>
                          )} */}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container sx={{ mt: 3 }} columnGap={10} rowGap={2}>
              <Grid item md={10} lg={5} sm={12}>
                <Grid container columnSpacing={1}>
                  <Grid item md={4} lg={4} sm={12} className="label_div">
                    <label>Company</label>
                  </Grid>
                  <Grid item md={7} lg={8} sm={12}>
                    <InputField
                      fullWidth
                      placeholder="Company Name"
                      value={cName}
                      onChange={(e) =>
                        handleFormChange("cName", e.target.value)
                      }
                    />
                    <br />
                    {/* {errors?.PhoneNumber && (
                      <span className="error_msg">
                        {errors.PhoneNumber.message}
                      </span>
                    )} */}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={10} lg={5} sm={12}>
                <Grid container columnSpacing={1}>
                  <Grid item md={4} lg={4} sm={12} className="label_div">
                    <label>Phone No</label>
                  </Grid>
                  <Grid item md={7} lg={8} sm={12}>
                    <InputField
                      label="Phone"
                      placeholder="phone Number"
                      fullWidth
                      // {...register("pNum", { required: true })}
                      value={pNum}
                      onChange={(e) => handleFormChange("pNum", e.target.value)}
                    />
                    {/* <br />
                          {errors?.pNum && (
                            <span className="error_msg">
                              {errors.pNum.message}
                            </span>
                          )} */}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container sx={{ mt: 3 }} columnGap={10} rowGap={2}>
              <Grid item md={10} lg={5} sm={12}>
                <Grid container className="select-cat" columnSpacing={1}>
                  <Grid item md={4} lg={4} sm={12} className="label_div">
                    <label>Service</label>
                  </Grid>
                  <Grid item md={7} lg={8} sm={12}>
                    <div>
                      <Autocomplete
                        multiple
                        id="checkboxes-tags-demo"
                        options={values}
                        value={values.filter((item) =>
                          formData.sCategory.includes(item.categoryName)
                        )}
                        onChange={handleCategoryChange}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.categoryName}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>{option.categoryName}</li>
                        )}
                        className="autocomplete_div"
                        style={{
                          width: "100%",
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            // className="scrollable-content"
                          />
                        )}
                      />
                    </div>
                    {/* <Autocomplete
                            style={{ width: "100%" }}
                            options={values}
                            getOptionLabel={(option) =>
                              option.categoryName || ""
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                // label="Service Category"
                                placeholder="Service Category"
                                className="categoryInput"
                                {...register("category")}
                                sx={{ height: "20px" }}
                              />
                            )}
                            value={sCategory}
                            onChange={(event, newValue) => {
                              setSelectedId(newValue._id);
                              setValue(newValue);
                              console.log(newValue?.categoryName);
                            }}
                            id="service_category"
                            isOptionEqualToValue={(option, value) =>
                              option?._id === value?._id
                            }
                            renderOption={(props, option, state) => (
                              <li {...props}>
                                {option?.categoryName || "No category selected"}
                              </li>
                            )}
                            sx={{
                              width: "100% !important",
                              maxWidth: "382px",
                              // marginBlockEnd: "20px",
                            }}
                          /> 

                          {/* {errors?.category && (
                            <span className="input-validation-error">
                              {errors.category.message}
                            </span>
                          )} */}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={10} lg={5} sm={12}>
                <Grid container columnSpacing={1}>
                  <Grid item md={4} lg={4} sm={12} className="label_div">
                    <label>Role</label>
                  </Grid>
                  <Grid item md={7} lg={8} sm={12}>
                    <div className="pm-user-role-selectors">
                      <input
                        type="radio"
                        name="role"
                        id="user_role1"
                        value="supplier"
                        onChange={(e) =>
                          handleFormChange("sRole", e.target.value)
                        }
                        checked={sRole === "supplier"}
                      />
                      <label htmlFor="user_role1" className="pm-form-label">
                        Supplier
                      </label>

                      <input
                        type="radio"
                        name="role"
                        id="user_role2"
                        value="buyer"
                        onChange={(e) =>
                          handleFormChange("sRole", e.target.value)
                        }
                        checked={sRole === "buyer"}
                      />
                      <label htmlFor="user_role2" className="pm-form-label">
                        Buyer
                      </label>

                      <input
                        type="radio"
                        name="role"
                        id="user_role3"
                        value="both"
                        onChange={(e) =>
                          handleFormChange("sRole", e.target.value)
                        }
                        checked={sRole === "both"}
                      />
                      <label htmlFor="user_role3" className="pm-form-label">
                        Both
                      </label>
                    </div>
                    {/* {errors?.role && (
                            <span className="input-validation-error">
                              {errors.role.message}
                            </span>
                          )} */}
                    {/* <span>Selected Role: {sRole}</span> */}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container sx={{ mt: 3 }} columnGap={10} rowGap={2}>
              <Grid item md={10} lg={5} sm={12}>
                <Grid container columnSpacing={1}>
                  <Grid item md={4} lg={4} sm={12} className="label_div">
                    <label>Descriptions</label>
                  </Grid>
                  <Grid item md={7} lg={8} sm={12}>
                    <InputField
                      className="scrollable-content"
                      type="text"
                      placeholder="Describe about your work"
                      name="Description"
                      defaultValue={des}
                      onChange={(e) => handleFormChange("des", e.target.value)}
                      // {...register("des", { required: true })}
                    />
                    <br />
                    {/* {errors?.des && (
                            <span className="error_msg">
                              {errors.des.message}
                            </span>
                          )} */}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={10} lg={5} sm={12}>
                <Grid container columnSpacing={1}>
                  <Grid item md={4} lg={4} sm={12} className="label_div">
                    <label>Location</label>
                  </Grid>
                  <Grid item md={7} lg={8} sm={12}>
                    <select
                      fullWidth
                      className="Profile_dropdown"
                      value={loc}
                      onChange={(e) => handleFormChange("loc", e.target.value)}
                      // {...register("loc", { required: true })}
                    >
                      {data.map((option, key) => (
                        <option key={key} value={option.value}>
                          {option.name}
                        </option>
                      ))}
                    </select>
                    {/* <br />
                          {errors?.loc && (
                            <span className="error_msg">
                              {errors.loc.message}
                            </span>
                          )} */}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container sx={{ mt: 3 }} columnGap={10} rowGap={2}>
              <Grid item md={10} lg={5} sm={12}>
                <Grid container columnSpacing={1}>
                  <Grid item md={4} lg={4} sm={12} className="label_div">
                    <label>
                      Password
                      {/* <RedAsterisk>*</RedAsterisk> */}
                    </label>
                  </Grid>
                  <Grid item md={7} lg={8} sm={12}>
                    {/* <InputField
                      fullWidth
                      // {...register("FullName", { required: true })}
                      placeholder="Password"
                      name="password"
                      type="text"
                      defaultValue={password}
                      onChange={(e) =>
                        handleFormChange("password", e.target.value)
                      }
                      
                    /> */}
                    <TextField
                      fullWidth
                      placeholder="Password"
                      name="password"
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={(e) => {
                        handleFormChange("password", e.target.value);
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleTogglePasswordVisibility}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <br />
                    {errors?.FullName && (
                      <span className="error_msg">
                        {errors.FullName.message}
                      </span>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={10} lg={5} sm={12}>
                <Grid container columnSpacing={1}>
                  <Grid item md={4} lg={4} sm={12} className="label_div">
                    <label>
                      Confirm Password
                      {/* <RedAsterisk>*</RedAsterisk> */}
                    </label>
                  </Grid>
                  <Grid item md={7} lg={8} sm={12}>
                    <TextField
                      fullWidth
                      type={showConPassword ? "text" : "password"}
                      placeholder="Confirm password"
                      value={confirmPassword}
                      onChange={(e) =>
                        handleFormChange("confirmPassword", e.target.value)
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleTogglePasswordVisibilitys}
                              edge="end"
                            >
                              {showConPassword ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container sx={{ mt: 3 }} columnGap={10} rowGap={2}>
              <Grid item md={10} lg={5} sm={12}>
                <Grid container columnSpacing={1}>
                  <Grid item md={4} lg={4} sm={12} className="label_div">
                    <label>Address</label>
                  </Grid>
                  <Grid item md={7} lg={8} sm={12}>
                    <InputField
                      placeholder="Address"
                      className="scrollable-content"
                      type="text"
                      defaultValue={address}
                      onChange={(e) =>
                        handleFormChange("address", e.target.value)
                      }
                    />
                    <br />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={10} lg={5} sm={12}>
                {/* <Grid container columnSpacing={1}>
                  <Grid item md={4} lg={4} sm={12} className="label_div">
                    <label>Image</label>
                  </Grid>
                  <Grid item md={7} lg={8} sm={12}>
                    <label htmlFor="fileInput" className="custom-file-label">
                      <BiSolidCloudUpload /> Upload Image
                    </label>
                    <input
                      id="fileInput"
                      className="image_input"
                      type="file"
                      onChange={handleFileSelect}
                    />
                    <br />
                  </Grid>
                </Grid> */}
              </Grid>
            </Grid>

            <div className="button">
              <Button
                style={submitButton}
                onClick={handleFormSubmit}
                // disabled={isButtonDisabled}
              >
                Submit
              </Button>
            </div>
          </Box>
        </div>
      </Container>
    </>
  );
}

export default ProfileField;
