import { createSlice } from "@reduxjs/toolkit";
const initialAddress = {};
const addressDetails = createSlice({
  initialState: initialAddress,
  name: "rfqclientAddress",
  reducers: {
    addAddress: (state, action) => {
      const { deliveryAddress } = action.payload;
      state.addressDetails = deliveryAddress;
    },
    modifyAddress: (state, action) => {},
    deleteAddress: (state, action) => {
      return initialAddress;
    },
  },
});
const { actions } = addressDetails;
export const { addAddress, deleteAddress, modifyAddress } = actions;
export default addressDetails.reducer;
