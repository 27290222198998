import React, { useState, useEffect } from "react";
import { Container, Grid, Typography, Card } from "@mui/material";
import {
  GET_RECENT_RFQS,
  TOP_FIVE_CATEGORY,
  PARTICULAR_DATA,
} from "../CompServices/Api_Routes_Helper";
import axios from "../../api/axios";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import avatar from "../../assets/innerpage/firebase-user.jpg";
const RecentRFQCards = () => {
  const [selectedItem, setSelectedItem] = useState(0);
  const [topFiveCategories, setTopFiveCategories] = useState([]);
  const [SubDes, setSubDes] = useState([]);
  const [getRfq, setRfqdata] = useState([]);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const dummyEmail = "client@peerme.tech";

  const fetchRequests = async () => {
    try {
      const {
        data: { data },
      } = await axios.get(`${GET_RECENT_RFQS}`);
      let reversedData = data;
      for (let i = reversedData.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [reversedData[i], reversedData[j]] = [reversedData[j], reversedData[i]];
      }
      setRfqdata(reversedData.slice(0, 4));
    } catch (error) {
      const message = error.response || error.response.data.message;
      console.error("Error fetching RFQs:", message);
    }
  };
  useEffect(() => {
    fetchRequests();
  }, []);

  useEffect(() => {
    const fetchTopFiveCategories = async () => {
      try {
        const response = await axios.get(TOP_FIVE_CATEGORY);
        const data = response.data;

        if (response.status === 200) {
          setTopFiveCategories(data);
          if (data.topCategories.length > 0) {
            // console.log('Auto-fetching data for the first category:', data.topCategories[0].categoryName);
            handleCategoryClick(0, data.topCategories[0].categoryName);
          }
        } else {
          console.error("Error fetching top five categories:", data.error);
        }
      } catch (error) {
        console.error("Error fetching top five categories:", error);
      }
    };

    fetchTopFiveCategories();
  }, []);

  const handleCategoryClick = async (index, categoryName) => {
    setSelectedItem(index);

    try {
      const queryParams = "?category=" + encodeURIComponent(categoryName);
      // Make a request to your backend API with the selected category name
      const response = await axios.get(`${PARTICULAR_DATA}${queryParams}`);
      const requestData = response.data;

      // Log or use the data received from the backend as needed
      // console.log('Latest requests for', categoryName, ':', requestData);
      setSubDes(requestData);
    } catch (error) {
      console.error("Error fetching latest requests:", error);
    }
  };
  // function handleMask(params) {
  //   const data = params.split("@");
  //   const masked = data[0] + "@" + "*".repeat(data[1].length);
  //   return masked;
  // }
  function randomNumber(min, max) {
    // const arr = [1, 2, 3, 5, 10];
    const random = Math.floor(Math.random() * (max - min + 1) + min);
    return random;
  }
  //testing
  function handleCompleteionStatus() {
    const StatusTypes = ["completed", "in-progress", "pending"];
    const randomValues = Math.floor(Math.random() * StatusTypes.length);
    const randomStatus = StatusTypes[randomValues];
    switch (randomStatus) {
      case "completed":
        return <span className="buttonn rfq_completed">Completed</span>;
      case "in-progress":
        return <span className="buttonn rfq_inprogress">In-progress</span>;
      case "pending":
        return <span className="buttonn rfq_pending">Pending</span>;
    }
  }
  const Colon = () => {
    return <span>:</span>;
  };
  const handleMasks = (email) => {
    if (!email) {
      return "";
    }
    const [username, domain] = email.split("@");
    const maskedDomain = domain ? "*".repeat(domain.length) : "";
    return `${username}@${maskedDomain}`;
  };
  return (
    <>
      <Container style={{ paddingBottom: "1rem", marginTop: "-1.5rem" }}>
        <Grid container spacing={2} mt={3} justifyContent="center">
          <Grid item>
            <h4 style={{ fontFamily: "poppins", fontSize: "36px" }}>
              Recent RFQ's
            </h4>
          </Grid>
        </Grid>
        {/* <Grid container spacing={2} mt={2} justifyContent="center">
  <Grid item lg={8}>
    <p style={{textAlign:'center',fontFamily:'poppins',color:"#878181",fontSize:'15px'}} >Lorem ipsum dolor sit amet consectetur, quasi esse voluptatum quaerat excepturi eaque sed ex magnam pariatur culpa. Voluptatum, rem harum.</p>
  </Grid> </Grid> */}
        {isSmallScreen ? (
          <Grid
            item
            container
            flexDirection="column"
            spacing={2}
            sx={{ width: "100%", height: "100px", overflowX: "auto" }}
            mt={2}
          >
            {topFiveCategories && Array.isArray(topFiveCategories.topCategories)
              ? topFiveCategories.topCategories.map((category, index) => (
                  <Grid
                    item
                    key={index}
                    onClick={() =>
                      handleCategoryClick(index, category.categoryName)
                    }
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        width: "100%",
                        minWidth: "100px",
                        whiteSpace: "nowrap",
                        fontWeight: "400",
                        position: "relative",
                        cursor: "pointer",
                        color: selectedItem === index ? "orange" : "#757575",
                        border: "1px solid #eeeeee",
                        paddingY: "10px",
                        paddingX: "10px",
                        alignItems: "center",
                        marginRight: "10px",
                        "&:hover": {
                          backgroundColor: "#f7f7f7",
                        },
                      }}
                    >
                      {category.categoryName}
                    </Typography>
                  </Grid>
                ))
              : null}
          </Grid>
        ) : (
          // Show first grid above 500px
          <Grid container spacing={2} justifyContent="center" mt={2}>
            {topFiveCategories && Array.isArray(topFiveCategories.topCategories)
              ? topFiveCategories.topCategories.map((category, index) => (
                  <Grid
                    item
                    key={index}
                    onClick={() =>
                      handleCategoryClick(index, category.categoryName)
                    }
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontFamily: "poppins",
                        fontSize: "16px",
                        width: "auto",
                        fontWeight: "600",
                        position: "relative",
                        cursor: "pointer",
                        color: selectedItem === index ? "orange" : "black",
                        borderBottom: `2px solid ${
                          selectedItem === index ? "orange" : "transparent"
                        }`,
                        paddingBottom: "15px",
                        paddingX: "10px",
                        marginLeft: "25px",
                      }}
                    >
                      {category.categoryName}
                    </Typography>
                  </Grid>
                ))
              : null}
          </Grid>
        )}

        <Grid container spacing={2} justifyContent="center">
          <div
            style={{
              width: "100%",
              height: "2px",
              backgroundColor: "lightgrey",
              marginTop: "14px",
            }}
          />
        </Grid>
        <Grid
          container
          spacing={2}
          mt={2}
          columnSpacing={7}
          rowSpacing={4}
          justifyContent="center"
        >
          {Array.isArray(SubDes) && SubDes.length > 0 ? (
            SubDes.map((rfq, index) => (
              <Grid item key={index}>
                <div className="sqare-div-main">
                  <div className="square-hand"></div>
                  <div>
                    <Card className="byclick-card" >
                      <div className="byclick-div">
                        <Grid container spacing={1} alignItems="center">
                          <Grid item lg={3} md={3} className="byclick-heading">
                            <img
                              src={avatar}
                              alt="avatar"
                              className="user-fetch-avatar"
                            />
                          </Grid>
                          <Grid item lg={9} md={9} className="overflow-ellipse">
                            {handleMasks(rfq.email) || handleMasks(dummyEmail)}
                          </Grid>
                        </Grid>
                        <Grid container mt={1}>
                          <Grid
                            item
                            lg={12}
                            md={12}
                            className="byclick-heading"
                          >
                            Subject
                            <Colon />
                          </Grid>
                          <Grid
                            item
                            lg={12}
                            md={12}
                            className="overflow-ellipse"
                            data-tooltip={rfq.subject}
                          >
                            {rfq.subject}
                          </Grid>
                        </Grid>
                        <Grid container mt={1}>
                          <Grid
                            item
                            lg={12}
                            md={12}
                            className="byclick-heading"
                          >
                            Description
                            <Colon />
                          </Grid>
                          <Grid
                            item
                            lg={12}
                            md={12}
                            className="overflow-ellipse"
                          >
                            {rfq.description}
                          </Grid>
                        </Grid>
                        <Grid container mt={1}>
                          <Grid
                            item
                            lg={12}
                            md={12}
                            className="byclick-heading"
                          >
                            Posted on
                            <Colon />
                          </Grid>
                          <Grid
                            item
                            lg={12}
                            md={12}
                            className="overflow-ellipse"
                          >
                            {new Date().toLocaleDateString()}
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          spacing={1}
                          mt={1}
                          justifyContent="space-between"
                        >
                          <Grid item lg={3} md={3}>
                            {" "}
                            <Typography
                              component={"p"}
                              variant="body2"
                              sx={{
                                color: "black",
                                fontWeight: "bold",
                                textAlign: "center",
                                fontFamily: "poppins",
                              }}
                            >
                              Quotations {randomNumber(1, 10)}
                            </Typography>
                          </Grid>
                          <Grid item lg={3} md={3} className="overflow-ellipse">
                            {handleCompleteionStatus()}
                          </Grid>
                        </Grid>
                      </div>
                    </Card>
                  </div>
                </div>
              </Grid>
            ))
          ) : (
            <Grid container spacing={2} mt={2} justifyContent="center">
              {getRfq.map((rfq, index) => (
                <Grid item key={index}>
                  <div className="sqare-div-main">
                    <div className="square-hand"></div>
                    <div>
                      <Card className="byclick-card">
                        <div className="byclick-div">
                          <Grid container>
                            <Grid
                              item
                              lg={12}
                              md={12}
                              className="byclick-heading"
                            >
                              Email
                            </Grid>
                            <Grid
                              item
                              lg={12}
                              md={12}
                              className="overflow-ellipse"
                            >
                              {handleMasks(rfq.user)}
                            </Grid>
                          </Grid>
                          <Grid container mt={1}>
                            <Grid
                              item
                              lg={12}
                              md={12}
                              className="byclick-heading"
                            >
                              Subject
                              <Colon />
                            </Grid>
                            <Grid
                              item
                              lg={12}
                              md={12}
                              className="overflow-ellipse"
                            >
                              {rfq.subject}
                            </Grid>
                          </Grid>
                          <Grid container mt={1}>
                            <Grid
                              item
                              lg={12}
                              md={12}
                              className="byclick-heading"
                            >
                              Description
                              <Colon />
                            </Grid>
                            <Grid
                              item
                              lg={12}
                              md={12}
                              className="overflow-ellipse"
                            >
                              {rfq.description}
                            </Grid>
                          </Grid>
                          <Grid container mt={1}>
                            <Grid
                              item
                              lg={12}
                              md={12}
                              className="byclick-heading"
                            >
                              Posted on
                              <Colon />
                            </Grid>
                            <Grid
                              item
                              lg={12}
                              md={12}
                              className="overflow-ellipse"
                            >
                              {new Date().toLocaleDateString()}
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            spacing={1}
                            mt={1}
                            justifyContent="space-between"
                          >
                            <Grid item lg={3} md={3}>
                              {" "}
                              <Typography
                                component={"p"}
                                variant="body2"
                                sx={{
                                  color: "black",
                                  fontWeight: "bold",
                                  textAlign: "center",
                                  fontFamily: "poppins",
                                }}
                              >
                                Quotations {randomNumber(1, 10)}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              lg={3}
                              md={3}
                              className="overflow-ellipse"
                            >
                              {handleCompleteionStatus()}
                            </Grid>
                          </Grid>
                        </div>
                      </Card>
                    </div>
                  </div>
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
      </Container>
    </>
  );
};

export default RecentRFQCards;
