import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { debounce } from "@mui/material/utils";
import { Button, Container, Select } from "@mui/material";
import { PlacesData } from "../End-User/User-Data/PlacesData";
import axios from "../../api/axios";
import { API_AUTH_URL } from "../CompServices/Api_Routes_Helper";
import Grid from "@mui/material/Grid";
import "./styles.css";

import MyAutoComplete from "./MyAutoComplete";
// interface PlacesData {
//   id: number;
//   name: string;
// }
const top100Films = [
  { label: "The Shawshank Redemption", year: 1994 },
  { label: "The Godfather", year: 1972 },
  { label: "The Godfather: Part II", year: 1974 },
  { label: "The Dark Knight", year: 2008 },
  { label: "12 Angry Men", year: 1957 },
  { label: "Schindler's List", year: 1993 },
  { label: "Pulp Fiction", year: 1994 },
  {
    label: "The Lord of the Rings: The Return of the King",
    year: 2003,
  },
  { label: "The Good, the Bad and the Ugly", year: 1966 },
  { label: "Fight Club", year: 1999 },
  {
    label: "The Lord of the Rings: The Fellowship of the Ring",
    year: 2001,
  },
  {
    label: "Star Wars: Episode V - The Empire Strikes Back",
    year: 1980,
  },
  { label: "Forrest Gump", year: 1994 },
  { label: "Inception", year: 2010 },
  {
    label: "The Lord of the Rings: The Two Towers",
    year: 2002,
  },
  { label: "One Flew Over the Cuckoo's Nest", year: 1975 },
  { label: "Goodfellas", year: 1990 },
  { label: "The Matrix", year: 1999 },
  { label: "Seven Samurai", year: 1954 },
  {
    label: "Star Wars: Episode IV - A New Hope",
    year: 1977,
  },
  { label: "City of God", year: 2002 },
  { label: "Se7en", year: 1995 },
  { label: "The Silence of the Lambs", year: 1991 },
  { label: "It's a Wonderful Life", year: 1946 },
  { label: "Life Is Beautiful", year: 1997 },
  { label: "The Usual Suspects", year: 1995 },
  { label: "Léon: The Professional", year: 1994 },
  { label: "Spirited Away", year: 2001 },
  { label: "Saving Private Ryan", year: 1998 },
  { label: "Once Upon a Time in the West", year: 1968 },
  { label: "American History X", year: 1998 },
  { label: "Interstellar", year: 2014 },
  { label: "Casablanca", year: 1942 },
  { label: "City Lights", year: 1931 },
  { label: "Psycho", year: 1960 },
  { label: "The Green Mile", year: 1999 },
  { label: "The Intouchables", year: 2011 },
  { label: "Modern Times", year: 1936 },
  { label: "Raiders of the Lost Ark", year: 1981 },
  { label: "Rear Window", year: 1954 },
  { label: "The Pianist", year: 2002 },
  { label: "The Departed", year: 2006 },
  { label: "Terminator 2: Judgment Day", year: 1991 },
  { label: "Back to the Future", year: 1985 },
  { label: "Whiplash", year: 2014 },
  { label: "Gladiator", year: 2000 },
  { label: "Memento", year: 2000 },
  { label: "The Prestige", year: 2006 },
  { label: "The Lion King", year: 1994 },
  { label: "Apocalypse Now", year: 1979 },
  { label: "Alien", year: 1979 },
  { label: "Sunset Boulevard", year: 1950 },
  { label: "The Great Dictator", year: 1940 },
  { label: "Cinema Paradiso", year: 1988 },
  { label: "The Lives of Others", year: 2006 },
  { label: "Grave of the Fireflies", year: 1988 },
  { label: "Paths of Glory", year: 1957 },
  { label: "Django Unchained", year: 2012 },
  { label: "The Shining", year: 1980 },
  { label: "WALL·E", year: 2008 },
  { label: "American Beauty", year: 1999 },
  { label: "The Dark Knight Rises", year: 2012 },
  { label: "Princess Mononoke", year: 1997 },
  { label: "Aliens", year: 1986 },
  { label: "Oldboy", year: 2003 },
  { label: "Once Upon a Time in America", year: 1984 },
  { label: "Witness for the Prosecution", year: 1957 },
  { label: "Das Boot", year: 1981 },
  { label: "Citizen Kane", year: 1941 },
  { label: "North by Northwest", year: 1959 },
  { label: "Vertigo", year: 1958 },
  {
    label: "Star Wars: Episode VI - Return of the Jedi",
    year: 1983,
  },
  { label: "Reservoir Dogs", year: 1992 },
  { label: "Braveheart", year: 1995 },
  { label: "M", year: 1931 },
  { label: "Requiem for a Dream", year: 2000 },
  { label: "Amélie", year: 2001 },
  { label: "A Clockwork Orange", year: 1971 },
  { label: "Like Stars on Earth", year: 2007 },
  { label: "Taxi Driver", year: 1976 },
  { label: "Lawrence of Arabia", year: 1962 },
  { label: "Double Indemnity", year: 1944 },
  {
    label: "Eternal Sunshine of the Spotless Mind",
    year: 2004,
  },
  { label: "Amadeus", year: 1984 },
  { label: "To Kill a Mockingbird", year: 1962 },
  { label: "Toy Story 3", year: 2010 },
  { label: "Logan", year: 2017 },
  { label: "Full Metal Jacket", year: 1987 },
  { label: "Dangal", year: 2016 },
  { label: "The Sting", year: 1973 },
  { label: "2001: A Space Odyssey", year: 1968 },
  { label: "Singin' in the Rain", year: 1952 },
  { label: "Toy Story", year: 1995 },
  { label: "Bicycle Thieves", year: 1948 },
  { label: "The Kid", year: 1921 },
  { label: "Inglourious Basterds", year: 2009 },
  { label: "Snatch", year: 2000 },
  { label: "3 Idiots", year: 2009 },
  { label: "Monty Python and the Holy Grail", year: 1975 },
];

const options = ["1", "2", "3"];
const ListYourBussiness = () => {
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  //fetch location
  const [location, setLocation] = React.useState([]);
  //add location
  const [addLocation, setAddLocation] = React.useState({
    locationName: "",
    locationPincode: "",
    address: "",
  });
  const { locationName, address, locationPincode } = addLocation;
  function handleChange(event) {
    const { name } = event.target;

    const {
      target: { value },
    } = event;
    console.log(name, value);
    setAddLocation((prevvalue) => ({ ...prevvalue, [name]: value }));
  }

  // console.log(value);
  // console.log("res", inputValue);
  function onDataFetch(data) {
    const Names = data.map((value) => value.locationName);

    setLocation(Names);
    return Names;
  }
  const fetchData = async () => {
    try {
      const response = await axios(`${API_AUTH_URL}/getAddedLocations`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          email: "test1@gmail.com",
        },
      });

      if (response && response.status === 200) {
        const data = response.data;
        console.log(data);
        // setOptions(response.data);
        onDataFetch(data);
      }
    } catch (error) {
      console.error(error);
      // onerror(error);
    }
  };
  React.useEffect(() => {
    let isMounted;
    isMounted = true;
    fetchData();
    return () => {
      isMounted = false;
    };
  }, []);
  // Memoize the list of locations so that it doesn't change unnecessarily
  const memoizedLocations = React.useMemo(() => location, [location]);
  console.log(memoizedLocations);
  // const locationNames = PlacesData.map((place) => place.name);
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(value);
  };
  async function handleAddLocation(event) {
    event.preventDefault();
    await axios
      .post(`${API_AUTH_URL}/add_locations`, JSON.stringify(addLocation), {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("response", response.data);
      })

      .catch((error) => console.error(error));
    // console.log(addLocation);

    // await axios.post(`${API_AUTH_URL}/add_locations`);
  }
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} sx={{ backgroundColor: "lightgray" }}>
          <Container maxWidth={"md"} sx={{ p: 1 }}>
            <h2>Search Component</h2>
            <form onSubmit={handleSubmit} method="post">
              <div>
                <div>{`value: ${value !== null ? `'${value}'` : "null"}`}</div>
                <div>{`inputValue: '${inputValue}'`}</div>
                <br />
                <Autocomplete
                  value={value}
                  onChange={(event, newValue) => {
                    setValue(newValue);
                  }}
                  inputValue={inputValue}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
                  id="controllable-states-demo"
                  options={location}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} label="location" />
                  )}
                />
              </div>
              <Button
                type="submit"
                variant="contained"
                sx={{ maxWidth: "min-content" }}
              >
                submit
              </Button>
            </form>

            <Box>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={top100Films}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Movie" />
                )}
              />
              {/* <MyAutoComplete /> */}
            </Box>
          </Container>
        </Grid>
        <Grid item xs={12} md={6} sx={{ backgroundColor: "white" }}>
          <Container maxWidth={"md"} sx={{ p: 1 }}>
            <h2>ADD Location </h2>

            <Box
              component="form"
              sx={{
                "& > :not(style)": { m: 1, width: "25ch" },
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
              }}
              onSubmit={handleAddLocation}
              method="post"
              autoComplete="off"
            >
              <div className="form">
                <label htmlFor="location">locationName</label>
                <TextField
                  type={"text"}
                  id="location"
                  name="locationName"
                  variant="standard"
                  className="form__input"
                  onChange={handleChange}
                  value={locationName}
                />
              </div>
              <div className="form">
                <label htmlFor="location">locationPincode</label>
                <TextField
                  type={"number"}
                  id="location"
                  name="locationPincode"
                  variant="standard"
                  className="form__input"
                  onChange={handleChange}
                  value={locationPincode}
                />
              </div>
              <div className="form">
                <label htmlFor="location">Location address</label>
                <TextField
                  id="location"
                  name="address"
                  variant="standard"
                  className="form__input"
                  onChange={handleChange}
                  value={address}
                />
              </div>
              {/* <div className="form">
                <input
                  type="email"
                  className="form__input"
                  placeholder=" "
                  name="email"
                  id="email"
                  onChange={handleChange}
                  required
                />
                <label htmlFor="email" className="form__label">
                  Email
                </label>
              </div> */}
              <Button
                type="submit"
                variant="text"
                sx={{ backgroundColor: "orangered", fontSize: 14, p: "7px" }}
              >
                Add_location
              </Button>
            </Box>
          </Container>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ListYourBussiness;
