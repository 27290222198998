import styled from "styled-components";

interface QupPfSelectProps {
  label: string;
  value?: any; 
  options: Option[]; 
  onChange: (e: any) => void;
  disabled?: boolean; 
}

const PfSelectWrapper = styled("div")`
  font-size: 14px;
  color: white;
  select {
    width: 100%;
    height: 30px;
  }
`;

export const QupPfSelect: React.FC<QupPfSelectProps> = ({ label, value, options, onChange, disabled }) => {
  return (
    <PfSelectWrapper>
      <div className="select-wrapper">
        <label>{label}</label>
        <select value={value} onChange={onChange} disabled={disabled}>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    </PfSelectWrapper>
  );
};
